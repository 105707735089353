import config from 'config';

import ReactGA from 'react-ga';

export function trackUserSessionOnGa(userId: string) {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      userId
    }
  });
}

export function pageview(url: string) {
  const { gtag } = window as any;

  if (!gtag) {
    return;
  }

  gtag('config', config.GOOGLE_ANALYTICS_ID, {
    page_path: url
  });
}

interface Transaction {
  id: string;
  affiliation: string;
  currency: string;
  tax: string;
  value: string;
  items: {
    id: string;
    name: string;
    quantity: number;
    price: string;
  }[];
}
export function trackTransaction(transaction: Transaction) {
  (window as any).gtag('event', 'purchase', {
    transaction_id: transaction.id,
    affiliation: transaction.affiliation,
    currency: transaction.currency,
    tax: transaction.tax,
    value: transaction.value,
    items: transaction.items
  });
}
