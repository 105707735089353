import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import * as stripe from 'legacy/common/utils/stripe';

import { toActionObject } from '../util/util';
import { resolveError, resolveFormError } from './actionUtil';
import { clearModal } from './modalActions';

export const actionIDs = [
  'ADD_CARD',
  'ADD_CARD_FORM',
  'STRIPE_CARD',
  'EDIT_CARD',
  'GET_DEFAULT_CARD',
  'SET_DEFAULT_CARD',
  'DELETE_CARD'
];

export const types = toActionObject(actionIDs);

/**
 * Adds a new card as a payment method
 * @param {*object} info the token object generated by Stripe
 */
export function addCard(info) {
  return function (dispatch) {
    dispatch({ type: types.ADD_CARD.start });
    stripe
      .getInstance()
      ?.createToken(info.cardNumber, {
        name: info.name,
        address_country: info.country,
        address_zip: info.postalCode
      })
      .then((res) => {
        if (res.error) {
          dispatch({
            type: types.STRIPE_CARD.error,
            payload: res
          });
          dispatch({
            type: types.ADD_CARD_FORM.success,
            payload: {
              FORM_ERROR: res.error.message
            }
          });
        } else {
          bounceApiClient
            .post('/api/payment', {
              token: res.token.id,
              setDefault: info.defaultCard
            })
            .then((response) => {
              dispatch({
                type: types.ADD_CARD.success,
                payload: response.data
              });
              dispatch({
                type: types.ADD_CARD_FORM.success
              });
              dispatch(clearModal());
            })
            .catch((err) => {
              dispatch({
                type: types.ADD_CARD.error,
                payload: resolveError(err)
              });
              dispatch({
                type: types.ADD_CARD_FORM.success,
                payload: {
                  FORM_ERROR: resolveFormError(err)
                }
              });
            });
        }
      });
  };
}

/**
 * Sets a new card as the default payment source
 * @param {*string} card_id id of the card to set as default
 */
export function setDefaultPaymentMethod(cardId) {
  return function (dispatch) {
    dispatch({ type: types.SET_DEFAULT_CARD.start });
    bounceApiClient
      .post('/api/payment/default', { cardId })
      .then((res) => {
        dispatch({
          type: types.SET_DEFAULT_CARD.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.SET_DEFAULT_CARD.error,
          payload: resolveError(err)
        });
      });
  };
}

/**
 * Deletes a payment method from the user account
 * @param {string} card_id the id of the card to delete
 */
export function deleteCard(cardId) {
  return function (dispatch) {
    dispatch({ type: types.DELETE_CARD.start });
    bounceApiClient
      .delete(`/api/payment/${cardId}`)
      .then((res) => {
        dispatch({
          type: types.DELETE_CARD.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.DELETE_CARD.error,
          payload: resolveError(err)
        });
      });
  };
}
