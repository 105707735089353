import { useMutation } from 'react-query';

import {
  appleLogin,
  appleRegister,
  confirmLoginVerificationSession,
  confirmSignupVerificationSession,
  createLoginVerificationSession,
  createSignupVerificationSession,
  emailLogin,
  emailRegister,
  phoneLogin,
  phoneLoginRequest,
  phoneRegister,
  resendLoginVerificationCode,
  resendVerificationEmail,
  resetPasswordRequest,
  updateLoginVerificationSession,
  verifyEmail
} from './service';

import { ErrorResponse } from 'legacy/common/utils/error';

import {
  LoginRequest,
  LoginResponse,
  PhoneLoginRequest,
  PhoneLoginRequestResponse,
  RegisterRequest,
  VerificationSessionDto
} from './types';
import { GenericSuccessResponse } from 'legacy/common/types/response';

export function useEmailLoginCommand() {
  return useMutation<LoginResponse, ErrorResponse, LoginRequest>(emailLogin);
}

export function useEmailRegisterCommand() {
  return useMutation<LoginResponse, ErrorResponse, RegisterRequest>(
    emailRegister
  );
}

export function usePhoneLoginRequestCommand() {
  return useMutation<PhoneLoginRequestResponse, ErrorResponse, string>(
    phoneLoginRequest
  );
}

export function usePhoneLoginCommand() {
  return useMutation<LoginResponse, ErrorResponse, PhoneLoginRequest>(
    phoneLogin
  );
}

export function usePhoneRegisterCommand() {
  return useMutation<LoginResponse, ErrorResponse, PhoneLoginRequest>(
    phoneRegister
  );
}

export function useAppleLoginCommand({
  autoRegister
}: {
  autoRegister: boolean;
}) {
  return useMutation<LoginResponse, ErrorResponse, any>(
    (appleAuthParams: any) => appleLogin(appleAuthParams, autoRegister)
  );
}

export function useAppleRegisterCommand() {
  return useMutation<LoginResponse, ErrorResponse, any>(appleRegister);
}

export function useResetPasswordRequestCommand() {
  return useMutation<void, ErrorResponse, string>(resetPasswordRequest);
}

export function useResendVerificationEmailCommand() {
  return useMutation<void, ErrorResponse, string>(resendVerificationEmail);
}

export function useCreateSignupVerificationSessionCommand() {
  return useMutation<
    VerificationSessionDto,
    ErrorResponse,
    {
      phone: string;
      countryCode: string;
      firstName?: string;
      lastName?: string;
      email?: string;
      country?: string;
    }
  >(createSignupVerificationSession);
}

export function useConfirmSignupVerificationSessionCommand() {
  return useMutation<
    LoginResponse,
    ErrorResponse,
    {
      verificationSessionId: string;
      password?: string;
      smsCode: string;
      mobileDeviceId?: string;
    }
  >(confirmSignupVerificationSession);
}

export function useCreateLoginVerificationSessionCommand() {
  return useMutation<
    VerificationSessionDto | { isLegacyAuthUser: boolean },
    ErrorResponse,
    {
      phoneNumber?: string;
      email?: string;
      countryCode?: string;
    }
  >(createLoginVerificationSession);
}

export function useUpdateLoginVerificationSessionCommand() {
  return useMutation<
    VerificationSessionDto,
    ErrorResponse,
    {
      password: string;
      verificationSessionId: string;
    }
  >(updateLoginVerificationSession);
}

export function useConfirmLoginVerificationSessionCommand() {
  return useMutation<
    LoginResponse,
    ErrorResponse,
    {
      verificationSessionId: string;
      smsCode: string;
    }
  >(confirmLoginVerificationSession);
}

export function useResendLoginVerificationCodeCommand() {
  return useMutation<
    GenericSuccessResponse,
    ErrorResponse,
    {
      verificationSessionId: string;
      deliveryMethod: 'sms' | 'email';
    }
  >(resendLoginVerificationCode);
}

export function useVerifyEmailCommand() {
  return useMutation<{ success: boolean }, ErrorResponse, string>(verifyEmail);
}
