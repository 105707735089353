import classNames from 'classnames';
import styles from './toast.module.scss';
import toast, { ToastOptions } from 'react-hot-toast';
import { MdOutlineWarning } from 'react-icons/md';
import React from 'react';
import { FaCheck } from 'react-icons/fa6';

type NotifyOptions = {
  imageUrl?: string;
  message: string;
  onClick?: () => void;
};

export function notify({ imageUrl, message, onClick }: NotifyOptions) {
  toast(
    <div
      className={styles.container}
      onClick={() => {
        onClick?.();
      }}
    >
      {imageUrl && <img src={imageUrl} />}
      <div className={styles.message}>{message}</div>
    </div>,
    {
      duration: 5000,
      className: styles.toast
    }
  );
}

const config: ToastOptions = {
  position: 'top-center',
  duration: 5000,
  style: {
    marginTop: '50px',
    width: '100%',
    justifyContent: 'flex-start'
  }
};

export function success(title: string, description?: string) {
  toast.success(
    <div>
      <div className="text-base">{title}</div>
      <div className="text-sm text-gray-500">{description}</div>
    </div>,
    config
  );
}

export function error(message: string) {
  toast.error(message, config);
}

export function showToast({
  type,
  icon,
  message
}: {
  type?: string;
  icon?: string;
  message: React.ReactNode;
}) {
  const iconMap = {
    success: <FaCheck className="text-white" />
  };

  toast(
    <div className="flex items-center">
      <div
        className={classNames('p-2 rounded-md bg-primary-50 text-xl mr-3', {
          'bg-green-700': type === 'success'
        })}
      >
        {iconMap?.[type] || icon}
      </div>
      <p className="text-base font-normal">{message}</p>
    </div>,
    config
  );
}

export function showError({ title, message }) {
  toast(
    (t) => (
      <div>
        <div className="flex items-center">
          <div className="p-2 rounded-lg bg-red-50 text-xl mr-2">
            <MdOutlineWarning size={20} className="text-red-700" />
          </div>
          <p className="text-lg font-semibold">{title}</p>
        </div>

        <p className="mt-4 text-base text-gray-600">{message}</p>

        <button
          type="button"
          className="mt-2 py-2 text-base font-semibold text-primary-400"
          onClick={() => {
            toast.dismiss(t.id);
          }}
        >
          Ok, Got it
        </button>
      </div>
    ),
    {
      ...config,
      id: message
    }
  );
}
