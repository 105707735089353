import React from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import styles from './ViewHosts.module.scss';
import ModalBase from '../ModalBase/ModalBase';
import { showQuickView } from '../../../util/modalFlows';

const ViewHosts = (props) => {
  const router = useRouter();
  const { host, cohost, operators, self } = props;

  const userDisplay = (user) => (
    <div
      className="person"
      key={user._id}
      onClick={() => {
        showQuickView(user._id, self._id, router);
      }}
    >
      <div className="pic">
        {user.photo ? (
          <img src={user.photo.url} alt="" />
        ) : (
          <i className="fas fa-user-alt" />
        )}
      </div>
      <div className="name">{`${user.firstName} ${user.lastName}`}</div>
    </div>
  );

  return (
    <ModalBase
      baseModalProps={props.baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={styles.ViewHosts}>
        <h2 className="modalTitle">Event Organizers</h2>
        <h2>{cohost ? 'Hosts' : 'Host'}</h2>
        <div className="host-wrapper">{userDisplay(host)}</div>

        {cohost && <div className="host-wrapper">{userDisplay(cohost)}</div>}

        {operators.length > 0 && (
          <>
            <h2>Operators</h2>
            <div className="operators">{operators.map(userDisplay)}</div>
          </>
        )}
      </div>
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  self: state.user.user
});

export default connect(mapStateToProps, null)(ViewHosts);
