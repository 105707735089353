import React from 'react';
import styles from './HorizontalDivider.module.scss';

export default function HorizontalDivider({
  top = 5,
  bottom = 5,
  style,
  ...props
}) {
  const _style = {
    paddingTop: `${top}px`,
    marginBottom: `${bottom}px`,
    ...style
  };
  return <div className={styles.wrapper} style={_style} {...props} />;
}
