import { UserAllPhoto } from 'legacy/common/types/models';
import { useUploadGalleryPhotoCommand } from 'legacy/user/hooks';

import UploadPhotoButton from './UploadPhotoButton';

type UploadGalleryPhotoButtonProps = {
  label?: string;
  onUpload: (photo: UserAllPhoto) => void;
  ButtonComponent?: React.FC<{ onClick: () => void }>;
};

const UploadGalleryPhotoButton: React.FC<UploadGalleryPhotoButtonProps> = ({
  label,
  onUpload,
  ButtonComponent
}) => {
  const uploadGalleryPhotoCommand = useUploadGalleryPhotoCommand();

  return (
    <UploadPhotoButton
      label={label}
      cropShape="rect"
      aspectRatio={16 / 9}
      ButtonComponent={ButtonComponent}
      isLoading={uploadGalleryPhotoCommand.isLoading}
      errorMessage={uploadGalleryPhotoCommand.error?.message}
      uploadPhoto={async (blob) => {
        await uploadGalleryPhotoCommand.mutateAsync(blob, {
          onSuccess: (data) => {
            onUpload(data.photo);
          }
        });
      }}
    />
  );
};

export default UploadGalleryPhotoButton;
