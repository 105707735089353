import bounceApiClient from 'legacy/common/utils/bounceApiClient';

import {
  LoginRequest,
  LoginResponse,
  PhoneLoginRequest,
  PhoneLoginRequestResponse,
  RegisterRequest,
  VerificationSessionDto
} from './types';
import { GenericSuccessResponse } from 'legacy/common/types/response';

export async function emailLogin(
  credentials: LoginRequest
): Promise<LoginResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/login',
    data: credentials
  });

  return data;
}

export async function emailRegister(
  credentials: RegisterRequest
): Promise<LoginResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/register/email',
    data: credentials
  });

  return data;
}

export async function phoneLoginRequest(
  phoneNumber: string
): Promise<PhoneLoginRequestResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/login/phone/requests',
    data: { phone: phoneNumber }
  });

  return data;
}

export async function phoneLogin(
  credentials: PhoneLoginRequest
): Promise<LoginResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/login/phone',
    params: {
      autoRegister: 0
    },
    data: credentials
  });

  return data;
}

export async function phoneRegister(
  credentials: PhoneLoginRequest
): Promise<LoginResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/register/phone',
    data: credentials
  });

  return data;
}

export async function appleLogin(
  appleAuthParams: any,
  autoRegister: boolean
): Promise<LoginResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/login/apple?platform=web',
    data: appleAuthParams,
    params: autoRegister === false && {
      autoRegister: '0'
    }
  });

  return data;
}

export async function appleRegister(
  appleAuthParams: any
): Promise<LoginResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/register/apple?platform=web',
    data: appleAuthParams
  });

  return data;
}

export async function resetPasswordRequest(email: string): Promise<void> {
  await bounceApiClient({
    method: 'post',
    url: '/auth/resetPasswordRequest',
    data: { email }
  });
}

export async function resendVerificationEmail(email: string): Promise<void> {
  await bounceApiClient({
    method: 'post',
    url: '/auth/resendVerification',
    data: { email }
  });
}

export async function createSignupVerificationSession({
  phone,
  countryCode,
  firstName,
  lastName,
  email,
  country
}: {
  phone: string;
  countryCode: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  country?: string;
}): Promise<VerificationSessionDto> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/register/verificationSessions',
    data: {
      phone,
      countryCode: countryCode ? parseInt(countryCode) : null,
      firstName,
      lastName,
      email,
      country
    }
  });

  return data;
}

export async function confirmSignupVerificationSession({
  verificationSessionId,
  password,
  smsCode
}: {
  verificationSessionId: string;
  password?: string;
  smsCode: string;
}): Promise<LoginResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/auth/register/verificationSessions/${verificationSessionId}/confirm`,
    data: {
      password,
      smsCode
    }
  });

  return data;
}

export async function createLoginVerificationSession({
  phoneNumber,
  email,
  countryCode
}: {
  phoneNumber?: string;
  email?: string;
  countryCode?: string;
}): Promise<VerificationSessionDto | { isLegacyAuthUser: boolean }> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/auth/login/verificationSessions',
    data: {
      phone: phoneNumber,
      email,
      countryCode: countryCode ? parseInt(countryCode) : null
    }
  });

  return data;
}

export async function updateLoginVerificationSession({
  password,
  verificationSessionId
}: {
  password: string;
  verificationSessionId: string;
}): Promise<VerificationSessionDto> {
  const { data } = await bounceApiClient({
    method: 'put',
    url: `/auth/login/verificationSessions/${verificationSessionId}`,
    data: {
      password
    }
  });

  return data;
}

export async function confirmLoginVerificationSession({
  verificationSessionId,
  smsCode
}: {
  verificationSessionId: string;
  smsCode: string;
}): Promise<LoginResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/auth/login/verificationSessions/${verificationSessionId}/confirm`,
    data: {
      smsCode
    }
  });

  return data;
}

export async function resendLoginVerificationCode({
  verificationSessionId,
  deliveryMethod
}: {
  verificationSessionId: string;
  deliveryMethod: 'sms' | 'email';
}): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/auth/login/verificationSessions/${verificationSessionId}/resendCode`,
    data: {
      deliveryMethod
    }
  });

  return data;
}

export async function verifyEmail(
  token: string
): Promise<{ success: boolean }> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/auth/verifyEmail/${token}?redirect=0`
  });

  return data;
}
