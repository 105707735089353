import { identifyUser } from 'legacy/common/utils/heap';
import { useEffect } from 'react';

type User = {
  id: string;
  email: string;
  name: string;
  country: string;
};

const useHeap = (user: User) => {
  useEffect(() => {
    if (!user?.id) {
      return;
    }

    identifyUser(user?.id, user);
  }, [user?.id]);
};

export default useHeap;
