import React from 'react';
import styles from './ScalableComponent.module.scss';

const ScalableComponent = (props) => {
  const wrapperStyle = {
    width: props.width
  };

  let { ratio } = props;
  if (!ratio) {
    if (!props.h || !props.w) {
      ratio = 100;
    } else {
      ratio = props.h / props.w;
    }
  }

  const ratioStyle = {
    paddingTop: `${ratio * 100}%`
  };

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      <div className={styles.scalable} style={ratioStyle}>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
};

export default ScalableComponent;
