import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './FormFields.module.scss';

class ValidatedInput extends React.Component {
  handleChange = (e, input) => {
    // check the validity of the input in the case a "pattern" prop is passed in inputProps
    // if valid, call react-final-form's onChange function like normal
    // this is so the pattern regex is checked every time the user enters a value
    // example usage in AddBank.js
    if (this.props.inputProps && this.props.inputProps.pattern) {
      if (e.target.validity.valid) {
        input.onChange(e);
      }
    } else {
      // default behaviour
      input.onChange(e);
    }
  };

  render() {
    const {
      labelComponent,
      label,
      className,
      style,
      validate,
      name,
      placeholder,
      type,
      component: Component,
      inputProps,
      errorVisible,
      showErrorBeforeTouched,
      errorClassName,
      ...rest
    } = this.props;

    const labelContent = labelComponent || (label && <div>{label}</div>);

    const showError = errorVisible === undefined ? true : errorVisible;
    return (
      <Field
        className={styles.fieldContainer}
        validate={validate}
        name={name}
        {...rest}
        render={({ meta, input }) => (
          <div className={styles.fieldContainer}>
            <div
              className={classnames(styles.field, {
                [styles.hasError]:
                  showError &&
                  meta.error !== undefined &&
                  (meta.touched || showErrorBeforeTouched)
              })}
            >
              {labelContent}
              <Component
                className={classnames(className)}
                style={style}
                placeholder={placeholder}
                type={type}
                {...input}
                onChange={(e) => this.handleChange(e, input)} // overriding onChange in {...input}
                {...inputProps}
                {...(rest.formProps && {
                  formProps: rest.formProps
                })}
              />
            </div>
            {showError &&
              meta.error !== undefined &&
              (meta.touched || showErrorBeforeTouched) && (
                <div
                  className={classnames(
                    styles.error,
                    'form-error',
                    errorClassName
                  )}
                  data-test={`error-${name}`}
                >
                  {meta.error}
                </div>
              )}
          </div>
        )}
      />
    );
  }
}

ValidatedInput.defaultProps = {
  component: 'input'
};

ValidatedInput.propTypes = {
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  max: PropTypes.string,
  type: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  inputProps: PropTypes.object,
  errorVisible: PropTypes.bool
};

export default ValidatedInput;
