import styles from './JoinEvent.module.scss';
import moment from 'moment';
import cn from 'classnames';

import Spinner from '../../util/Spinner/Spinner';
import HorizontalDivider from '../../util/HorizontalDivider/HorizontalDivider';

export const CheckoutPricing = ({
  loading,
  pricing,
  showPricing,
  priceLabel = 'Ticket Price',
  currency
}) =>
  loading ? (
    <Spinner style={{ margin: '20px' }} height={40} />
  ) : (
    showPricing && (
      <>
        <HorizontalDivider top={10} bottom={5} />
        <div className={styles.section}>
          <div className={styles.sectionLabel}>Pricing</div>

          <div className={styles.pricingBreakdown}>
            <div className={styles.pricingItem}>
              <span>{priceLabel}</span>
              <span data-cy="ticket-price">{pricing.price}</span>
            </div>
            {pricing.tip && (
              <div className={styles.pricingItem}>
                <span>Tip</span>
                <span data-cy="ticket-tip">+{pricing.tip}</span>
              </div>
            )}
            <div className={styles.pricingItem}>
              <span>Fees</span>
              <span data-cy="ticket-fees">+{pricing.fees}</span>
            </div>
            <HorizontalDivider top={5} bottom={5} />
            <div className={cn(styles.pricingItem, styles.total)}>
              <span>TOTAL:</span>
              <span data-cy="ticket-total">
                ${pricing.total} {currency}
              </span>
            </div>
          </div>
        </div>
        <HorizontalDivider top={10} bottom={10} />
      </>
    )
  );

export const EventDetails = ({ event }) => (
  <div className={styles.section}>
    <div className={styles.sectionLabel}>
      {event.address ? 'Date & Location' : 'Date'}
    </div>
    <div className={styles.eventDetails}>
      <div className={styles.date}>
        <span className={styles.day}>
          {moment(event.startDate).format('MMM D, YYYY')}
        </span>
        <span className={styles.time}>
          {moment(event.startDate).format('h:mm A')}
        </span>
      </div>
      {event.address && (
        <div className={styles.address}>
          <span>{event.address.split(',')[0]}</span>
        </div>
      )}
    </div>
  </div>
);
