import bounceApiClient from '../common/utils/bounceApiClient';

export async function registerForFCM(
  fcmToken: string
): Promise<Record<string, unknown>> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/api/notifications/push/fcm',
    params: {
      deviceType: 'web'
    },
    data: {
      fcmToken
    }
  });

  return data;
}

export async function unregisterForFCM(
  fcmToken: string
): Promise<Record<string, unknown>> {
  const { data } = await bounceApiClient({
    method: 'delete',
    url: '/api/notifications/push/fcm',
    params: {
      deviceType: 'web'
    },
    data: {
      fcmToken
    }
  });

  return data;
}
