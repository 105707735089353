import React from 'react';

import storage from 'legacy/common/utils/storage';
import { STORAGE_KEYS } from 'legacy/common/constants/storage';

const withLoginCheck = (WrappedComponent: React.FC) => (props: any) => {
  if (!storage.getItem(STORAGE_KEYS.AUTH_TOKEN)) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...props} />;
};

export default withLoginCheck;
