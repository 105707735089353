import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import { toActionObject } from '../util/util';
import { resolveError } from './actionUtil';

export const actionIDs = [
  'GET_NOTIFICATIONS',
  'VIEW_NOTIFICATIONS',
  'DELETE_NOTIFICATION',
  'ADD_NOTIFICATION'
];

export const types = toActionObject(actionIDs);

export function addNotification(notification) {
  return {
    type: types.ADD_NOTIFICATION.id,
    payload: notification
  };
}

export function getNotifications(fromDate) {
  return function (dispatch) {
    bounceApiClient
      .get('/api/notifications', { fromDate })
      .then((res) => {
        dispatch({
          type: types.GET_NOTIFICATIONS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_NOTIFICATIONS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function viewNotifications(notifications) {
  return function (dispatch) {
    bounceApiClient
      .put('/api/notifications/', { notifications })
      .then(() => {
        dispatch({
          type: types.VIEW_NOTIFICATIONS.success,
          payload: notifications
        });
      })
      .catch((err) => {
        dispatch({
          type: types.VIEW_NOTIFICATIONS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function deleteNotification(notificationId) {
  return function (dispatch) {
    bounceApiClient
      .delete(`/api/notifications/${notificationId}`)
      .then(() => {
        dispatch({
          type: types.DELETE_NOTIFICATION.success,
          payload: notificationId
        });
      })
      .catch((err) => {
        dispatch({
          type: types.DELETE_NOTIFICATION.error,
          payload: resolveError(err)
        });
      });
  };
}
