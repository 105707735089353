import React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

import { useGetMe } from 'legacy/user/hooks';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';

type UpdateEmailModalProps = {
  onClose: () => void;
  onSubmit: (data: { email: string }) => void;
};

const UpdateEmailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').required('Required')
});

const UpdateEmailModal: React.FC<UpdateEmailModalProps> = ({
  onClose,
  onSubmit
}) => {
  const { data: user } = useGetMe({
    enabled: false
  });

  return (
    <Modal visible onClose={onClose} type={ModalType.ALERT}>
      <div className="px-8 py-6" data-testid="modal-update-email">
        <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
          {user?.email ? 'Update Email Address' : 'Add Email Address'}
        </h1>
        <p className="mt-4 font-normal text-base text-gray-500 leading-loose">
          Your email address will be linked to your account.
        </p>

        <Formik
          initialValues={{
            email: user?.email ?? ''
          }}
          onSubmit={onSubmit}
          validationSchema={UpdateEmailValidationSchema}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              <>
                {dirty && (
                  <p className="mt-4 p-4 text-gray-500 bg-orange-100 rounded-lg">
                    You will need to re-verify your email to access most
                    platform functionality.
                  </p>
                )}

                <div className="mt-6 mb-2">
                  <p className="text-sm text-gray-400">Email Address</p>
                </div>

                <Field
                  id="email"
                  name="email"
                  type="text"
                  data-testid="input-email"
                  placeholder="Enter your email"
                  className="w-full h-full py-2 px-4 border-1 rounded-2xl text-base text-gray-600 placeholder:text-gray-400"
                />

                {errors.email && touched.email && (
                  <div className="mt-1 text-xs text-right text-red-500">
                    {errors.email}
                  </div>
                )}

                <div className="pl-20 mt-8 flex justify-end">
                  <Button type={ButtonType.Clear} onClick={() => onClose()}>
                    <span className="text-gray-400">Cancel</span>
                  </Button>
                  <Button
                    isSubmit
                    testId="button-submit"
                    disabled={!dirty || !isValid}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdateEmailModal;
