import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import { toActionObject } from '../util/util';
import { resolveError } from './actionUtil';

export const actionIDs = ['SEARCH_ALL', 'SEACH_USERS', 'SEARCH_EVENTS'];

export const types = toActionObject(actionIDs);

export function searchAll(name) {
  return function (dispatch) {
    dispatch({ type: types.SEARCH_ALL.start });
    bounceApiClient
      .get(`/api/search/all`, {
        params: {
          name
        }
      })
      .then((res) => {
        dispatch({
          type: types.SEARCH_ALL.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.SEARCH_ALL.error,
          payload: resolveError(err)
        });
      });
  };
}

export function searchUsers(name) {
  return function (dispatch) {
    dispatch({ type: types.SEARCH_ALL.start });
    bounceApiClient
      .get(`/api/search/users`, {
        params: {
          name
        }
      })
      .then((res) => {
        dispatch({
          type: types.SEARCH_ALL.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.SEARCH_ALL.error,
          payload: resolveError(err)
        });
      });
  };
}

export function searchEvents(name) {
  return function (dispatch) {
    dispatch({ type: types.SEARCH_ALL.start });
    bounceApiClient
      .get(`/api/search/events`, {
        params: {
          name
        }
      })
      .then((res) => {
        dispatch({
          type: types.SEARCH_ALL.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.SEARCH_ALL.error,
          payload: resolveError(err)
        });
      });
  };
}
