import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import styles from './ViewFriends.module.scss';
import ModalBase from '../ModalBase/ModalBase';
import { showQuickView } from '../../../util/modalFlows';

const Person = ({ self, person, router }) => (
  <div
    className={styles.person}
    onClick={() => {
      showQuickView(person._id, self._id, router);
    }}
  >
    <div className="pic">
      {person.photo?.url || person.photoUrl ? (
        <img src={person.photo?.url || person.photoUrl} alt="" />
      ) : (
        <i className="fas fa-user-alt" />
      )}
    </div>
    <div className={styles.guestInfo}>
      <div className="name">
        {person.name || `${person.firstName} ${person.lastName}`}
      </div>
    </div>
  </div>
);

const ViewFriends = ({ self, friends, router, baseModalProps }) => (
  <ModalBase
    baseModalProps={baseModalProps}
    back_img="Back"
    contentClassName="basic"
  >
    <div className={styles.wrapper}>
      <h2 className="modalTitle">Friends List</h2>
      {friends.map((friend) => (
        <Person key={friend._id} self={self} person={friend} router={router} />
      ))}
    </div>
  </ModalBase>
);

const mapStateToProps = (state) => ({
  self: state.user.user
});

export default withRouter(connect(mapStateToProps, null)(ViewFriends));
