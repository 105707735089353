import config from 'config';
import { FullStory, init as initializeFullStory } from '@fullstory/browser';

export function init() {
  initializeFullStory({
    orgId: config.FULLSTORY_ORG_ID
  });
}

export function identifyUser(id: string, properties: Record<string, string>) {
  FullStory('setIdentity', {
    uid: id,
    properties: {
      displayName: properties.name,
      email: properties.email,
      country: properties.country
    }
  });
}
