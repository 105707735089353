import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './JoinEvent.module.scss';
import ModalBase from '../ModalBase/ModalBase';
import BounceButton from '../../util/BounceButton/BounceButton';
import Spinner from '../../util/Spinner/Spinner';

import { hideModal } from 'legacy/actions/modalActions';
import { updateTicketInfo } from 'legacy/actions/eventActions';

const onUnload = (e) => {
  e.preventDefault();
  e.returnValue =
    'Are you sure you want to leave? Google Forms progress will not be saved.';
};

function FormModal({
  baseModalProps,
  shouldPromptBeforeClose,
  event,
  hideModal,
  updateTicketInfo,
  onSuccess
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (shouldPromptBeforeClose) {
      window.addEventListener('beforeunload', onUnload);
      return () => window.removeEventListener('beforeunload', onUnload);
    }

    return undefined;
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      updateTicketInfo({
        eventId: event._id,
        googleFormsAnswered: isSubmitted
      }).then(() => {
        onSuccess?.();
      });

      window.removeEventListener('beforeunload', onUnload);
    }
  }, [isSubmitted]);

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      hideBackButton
      large
      contentClassName="basic"
    >
      <div className={classnames(styles.JoinEvent, 'component')}>
        {isLoading ? (
          <div className="spinner">
            <Spinner height={100} />
          </div>
        ) : null}
        <iframe
          title="Form"
          src={event.googleForms.embedUrl || event.googleForms.url}
          width="100%"
          height="100%"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          onLoad={() => {
            if (isLoading) {
              setIsLoading(false);
            } else if (isLoading === false) {
              setIsSubmitted(true);
            }
          }}
        />
        {isLoading ? null : (
          <p className="googleFormsLink">
            Having trouble filling out the form?{' '}
            <a
              href={event.googleForms.url}
              target="_blank"
              rel="noreferrer"
              onClick={() => setIsSubmitted(true)}
              data-cy="forms-link"
            >
              Click here{' '}
            </a>
            to access the Google Form directly.
          </p>
        )}
        <div className="buttonRow">
          <BounceButton
            className="alreadyCompletedButton"
            onClick={() => {
              setIsSubmitted(true);
              hideModal();
            }}
          >
            ALREADY COMPLETED
          </BounceButton>
          <BounceButton
            primary
            disabled={!isSubmitted}
            onClick={() => {
              hideModal();
            }}
          >
            CLOSE
          </BounceButton>
        </div>
      </div>
    </ModalBase>
  );
}

FormModal.defaultProps = {
  shouldPromptBeforeClose: true
};

function mapStateToProps(state) {
  return { events: state.events.events };
}

const actions = { updateTicketInfo, hideModal };

export default connect(mapStateToProps, actions)(FormModal);
