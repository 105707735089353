import React, { useState } from 'react';

import { useUpdateEmailCommand } from 'legacy/user/hooks';

import * as toast from 'legacy/common/utils/toast';

import UpdateEmailModal from './UpdateEmalModal';
import ConfirmPassword from '../ConfirmPasswordModal';

type UpdateEmailFlowProps = {
  onSuccess: () => void;
  onClose: () => void;
};

const UpdateEmailFlow: React.FC<UpdateEmailFlowProps> = ({
  onClose,
  onSuccess
}) => {
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(true);

  const [newEmail, setNewEmail] = useState<string>();

  const handleSubmit = ({ email }: { email: string }) => {
    setNewEmail(email);
    setShowUpdateEmailModal(false);
  };

  const reset = () => {
    setNewEmail('');
    updateEmailCommand.reset();

    onClose();
  };

  const updateEmailCommand = useUpdateEmailCommand();
  const handleConfirm = ({ password }: { password: string }) => {
    updateEmailCommand.mutate(
      {
        email: newEmail,
        password
      },
      {
        onSuccess: () => {
          reset();
          onSuccess();

          toast.success('Email Address Updated');
        }
      }
    );
  };

  return (
    <>
      {showUpdateEmailModal && (
        <UpdateEmailModal
          onSubmit={handleSubmit}
          onClose={() => {
            reset();
            setShowUpdateEmailModal(false);
          }}
        />
      )}

      {newEmail && (
        <ConfirmPassword
          errorMessage={updateEmailCommand.error?.message}
          isLoading={updateEmailCommand.isLoading}
          onSubmit={handleConfirm}
          onClose={reset}
        />
      )}
    </>
  );
};

export default UpdateEmailFlow;
