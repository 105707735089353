export const actionIDs = ['REPORT_DEVICE', 'STRIPE_LOADED'];

export const types = {
  REPORT_DEVICE: 'REPORT_DEVICE',
  STRIPE_LOADED: 'STRIPE_LOADED'
};

export function reportDevice(val) {
  return { type: types.REPORT_DEVICE, payload: val };
}

export function stripeHasLoaded() {
  return { type: types.STRIPE_LOADED };
}
