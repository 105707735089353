import React from 'react';
import cn from 'classnames';
import inputStyles from './ModalInput.module.scss';

export const ModalInput = ({ children, className, style, ...props }) => (
  // eslint-disable-next-line react/void-dom-elements-no-children
  <input
    {...props}
    className={cn(inputStyles.wrapper, className)}
    style={style}
  >
    {children}
  </input>
);

export const ModalSelect = ({ children, className, style, ...props }) => (
  <select
    {...props}
    className={cn(inputStyles.wrapper, className)}
    style={style}
  >
    {children}
  </select>
);

export default { ModalInput, ModalSelect };
