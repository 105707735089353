import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'next/router';
import AddToCalendar from 'react-add-to-calendar';
import moment from 'moment';
import { connect } from 'react-redux';
import styles from './Ticket.module.scss';
import ModalBase from '../ModalBase/ModalBase';
import HorizontalDivider from '../../util/HorizontalDivider/HorizontalDivider';
import { hideModal } from 'legacy/actions/modalActions';

const Ticket = ({
  qrCodePhotoUrl,
  event,
  tier,
  router,
  baseModalProps,
  device,
  hideModal
}) => {
  const startDateMoment = moment(event.startDate);

  const goToEvent = () => {
    hideModal();
    router.push(`/events/${event._id}`);
  };

  const { tiers } = event.pricing;
  const tierDetails = tiers.find((t) => t._id === tier);
  const tierName = tierDetails && tierDetails.name;

  let ticketLabel = 'Ticket';
  if (tierName) {
    ticketLabel += ` - ${tierName}`;
  }

  const calendarIcon = { textOnly: 'none' };
  let calendarEvent;
  if (event) {
    // Set endDateMoment to end of day if event has no scheduled end date
    // Moment does not return the correct object when chaining promises - we have to clone the startDate
    let endDateMoment;
    if (event.endDate) {
      endDateMoment = moment(event.endDate);
    } else {
      endDateMoment = startDateMoment.clone();
      endDateMoment.add(1, 'days').startOf('day');
    }

    calendarEvent = {
      title: event.title,
      description: event.description,
      location: event.address,
      startTime: startDateMoment,
      endTime: endDateMoment
    };
  }

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={classnames(device, [styles.Ticket])}>
        <h2 className="modalTitle">Your Ticket to {event.title}</h2>
        <div className={styles.section}>
          <div className={styles.eventDetailsContainer}>
            <div className={styles.sectionLabel}>Event Details</div>
            <div className="calendarContainer">
              <AddToCalendar
                event={calendarEvent}
                buttonTemplate={calendarIcon}
                buttonLabel="Add to My Calendar"
                dropdownClass="dropdown"
                listItems={[
                  { google: 'Google Calendar' },
                  { outlookcom: 'Outlook.com' },
                  { outlook: 'Outlook (Desktop)' },
                  { apple: 'Apple Calendar' },
                  { yahoo: 'Yahoo Calendar' }
                ]}
              />
            </div>
          </div>
          <div className={styles.eventDetails}>
            <div className={styles.date}>
              <span>
                {startDateMoment.format('MMM D, YYYY')}{' '}
                {startDateMoment.format('h:mm A')}
              </span>
              {event.address && (
                <div className={styles.address}>
                  <span>{event.address.split(',')[0]}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <HorizontalDivider top={5} bottom={10} />
        <div className={styles.qrSection}>
          <div className={styles.sectionLabel}>{ticketLabel}</div>
          <div className="ticket-wrapper">
            {qrCodePhotoUrl && (
              <img
                src={qrCodePhotoUrl}
                alt="QR Code"
                style={{
                  width: '100%',
                  height: '100%'
                }}
              />
            )}
          </div>
        </div>
        <div className="bn-btn primary" onClick={goToEvent}>
          VIEW EVENT
        </div>
      </div>
    </ModalBase>
  );
};

export default withRouter(connect(null, { hideModal })(Ticket));
