import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import viewStyles from '../ViewGuests/ViewGuests.module.scss';
import styles from './ViewOrgMembers.module.scss';
import ModalBase from '../ModalBase/ModalBase';
import { showQuickView } from '../../../util/modalFlows';

const Person = ({ self, person, history }) => (
  <div
    className={viewStyles.person}
    onClick={() => {
      showQuickView(person._id, self._id, history);
    }}
  >
    <div className="pic">
      {person.photo?.url || person.photoUrl ? (
        <img src={person.photo?.url || person.photoUrl} alt="" />
      ) : (
        <i className="fas fa-user-alt" />
      )}
    </div>
    <div className={viewStyles.guestInfo}>
      <div className="name">
        {person.name || `${person.firstName} ${person.lastName}`}
      </div>
    </div>
  </div>
);

const ViewOrgMembers = ({ self, members, router, baseModalProps }) => {
  const admins = members.filter(({ role }) => role === 'admin');
  const hosts = members.filter(({ role }) => role === 'host');

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={styles.wrapper}>
        <h2 className="modalTitle">Members</h2>
        <h3>Admin</h3>
        {admins.map(({ user }) => (
          <Person key={user._id} self={self} person={user} router={router} />
        ))}
        {hosts.length > 0 && <h3>Hosts</h3>}
        {hosts.map(({ user }) => (
          <Person key={user._id} self={self} person={user} router={router} />
        ))}
      </div>
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  self: state.user.user
});

export default withRouter(connect(mapStateToProps, null)(ViewOrgMembers));
