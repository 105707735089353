import styles from 'legacy/common/components/form/Form.module.scss';

import React, { useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';

type ConfirmPhoneNumberModalProps = {
  errorMessage: string;
  isLoading: boolean;
  isResendingCode: boolean;
  onClose: () => void;
  onSubmit: (code: string) => void;
  onResendCode: () => void;
};

const ConfirmPhoneNumberModal: React.FC<ConfirmPhoneNumberModalProps> = ({
  errorMessage,
  isLoading,
  isResendingCode,
  onClose,
  onSubmit,
  onResendCode
}) => {
  const [code, setCode] = useState('');

  return (
    <Modal visible type={ModalType.ALERT}>
      <div className="px-8 py-6" data-testid="modal-confirm-phone-number">
        <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
          Confirmation
        </h1>
        <p className="mt-4 font-normal text-base text-gray-500 leading-loose">
          We sent a confirmation code to your phone number.
        </p>

        {errorMessage && (
          <div className="mt-2 text-red-500">{errorMessage}</div>
        )}
        <div className="mt-6 mb-2">
          <p className="text-sm text-gray-400 font-normal">Verification Code</p>
        </div>

        <ReactCodeInput
          fields={4}
          disabled={isResendingCode}
          fieldWidth={45}
          fieldHeight={60}
          className={styles.codeInput}
          onComplete={(val) => {
            onSubmit(val);
            setCode(val);
          }}
          placeholder={['#', '#', '#', '#']}
        />

        <div className="mt-6 text-center text-gray-400 font-normal">
          {isResendingCode ? (
            'Sending Again...'
          ) : (
            <>
              {"Didn't receive? "}
              <span
                className="text-primary-500 font-semibold cursor-pointer"
                onClick={onResendCode}
              >
                Send Again
              </span>
            </>
          )}
        </div>

        <div className="pl-20 mt-8 flex justify-end">
          <Button type={ButtonType.Clear} onClick={() => onClose()}>
            <span className="text-gray-400">Cancel</span>
          </Button>
          <Button
            disabled={isResendingCode || !code}
            loading={isLoading}
            onClick={() => {
              onSubmit(code);
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPhoneNumberModal;
