import axios from 'axios';
import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import * as Sentry from '@sentry/nextjs';
import { omitBy, isUndefined } from 'lodash';
import { toActionObject } from '../util/util';
import { createAxiosRequest, resolveError } from './actionUtil';

export const actionIDs = [
  'GET_NEARBY_EVENTS',
  'SEARCH_EVENTS',
  'GET_EVENT',
  'GET_EVENT_GUESTS',
  'CHECK_TICKET_NOTES_STATUS',
  'CANCEL_TICKET',
  'GET_ACTIVE_TICKETS',
  'GET_EVENT_INVITES',
  'DECLINE_EVENT_INVITE',
  'VALIDATE_EVENT_URL',
  'GET_LOCATION',
  'ESTIMATE_LOCATION',
  'PUBLIC_GET_EVENT',
  'GET_PRICE_BREAKDOWN',
  'VERIFY_TIER_TOKEN',
  'UPLOAD_PHOTO_TO_GALLERY',
  'UPLOAD_PHOTO_TO_GALLERY_FORM',
  'DELETE_PHOTO_FROM_GALLERY',
  'CLEAR_EVENT_CHECKOUT_ERRORS',
  'START_CHECKOUT_SESSION',
  'UPDATE_CHECKOUT_SESSION',
  'CONFIRM_CHECKOUT_SESSION',
  'UPDATE_TICKET_INFO',
  'REMOVE_REFUNDED_GUEST',
  'INVITE_GROUP'
];

export const types = toActionObject(actionIDs);

export function getUserLocation() {
  function estimateLocation() {
    return axios
      .get(
        'https://geolocation-db.com/json/42e6a770-b3ac-11e9-80ca-c95181800da7',
        {
          // Remove authorization header when calling geo-ip url
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authorization;
              return data;
            }
          ]
        }
      )
      .then(({ data }) => ({ lat: data.latitude, long: data.longitude }));
  }
  return function (dispatch) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            long: position.coords.longitude
          };
          dispatch({
            type: types.GET_LOCATION.success,
            payload: location
          });
        },
        () => {
          estimateLocation()
            .then((loc) =>
              dispatch({
                type: types.GET_LOCATION.success,
                payload: loc
              })
            )
            .catch((e) => {
              Sentry.withScope((scope) => {
                scope.setLevel('warning');
                Sentry.captureException(e);
              });
              dispatch({
                type: types.GET_LOCATION.error,
                payload: {
                  error: {
                    type: 'GEO_ERROR',
                    message: 'Geo Error Occurred'
                  }
                }
              });
            });
        }
      );
    } else {
      estimateLocation()
        .then((loc) =>
          dispatch({
            type: types.GET_LOCATION.success,
            payload: loc
          })
        )
        .catch((e) => {
          Sentry.withScope((scope) => {
            scope.setLevel('warning');
            Sentry.captureException(e);
          });
          dispatch({
            type: types.GET_LOCATION.error,
            payload: {
              error: {
                type: 'GEO_ERROR',
                message: 'Geo Error Occurred'
              }
            }
          });
        });
    }
  };
}

export function getNearbyEvents({ lat, long, range = 50000 }) {
  return function (dispatch) {
    dispatch({ type: types.GET_NEARBY_EVENTS.start });
    bounceApiClient
      .get(`/api/events/nearby/?long=${long}&lat=${lat}&range=${range}`)
      .then((res) => {
        dispatch({
          type: types.GET_NEARBY_EVENTS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_NEARBY_EVENTS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getEvent(event) {
  return function (dispatch) {
    dispatch({ type: types.GET_EVENT.start });
    if (typeof event === 'string') {
      bounceApiClient
        .get(`/api/events/${event}`)
        .then((res) => {
          dispatch({
            type: types.GET_EVENT.success,
            payload: res.data
          });
        })
        .catch((err) => {
          dispatch({
            type: types.GET_EVENT.error,
            payload: resolveError(err)
          });
        });
    } else {
      dispatch({ type: types.GET_EVENT.success, payload: event });
    }
  };
}

export function getEventGuests(eventId) {
  return function (dispatch) {
    bounceApiClient
      .get(`/api/events/${eventId}/guests/search`, { params: { name: '' } })
      .then((res) => {
        dispatch({
          type: types.GET_EVENT_GUESTS.success,
          payload: { _id: eventId, guests: res.data }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_EVENT_GUESTS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function checkTicketNotesStatus(eventId) {
  return createAxiosRequest({
    action: 'get',
    url: `/api/events/${eventId}/ticketInfoRequired`,
    dispatchType: types.CHECK_TICKET_NOTES_STATUS,
    generatePayload: (data) => ({
      _id: eventId,
      infoRequired: data
    })
  });
}

export function cancelTicket(eventId) {
  return function (dispatch) {
    bounceApiClient
      .delete(`/api/events/${eventId}/guests`)
      .then((res) => {
        dispatch({
          type: types.CANCEL_TICKET.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.CANCEL_TICKET.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getActiveTickets() {
  return function (dispatch) {
    dispatch({ type: types.GET_ACTIVE_TICKETS.start });
    bounceApiClient
      .get('/api/events/tickets')
      .then((res) => {
        dispatch({
          type: types.GET_ACTIVE_TICKETS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_ACTIVE_TICKETS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getEventInvites() {
  return function (dispatch) {
    bounceApiClient
      .get(`/api/events/invites`)
      .then((res) => {
        dispatch({
          type: types.GET_EVENT_INVITES.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_EVENT_INVITES.error,
          payload: resolveError(err)
        });
      });
  };
}

/**
 *
 * @param {*} eventId
 */
export function declineEventInvite(eventId) {
  return function (dispatch) {
    bounceApiClient
      .delete(`/api/events/invites/${eventId}`)
      .then((res) => {
        dispatch({
          type: types.DECLINE_EVENT_INVITE.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.DECLINE_EVENT_INVITE.error,
          payload: resolveError(err)
        });
      });
  };
}

export function validateEventUrl(eventId, shareToken) {
  return function (dispatch) {
    dispatch({ type: types.VALIDATE_EVENT_URL.start });
    bounceApiClient
      .post(`/api/events/${eventId}/invites/url`, {
        shareToken
      })
      .then((res) => {
        dispatch({
          type: types.VALIDATE_EVENT_URL.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.VALIDATE_EVENT_URL.error,
          payload: resolveError(err)
        });
      });
  };
}

export function publicGetEvent(_id) {
  return (dispatch) => {
    dispatch({ type: types.PUBLIC_GET_EVENT.start });
    bounceApiClient
      .get(`/service/events/${_id}`)
      .then((res) => {
        dispatch({
          type: types.PUBLIC_GET_EVENT.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.PUBLIC_GET_EVENT.error,
          payload: resolveError(err)
        });
      });
  };
}

export function publicGetPrivateEvent(_id, options) {
  return (dispatch) => {
    dispatch({ type: types.PUBLIC_GET_EVENT.start });
    bounceApiClient
      .get(`/service/events/${_id}`, {
        params: {
          shareToken: options.shareToken
        }
      })
      .then((res) => {
        dispatch({
          type: types.PUBLIC_GET_EVENT.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.PUBLIC_GET_EVENT.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getPriceBreakdown(eventId) {
  return createAxiosRequest({
    action: 'get',
    url: `/api/events/${eventId}/pricing`,
    dispatchType: types.GET_PRICE_BREAKDOWN,
    generatePayload: (data) => ({ _id: eventId, priceBreakdown: data })
  });
}

export function verifyTierToken(eventId, tier, tierShareToken) {
  return createAxiosRequest({
    action: 'post',
    url: `/api/events/${eventId}/verifyTierToken`,
    dispatchType: types.VERIFY_TIER_TOKEN,
    params: { tier, tierShareToken },
    generatePayload: (data) => ({ _id: eventId, tier, valid: data.valid })
  });
}

export const deletePhotoFromGallery = (_id, photoId) =>
  createAxiosRequest({
    action: 'delete',
    url: `/api/events/${_id}/gallery/${photoId}`,
    dispatchType: types.DELETE_PHOTO_FROM_GALLERY,
    generatePayload: (data) => ({ ...data, _id, photo_id })
  });

export const uploadPhotoToGallery = (_id, formData, url, caption) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/events/${_id}/gallery`,
    params: formData,
    dispatchType: types.UPLOAD_PHOTO_TO_GALLERY,
    formType: types.UPLOAD_PHOTO_TO_GALLERY_FORM,
    generatePayload: (data) => ({
      ...data,
      _id,
      photo: { photo: url, caption }
    })
  });

export const clearEventCheckoutErrors = () => (dispatch) =>
  dispatch({
    type: types.CLEAR_EVENT_CHECKOUT_ERRORS.success
  });

export const startCheckoutSession = (eventId) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/events/${eventId}/checkout`,
    dispatchType: types.START_CHECKOUT_SESSION,
    generatePayload: (data) => ({ eventId, session: data })
  });

export const confirmCheckoutSession = (eventId, paymentIntentId) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/events/${eventId}/checkout/${paymentIntentId}/confirm`,
    dispatchType: types.CONFIRM_CHECKOUT_SESSION,
    generatePayload: (data) => ({ eventId, ticket: data })
  });

export const updateCheckoutSession = ({
  eventId,
  updates,
  checkoutSession,
  tierShareToken
}) => {
  const { card, tier, tip, paymentIntentId } = checkoutSession;
  const reqData = omitBy(
    { card, tier, tip, tierShareToken, ...updates },
    isUndefined
  );

  return createAxiosRequest({
    action: 'put',
    url: `/api/events/${eventId}/checkout/${paymentIntentId}`,
    params: reqData,
    dispatchType: types.UPDATE_CHECKOUT_SESSION,
    generatePayload: (data) => ({ eventId, session: data })
  });
};

export const updateTicketInfo = ({
  eventId,
  guestAnswers,
  googleFormsAnswered
}) =>
  createAxiosRequest({
    action: 'put',
    url: `/api/events/${eventId}/guests/ticketInfo`,
    params: omitBy({ guestAnswers, googleFormsAnswered }, isUndefined),
    dispatchType: types.UPDATE_TICKET_INFO,
    generatePayload: () => ({
      _id: eventId,
      ...omitBy({ guestAnswers, googleFormsAnswered }, isUndefined)
    })
  });

export const removeRefundedGuest = (eventId, userId) => (dispatch) =>
  dispatch({
    type: types.REMOVE_REFUNDED_GUEST.success,
    payload: { _id: eventId, userId }
  });
