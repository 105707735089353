import React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';
import { MigrationRequirementsDto } from 'legacy/user/types';
import { useGetMe } from 'legacy/user/hooks';
import { isPrivateAppleRelayEmailAddress } from 'legacy/common/utils/apple';

type UpdateEmailModalProps = {
  isLoading?: boolean;
  errorMessage?: string;
  migrationRequirements: MigrationRequirementsDto;
  onClose: () => void;
  onSubmit: (data: { email: string }) => void;
};

const UpdateEmailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').required('Required')
});

const UpdateEmailModal: React.FC<UpdateEmailModalProps> = ({
  isLoading,
  migrationRequirements,
  errorMessage,
  onClose,
  onSubmit
}) => {
  const { data: user } = useGetMe({
    enabled: false
  });

  const hasEmail = (email: string) => {
    if (!email) {
      return false;
    }

    return user?.email && !isPrivateAppleRelayEmailAddress(user.email);
  };

  const getHeaderMessage = () => {
    if (hasEmail(user?.email)) {
      return 'Confirm Email Address';
    }

    return 'Add Email Address';
  };

  const getMessage = () => {
    if (hasEmail(user?.email)) {
      return 'Please confirm your email address.';
    }

    return 'Your email address will be linked to your account.';
  };

  const getWarningMessage = ({ dirty }) => {
    /* For users adding a new email */
    if (!hasEmail(user?.email)) {
      return (
        <p className="mt-4 p-4 text-gray-500 bg-orange-100 rounded-lg">
          You will need to verify your email after adding it to re-access most
          platform functionality.
        </p>
      );
    }

    /* For updating a verified email */
    if (dirty && user?.emailVerified) {
      return (
        <p className="mt-4 p-4 text-gray-500 bg-orange-100 rounded-lg">
          You will need to re-verify your email to access most platform
          functionality.
        </p>
      );
    }

    /* For unverified email */
    if (!user?.emailVerified) {
      return (
        <p className="mt-4 p-4 text-gray-500 bg-orange-100 rounded-lg">
          You will need to verify your email after confirming it to re-access
          most platform functionality.
        </p>
      );
    }

    return null;
  };

  return (
    <Modal
      visible
      onClose={onClose}
      type={ModalType.ALERT}
      shouldStopModalClose={isLoading}
    >
      <div className="px-8 py-6" data-testid="modal-update-email">
        <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
          {getHeaderMessage()}
        </h1>
        <p className="my-4 font-normal text-base text-gray-500 leading-loose">
          {getMessage()}
        </p>

        <Formik
          initialValues={{
            email:
              !!user?.email && !isPrivateAppleRelayEmailAddress(user?.email)
                ? user.email
                : ''
          }}
          onSubmit={onSubmit}
          validationSchema={UpdateEmailValidationSchema}
          enableReinitialize
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              {getWarningMessage({ dirty })}

              {migrationRequirements.hasConnectedFacebookAccount && (
                <p className="-mt-4 p-4 text-gray-500 bg-orange-100 rounded-lg">
                  We detected that you have a connected Facebook account. Bounce
                  will no longer support login through Facebook in near future.
                </p>
              )}

              {migrationRequirements.hasConnectedAppleAccount && (
                <p className="-mt-4 p-4 text-gray-500 bg-orange-100 rounded-lg">
                  We detected that you have a connected Apple account. Bounce
                  will no longer support login through Apple in near future.
                </p>
              )}

              {errorMessage && (
                <div className="mt-4 text-red-500">{errorMessage}</div>
              )}

              <div className="mt-6 mb-2">
                <p className="text-sm text-gray-400">Email Address</p>
              </div>

              <Field
                id="email"
                name="email"
                type="text"
                disabled={isLoading}
                data-testid="input-email"
                placeholder="Enter your email"
                className="w-full h-full py-2 px-4 border-1 rounded-2xl text-base text-gray-600 placeholder:text-gray-400"
              />

              {errors.email && touched.email && (
                <div className="mt-1 text-xs text-right text-red-500">
                  {errors.email}
                </div>
              )}

              <div className="pl-20 mt-8 flex justify-end">
                <Button
                  disabled={isLoading}
                  type={ButtonType.Clear}
                  onClick={() => onClose()}
                >
                  <span className="text-gray-400">Cancel</span>
                </Button>
                <Button
                  isSubmit
                  loading={isLoading}
                  testId="button-submit"
                  disabled={!isValid}
                >
                  Confirm
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpdateEmailModal;
