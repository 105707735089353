import bounceApiClient from 'legacy/common/utils/bounceApiClient';

import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';

import { toActionObject } from '../util/util';
import {
  createAxiosRequest,
  resolveError,
  resolveFormError
} from './actionUtil';

export const actionIDs = [
  'CREATE_EVENT',
  'EVENT_FORM',
  'EDIT_EVENT',
  'UPLOAD_COVER_PHOTO',
  'DELETE_EVENT',
  'SCAN_TICKET',
  'GET_HOSTED_FB_EVENTS',
  'VERIFY_EVENT_CODE',
  'ADD_OPERATORS',
  'DELETE_OPERATOR',
  'GET_AVAILABLE_OPERATORS',
  'GET_GUEST_BREAKDOWN',
  'TIER_FORM',
  'GET_FB_EVENT_DETAILS',
  'FB_DETAILS_FORM',
  'RETURN_TO_IMPORT',
  'GET_AVAILABLE_COHOSTS',
  'ADD_COHOST',
  'REMOVE_COHOST'
];

export const types = toActionObject(actionIDs);

export function createEvent(event) {
  return createAxiosRequest({
    action: 'post',
    url: `/api/hosts/events/`,
    params: event,
    dispatchType: types.CREATE_EVENT,
    formType: types.EVENT_FORM
  });
}

export function editEvent(eventId, data, options) {
  const formattedData = _cloneDeep(data);
  // Only edit the position field, url field handled by uploadPhoto
  if (formattedData.photo) {
    formattedData.photo = _pick(formattedData.photo, 'position');
  }

  return createAxiosRequest({
    action: 'put',
    url: `/api/hosts/events/${eventId}`,
    params: formattedData,
    dispatchType: types.EDIT_EVENT,
    formType: types.EVENT_FORM,
    onSuccess: () => {
      if (options.onSuccess) {
        options.onSuccess();
      }
    }
  });
}

export function uploadPhoto(eventId, formData, onSuccess) {
  return createAxiosRequest({
    action: 'post',
    url: `/api/hosts/events/${eventId}/photo`,
    params: formData,
    dispatchType: types.UPLOAD_COVER_PHOTO,
    generatePayload: (data) => ({ ...data, _id: eventId }),
    onSuccess
  });
}

export function deleteEvent(eventId, { onSuccess }) {
  return createAxiosRequest({
    action: 'delete',
    url: `/api/hosts/events/${eventId}`,
    dispatchType: types.DELETE_EVENT,
    payload: eventId,
    onSuccess
  });
}

export function scanTicket(eventId, qrCodeString) {
  return function (dispatch) {
    dispatch({ type: types.SCAN_TICKET.start });
    bounceApiClient
      .post(`/api/hosts/events/${eventId}/scanTicket`, {
        qrCodeString
      })
      .then((res) => {
        dispatch({
          type: types.SCAN_TICKET.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.SCAN_TICKET.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getHostedFbEvents() {
  return function (dispatch) {
    dispatch({ type: types.GET_HOSTED_FB_EVENTS.start });
    bounceApiClient
      .get('/api/hosts/events/fb')
      .then((res) => {
        dispatch({
          type: types.GET_HOSTED_FB_EVENTS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_HOSTED_FB_EVENTS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function verifyEventCode(code) {
  return function (dispatch) {
    dispatch({ type: types.VERIFY_EVENT_CODE.start });
    bounceApiClient
      .get(`/api/codes/events/verify/${code}`)
      .then((res) => {
        dispatch({
          types: types.VERIFY_EVENT_CODE.success,
          code,
          valid: res.data.valid
        });
      })
      .catch((err) => {
        dispatch({
          type: types.VERIFY_EVENT_CODE.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getGuestBreakdown(eventId) {
  return function (dispatch) {
    dispatch({ type: types.GET_GUEST_BREAKDOWN.start });
    bounceApiClient
      .get(`/api/hosts/events/${eventId}/guests/search`, {
        params: { name: '' }
      })
      .then((res) => {
        dispatch({
          type: types.GET_GUEST_BREAKDOWN.success,
          payload: { _id: eventId, guestBreakdown: res.data }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_GUEST_BREAKDOWN.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getFbEventDetails({ url, fbid }) {
  const query = url ? `url=${url}` : `fbid=${fbid}`;
  return function (dispatch) {
    dispatch({ type: types.GET_FB_EVENT_DETAILS.start });
    bounceApiClient
      .get(`/api/hosts/events/fb/details?${query}`)
      .then((res) => {
        dispatch({
          type: types.GET_FB_EVENT_DETAILS.success,
          payload: res.data
        });
        dispatch({ type: types.FB_DETAILS_FORM.success });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_FB_EVENT_DETAILS.error,
          payload: resolveError(err)
        });
        dispatch({
          type: types.FB_DETAILS_FORM.success,
          payload: {
            FORM_ERROR: resolveFormError(err)
          }
        });
      });
  };
}

export function returnToImport() {
  return function (dispatch) {
    dispatch({ type: types.RETURN_TO_IMPORT.success });
  };
}
