import axios from 'axios';

import config from 'config';

/**
 * Http Utility.
 */
const bounceApiClient = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Version: config.APP_VERSION
  }
});

export default bounceApiClient;
