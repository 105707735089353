import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styles from './ChoosePaymentMethod.module.scss';
import { modalBack } from 'legacy/actions/modalActions';

import Card from '../../util/Card/Card';
import ModalBase from '../ModalBase/ModalBase';

class ChoosePaymentMethod extends Component {
  constructor(props) {
    super();
    let { selectedCard } = props;
    const { cards } = props;
    // if none is passed, look for a default card (this should never be needed, but safer to have)
    if (!selectedCard) {
      selectedCard = cards.find((card) => card.default) || cards[0];
    }

    this.state = {
      selectedCard
    };
  }

  render() {
    const cards = this.props.cards.map((card) => (
      <div
        key={card._id}
        onClick={() => this.setState({ selectedCard: card })}
        className={classnames('card', {
          active: this.state.selectedCard._id === card._id
        })}
      >
        <Card card={card} width="80%" />
      </div>
    ));

    const { onCardSelect } = this.props;

    const onDone = () => {
      onCardSelect(this.state.selectedCard);
    };

    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        contentClassName="basic"
        // if user clicks back, still persist selected card
        onBack={onDone}
      >
        <div className={classnames(styles.ChoosePaymentMethod, 'component')}>
          <div className="cards">{cards}</div>

          <div className="bn-btn plain" onClick={onDone}>
            SELECT CARD
          </div>
        </div>
      </ModalBase>
    );
  }
}

function mapStateToProps(state) {
  return {
    cards: state.payment.cards
  };
}

const actions = { modalBack };

export default connect(mapStateToProps, actions)(ChoosePaymentMethod);
