import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/userActions';
import { types as friendTypes } from 'legacy/actions/friendActions';
import { types as connectTypes } from 'legacy/actions/connectActions';
import { toActionID } from '../util/util';

export const initialState = {
  user: false,
  users: {},
  chatTokens: {},
  // transactions: [],
  outgoingTransactions: [],
  incomingTransactions: [],
  fbLoaded: false,
  appleLoaded: false,
  searchResults: [],
  newsFeed: [],
  errors: _.mapValues(types, () => ({})),
  loading: _.mapValues(types, () => false),
  editSuggestions: {}
};

// INITIAL_LOGIN should start as true
initialState.loading[types.INITIAL_LOGIN.id] = true;

// Allow specific sister types from other actions
const SISTER_TYPES = [
  friendTypes.SEND_FRIEND_REQUEST.success,
  friendTypes.ACCEPT_FRIEND_REQUEST.success,
  friendTypes.DECLINE_FRIEND_REQUEST.success,
  friendTypes.REMOVE_FRIEND.success,
  connectTypes.ADD_BANK_MANUALLY.success,
  connectTypes.ADD_PLAID_BANK.success,
  connectTypes.UPDATE_ACCOUNT_VERIFICATION.success
];

export function userReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  let newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
  }
  switch (action.type) {
    case types.UNAUTHORIZED_USER.success:
      // Hack to handle showing the public event header for unauthorized users
      newState.loading.INITIAL_LOGIN = false;
      return newState;
    case types.LOGOUT.success:
      newState = {
        ..._.cloneDeep(initialState),
        fbLoaded: newState.fbLoaded
      };
      newState.loading.INITIAL_LOGIN = false;
      return newState;
    case friendTypes.SEND_FRIEND_REQUEST.success:
      newState.users[action.payload.user._id].friendStatus = 'requestSent';
      return newState;
    case friendTypes.ACCEPT_FRIEND_REQUEST.success:
      newState.users[action.payload.user._id].friendStatus = 'friends';

      if (newState.user) {
        newState.user.friends = [
          ..._.get(newState, 'user.friends', []),
          action.payload
        ];
      }
      return newState;
    case friendTypes.DECLINE_FRIEND_REQUEST.success:
    case friendTypes.REMOVE_FRIEND.success:
      newState.users[action.payload._id].friendStatus = 'requestable';
      return newState;
    case types.JOIN_GROUP_CHAT.success:
    case types.FETCH_CHAT_TOKEN.success:
      newState.chatTokens[action.payload.eventId] = action.payload.token;
      return newState;
    case types.PHONE_LOGIN.success:
    case types.APPLE_LOGIN.success:
    case types.INITIAL_LOGIN.success:
    case types.CUSTOM_REGISTER.success:
    case types.GET_MY_PROFILE.success:
      return {
        ...newState,
        user: _.isObject(action.payload.user) && {
          ...newState.user,
          ...action.payload.user
        },
        errors: {
          ...newState.errors,
          INITIAL_LOGIN: {}
        }
      };
    case types.GET_USER.success:
    case types.GET_USER_QUICKVIEW.success:
      newState.users[action.payload._id] = {
        ...newState.users[action.payload._id],
        ...action.payload
      };
      return newState;
    case types.APPLE_LOADED.success:
      return {
        ...newState,
        appleLoaded: true
      };
    case types.GET_NEWSFEED.success:
      return {
        ...newState,
        newsFeed: action.payload
      };
    case types.GET_OUTGOING_TRANSACTIONS.success:
      return {
        ...newState,
        outgoingTransactions: action.payload
      };
    case types.GET_INCOMING_TRANSACTIONS.success:
      return {
        ...newState,
        incomingTransactions: action.payload
      };
    case types.ADD_PHONE.success:
      return {
        ...newState,
        user: action.payload
      };
    case types.DELETE_PHONE.success:
      return {
        ...newState,
        user: action.payload
      };
    case types.VERIFY_PHONE.success:
      newState.user.phoneVerified = action.payload.success;
      return newState;
    case types.UPLOAD_PROFILE_PICTURE.success:
      newState.user.photo = {
        ...(newState.user.photo || {}),
        url: action.payload
      };
      return newState;
    case types.UPLOAD_PHOTO.success:
      newState.user = {
        ...newState.user,
        allPhotos: action.payload.allPhotos
      };
      return newState;
    case types.DELETE_PHOTO.success:
      if (newState.user.photo) {
        if (
          !action.payload.allPhotos.find(
            ({ _id }) => _id === newState.user.photo._id
          )
        ) {
          delete newState.user.photo;
        }
      }

      newState.user = {
        ...newState.user,
        ...action.payload
      };

      return newState;
    case types.EDIT_ME.success:
    case types.UPDATE_PROFILE.success:
      // newState.user = _.merge(newState.user, action.payload)
      newState.user = {
        ...newState.user,
        ...action.payload
      };
      return newState;
    case types.GET_EDIT_SUGGESTIONS.success:
      newState.editSuggestions = action.payload;
      return newState;
    case types.GET_USER_ORGANIZATIONS.success:
      if (action.payload.userId) {
        newState.users[action.payload.userId] = {
          ...newState.users[action.payload.userId],
          organizations: action.payload.data
        };
      } else {
        newState.user = {
          ...newState.user,
          organizations: action.payload.data
        };
      }
      return newState;
    case types.GET_USER_FRIENDS.success:
      if (action.payload.userId) {
        newState.users[action.payload.userId] = {
          ...newState.users[action.payload.userId],
          friends: action.payload.data
        };
      } else {
        newState.user = {
          ...newState.user,
          friends: action.payload.data
        };
      }
      return newState;
    case types.GET_USER_ATTENDED_EVENTS.success:
      if (action.payload.userId) {
        newState.users[action.payload.userId] = {
          ...newState.users[action.payload.userId],
          attendedEvents: action.payload.data
        };
      } else {
        newState.user = {
          ...newState.user,
          attendedEvents: action.payload.data
        };
      }
      return newState;
    case types.GET_USER_HOSTED_EVENTS.success:
      if (action.payload.userId) {
        newState.users[action.payload.userId] = {
          ...newState.users[action.payload.userId],
          hostedEvents: action.payload.data
        };
      } else {
        newState.user = {
          ...newState.user,
          hostedEvents: action.payload.data
        };
      }
      return newState;
    case connectTypes.ADD_BANK_MANUALLY.success:
    case connectTypes.ADD_PLAID_BANK.success:
      newState.user.connect.verification.bank = 'complete';
      return newState;
    case connectTypes.UPDATE_ACCOUNT_VERIFICATION.success:
      newState.user.connect.verification.initial = 'complete';
      if (newState.user.connect.verification.identity === 'required') {
        newState.user.connect.verification.identity = 'complete';
      }
      return newState;
    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: userReducer };
