import React from 'react';
import { connect } from 'react-redux';
import { addPhone } from 'legacy/actions/userActions';
import { addOrgPhone } from 'legacy/actions/orgActions';
import ModalBase from '../ModalBase/ModalBase';
import EnterPhoneForm from './components/EnterPhoneForm';

function AddPhone({
  loading,
  errors,
  user,
  addPhone,
  addOrgPhone,
  orgId,
  baseModalProps,
  onSuccess,
  overrideBaseModalProps
}) {
  const handleSubmit = (number) => {
    if (orgId) {
      addOrgPhone(orgId, number);
    } else {
      addPhone(number);
    }
  };

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      contentClassName="basic"
      hideBackButton
      {...overrideBaseModalProps}
    >
      <EnterPhoneForm
        user={user}
        errors={errors}
        loading={loading}
        onSubmitNumber={handleSubmit}
        submitActionType={orgId ? 'ADD_ORG_PHONE' : 'ADD_PHONE'}
        onSuccess={onSuccess}
      />
    </ModalBase>
  );
}

const actions = { addPhone, addOrgPhone };

const mapStateToProps = (state, { orgId }) => {
  const selector = orgId ? 'orgs' : 'user';
  return {
    user: orgId ? state.orgs.orgs[orgId] : state.user.user,
    errors: state[selector].errors,
    loading: state[selector].loading
  };
};

export default connect(mapStateToProps, actions)(AddPhone);
