import React, { useState } from 'react';
import { FaRegEnvelope } from 'react-icons/fa';

import { useGetMe } from 'legacy/user/hooks';

import VerifyEmailAddressModal from './VerifyEmailAddressModal';

const UnverifiedEmailBanner = () => {
  const { data: user } = useGetMe();
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);

  if (!!user?.email && user?.isEmailVerified === false) {
    return (
      <>
        <div
          data-testid="banner-unverified-email"
          className="bounce-banner h-10 w-full bg-yellow-400 flex items-center justify-center cursor-pointer hover:underline"
          onClick={() => {
            setShowVerifyEmailModal(true);
          }}
        >
          <div className="text-sm flex items-center">
            <FaRegEnvelope className="mr-2" /> Email Verification Required.
            Verify your email to access all platform functionality.
          </div>
        </div>

        {showVerifyEmailModal && (
          <VerifyEmailAddressModal
            user={user}
            onClose={() => {
              setShowVerifyEmailModal(false);
            }}
          />
        )}
      </>
    );
  }

  return null;
};

export default UnverifiedEmailBanner;
