import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import classnames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Popup } from 'semantic-ui-react';
import BounceButton from '../../util/BounceButton/BounceButton';
import styles from './PhotoUploadModal.module.scss';
import ModalBase from '../ModalBase/ModalBase';

class PhotoUploadModal extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      src: null,
      croppedImage: {},
      originalImage: {},
      originalImageDimensions: {},
      hasImageTypeError: false,
      hasImageSizeError: false,
      waitingToSubmit: false,
      cropLoading: false,
      crop: {
        aspect: props.aspect,
        unit: '%',
        height: 100,
        x: 0,
        y: 0
      }
    };
    this.state = this.defaultState;
  }

  loadImage = () => {
    requestAnimationFrame(() => {
      this.fileInput = document.getElementById('uploadimage');
      this.fileInput.click();
    });
  };

  resetState = () => this.setState(this.defaultState);

  componentDidMount = this.loadImage;

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.loadImage();
    }
    if (!this.props.isOpen && prevProps.isOpen) {
      this.resetState();
    }
    if (this.state.waitingToSubmit && !this.state.cropLoading) {
      const percentCrop = this.state.crop;
      const { width: originalWidth, height: originalHeight } =
        this.state.originalImageDimensions;
      const pixelCrop = {
        left: Math.round((percentCrop.x * originalWidth) / 100),
        top: Math.round((percentCrop.y * originalHeight) / 100),
        width: Math.round((percentCrop.width * originalWidth) / 100),
        height: Math.round((percentCrop.height * originalHeight) / 100)
      };
      this.setState({ waitingToSubmit: false });
      this.props.onSubmit(
        {
          data: this.state.originalImage,
          crop: pixelCrop
        },
        this.state.croppedImage
      );
    }
    if (!this.props.loading && prevProps.loading && !this.props.error.message) {
      this.props.onSuccess();
    }
  };

  handleSubmit = () => this.setState({ waitingToSubmit: true });

  getImageType(fileType) {
    if (['image/jpeg', 'image/png'].includes(fileType)) {
      return fileType;
    }
    return 'image/png';
  }

  isValidImageType = (type) => ['image/jpeg', 'image/png'].includes(type);

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const newFile = e.target.files[0];
      if (!this.isValidImageType(newFile.type)) {
        this.setState({ hasImageTypeError: true });
      } else if (newFile.size > 10000000) {
        this.setState({ hasImageSizeError: true });
      } else {
        this.setState({
          hasImageTypeError: false,
          hasImageSizeError: false,
          originalImage: newFile
        });
        reader.addEventListener('load', () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(newFile);
      }
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
    this.setState({
      originalImageDimensions: {
        width: image.naturalWidth,
        height: image.naturalHeight
      }
    });
  };

  onCropComplete = (crop) => this.makeClientCrop(crop);

  onCropChange = (crop, percentCrop) => this.setState({ crop: percentCrop });

  makeClientCrop = (crop) => {
    this.setState({ cropLoading: true });
    this.getCroppedImg(this.imageRef, crop, this.state.originalImage.name).then(
      (croppedImage) => this.setState({ croppedImage, cropLoading: false })
    );
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          // reject(new Error('Canvas is empty'));
          // console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ data: blob, url: this.fileUrl });
      }, this.state.originalImage.type);
    });
  }

  render() {
    const { src, croppedImage, crop, hasImageTypeError, hasImageSizeError } =
      this.state;
    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        contentClassName="photoUpload"
      >
        <div className={styles.wrapper}>
          <h2 className="modalTitle">{this.props.title}</h2>
          <div className={styles.input}>
            <input
              type="file"
              id="uploadimage"
              name="uploadimage"
              onChange={this.onSelectFile}
              accept=".png,.jpeg,.jpg"
            />
          </div>
          <h3 className={styles.imageTitle}>Original Image</h3>

          {src && (
            <div className={styles.originalImage}>
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                circularCrop={this.props.circular}
                ruleOfThirds
              />
            </div>
          )}

          <h3 className={styles.imageTitle}>Cropped Image</h3>

          {croppedImage.url && (
            <div
              className={classnames(styles.croppedImage, {
                [styles.circle]: this.props.circular
              })}
            >
              <img alt="Cropped Upload" src={croppedImage.url} />
            </div>
          )}
          <Popup
            position="right center"
            trigger={
              <BounceButton
                loading={this.props.loading}
                disabled={!croppedImage.url}
                primary
                type="submit"
                className="submit"
                onClick={this.handleSubmit}
              >
                Upload Image
              </BounceButton>
            }
            content={
              hasImageSizeError
                ? 'Image size cannot exceed 10MB'
                : hasImageTypeError
                ? 'Images must be either png or jpg/jpeg'
                : null
            }
            open={hasImageSizeError || hasImageTypeError}
          />
        </div>
      </ModalBase>
    );
  }
}

PhotoUploadModal.defaultProps = {
  circular: false,
  aspect: 16 / 9,
  title: 'Upload Photo'
};

const mapStateToProps = (state) => ({
  user: state.user,
  host: state.host,
  orgs: state.orgs
});

const mergeProps = (stateProps, __, ownProps) => {
  const { loadingSelector, errorSelector, ...otherOwnProps } = ownProps;
  return {
    ...otherOwnProps,
    loading: _.get(stateProps, loadingSelector) || false,
    error: _.get(stateProps, errorSelector) || {}
  };
};

export default connect(mapStateToProps, null, mergeProps)(PhotoUploadModal);
