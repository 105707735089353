import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/friendActions';
import { types as userTypes } from 'legacy/actions/userActions';
import { toActionID } from '../util/util';

export const initialState = {
  friends: [],
  sent: [],
  requests: [],
  loading: _.mapValues(types, () => false),
  errors: _.mapValues(types, () => ({}))
};

const SISTER_TYPES = [userTypes.LOGOUT.success];

export function friendReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
  }
  switch (action.type) {
    case userTypes.LOGOUT.success:
      return initialState;
    case types.GET_FRIENDS.success:
      newState.friends = action.payload;
      return newState;
    case types.REMOVE_FRIEND.success:
      if (action.payload.success) {
        newState.friends = newState.friends.filter(
          (f) => f._id !== action.payload._id
        );
      }
      return newState;
    // TODO add backend call for pending invitations user has sent, used to indicate they have
    // already sent a request on other's profiles
    case types.SEND_FRIEND_REQUEST.success:
      newState.sent.push(action.payload.user);
      return newState;
    case types.CANCEL_FRIEND_REQUEST.success:
      newState.sent = newState.sent.filter(
        (user) => user._id !== action.payload.user._id
      );
      return newState;
    case types.GET_RECEIVED_FRIEND_REQUESTS.success:
      newState.requests = action.payload;
      return newState;
    case types.GET_SENT_FRIEND_REQUESTS.success:
      newState.sent = action.payload;
      return newState;
    case types.ACCEPT_FRIEND_REQUEST.success:
      newState.requests = action.payload.requests;
      newState.friends.push(action.payload.user);
      return newState;
    case types.DECLINE_FRIEND_REQUEST.success:
      newState.requests = action.payload.requests;
      return newState;
    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: friendReducer };
