import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { Dimmer, Loader } from 'semantic-ui-react';
import styles from './Card.module.scss';

import { setDefaultPaymentMethod } from 'legacy/actions/paymentActions';
import { showModal } from 'legacy/actions/modalActions';

export const Card = ({
  card,
  loading,
  width,
  enableHover,
  showModal,
  setDefaultPaymentMethod,
  handleDelete
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const setDefault = () => {
    if (!card.default) {
      setDefaultPaymentMethod(card._id);
    }
  };

  const deleteMe = () => {
    setIsDeleting(true);
    handleDelete();
  };

  useEffect(() => {
    if (!loading.DELETE_CARD) {
      setIsDeleting(false);
    }
  }, [loading.DELETE_CARD]);

  // Styles
  const cardtype = `fab fa-cc-${card.brand.toLowerCase()}`;
  const date = card.exp.split('-');
  const exp = `${date[1]} / ${date[0].slice(2)}`;

  const cardClass = classnames([styles.Card], {
    'enable-hover': enableHover
  });

  const style = {};
  if (width) {
    style.width = width;
  }

  return (
    <div className={cardClass} style={style} data-testid="card">
      {isDeleting && (
        <Dimmer active>
          <Loader active />
        </Dimmer>
      )}
      <div className="wrapper" data-cy="card-wrapper">
        <div className="abs-content">
          <div className={styles.mainContent}>
            {enableHover && (
              <div
                className={classnames('card-type', {
                  default: card.default,
                  alt: !card.default
                })}
                onClick={setDefault}
              >
                {card.default ? 'DEFAULT' : 'MAKE DEFAULT'}
                {(loading.EDIT_CARD || loading.SET_DEFAULT_CARD) && (
                  <Dimmer active>
                    <Loader active size="tiny" />
                  </Dimmer>
                )}
              </div>
            )}
            <div className="type">
              <i className={cardtype} />
            </div>
            <div className="bn-row info">
              <div>{exp}</div>
            </div>
            <div className="number">
              <div>●●●●</div>
              <div>●●●●</div>
              <div>●●●●</div>
              <div>{card.last4}</div>
            </div>
          </div>

          <div className="buttons actions">
            <img
              data-testid="button-delete-card"
              onClick={() =>
                showModal('CONFIRMATION', {
                  message: 'Are you sure you want to delete this card?',
                  actionText: 'Delete Card',
                  destructive: true,
                  action: deleteMe
                })
              }
              src="/img/buttons/button_Delete.svg"
              alt="delete"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = exact({
  loading: PropTypes.objectOf(PropTypes.bool).isRequired,
  setDefaultPaymentMethod: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  width: PropTypes.string,
  enableHover: PropTypes.bool.isRequired
});

const mapStateToProps = (state) => ({
  loading: state.payment.loading
});
const actions = { setDefaultPaymentMethod, showModal };
export default connect(mapStateToProps, actions)(Card);
