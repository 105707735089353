import React from 'react';

import styles from './Loader.module.scss';

import Spinner, { SpinnerType } from 'legacy/common/components/spinner/Spinner';

type LoaderProps = {
  isLoading: boolean;
  message?: string;
  isOverlay?: boolean;
  transparent?: boolean;
  children?: React.ReactNode;
};

/**
 * This component wraps around components to show a loading spinner.
 */
const Loader: React.FC<LoaderProps> = ({
  isLoading,
  isOverlay,
  children,
  transparent = false,
  message
}) => {
  // Shows a spinner at the center of the screen without mounting any of the children components.
  if (isLoading && !isOverlay) {
    return (
      <div className={styles.wrapper}>
        <Spinner center type={SpinnerType.PRIMARY} message={message} />
      </div>
    );
  }

  return (
    <div className={styles.overlayWrapper}>
      {children}

      {/* Shows a spinner as an overlay on top of the mounted children components. */}
      {isLoading && isOverlay && (
        <div
          className={`w-full h-full absolute top-0 left-0 ${
            transparent ? '' : 'bg-white opacity-70'
          }`}
        >
          <div className={styles.wrapper}>
            <Spinner center type={SpinnerType.PRIMARY} message={message} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Loader;
