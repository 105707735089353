import { QueryClient } from 'react-query';

const options = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
};

const queryClient = new QueryClient(options);
export { queryClient };
