import styles from './Message.module.scss';

type MessageProps = {
  message: string;
};

const Message: React.FC<MessageProps> = ({ message }) => (
  <div data-cy="search-message" className={styles.message}>
    {message}
  </div>
);

export default Message;
