import React, { useEffect } from 'react';
import styles from './CreateConnect.module.scss';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { Popup } from 'semantic-ui-react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  SUPPORTED_COUNTRY_OPTIONS,
  COUNTRY_PROVINCES
} from '../../../util/constants';
import {
  updateAccountVerification,
  types as connectTypes
} from 'legacy/actions/connectActions';
import { updateBusinessVerification } from 'legacy/actions/orgActions';

import {
  composeValidators,
  isChanged,
  isEmail,
  isNotEqualTo,
  isRequired,
  minLength
} from '../../../util/validators';
import {
  ValidatedInput,
  withForm,
  DatePickerField,
  SubmitButton
} from '../../util/FormHelpers';
import ModalBase from '../ModalBase/ModalBase';
import { ModalInput } from '../ModalUtils/index';

import { usePrevious } from '../../../util/hooks';
import { getMyProfile } from 'legacy/actions/userActions';
import { showModal } from 'legacy/actions/modalActions';
import ValidatedSelect from 'legacy/components/util/FormHelpers/ValidatedSelect';

function CreateConnect({
  user,
  updateAccountVerification,
  updateBusinessVerification,
  formProps,
  isOpen,
  baseModalProps,
  close,
  loading,
  error,
  orgId,
  org,
  // only passed if opening in a react native webview
  onBack,
  getMyProfile,
  showModal,
  onSuccess
}) {
  const data = user?.connect?.verification?.data || {};
  const actions = user?.connect?.verification?.actions || {};
  const requiredFields =
    actions.required || actions.recollect || actions.collectable || [];

  const {
    firstName,
    lastName,
    address,
    dob,
    email,
    phone,
    tosAccepted,
    ssnLast4
  } = data;

  useEffect(() => {
    formProps.form.initialize({
      dob: dob.day ? `${dob.year}-${dob.month}-${dob.day}` : '', // date picker uses this format when value is changed, previous behaviour caused an uncaught error
      firstName: firstName || user.firstName || '',
      lastName: lastName || user.lastName || '',
      line1: address?.line1 || '',
      city: address?.city || '',
      postalCode: address?.postalCode || '',
      state: address?.state || '',
      email: email || '',
      phone: phone || '',
      tosAccepted: tosAccepted ? '' : true,
      ssnLast4: ssnLast4 || ''
    });
  }, []);

  const { connect } = orgId ? org : user;
  const { verification } = connect;
  const { initial } = verification;
  const prevLoading = usePrevious(loading);
  useEffect(() => {
    if (initial === 'complete' || (!loading && prevLoading && !error.message)) {
      close();
    }
  }, [initial, prevLoading, loading, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    formProps.handleSubmit();

    if (formProps.valid) {
      const address = formProps.values.line1
        ? {
            city: formProps.values.city,
            line1: formProps.values.line1,
            postal_code: formProps.values.postalCode,
            state: formProps.values.state
          }
        : undefined;

      if (orgId) {
        address.country = formProps.values.country || 'CA';
        const { name } = formProps.values;
        updateBusinessVerification(orgId, { name, address });
      } else {
        const date = formProps.values.dob?.split('-');
        const dob = date
          ? {
              month: Number(date[1]),
              year: Number(date[0]),
              day: Number(date[2])
            }
          : undefined;

        const { firstName, lastName, tosAccepted, phone, email, ssnLast4 } =
          formProps.values;
        const names = firstName && lastName && { firstName, lastName };
        await updateAccountVerification({
          dob,
          address,
          tosAccepted,
          phone,
          email,
          ssnLast4,
          onSuccess: () => {
            onSuccess();
            getMyProfile();

            setTimeout(() => {
              getMyProfile();
            }, 3000);

            showModal('ALERT', {
              type: 'success',
              actionText:
                requiredFields.includes('bankAdded') ||
                requiredFields.includes('idNumberProvided')
                  ? 'Next'
                  : 'Ok',
              message: 'Your information has been submitted successfully!',
              onSuccess: ({ close }) => {
                if (requiredFields.includes('bankAdded')) {
                  showModal('ADD_BANK', {
                    onSuccess: () => {
                      if (requiredFields.includes('idNumberProvided')) {
                        showModal('CONNECT_VERIFICATION', {
                          onSuccess
                        });
                      }
                    }
                  });

                  return;
                }

                if (requiredFields.includes('idNumberProvided')) {
                  showModal('CONNECT_VERIFICATION', {
                    onSuccess
                  });

                  return;
                }

                close();
              },
              hideBackButton: true
            });
          },
          ...names
        });
      }
    }
  };

  return (
    <ModalBase
      onBack={onBack}
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <form
        data-cy=""
        className={classnames(styles.CreateConnect, 'component')}
        onSubmit={handleSubmit}
      >
        <h2 className="modalTitle">
          Legal Verification
          <Popup
            hoverable
            position="right center"
            trigger={<i className="fas fa-info-circle" />}
          >
            <div className={styles.popupMessage}>
              This information will only be used for legal verification purposes
              in accordance with global <br />
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                href="https://en.wikipedia.org/wiki/Know_your_customer"
              >
                Know Your Customer (KYC)
              </a>{' '}
              regulations.
            </div>
          </Popup>
        </h2>
        <div className={styles.messageContainer}>
          <p>
            Please enter your personal information below. This will not be
            displayed on your profile.
          </p>
          <p>
            Bank payouts will be disabled if this information is incorrect or
            does not belong to a real person.
          </p>
        </div>
        {orgId ? (
          <>
            <label className={styles.fieldLabel} htmlFor="name">
              ORGANIZATION NAME
            </label>
            <ValidatedInput
              errorVisible={isOpen}
              type="text"
              name="name"
              validate={isRequired}
              component={ModalInput}
            />
          </>
        ) : (
          ''
        )}
        {(requiredFields.includes('dob') || dob.day) && (
          <>
            <label className={styles.fieldLabel} htmlFor="dob">
              DATE OF BIRTH
            </label>
            <DatePickerField
              validate={isRequired}
              name="dob"
              formProps={formProps}
              disabled={!!dob.day && !requiredFields.includes('dob')}
              disabledBefore={new Date(1920, 0)}
              disabledAfter={new Date(new Date().getFullYear(), 11)}
            />
          </>
        )}

        {!orgId && (
          <>
            {(requiredFields.includes('firstName') || firstName) && (
              <>
                <label className={styles.fieldLabel} htmlFor="firstName">
                  LEGAL FIRST NAME
                </label>
                <ValidatedInput
                  showErrorBeforeTouched={actions?.recollect?.includes(
                    'firstName'
                  )}
                  errorVisible={isOpen}
                  type="text"
                  name="firstName"
                  validate={
                    actions?.recollect?.includes('firstName')
                      ? composeValidators(isRequired, isNotEqualTo(firstName))
                      : isRequired
                  }
                  component={ModalInput}
                  inputProps={{
                    disabled:
                      !!data?.firstName && !requiredFields.includes('firstName')
                  }}
                />
              </>
            )}

            {(requiredFields.includes('lastName') || lastName) && (
              <>
                <label className={styles.fieldLabel} htmlFor="lastName">
                  LEGAL LAST NAME
                </label>
                <ValidatedInput
                  showErrorBeforeTouched={actions?.recollect?.includes(
                    'lastName'
                  )}
                  errorVisible={isOpen}
                  type="text"
                  name="lastName"
                  validate={
                    actions?.recollect?.includes('lastName')
                      ? composeValidators(isRequired, isNotEqualTo(lastName))
                      : isRequired
                  }
                  component={ModalInput}
                  inputProps={{
                    disabled:
                      !!data?.lastName && !requiredFields.includes('lastName')
                  }}
                />
              </>
            )}
          </>
        )}

        {(requiredFields.includes('email') || email) && (
          <>
            <label className={styles.fieldLabel} htmlFor="email">
              Email
            </label>
            <ValidatedInput
              showErrorBeforeTouched={actions?.recollect?.includes('email')}
              errorVisible={isOpen}
              type="text"
              name="email"
              validate={
                actions?.recollect?.includes('email')
                  ? composeValidators(isRequired, isEmail, isNotEqualTo(email))
                  : composeValidators(isRequired, isEmail)
              }
              component={ModalInput}
              inputProps={{
                disabled: !!data?.email && !requiredFields.includes('email')
              }}
            />
          </>
        )}

        {(requiredFields.includes('phone') || phone) && (
          <>
            <label className={styles.fieldLabel} htmlFor="phone">
              Phone
            </label>
            <ValidatedInput
              showErrorBeforeTouched={actions?.recollect?.includes('phone')}
              errorVisible={isOpen}
              type="text"
              name="phone"
              validate={
                actions?.recollect?.includes('phone')
                  ? composeValidators(isRequired, isNotEqualTo(phone))
                  : isRequired
              }
              component={ModalInput}
              inputProps={{
                disabled: !!data?.phone && !requiredFields.includes('phone')
              }}
            />
            <div>This must be a {user?.country} phone number</div>
          </>
        )}

        {(requiredFields.includes('address') || address.postalCode) && (
          <>
            <p className="divider">
              {orgId ? 'ORGANIZATION ADDRESS' : 'LEGAL ADDRESS'}
            </p>

            <label className={styles.fieldLabel} htmlFor="line1">
              Country
            </label>
            <ValidatedSelect
              name="country"
              defaultValue={user.country}
              className={styles.SelectField}
              placeholder="Select your Country"
              options={SUPPORTED_COUNTRY_OPTIONS}
              disabled
            />

            <label className={styles.fieldLabel} htmlFor="line1">
              ADDRESS LINE 1
            </label>
            <ValidatedInput
              showErrorBeforeTouched={actions?.recollect?.includes('address')}
              errorVisible={isOpen}
              type="address"
              name="line1"
              validate={
                actions?.recollect?.includes('address')
                  ? composeValidators(
                      isRequired,
                      isChanged([...Object.keys(address)], address)
                    )
                  : isRequired
              }
              placeholder="5230 Newell Road"
              autoComplete="shipping street-address"
              component={ModalInput}
              inputProps={{
                disabled: !!address.line1 && !requiredFields.includes('address')
              }}
            />
            <label className={styles.fieldLabel} htmlFor="city">
              CITY
            </label>
            <ValidatedInput
              showErrorBeforeTouched={actions?.recollect?.includes('address')}
              errorVisible={isOpen}
              type="text"
              name="city"
              autoComplete="shipping locality"
              validate={
                actions?.recollect?.includes('address')
                  ? composeValidators(
                      isRequired,
                      isChanged([...Object.keys(address)], address)
                    )
                  : isRequired
              }
              placeholder="City"
              component={ModalInput}
              inputProps={{
                disabled: !!address.city && !requiredFields.includes('address')
              }}
            />
            <label className={styles.fieldLabel} htmlFor="state">
              {user.country === 'CA' ? 'PROVINCE' : 'STATE'}
            </label>

            <ValidatedSelect
              name="state"
              placeholder={`Select your ${
                user.country === 'CA' ? 'Province' : 'State'
              }`}
              validate={
                actions?.recollect?.includes('address')
                  ? composeValidators(
                      isRequired,
                      isChanged([...Object.keys(address)], address)
                    )
                  : isRequired
              }
              options={COUNTRY_PROVINCES[user.country]}
              showErrorBeforeTouched={actions?.recollect?.includes('address')}
              disabled={!!address.state && !requiredFields.includes('address')}
            />

            {/* {orgId && (
                    <>
                        <label className={styles.fieldLabel} htmlFor="state">
                            COUNTRY
                        </label>
                        <Field
                            name="country"
                            component="select"
                            className="ui selection dropdown"
                            autoComplete="shipping-region"
                            style={{ width: '100%', marginBottom: '18px' }}
                        >
                            <option selected value="">
                                Select your Country
                            </option>
                            {COUNTRY_OPTIONS.map((p) => (
                                <option key={p} {...p}>
                                    {p.text}
                                </option>
                            ))}
                        </Field>
                    </>
                )} */}
            <label className={styles.fieldLabel} htmlFor="postalCode">
              ZIP/POSTAL CODE
            </label>
            <ValidatedInput
              showErrorBeforeTouched={actions?.recollect?.includes('address')}
              errorVisible={isOpen}
              validate={
                actions?.recollect?.includes('address')
                  ? composeValidators(
                      isRequired,
                      isChanged([...Object.keys(address)], address)
                    )
                  : isRequired
              }
              type="text"
              name="postalCode"
              placeholder="B0U7C3"
              autoComplete="shipping postal-code"
              component={ModalInput}
              inputProps={{
                disabled:
                  !!address.postalCode && !requiredFields.includes('address')
              }}
            />
          </>
        )}

        {(requiredFields.includes('ssnLast4Provided') || !!ssnLast4) && (
          <>
            <label className={styles.fieldLabel} htmlFor="ssnLast4">
              LAST 4 DIGITS OF SSN
            </label>
            <ValidatedInput
              showErrorBeforeTouched={actions?.recollect?.includes(
                'ssnLast4Provided'
              )}
              errorVisible={isOpen}
              type="text"
              name="ssnLast4"
              validate={
                actions?.recollect?.includes('ssnLast4Provided')
                  ? composeValidators(
                      isRequired,
                      minLength(4),
                      isNotEqualTo(ssnLast4)
                    )
                  : composeValidators(isRequired, minLength(4))
              }
              component={ModalInput}
              inputProps={{
                pattern: '\\d*',
                maxLength: 4,
                disabled:
                  !!data?.ssnLast4 &&
                  !requiredFields.includes('ssnLast4Provided')
              }}
            />
          </>
        )}

        <SubmitButton
          errorOpen={
            isOpen &&
            formProps.hasSubmitErrors &&
            !formProps.dirtySinceLastSubmit &&
            !formProps.submitting
          }
          loading={loading}
          errorContent={
            formProps.hasSubmitErrors ? formProps.submitErrors.FORM_ERROR : null
          }
          buttonText="Submit"
        />
        {!tosAccepted && (
          <div className="agreement">
            By registering your account, you agree to our{' '}
            <a target="_blank" rel="noopener noreferrer" href="/terms">
              Terms of Use
            </a>{' '}
            and the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stripe.com/ca/connect-account/legal"
            >
              Stripe Connected Account Agreement
            </a>
            .
          </div>
        )}
      </form>
    </ModalBase>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    loading: ownProps.orgId
      ? state.orgs.loading.UPDATE_BUSINESS_VERIFICATION
      : state.connect.loading.UPDATE_ACCOUNT_VERIFICATION,
    user: state.user.user,
    error: ownProps.orgId
      ? state.orgs.errors.UPDATE_BUSINESS_VERIFICATION
      : state.connect.errors.UPDATE_ACCOUNT_VERIFICATION
  };
}

const actions = {
  updateAccountVerification,
  updateBusinessVerification,
  getMyProfile,
  showModal
};

export default connect(
  mapStateToProps,
  actions
)(withForm(connectTypes.UPDATE_ACCOUNT_VERIFICATION_FORM)(CreateConnect));
