import React from 'react';
import { Field } from 'react-final-form';
import styles from './FormFields.module.scss';
import TelInput from 'react-intl-tel-input';
import cn from 'classnames';
import { SUPPORTED_COUNTRIES } from '../../../util/constants';

const PhoneNumberField = ({
  name,
  formProps,
  fieldProps,
  defaultCountry,
  ...props
}) => {
  const currentValue = formProps.values[name];

  const handleChange = (valid, value) => {
    formProps.form.change(`${name}_valid`, valid);
    formProps.form.change(name, value);
  };

  return (
    <>
      <TelInput
        format
        autocomplete="tel"
        defaultCountry={defaultCountry?.toLowerCase() || 'ca'}
        onlyCountries={SUPPORTED_COUNTRIES}
        preferredCountries={[]}
        customPlaceholder={() => '(416) 123-4567'}
        inputClassName={cn(styles.phoneInput, props.className)}
        // fieldName={name}
        onPhoneNumberChange={handleChange}
        value={currentValue}
      />

      {/* This hidden field ensures all of the react-final-form logic runs as expected when the telinput is changed */}
      <Field name={name} type="hidden" component="input" {...fieldProps} />
    </>
  );
};

export default PhoneNumberField;
