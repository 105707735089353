import { useEffect } from 'react';

import { store } from '../../store';

import { notify } from 'legacy/common/utils/toast';
import * as firebase from 'legacy/common/utils/firebase';

import { registerForFCM } from '../../notification/service';

import { addNotification } from 'legacy/actions/notificationActions';
import { useRouter } from 'next/router';

function useFCM() {
  const router = useRouter();

  useEffect(() => {
    async function initialize() {
      try {
        firebase.init();

        const token = await firebase.initFCM({
          onMessage: (payload) => {
            notify({
              imageUrl: payload?.notification?.image,
              message: payload?.notification?.body,
              onClick: () => {
                router.push(payload.data.redirectPath);
              }
            });

            store.dispatch(addNotification(payload.data));
          }
        });

        registerForFCM(token);
      } catch (e) {
        // For showing message if notifications are not allowed by the user
      }
    }

    initialize();
  }, []);
}

export default useFCM;
