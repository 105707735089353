import React, { useEffect } from 'react';
import { isEmpty, pickBy } from 'lodash';

import { connect } from 'react-redux';
import cn from 'classnames';
import { Avatar, Button, Dropdown, Menu, Modal } from 'antd';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getUser, getUserQuickView } from 'legacy/actions/userActions';
import {
  acceptFriendRequest,
  cancelFriendRequest,
  declineFriendRequest,
  getReceivedFriendRequests,
  getSentFriendRequests,
  removeFriend,
  sendFriendRequest
} from 'legacy/actions/friendActions';
import { sendMoneyFlow } from '../../../util/modalFlows';
import { hideModal, showModal } from 'legacy/actions/modalActions';

import styles from './QuickViewProfile.module.scss';
import ModalBase from '../ModalBase/ModalBase';
import DefaultUserPhoto from '../../util/DefaultUserPhoto/DefaultUserPhoto';
import Gallery from '../../pages/Profile/User/Gallery';
import Spinner, { SpinnerType } from 'legacy/common/components/spinner/Spinner';

const QuickViewProfile = ({
  router,
  device,
  userId,
  users,
  self,
  ownFriends,
  receivedRequests,
  sentRequests,
  getUserQuickView,
  getUser,
  sendFriendRequest,
  cancelFriendRequest,
  removeFriend,
  acceptFriendRequest,
  declineFriendRequest,
  getSentFriendRequests,
  getReceivedFriendRequests,
  friendLoading,
  showModal,
  hideModal,
  baseModalProps,
  userLoading
}) => {
  // Since modals don't actually unmount on close,
  // trigger this useEffect everytime we get a new userId
  useEffect(() => {
    getUserQuickView(userId);
    getSentFriendRequests();
    getReceivedFriendRequests();
  }, [userId]);

  // DESTRUCTURE IMPORTANT PROPS
  const user = users[userId] || {};
  // To check mutual interests
  const { ownProfile = {} } = self;
  const { ownInterests = [] } = ownProfile;

  const {
    mutualAttendedEvents = [],
    mutualFriends = [],
    profile = {},
    privacy = {}
  } = user;
  const {
    socialMedia = {},
    interests = [],
    education = {},
    gallery = []
  } = profile;

  if (!self && userId) {
    router.push({
      pathname: `/profile/${userId}`
    });
    return null;
  }

  // UTILS
  const goToProfile = (id, onMount) => {
    getUser(id);
    router.push({
      pathname: `/profile/${id}`,
      onMount // FIX: what does this look like for next?
    });
    hideModal();
  };
  const sendMoney = (receiver) =>
    sendMoneyFlow(() => showModal('SEND_MONEY', { receiver }));
  // Returns "A, B, and X others" given an array of objects and the corresponding key
  const generateArrayText = (array, key, singularNoun = '') => {
    if (array.length < 3) {
      return array.map((item) => item[key]).join(', ');
    }
    if (array.length === 3) {
      return `${array
        .slice(0, 2)
        .map((item) => item[key])
        .join(', ')} and 1 other ${singularNoun}`;
    }

    // array.length > 3
    return `${array
      .slice(0, 2)
      .map((item) => item[key])
      .join(', ')} and ${array.length - 2} other ${singularNoun}s`;
  };
  const showCancelFriendRequestModal = () => {
    Modal.confirm({
      title: 'Cancel friend request',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to cancel your friend request to ${user.name}?`,
      centered: true,
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Close',
      onOk() {
        cancelFriendRequest(user);
      }
    });
  };
  const showRemoveFriendModal = () => {
    Modal.confirm({
      title: 'Remove friend',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      content: `Are you sure you want to unfriend ${user.name}?`,
      centered: true,
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        removeFriend(user._id);
      }
    });
  };

  // CONDITIONAL RENDER CONSTS
  const hasUserInfo =
    (education && education.school) || (education && education.program);

  const hasSocialMedia = !isEmpty(socialMedia);
  const socialIconMap = {
    facebook: <img src="/img/icons/social/icon-fb.svg" alt="" />,
    instagram: <img src="/img/icons/social/icon-ins.svg" alt="" />,
    linkedin: <i className="fab fa-linkedin" />,
    snapchat: <i className="fab fa-snapchat" />,
    twitter: <i className="fab fa-twitter-square" />
  };
  const socialColourMap = {
    facebook: 'rgba(22, 108, 240, 0.1)',
    instagram: 'rgba(255, 92, 0, 0.1)',
    linkedin: 'rgba(22, 108, 240, 0.1)',
    twitter: 'rgba(58, 161, 236, 0.1)',
    snapchat: 'rgba(255, 165, 81, 0.1)'
  };
  const socialLinks = Object.entries(pickBy(socialMedia, ({ url }) => url)).map(
    ([media, { url }], index) => (
      <a
        key={index}
        style={{ backgroundColor: socialColourMap[media] || '#ddd' }}
        className={styles.socialLink}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {socialIconMap[media]}
      </a>
    )
  );

  const privateIcon = (
    <div className={styles.circle}>
      <img className={styles.icon} src="/img/icon_private.svg" alt="" />
    </div>
  );
  const hasMutualFriends = !isEmpty(mutualFriends);
  const mutualFriendsText = generateArrayText(
    mutualFriends,
    'name',
    'mutual friend'
  );

  const hasMutualAttendedEvents = !isEmpty(mutualAttendedEvents);
  const mutualAttendedEventsText = generateArrayText(
    mutualAttendedEvents,
    'title',
    'event'
  );
  const mutualEventsIcon = (
    <div className={styles.circle}>
      <img className={styles.icon} src="/img/icon_mutualEvents.svg" alt="" />
    </div>
  );

  const hasInterests = !isEmpty(interests);
  const interestsIcon = (
    <div className={styles.circle}>
      <img className={styles.icon} src="/img/icon_interest.svg" alt="" />
    </div>
  );
  // If more than 4 interests return as many mutual interests as possible, then fill interests from user until 4 interests
  const processInterests = () => {
    let interestsArray = interests;
    if (interests.length > 4) {
      const mutualInterests = interests.filter((interest) =>
        ownInterests.includes(interest)
      );
      for (let i = 0; i < interests.length; i++) {
        if (mutualInterests.length === 4) {
          break;
        } else if (!mutualInterests.includes(interests[i])) {
          mutualInterests.push(interests[i]);
        }
      }
      interestsArray = mutualInterests;
    }
    if (device === 'mobile') {
      return interestsArray.slice(0, 2);
    }
    return interestsArray;
  };

  const interestsElement = (
    <div className={styles.interests}>
      {processInterests().map((interest, index) => (
        <div className={styles.interest} key={index}>
          {interest}
        </div>
      ))}
    </div>
  );

  const hasGallery = !isEmpty(gallery);
  const galleryIcon = (
    <div className={styles.circle}>
      <img className={styles.icon} src="/img/icon_gallery.svg" alt="" />
    </div>
  );

  // FRIEND STATUS
  const isFriend =
    !isEmpty(ownFriends.find((friend) => friend._id === user._id)) ||
    user.friendStatus === 'friends';
  const isReceivedRequest = !isEmpty(
    receivedRequests.find((req) => req._id === user._id)
  );
  const isSentRequest = !isEmpty(
    sentRequests.find((req) => req._id === user._id)
  );
  const isPending = isReceivedRequest || isSentRequest;

  let friendButtonText;
  if (isFriend) {
    friendButtonText = 'Remove Friend';
  } else if (isSentRequest) {
    friendButtonText = 'Pending';
  } else {
    friendButtonText = 'Add Friend';
  }

  const friendRequestMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => acceptFriendRequest(user)}>
        Accept
      </Menu.Item>
      <Menu.Item key="2" onClick={() => declineFriendRequest(user)}>
        Decline
      </Menu.Item>
    </Menu>
  );

  // PRIVACY
  const canSeeProfile =
    privacy.profile === 'everyone' ||
    (privacy.profile === 'friends' && isFriend);
  const canSeeEvents =
    privacy.events === 'everyone' || (privacy.events === 'friends' && isFriend);

  // ACTION BUTTON ICONS
  const friendButtonIcon = !isPending ? (
    <i className={`fas fa-${isFriend ? 'minus' : 'plus'}`} />
  ) : (
    <img
      className={styles.pendingRequestIcon}
      src="/img/request_pending.svg"
      alt=""
    />
  );
  const transferIcon = (
    <img className={styles.transferIcon} src="/img/icon_transfer.svg" alt="" />
  );
  /* // Can potentially add this to right of Transfer button in the future, I don't think it's good design practice
    const expandDropdownIcon = (
        <img
            className={styles.expandDropdownIcon}
            src="/img/icon_expand.svg"
            alt=""
        />
    ); */

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      className={styles.modalBase}
      contentClassName="basic"
      back_img="Back"
      large
      tall
    >
      {userLoading.GET_USER_QUICKVIEW || !user._id ? (
        <div className={styles.loading}>
          <Spinner type={SpinnerType.PRIMARY} />
        </div>
      ) : (
        <div className={styles.QuickViewProfile}>
          <div className={styles.coverPhoto} />
          <div
            className={styles.profilePic}
            onClick={() => goToProfile(user._id)}
          >
            {user.photo ? (
              <img src={user.photo.url} alt="" />
            ) : (
              <DefaultUserPhoto style={{ fontSize: '50px' }} />
            )}
          </div>
          <div className={styles.name} onClick={() => goToProfile(user._id)}>
            {user.name}
          </div>
          {hasSocialMedia && (
            <div className={styles.socialIconsRow}>{socialLinks}</div>
          )}
          {hasUserInfo && canSeeProfile && (
            <div className={styles.userInfoRow}>
              {canSeeProfile && (
                <>
                  <span>{education.school || ''}</span>
                  <span>{education.school && education.program && '|'}</span>
                  <span>{education.program}</span>
                </>
              )}
            </div>
          )}
          <div className={styles.actionbtnRow}>
            {isReceivedRequest ? (
              <Dropdown overlay={friendRequestMenu}>
                <Button
                  loading={
                    friendLoading.REMOVE_FRIEND ||
                    friendLoading.SEND_FRIEND_REQUEST ||
                    friendLoading.ACCEPT_FRIEND_REQUEST ||
                    friendLoading.DECLINE_FRIEND_REQUEST
                  }
                  className={styles.actionButton}
                  icon={friendButtonIcon}
                >
                  Respond <DownOutlined />
                </Button>
              </Dropdown>
            ) : (
              <Button
                loading={
                  friendLoading.REMOVE_FRIEND ||
                  friendLoading.SEND_FRIEND_REQUEST ||
                  friendLoading.ACCEPT_FRIEND_REQUEST ||
                  friendLoading.DECLINE_FRIEND_REQUEST
                }
                className={styles.actionButton}
                icon={friendButtonIcon}
                onClick={() => {
                  if (isFriend) {
                    showRemoveFriendModal();
                  } else if (isPending) {
                    showCancelFriendRequestModal();
                  } else {
                    sendFriendRequest(user);
                  }
                }}
              >
                {friendButtonText}
              </Button>
            )}
            <Button
              className={styles.actionButton}
              icon={transferIcon}
              onClick={() => sendMoney(user)}
            >
              Transfer {/* {expandDropdownIcon} */}
            </Button>
          </div>
          <div className={styles.quickViewRowsContainer}>
            <div
              className={cn({
                [styles.quickViewRow]: !canSeeProfile || hasMutualFriends
              })}
              onClick={() => {
                if (canSeeProfile) {
                  goToProfile(user._id, 'showFriends');
                } else {
                  goToProfile(user._id);
                }
              }}
            >
              {!canSeeProfile ? (
                <>
                  {privateIcon}
                  <div className={styles.contentContainer}>
                    <h2>Mutual Friends</h2>
                    <p>Private</p>
                  </div>
                </>
              ) : hasMutualFriends ? (
                <>
                  <div
                    data-cy="friends-visible"
                    className={cn(styles.avatarGroupContainer, {
                      [styles.multipleAvatars]: mutualFriends.length >= 2
                    })}
                  >
                    <Avatar.Group maxCount={2} maxStyle={{ display: 'none' }}>
                      {mutualFriends.map((friend, index) => (
                        <Avatar
                          key={index}
                          size="large"
                          src={friend.photo && friend.photo.url}
                          icon={<DefaultUserPhoto />}
                        />
                      ))}
                    </Avatar.Group>
                  </div>
                  <div className={styles.contentContainer}>
                    <h2>
                      {mutualFriends.length} Mutual Friend
                      {mutualFriends.length > 1 && 's'}
                    </h2>
                    <p>{mutualFriendsText}</p>
                  </div>
                </>
              ) : null}
            </div>
            <div
              className={cn({
                [styles.quickViewRow]: !canSeeEvents || hasMutualAttendedEvents
              })}
              onClick={() => goToProfile(user._id)}
            >
              {!canSeeEvents ? (
                <>
                  {privateIcon}
                  <div className={styles.contentContainer}>
                    <h2>Mutual Events</h2>
                    <p>Private</p>
                  </div>
                </>
              ) : hasMutualAttendedEvents ? (
                <>
                  {mutualEventsIcon}
                  <div
                    className={styles.contentContainer}
                    data-cy="events-visible"
                  >
                    <h2>You both attended</h2>
                    <p>{mutualAttendedEventsText}</p>
                  </div>
                </>
              ) : null}
            </div>
            {!canSeeProfile ? (
              <>
                <div
                  className={styles.quickViewRow}
                  onClick={() => goToProfile(user._id)}
                >
                  {privateIcon}
                  <div className={styles.contentContainer}>
                    <h2>Interests</h2>
                    <p>Private</p>
                  </div>
                </div>
                <div
                  className={styles.quickViewRow}
                  onClick={() => goToProfile(user._id)}
                >
                  {privateIcon}
                  <div className={styles.contentContainer}>
                    <h2>Gallery</h2>
                    <p>Private</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className={styles.quickViewRow}
                  onClick={() => goToProfile(user._id)}
                >
                  {interestsIcon}
                  <div
                    className={styles.contentContainer}
                    data-cy="interests-visible"
                  >
                    <h2>Interests</h2>
                    {hasInterests ? (
                      interestsElement
                    ) : (
                      <p>No interests to show</p>
                    )}
                  </div>
                </div>
                <div
                  className={cn(styles.quickViewRow, {
                    [styles.galleryContainer]: hasGallery && canSeeProfile
                  })}
                  onClick={() => goToProfile(user._id)}
                >
                  {galleryIcon}
                  <div
                    className={styles.contentContainer}
                    data-cy="gallery-visible"
                  >
                    <h2>Gallery</h2>
                    {hasGallery ? (
                      <Gallery gallery={gallery} />
                    ) : (
                      <p>No photos to show</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  userLoading: state.user.loading,
  users: state.user.users,
  self: state.user.user,
  ownFriends: state.friends.friends,
  receivedRequests: state.friends.requests,
  sentRequests: state.friends.sent,
  friendLoading: state.friends.loading
});

const actions = {
  getUserQuickView,
  getUser,
  removeFriend,
  sendFriendRequest,
  cancelFriendRequest,
  acceptFriendRequest,
  declineFriendRequest,
  getSentFriendRequests,
  getReceivedFriendRequests,
  showModal,
  hideModal
};

export default connect(mapStateToProps, actions)(QuickViewProfile);
