/**
 * Heap is initialized from _document.tsx.
 */

export function identifyUser(id: string, properties: Record<string, string>) {
  (window as any).heap?.identify(id);
  (window as any).heap?.addUserProperties({
    ...properties,
    Name: properties?.name || undefined
  });
}
