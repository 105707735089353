import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

function useParams<T extends ParsedUrlQuery>() {
  const router = useRouter();

  return router.query as T;
}

export default useParams;
