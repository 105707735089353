import React from 'react';
import { connect } from 'react-redux';
import styles from './User.module.scss';

import { showModal, modalBack } from 'legacy/actions/modalActions';

const Gallery = ({ gallery, showModal, modalBack }) => {
  const emptySlots = [];
  for (let i = 0; i < 3 - gallery.length; i++) {
    emptySlots.push(i);
  }

  return gallery.length > 0 ? (
    <div className={styles.galleryWrapper}>
      <div className={styles.gallery} data-cy="user-gallery">
        {gallery.map(({ _id, url }, index) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              showModal('VIEW_USER_GALLERY', {
                index,
                gallery,
                modalBack
              });
            }}
            key={_id}
            className={styles.galleryPic}
            style={{ backgroundImage: `url(${url})` }}
          />
        ))}
        {emptySlots.map((slot) => (
          <div key={slot} className={styles.galleryPic} />
        ))}
      </div>
    </div>
  ) : (
    <div className={styles.empty} data-cy="user-gallery">
      No pictures to display
    </div>
  );
};

const actions = { showModal, modalBack };

export default connect(null, actions)(Gallery);
