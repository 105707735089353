// Validate SIN using Luhn's Algorithm
export function validateSIN(value: string) {
  // Must be 9-digit string
  if (!/^[0-9]{9}/.test(value)) {
    return false;
  }

  // The Luhn Algorithm.
  // https://en.wikipedia.org/wiki/Social_Insurance_Number#Validation
  let nCheck = 0;
  let bEven = false;

  for (let i = value.length - 1; i >= 0; i--) {
    const cDigit = value.charAt(i);
    let nDigit = parseInt(cDigit, 10);
    // eslint-disable-next-line no-cond-assign
    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }
    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}

/**
 * SSN validation conditions
 * For XXX-XX-XXXX,
 * - First part should have 3 digits; should not be 000, 666 or between 900 and 999
 * - Second part should not be 00
 * - Third part should not be 0000
 */
export function validateSSN(value: string) {
  const ssn = value;

  // Regex from https://stackoverflow.com/a/4087530/3783888
  const validationRegex = /^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;

  return validationRegex.test(ssn);
}
