import { EventPricingTier } from 'legacy/common/types/models';
import { FaTicketAlt } from 'react-icons/fa';

type TierGuestsProps = {
  tier: EventPricingTier;
};

const TierGuests: React.FC<TierGuestsProps> = ({ tier }) => (
  <div className="flex align-center text-gray-500 text-sm">
    <FaTicketAlt className="mr-2" />
    {`${tier?.totalGuests ?? 0}${
      tier?.maxAttendance ? `/${tier?.maxAttendance}` : ''
    } sold`}
  </div>
);

export default TierGuests;
