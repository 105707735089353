import React from 'react';
import classnames from 'classnames';
import styles from './AddPhone.module.scss';

import ModalBase from '../ModalBase/ModalBase';
import BounceButton from '../../util/BounceButton/BounceButton';

function VerifyPhoneSuccessModal({ baseModalProps, onSuccess, orgId }) {
  return (
    <ModalBase
      baseModalProps={baseModalProps}
      contentClassName="basic"
      hideBackButton
    >
      <div className={classnames(styles.AddPhone, 'component')}>
        <h2 className="modalTitle">Phone Number Added!</h2>

        <img
          src="/img/illustrations/phoneVerified.svg"
          alt="Phone verification illustration"
        />

        <div>
          {`You successfully connected your phone number to your ${
            orgId ? 'organization' : 'Bounce account'
          }.`}
        </div>

        <BounceButton primary onClick={onSuccess}>
          Start Bouncing
        </BounceButton>
      </div>
    </ModalBase>
  );
}

export default VerifyPhoneSuccessModal;
