import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FLAGS } from './common/constants/launchdarkly';

import { usePrevious } from './util/hooks';

import App from './App';
import PrivatePage from './components/pageLayout/PrivatePage/PrivatePage';
import PublicPage from './components/pageLayout/PublicPage/PublicPage';

import EventRating from './events/components/rating/EventRating';
import MigrationFlow from './auth/components/migration-flow/MigrationFlow';

import { attemptInitialJwtLogin } from './actions/userActions';

import withAuthRedirect from './auth/hoc/withAuthRedirect';

import storage from './common/utils/storage';
import { STORAGE_KEYS } from './common/constants/storage';
import {
  isPublicRoute,
  isAuthRoute,
  isClaimTicketPageRoute,
  isEventPageRoute
} from './common/utils/routes';

import Loader from './common/components/layout/Loader';
import { useGetRequiredEventRatings } from './user/hooks';

/**
 * This component is responsible for redirecting the user from private pages if not logged in
 */
const RedirectContainer = ({
  Component,
  pageProps,
  user,
  userLoading,
  attemptInitialJwtLogin
}) => {
  const flags = useFlags();
  const router = useRouter();
  const { pathname, asPath } = router;
  const isEventPage = isEventPageRoute(pathname);
  const isClaimTicketPage = isClaimTicketPageRoute(pathname);
  const isPublic = isPublicRoute(pathname);
  const isAuthRoutes = isAuthRoute(pathname);

  const [shouldShowMigrationFlow, setShouldShowMigrationFlow] = useState(false);
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const { data: requiredEventRatings, status } = useGetRequiredEventRatings();

  useEffect(() => {
    if (isEventPage) {
      // Ensures we do not show the migration flow after simple sign on
      return;
    }

    // Show migration flow only when rating is not required.
    if (
      status === 'success' &&
      !requiredEventRatings?.requiredRatings?.length
    ) {
      setShouldShowMigrationFlow(true);
      return;
    }

    setShouldShowMigrationFlow(false);
  }, [status, requiredEventRatings]);

  useEffect(() => {
    const token = storage.getItem(STORAGE_KEYS.AUTH_TOKEN);
    document.cookie = `token=${token}; path=/`;

    if (!asPath.toLowerCase().startsWith('/verifyemail')) {
      attemptInitialJwtLogin({
        isAuthenticated: !!token
      });
    }
  }, []);

  const prevInitialLoginLoading = usePrevious(userLoading.INITIAL_LOGIN);

  // Make sure we keep track of the actual state of the app loading.
  // Not ideal, but given this entire thing is based on useEffects, we need to use one for this.
  // Fight fire with fire
  useEffect(() => {
    if (prevInitialLoginLoading && !userLoading.INITIAL_LOGIN) {
      setIsAppLoaded(true);
    }
  }, [userLoading.INITIAL_LOGIN]);

  useEffect(() => {
    if (isAppLoaded && router.isReady) {
      if (
        !isPublic &&
        !pathname.endsWith('events/[slug]') &&
        !pathname.endsWith('/resetPassword/[token]') &&
        !pathname.endsWith('/verifyEmail/[token]') &&
        !user
      ) {
        router.push({
          pathname: '/login',
          query: {
            from: router.asPath
          }
        });
      }
    }
  }, [isAppLoaded, router.isReady]);
  const prevIsPublic = usePrevious(isPublic);
  useEffect(() => {
    if (!isPublic && prevIsPublic && !user) {
      router.push({
        pathname: '/login',
        query: {
          from: router.asPath
        }
      });
    }
  }, [isPublic]);

  useEffect(() => {
    if (!user?.signupRequirements) {
      return;
    }

    // this is to allow the logic from `src/components/login/Container/Container.js` that checks
    // for `signupRequirements` in componentDidUpdate (if we dont do this, this callback ends up doing the redirect again unnecessarily)
    // we also ensure that redirects are not triggered from event pages following simple sign on
    if (isAuthRoutes || isEventPage) {
      return;
    }

    // TODO: this func should make use of `from` to ensure the user gets redirected back properly
    if (
      user.signupRequirements.includes('email') ||
      user.signupRequirements.includes('name') ||
      user.signupRequirements.includes('country')
    ) {
      router.push('/login/updateProfile');
      return;
    }

    if (
      user.signupRequirements.includes('emailVerified') &&
      !asPath.toLowerCase().startsWith('/verifyemail')
    ) {
      router.push('/login/verifyEmail');
    }
  }, [user?.signupRequirements]);

  const showLoading =
    !pathname.endsWith('events/[slug]') &&
    !pathname.endsWith('/resetPassword/[token]') &&
    !pathname.endsWith('/verifyEmail/[token]') &&
    !isPublic &&
    !user;

  return showLoading ? (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Loader isLoading />
    </div>
  ) : (
    <App
      showMobileAppPrompt={!isPublic || isAuthRoutes || isEventPage}
      showLandingHeader={
        !isEventPage && !isClaimTicketPage && isPublic && !isAuthRoutes
      }
      router={router}
      isPublic={isPublic}
      isEventPage={isEventPage}
    >
      {isPublic && !isEventPage ? (
        <PublicPage showFooter={!isAuthRoutes && !isClaimTicketPage}>
          <Component router={router} {...pageProps} />
        </PublicPage>
      ) : (
        <PrivatePage pathname={pathname}>
          <EventRating
            onComplete={() => {
              // Show migration flow once the event is rated
              setShouldShowMigrationFlow(true);
            }}
          />

          {flags[FLAGS.ENABLE_AUTH_MIGRATION_FLOW] &&
            shouldShowMigrationFlow && <MigrationFlow />}

          <Component router={router} {...pageProps} />
        </PrivatePage>
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userLoading: state.user.loading
});

const actions = { attemptInitialJwtLogin };

export default connect(
  mapStateToProps,
  actions
)(withAuthRedirect(RedirectContainer));
