import _ from 'lodash';
import { types } from 'legacy/actions/appActions';
import { types as userTypes } from 'legacy/actions/userActions';

export const GLOBAL_ERROR_TYPES = { JwtExpiredError: 'JwtExpiredError' };

export const initialState = {
  device: 'desktop',
  stripeHasLoaded: false,
  globalErrors: _.mapValues(GLOBAL_ERROR_TYPES, () => ({}))
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_DEVICE:
      return {
        ...state,
        device: action.payload
      };
    case types.STRIPE_LOADED:
      return { ...state, stripeHasLoaded: true };
    case userTypes.LOGOUT.success:
      return { ...state, globalErrors: initialState.globalErrors };

    default: {
      const errorType = _.get(action, 'payload.error.type');
      if (
        _.get(action, 'type').endsWith('_ERROR') &&
        _.has(GLOBAL_ERROR_TYPES, errorType)
      ) {
        const newState = _.cloneDeep(state);
        newState.globalErrors[errorType] = action.payload.error;
        return newState;
      }
      return state;
    }
  }
}

export default { initialState, reducer: appReducer };
