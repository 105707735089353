import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/notificationActions';
import { types as userTypes } from 'legacy/actions/userActions';
import { toActionID } from '../util/util';

export const initialState = {
  notifications: [],
  loading: _.mapValues(types, () => false),
  errors: _.mapValues(types, () => ({}))
};

const SISTER_TYPES = [userTypes.LOGOUT.success];

export function notificationReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
  }
  switch (action.type) {
    case userTypes.LOGOUT.success:
      return _.cloneDeep(initialState);
    case types.GET_NOTIFICATIONS.success:
      newState.notifications = action.payload;
      return newState;

    case types.VIEW_NOTIFICATIONS.success:
      newState.notifications = newState.notifications.map((n) => {
        if (action.payload.includes(n._id)) {
          n.viewed = true;
        }
        return n;
      });
      return newState;
    case types.DELETE_NOTIFICATION.success:
      newState.notifications = newState.notifications.filter(
        (n) => n._id !== action.payload
      );
      return newState;
    case types.ADD_NOTIFICATION.id:
      return {
        ...state,
        notifications: [
          {
            ...action.payload,
            viewed: action?.payload?.viewed === 'true'
          },
          ...state.notifications
        ]
      };

    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: notificationReducer };
