import config from 'config';

import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: `${config.FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${config.FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: `${config.FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: config.FIREBASE_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: `G-${config.FIREBASE_MEASUREMENT_ID}`
};

export function init() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    return;
  }

  firebase.app();
}

export async function getToken() {
  const messaging = firebase.messaging();
  const token = await messaging.getToken({
    vapidKey: config.FIREBASE_VAPID_KEY
  });

  return {
    messaging,
    token
  };
}

export async function initFCM({ onMessage }) {
  init();

  const { messaging, token } = await getToken();
  messaging.onMessage(onMessage);

  return token;
}
