import router from 'next/router';
import jwtDecode from 'jwt-decode';
import { isWebview } from 'legacy/common/utils/webview';

import config from 'config';

import { login } from 'legacy/actions/userActions';

import storage from 'legacy/common/utils/storage';
import { setSentryUser } from 'legacy/common/utils/sentry';
import { STORAGE_KEYS } from 'legacy/common/constants/storage';

import { UserMe } from 'legacy/user/types';
import { LoginResponse } from './types';

import { store } from '../store';
import { trackUserSession } from 'legacy/common/utils/amplitude';
import { trackUserSessionOnGa } from 'legacy/common/utils/ga';

interface TokenData {
  _id: string;
  exp: string;
  iat: string;
}

export function decodeJWT(jwt: string) {
  return jwtDecode<TokenData>(jwt);
}

function setToken(token: string) {
  const jwt = token.split('JWT ')[1];
  const { exp } = decodeJWT(jwt);

  storage.setItem(STORAGE_KEYS.AUTH_TOKEN, jwt);

  document.cookie = `token=${jwt}; path=/; Max-Age=${exp}`;
}

type Params = {
  from: string;
};
export function handleRedirect(user: UserMe, params?: Params) {
  const from = params?.from || router?.query?.from;
  let query = null;
  if (from) {
    query = {
      from
    };
  }

  if (
    user?.signupRequirements?.includes('email') ||
    user?.signupRequirements?.includes('name') ||
    user?.signupRequirements?.includes('country')
  ) {
    router.push({
      pathname: '/login/updateProfile',
      query
    });
    return;
  }

  if (user?.signupRequirements?.includes('emailVerified')) {
    router.push({
      pathname: '/login/verifyEmail',
      query
    });
    return;
  }

  if (typeof from === 'string' && from) {
    router.push(from);
    return;
  }

  router.push('/dashboard');
}

export function handleLogin(data: LoginResponse, params?: Params) {
  trackUserSession(data.user._id);
  trackUserSessionOnGa(data.user._id);

  setToken(data.token);
  setSentryUser(data.user);

  handleRedirect(data.user, params);
  store.dispatch(login(data));
}

/**
 * Copy of handleLogin without the redirect.
 * Used to support simple sign on where we want to preserve the active view after authentication.
 */
export function handleLoginNoRedirect(data: LoginResponse) {
  trackUserSession(data.user._id);
  trackUserSessionOnGa(data.user._id);

  setToken(data.token);
  setSentryUser(data.user);

  store.dispatch(login(data));
}

export function parsePhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/\D/g, '');
}

export function parseRedirectState(state: string): {
  from?: string;
} {
  if (!state) {
    return {};
  }

  try {
    return JSON.parse(state);
  } catch (e) {
    return {};
  }
}

// NOTE: docs - https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
export function initAppleID({ setErrorMessage, onSuccess }) {
  const { userAgent } = window.navigator;

  // if we're using a webview, dont use popup
  const usePopup = !isWebview(userAgent);

  const state = JSON.stringify({ from: router.query?.from });

  (window as any).AppleID?.auth?.init({
    clientId: config.APPLE_CLIENT_ID,
    scope: 'name email',
    state,
    usePopup,
    // if we aren't using popup, we need to redirect to backend url so that
    // it can redirect us back here (I forget why we couldnt just come straight back to
    // the site, I believe it was because the redirect is done via a POST request rather than a GET
    // request). The backend takes the POST req data, encodes it as query params and redirects user
    // back to /login/apple_redirect
    redirectURI: usePopup
      ? `${config.SITE_URL}login/apple_redirect`
      : `${config.BASE_URL}auth/login/apple/redirect`
  });

  const onAppleIDSignInOnSuccess = (data: any) => {
    onSuccess(data.detail);
  };

  const onAppleIDSignInOnFailure = (error: any) => {
    // if user simply closed window, dont show error
    if (error?.detail && error?.detail?.error === 'popup_closed_by_user') {
      return;
    }

    // handle error, apparently it's just a code so putting generic message.
    setErrorMessage(
      'We could not sign you in with Apple, please try another login method to continue.'
    );
  };

  // Listen for authorization success
  document.addEventListener('AppleIDSignInOnSuccess', onAppleIDSignInOnSuccess);

  // Listen for authorization failures
  document.addEventListener('AppleIDSignInOnFailure', onAppleIDSignInOnFailure);

  const removeEventListeners = () => {
    document.removeEventListener(
      'AppleIDSignInOnSuccess',
      onAppleIDSignInOnSuccess
    );
    document.removeEventListener(
      'AppleIDSignInOnFailure',
      onAppleIDSignInOnFailure
    );
  };

  return {
    removeEventListeners
  };
}

// https://stackoverflow.com/a/16702965
export const phoneNumberRegex =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

// https://github.com/jquense/yup/issues/743#issuecomment-884851222
export const emailRegex =
  /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

type LoginMethod = 'phone' | 'email' | 'unknown';

export const isPhoneNumber = (value: string): boolean =>
  phoneNumberRegex.test(value);
export const isEmailAddress = (value: string): boolean =>
  emailRegex.test(value);

export const getLoginMethod = (value: string): LoginMethod => {
  if (isPhoneNumber(value)) {
    return 'phone';
  }

  if (isEmailAddress(value)) {
    return 'email';
  }

  return 'unknown';
};
