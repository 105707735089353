import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { AvatarBadgePositions, AvatarBadgeSizes } from '../../types';

interface Props {
  children: ReactNode;
  size?: AvatarBadgeSizes;
  position?: AvatarBadgePositions;
}

function AvatarBadge({
  children,
  size = AvatarBadgeSizes.Md,
  position = AvatarBadgePositions.BottomRight
}: Props) {
  return (
    <div
      className={classNames(
        'bg-primary-50 border-2 border-white absolute rounded-full overflow-hidden flex items-center justify-center',
        `w-${size} h-${size}`,
        position === AvatarBadgePositions.BottomRight && 'bottom-0 right-0'
      )}
    >
      {children}
    </div>
  );
}

export default AvatarBadge;
