import { Transaction } from 'legacy/common/types/models';
import { UserLean, UserPublic } from 'legacy/user/types';

export interface Group {
  id: string;
  name: string;
  address: string;
  description: string;
  badgeImageUrl: string;
  coverImageUrl: string;
  creator: {
    id: string;
    firstName: string;
    lastName: string;
  };
  location: {
    coordinates: number[];
  };
  metadata: {
    memberCount: number;
    memberPreview: {
      id: string;
      firstName: string;
      lastName: string;
      photoUrl: string;
    }[];
    currentUser: {
      role: string;
      status: string;
    };
  };
}

export interface EventBasic {
  _id: string;
  title: string;
  isOrganizer?: boolean;
  photo: {
    position: string;
    url: string;
  };
  startDate: string;
}

export type CombinedSearchResult =
  | (EventBasic & { type: 'event' })
  | (UserLean & { type: 'user' })
  | (Group & { type: 'group' });

/** Guest breakdown for event organizers */
export type EventGuestBreakdown = Pick<
  Transaction,
  'amount' | 'payoutAmount' | 'currency' | 'tier' | 'event' | 'createdAt'
> & {
  user: UserLean;
  scanned: boolean;
  ticketId: string;
  ticketNotes: string;
  status: 'completed' | 'refunded' | 'revoked';
  numberOfTickets: number;
  numberOfUnclaimedTickets: number;
  purchaser: {
    id: string;
    name: string;
    firstName?: string;
    lastName?: string;
    photoUrl?: string;
  };
  isPurchaser: boolean;
  transactionId: string;
  purchasedAt?: Date;
  name?: string;
  tierData: {
    id: string;
    name: string;
  };
};

export enum EventGuestsCategories {
  All = 'all',
  Friends = 'friends',
  Organizers = 'organizers'
}

export type EventGuestsData = { total: number; users: UserPublic[] };

export type EventGuestsSearchResults = {
  [EventGuestsCategories.All]: EventGuestsData;
  [EventGuestsCategories.Friends]: EventGuestsData;
  [EventGuestsCategories.Organizers]: EventGuestsData;
};
