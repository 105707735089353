import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import { toActionObject } from '../util/util';
import { resolveFormError, resolveError } from './actionUtil';

export const actionIDs = ['CONTACT_US', 'CONTACT_US_FORM'];

export const types = toActionObject(actionIDs);

export function sendContactEmail(contactInfo) {
  return function (dispatch) {
    dispatch({ type: types.CONTACT_US.start });
    bounceApiClient
      .post('/service/contact', contactInfo)
      .then((res) => {
        dispatch({ type: types.CONTACT_US.success, payload: res.data });
        dispatch({ type: types.CONTACT_US_FORM.success });
      })
      .catch((err) => {
        dispatch({
          type: types.CONTACT_US.error,
          payload: resolveError(err)
        });
        dispatch({
          type: types.CONTACT_US_FORM.success,
          payload: { FORM_ERROR: resolveFormError(err) }
        });
      });
  };
}
