import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import styles from './DefaultUserPhoto.module.scss';

const DefaultUserPhoto = ({ style }) => (
  <div className={styles.wrapper} style={style}>
    <i className="fas fa-user" />
  </div>
);

DefaultUserPhoto.propTypes = exact({
  style: PropTypes.objectOf(PropTypes.string)
});

export default DefaultUserPhoto;
