export const types = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  CLEAR_MODAL: 'CLEAR_MODAL',
  MODAL_BACK: 'MODAL_BACK',
  MODAL_NEXT: 'MODAL_NEXT',
  CLEAR_HISTORY: 'CLEAR_HISTORY',
  UPDATE_MODAL_PROPS: 'UPDATE_MODAL_PROPS'
};

export const modalComponentTypes = {
  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  ADD_BANK_MANUALLY: 'ADD_BANK_MANUALLY',
  ADD_PHONE: 'ADD_PHONE',
  CHOOSE_PAYMENT_METHOD: 'CHOOSE_PAYMENT_METHOD',
  CREATE_CONNECT: 'CREATE_CONNECT',
  INVITE_TO_BOUNCE: 'INVITE_TO_BOUNCE',
  INVITE_USERS_TO_EVENT: 'INVITE_USERS_TO_EVENT',
  JOIN_EVENT: 'JOIN_EVENT',
  NOTIFY_GUESTS: 'NOTIFY_GUESTS',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  VIEW_GUESTS: 'VIEW_GUESTS',
  VIEW_TICKET: 'VIEW_TICKET',
  VALIDATE_TICKET: 'VALIDATE_TICKET',
  SHARE_URL: 'SHARE_URL',
  FILL_FORM: 'FILL_FORM',
  /* common modals */
  CONFIRMATION: 'CONFIRMATION',
  ALERT: 'ALERT'
};

export function clearHistory() {
  return { type: types.CLEAR_HISTORY };
}

export function showModal(modalType, modalProps, futureState) {
  return {
    type: types.SHOW_MODAL,
    modalType,
    modalProps,
    futureState
  };
}

// close modal
export function hideModal() {
  return { type: types.HIDE_MODAL };
}

// clear modal
export function clearModal() {
  return { type: types.CLEAR_MODAL };
}

// go to prev modal state or close modal
export function modalBack() {
  return { type: types.MODAL_BACK };
}

// update the current props
// allows logic inside of a connected component to update the loading state of the modal, etc
export function updateModalProps(modalProps) {
  return { type: types.UPDATE_MODAL_PROPS, modalProps };
}

// not sure about this one
export function modalNext() {
  return { type: types.MODAL_NEXT };
}
