import React, { useState, useCallback, useEffect } from 'react';

import classnames from 'classnames';
import { connect } from 'react-redux';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSuccess from './ResetPasswordSuccess';

import ModalBase from '../ModalBase/ModalBase';

import styles from './ResetPasswordRequest.module.scss';

function ResetPasswordRequest({ isOpen, baseModalProps, device, close }) {
  const [success, setSuccess] = useState(false);

  const onSuccess = useCallback(() => {
    setSuccess(true);
  }, [setSuccess]);

  useEffect(() => {
    if (!isOpen) {
      setSuccess(false);
    }
  }, [isOpen]);

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={classnames(styles.wrapper, device, 'component')}>
        {success ? (
          <ResetPasswordSuccess close={close} />
        ) : (
          <ResetPasswordForm onSuccess={onSuccess} device={device} />
        )}
      </div>
    </ModalBase>
  );
}

function mapStateToProps(state) {
  return { device: state.app.device };
}
export default connect(mapStateToProps)(ResetPasswordRequest);
