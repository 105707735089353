import React from 'react';

import useParams from 'legacy/common/hooks/useParams';
import { isBounceWebview } from 'legacy/common/utils/webview';

const withDisableInWebview = (WrappedComponent: React.FC) => (props: any) => {
  const params = useParams<{ webview: string }>();

  if (isBounceWebview(params)) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...props} />;
};

export default withDisableInWebview;
