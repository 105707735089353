import config from 'config';

import { loadStripe, Stripe } from '@stripe/stripe-js';

// Stripe instance
let stripe: Stripe;
let currentCountry: string;

const STRIPE_KEYS = {
  ca: config.STRIPE_PK_CA,
  us: config.STRIPE_PK_US
};

export async function init(country: string) {
  if (!country) {
    return Promise.reject(new Error('You need to specify a country'));
  }

  if (!STRIPE_KEYS[country.toLowerCase()]) {
    return Promise.reject(new Error(`Country not supported: ${country}`));
  }

  stripe = await loadStripe(STRIPE_KEYS[country.toLowerCase()]);
  currentCountry = country;

  return stripe;
}

export function getInstance() {
  return stripe;
}

export function getCountry() {
  return currentCountry;
}
