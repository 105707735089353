import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import { pickBy, identity } from 'lodash';

import { toActionObject } from '../util/util';
import {
  createAxiosRequest,
  resolveError,
  resolveFormError
} from './actionUtil';

import * as stripe from 'legacy/common/utils/stripe';

export const actionIDs = [
  'ADD_BANK_MANUALLY',
  'ADD_BANK_MANUALLY_FORM',
  'ADD_PLAID_BANK',
  'DELETE_BANK',
  'SET_DEFAULT_BANK',
  'STRIPE_BANK',
  'UPDATE_ACCOUNT_VERIFICATION',
  'UPDATE_ACCOUNT_VERIFICATION_FORM',
  'GET_ALL_BANKS'
];

export const types = toActionObject(actionIDs);

export function getAllBanks() {
  return createAxiosRequest({
    action: 'get',
    url: '/api/connect/payout'
  });
}

export function updateAccountVerification({
  address,
  dob,
  sin,
  firstName,
  lastName,
  email,
  phone,
  onSuccess,
  tosAccepted,
  ssnLast4
}) {
  return createAxiosRequest({
    action: 'put',
    url: '/api/connect',
    params: pickBy(
      {
        address,
        dob: dob?.year ? dob : '',
        idNumber: sin,
        firstName,
        lastName,
        email,
        phone,
        tosAccepted,
        ssnLast4
      },
      identity
    ),
    dispatchType: types.UPDATE_ACCOUNT_VERIFICATION,
    formType: types.UPDATE_ACCOUNT_VERIFICATION_FORM,
    onSuccess
  });
}

export function addBank(info, orgId, onSuccess) {
  return function (dispatch) {
    dispatch({ type: types.ADD_BANK_MANUALLY.start });
    const { defaultBank, ...rest } = info;

    stripe
      .getInstance()
      ?.createToken('bank_account', rest)
      .then((res) => {
        if (res.error) {
          dispatch({ type: 'STRIPE_BANK_ERROR', payload: res });
          dispatch({
            type: types.ADD_BANK_MANUALLY_FORM.success,
            payload: { FORM_ERROR: res.error.message.split('.')[0] }
          });
        } else {
          bounceApiClient
            .post(`/api/connect/payout${orgId ? `?org=${orgId}` : ''}`, {
              token: res.token.id,
              setDefault: defaultBank
            })
            .then((response) => {
              dispatch({
                type: types.ADD_BANK_MANUALLY.success,
                payload: { banks: response.data, orgId }
              });
              dispatch({
                type: types.ADD_BANK_MANUALLY_FORM.success
              });

              onSuccess && onSuccess();
            })
            .catch((err) => {
              dispatch({
                type: types.ADD_BANK_MANUALLY.error,
                payload: resolveError(err)
              });
              dispatch({
                type: types.ADD_BANK_MANUALLY_FORM.success,
                payload: {
                  FORM_ERROR: resolveFormError(err)
                }
              });
            });
        }
      });
  };
}

export function addPlaidBank(plaidPublicToken, accountID, orgId) {
  return function (dispatch) {
    dispatch({ type: types.ADD_PLAID_BANK.start });

    bounceApiClient
      .post(`/api/connect/plaidBank${orgId ? `?org=${orgId}` : ''}`, {
        plaidPublicToken,
        accountID
      })
      .then((response) => {
        dispatch({
          type: types.ADD_PLAID_BANK.success,
          payload: { banks: response.data, orgId }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.ADD_PLAID_BANK.error,
          payload: resolveError(err)
        });
      });
  };
}

export function setDefaultBank(bankAccountId, orgId) {
  return createAxiosRequest({
    action: 'post',
    url: `/api/connect/payout/default${orgId ? `?org=${orgId}` : ''}`,
    params: { bankAccountId },
    dispatchType: types.SET_DEFAULT_BANK,
    generatePayload: (data) => ({ ...data, orgId })
  });
}

export function deleteBank(bankId, orgId) {
  return createAxiosRequest({
    action: 'delete',
    url: `/api/connect/payout/${bankId}${orgId ? `?org=${orgId}` : ''}`,
    dispatchType: types.DELETE_BANK,
    generatePayload: (data) => ({ banks: data, orgId })
  });
}
