import { useDebounce } from 'use-debounce';

import { useEventsSearch } from 'legacy/search/hooks';

import * as datetime from 'legacy/common/utils/datetime';

import Message from './Message';
import ResultList from './ResultList';

type EventsProps = {
  input: string;
};

const Events: React.FC<EventsProps> = ({ input }) => {
  const [debouncedInput] = useDebounce(input, 200);
  const isDebouncing = input !== debouncedInput;

  const { data: events, isLoading } = useEventsSearch(debouncedInput);

  if (!debouncedInput && !isDebouncing) {
    return <Message message="Search for events" />;
  }

  if (debouncedInput && !isLoading && !isDebouncing && !events?.length) {
    return <Message message={`No results for "${debouncedInput}"`} />;
  }

  return (
    <ResultList
      id="results-events"
      isLoading={isLoading || isDebouncing}
      results={events?.map((event) => ({
        id: event._id,
        image: event.photo.url || '/img/search/event.svg',
        value: event.title,
        subtitle: datetime.format(event.startDate),
        linkTo: event.isOrganizer
          ? `/events/${event._id}/dashboard`
          : `/events/${event._id}`,
        rawData: {
          ...event,
          type: 'event'
        }
      }))}
    />
  );
};

export default Events;
