import { RefObject, useEffect } from 'react';

const useOnOutsideClick = (
  ref: RefObject<HTMLElement>,
  { isActive, onOutsideClick }
) => {
  const handleOutsideClick = (e) => {
    if (ref.current?.contains(e.target)) {
      return;
    }

    onOutsideClick();
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isActive, handleOutsideClick]);
};

export default useOnOutsideClick;
