import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Carousel } from 'antd';
import styles from './ViewUserGallery.module.scss';
import ModalBase from '../ModalBase/ModalBase';

const ViewUserGallery = ({
  baseModalProps,
  gallery,
  index,
  isOpen,
  modalBack
}) => {
  const slider = useRef();
  const [hasRef, setHasRef] = useState(false);

  useEffect(() => {
    if (hasRef) {
      slider.current.goTo(index, true);
    }
  }, [hasRef, index]);

  useEffect(() => {
    if (!isOpen) {
      setHasRef(false);
    }
  }, [isOpen]);

  const prev = () => {
    if (hasRef) {
      slider.current.prev();
    }
  };

  const next = () => {
    if (hasRef) {
      slider.current.next();
    }
  };

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      className={styles.modal}
    >
      {isOpen && (
        <div className={cn(styles.wrapper, 'custom')}>
          <Carousel
            dots={false}
            className={styles.carousel}
            ref={(ref) => {
              slider.current = ref;
              setHasRef(true);
            }}
          >
            {gallery.map(({ url, _id }) => (
              <div key={_id} className={styles.pic}>
                <div style={{ backgroundImage: `url(${url})` }} />
              </div>
            ))}
          </Carousel>
          <div
            className={cn(styles.close, styles.btn)}
            onClick={() => modalBack()}
          >
            <i className="fa fa-times" />
          </div>
          {gallery.length > 1 && (
            <>
              <div
                className={cn(styles.btn, styles.left)}
                onClick={() => prev()}
              >
                <i className="fa fa-chevron-left" />
              </div>
              <div
                className={cn(styles.btn, styles.right)}
                onClick={() => next()}
              >
                <i className="fa fa-chevron-right" />
              </div>
            </>
          )}
        </div>
      )}
    </ModalBase>
  );
};

export default ViewUserGallery;
