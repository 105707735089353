import { useEffect, useRef, useState } from 'react';
import { Carousel } from 'antd';
import * as Yup from 'yup';
import { CarouselRef } from 'antd/lib/carousel';

import { useGetRequiredEventRatings } from 'legacy/user/hooks';

import { format } from 'legacy/common/utils/datetime';
import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';

import StarRating from './StarRating';
import EmojiRating from './EmojiRating';
import {
  Impression,
  RequiredEventRating,
  SubmitEventRatingBody
} from 'legacy/events/types';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useSubmitEventRatingCommand } from 'legacy/events/hooks';
import { Formik } from 'formik';
import * as toast from 'legacy/common/utils/toast';

import BackButton from 'legacy/common/components/back-button/BackButton';

import withLoginCheck from 'legacy/auth/hoc/withLoginCheck';
import withDisableInWebview from 'legacy/auth/hoc/withDisableInWebview';

const EventRatingSchema = Yup.object().shape({
  overall: Yup.object().shape({
    score: Yup.number()
  }),
  safety: Yup.object().shape({
    explanation: Yup.string().max(1000).label('Explanation'),
    impression: Yup.string()
  })
});

type EventRatingProps = {
  onComplete?: () => void;
};

const EventRating: React.FC<EventRatingProps> = ({ onComplete }) => {
  const [eventToRate, setEventToRate] =
    useState<RequiredEventRating>(undefined);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const carouselRef = useRef<CarouselRef>();

  const { data: requiredEventRatings } = useGetRequiredEventRatings();

  useEffect(() => {
    const eventToRate = requiredEventRatings?.requiredRatings?.[0];

    if (!eventToRate) {
      return;
    }

    setEventToRate(eventToRate);
  }, [requiredEventRatings]);

  const submitEventRatingCommand = useSubmitEventRatingCommand();

  const submitEventRating = (values: SubmitEventRatingBody) => {
    submitEventRatingCommand.mutate({
      eventId: eventToRate.event.id,
      body: values
    });

    setEventToRate(null);
    onComplete?.();
  };

  if (!eventToRate) {
    return null;
  }

  return (
    <Modal visible type={ModalType.SMALL}>
      <Formik
        initialValues={{
          overall: {
            score: undefined
          },
          safety: {
            explanation: undefined,
            impression: undefined
          }
        }}
        onSubmit={(values) => {
          submitEventRating(values);

          // Show a success toast even before the request completes
          toast.success(
            'Thanks for your feedback!',
            'Your feedback helps us bring better events'
          );
        }}
        validationSchema={EventRatingSchema}
      >
        {({ errors, values, isValid, setFieldValue, submitForm }) => (
          <div className="h-full px-4 py-14">
            <div className="px-4 h-full overflow-hidden pb-20">
              <h1 className="text-2xl" id="top">
                How was your recent event?
              </h1>
              <div className="pb-6 border-b-1 border-dashed">
                <div
                  className="mt-6 text-xl text-gray-400"
                  data-testid="event-title"
                >
                  {eventToRate.event.title}
                </div>
                <img
                  className="mt-4 w-full h-56 object-cover rounded-2xl md:h-56"
                  src={eventToRate.event.photoUrl}
                />
                <div className="mt-6 flex justify-between">
                  <div className="text-gray-400" data-testid="event-startdate">
                    {format(eventToRate?.event?.startDate)}
                  </div>
                  <div
                    className="text-sm text-gray-400 flex"
                    data-testid="event-address"
                  >
                    <FaMapMarkerAlt className="text-base mr-2" />
                    {eventToRate?.event?.address}
                  </div>
                </div>
              </div>
              <Carousel
                ref={carouselRef}
                dots={false}
                infinite={false}
                autoplay={false}
                touchMove={false}
              >
                <div className="w-10 mt-12 pb-10">
                  <div className="text-center text-2xl text-gray-700">
                    How was your overall experience?
                  </div>

                  <div className="mt-6 px-10">
                    <StarRating
                      rating={values.overall.score}
                      onSelect={(rating) => {
                        setFieldValue('overall.score', rating);

                        setTimeout(() => {
                          carouselRef.current?.next();
                          setShowSubmitButton(true);
                        }, 600);
                      }}
                    />
                  </div>
                </div>

                <div
                  id="smiley-rating"
                  className="w-10 mt-4 pb-10 border-b-1 border-dashed"
                >
                  <div className="text-center text-2xl text-gray-700">
                    <BackButton
                      onClick={() => {
                        carouselRef.current?.prev();
                      }}
                    />
                    Did you feel safe?
                    <span className="text-base text-gray-400"> (optional)</span>
                    <div className="mt-6 px-10">
                      <EmojiRating
                        rating={values.safety.impression}
                        onSelect={(rating) => {
                          setFieldValue('safety.impression', rating);

                          if (rating === Impression.GOOD) {
                            setFieldValue('safety.explanation', undefined);
                            const el = document.querySelector('#top');

                            // To reset scroll when textarea is removed
                            el.scrollIntoView();
                          }

                          // Scroll "tell us more" into view
                          setTimeout(() => {
                            const el = document.querySelector('#tell-us-more');

                            if (el) {
                              el.scrollIntoView({
                                behavior: 'smooth'
                              });

                              // Focus textarea after it scrolls into view
                              setTimeout(() => {
                                document
                                  .getElementById('rating-textarea')
                                  ?.focus();
                              }, 300);
                            }
                          }, 100);
                        }}
                      />
                    </div>
                  </div>

                  {(values.safety.impression === Impression.BAD ||
                    values.safety.impression === Impression.OK) && (
                    <div
                      className="mt-6 text-base text-gray-700"
                      id="tell-us-more"
                    >
                      Tell us more about your experience.
                      <span className="text-base text-gray-400">
                        {' '}
                        (optional)
                      </span>
                      <div className="mt-2">
                        <textarea
                          id="rating-textarea"
                          className="w-full h-24 border-1 rounded-2xl p-2 resize-none"
                          value={values.safety.explanation}
                          onChange={(e) => {
                            setFieldValue('safety.explanation', e.target.value);
                          }}
                        />
                        {errors?.safety?.explanation && (
                          <div className="mt-2 text-sm text-center text-red-500">
                            {errors.safety.explanation as string}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Carousel>
            </div>

            <div className="absolute w-full px-8 pb-8 left-0 bottom-0 bg-white">
              <div className="text-sm text-center mb-4 text-gray-400">
                All ratings will remain anonymous.
              </div>

              {showSubmitButton && (
                <Button
                  disabled={!isValid}
                  onClick={submitForm}
                  testId="button-submit"
                >
                  Submit
                </Button>
              )}

              {!showSubmitButton && (
                <div className="mt-4">
                  <Button
                    testId="button-skip"
                    type={ButtonType.Clear}
                    // Silently submit values if skipped
                    onClick={() => submitEventRating(values)}
                  >
                    <span className="font-semibold text-gray-400">Skip</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default withDisableInWebview(withLoginCheck(EventRating));
