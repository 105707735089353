import cn from 'classnames';

import styles from './Spinner.module.scss';

export enum SpinnerType {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  DANGER = 'danger'
}

type SpinnerProps = {
  type?: SpinnerType;
  center?: boolean;
  message?: string;
};

const Spinner: React.FC<SpinnerProps> = ({ message, type, center }) => (
  <div
    data-testid="spinner"
    className={cn(styles.container, {
      [styles.center]: center
    })}
  >
    <div
      className={cn(styles.loader, {
        [styles.primary]: type === SpinnerType.PRIMARY,
        [styles.danger]: type === SpinnerType.DANGER
      })}
    >
      Loading...
    </div>
    {!!message && <div className={styles.message}>{message}</div>}
  </div>
);

export default Spinner;
