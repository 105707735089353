import storage from '../utils/storage';
import { STORAGE_KEYS } from 'legacy/common/constants/storage';

/**
 * Convenience hook to determine whether a user is authenticated or not.
 * Note that it does NOT say anything about whether or not their token is valid or expired.
 */
const useAuthenticationStatus = () => ({
  isAuthenticated: () => !!storage.getItem(STORAGE_KEYS.AUTH_TOKEN)
});

export default useAuthenticationStatus;
