import React from 'react';
import { Button } from 'semantic-ui-react';
import cn from 'classnames';
import ModalBase from '../../ModalBase/ModalBase';

const Alert = ({
  type,
  actionText,
  message,
  baseModalProps,
  onSuccess,
  hideBackButton,
  onBack,
  backImg = 'Back',
  close
}) => (
  <ModalBase
    baseModalProps={baseModalProps}
    back_img={backImg}
    contentClassName="confirmation"
    className={cn({ success: type === 'success', error: type === 'error' })}
    hideBackButton={hideBackButton}
    onBack={onBack}
  >
    <div className="confirmationMessage">
      <p>{message}</p>
    </div>

    <div className="actions">
      <Button
        primary
        onClick={() => {
          onSuccess({
            close
          });
        }}
        data-cy="action-btn"
      >
        {actionText}
      </Button>
    </div>
  </ModalBase>
);

Alert.defaultProps = {
  actionText: 'Ok'
};

export default Alert;
