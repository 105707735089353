import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import * as Sentry from '@sentry/nextjs';

import { UserMe } from 'legacy/user/types';

import * as toast from 'legacy/common/utils/toast';

import { useResendVerificationEmailCommand } from 'legacy/auth/hooks';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';
import UpdateEmailFlow from 'legacy/user/components/settings/email/UpdateEmailFlow';

type VerifyEmailAddressModalProps = {
  user: UserMe;
  onClose: () => void;
};
const VerifyEmailAddressModal: React.FC<VerifyEmailAddressModalProps> = ({
  user,
  onClose
}) => {
  const [showUpdateEmailFlow, setShowUpdateEmailFlow] = useState(false);
  const resendVerificationEmailCommand = useResendVerificationEmailCommand();

  if (showUpdateEmailFlow) {
    return (
      <UpdateEmailFlow
        onClose={() => {
          setShowUpdateEmailFlow(false);
          onClose();
        }}
        onSuccess={() => {
          setShowUpdateEmailFlow(false);
          onClose();
        }}
      />
    );
  }

  return (
    <Modal visible type={ModalType.ALERT} onClose={onClose}>
      <div className="px-8 py-6" data-testid="modal-verify-email">
        <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
          Verify Email Address
        </h1>
        <p className="mt-4 font-normal text-base text-gray-500 leading-loose">
          We sent a verification email to the following address.
        </p>

        <p
          data-testid="button-update-email"
          className="flex items-center mt-2 font-normal text-base text-primary-500 cursor-pointer hover:underline"
          onClick={() => {
            setShowUpdateEmailFlow(true);
          }}
        >
          {user?.email}
          <FaEdit className="ml-2" />
        </p>

        <p className="flex items-center  mt-6 font-normal text-sm text-gray-400">
          {resendVerificationEmailCommand.isLoading ? (
            'Sending Again...'
          ) : (
            <>
              {"Didn't receive? "}
              <span
                className="ml-1 text-primary-400 cursor-pointer"
                onClick={() => {
                  if (!user?.email) {
                    // This should never happen but send this scenario if it occurs.
                    Sentry.captureException(
                      new Error(
                        `Encountered an unexpected error: User clicked on "Resend" button without an email`
                      )
                    );

                    return;
                  }

                  resendVerificationEmailCommand.mutate(user.email, {
                    onSuccess: () => {
                      toast.success('Confirmation email sent.');
                    }
                  });
                }}
              >
                Send Again
              </span>
            </>
          )}
        </p>

        <div className="mt-8 flex justify-end">
          <Button type={ButtonType.Secondary} onClick={onClose}>
            <span className="text-gray-500">Close</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyEmailAddressModal;
