import styles from './Search.module.scss';

import cn from 'classnames';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import Results from 'legacy/search/components/Results';

import useOnRouteChange from 'legacy/common/hooks/useOnRouteChange';
import useOnOutsideClick from 'legacy/common/hooks/useOnOutsideClick';
import { FLAGS } from 'legacy/common/constants/launchdarkly';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Search = () => {
  const searchRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>();

  const [isSearchBoxActive, setIsSearchBoxActive] = useState(false);
  const [isVisibleForPhone, setIsVisibleForPhone] = useState(false);

  useEffect(() => {
    if (isSearchBoxActive) {
      inputRef.current.focus();
    }
  }, [isSearchBoxActive]);

  const close = () => {
    setIsSearchBoxActive(false);
    setIsVisibleForPhone(false);
  };

  useOnRouteChange(close);

  useOnOutsideClick(searchRef, {
    isActive: isSearchBoxActive,
    onOutsideClick: () => {
      setIsSearchBoxActive(false);
    }
  });

  const [input, setInput] = useState('');

  const onInputChange = (e: SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    setInput(target.value);
  };

  const flags = useFlags();
  return (
    <>
      <div
        data-testid="search-button"
        onClick={() => {
          setIsVisibleForPhone(true);
          setIsSearchBoxActive(true);
        }}
        className={styles.mobileSearch}
      >
        <img src="/img/search/search-white.svg" />
      </div>

      <div
        ref={searchRef}
        className={styles.wrapper}
        style={isVisibleForPhone ? { display: 'block' } : {}}
      >
        <div className={styles.searchContainer}>
          <img
            className={styles.back}
            src="/img/search/back.svg"
            onClick={() => {
              close();
            }}
          />
          <div
            className={cn(styles.search, {
              [styles.active]: isSearchBoxActive
            })}
          >
            <img src="/img/search/search.svg" />
            <input
              autoComplete="off"
              value={input}
              ref={inputRef}
              name="global-search"
              className={styles.input}
              placeholder={
                flags[FLAGS.ENABLE_GROUPS]
                  ? 'Search events, people, and organizations'
                  : 'Search events and people'
              }
              onFocus={() => {
                setIsSearchBoxActive(true);
              }}
              onChange={onInputChange}
              data-testid="search-input"
            />
            {!!input && (
              <img
                id="clear-button"
                data-testid="search-clear"
                onClick={() => {
                  setInput('');
                  inputRef.current.focus();
                }}
                className={styles.close}
                src="/img/search/cross.svg"
              />
            )}
          </div>
        </div>

        {isSearchBoxActive && <Results close={close} input={input} />}
      </div>
    </>
  );
};

export default Search;
