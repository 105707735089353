import React, { useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { Field } from 'react-final-form';
import styles from './FormFields.module.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { Dropdown } from 'semantic-ui-react';

const YearMonthForm = ({ date, localeUtils, onChange, fromMonth, toMonth }) => {
  const startMonth =
    date.getFullYear() > fromMonth.getFullYear() ? 0 : fromMonth.getMonth();
  const endMonth = toMonth.getMonth();
  const months = localeUtils.getMonths().slice(startMonth, endMonth + 1);

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleMonth = (e, { value }) => {
    onChange(new Date(date.getFullYear(), value));
  };

  const handleYear = (e, { value }) => {
    onChange(new Date(value, date.getMonth()));
  };

  return (
    <div className="DayPicker-Caption" style={{ marginRight: '3.1em' }}>
      <Dropdown
        className={styles.captionDropdown}
        selection
        compact
        name="month"
        onChange={handleMonth}
        value={date.getMonth()}
        options={months.map((month, i) => ({
          key: month,
          value: i + startMonth,
          text: month,
          className: styles.captionOption
        }))}
      />
      <Dropdown
        className={styles.captionDropdown}
        selection
        compact
        name="year"
        onChange={handleYear}
        value={date.getFullYear()}
        options={years.map((year) => ({
          key: year,
          value: year,
          text: year,
          className: styles.captionOption
        }))}
      />
    </div>
  );
};

const DatePickerField = ({
  name,
  formProps,
  fieldProps,
  disabledBefore, // only passed to endDate input field to disable dates before startDate
  disabledAfter,
  validate,
  className,
  onChange,
  ...props
}) => {
  const currentValue = formProps?.values[name] ?? '';
  const selectedDay = currentValue ? moment(currentValue).toDate() : undefined;

  const change = onChange || formProps?.form.change;

  const handleChange = (value) => {
    if (!value) {
      return;
    }

    const day = value || null;
    const newValue = moment(day).format('YYYY-MM-DD');

    change(name, newValue);
  };

  return (
    <>
      <Field
        name={name}
        validate={validate}
        {...fieldProps}
        component={({ meta }) => {
          const [month, setMonth] = useState();
          const handleYearMonthChange = (month) => {
            setMonth(month);
          };

          return (
            <>
              <DayPickerInput
                classNames={{
                  container: cn(className ? '' : styles.datepickerField, {
                    [styles.datepickerError]: meta.error && meta.touched
                  })
                }}
                value={selectedDay}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder="mm/dd/yyyy"
                onDayChange={handleChange}
                dayPickerProps={{
                  month,
                  selectedDays: selectedDay,
                  showOutsideDays: true,
                  className: styles.calender,
                  disabledDays: {
                    before: disabledBefore,
                    after: disabledAfter
                  },
                  captionElement: ({ date, localeUtils }) => (
                    <YearMonthForm
                      date={date}
                      localeUtils={localeUtils}
                      onChange={handleYearMonthChange}
                      toMonth={disabledAfter}
                      fromMonth={disabledBefore}
                    />
                  )
                }}
                inputProps={{
                  style: {
                    // Removes inner shadow in input for webview
                    backgroundClip: 'padding-box'
                  },
                  'data-cy': 'datepicker',
                  className: className || 'daypicker_input',
                  disabled: props.disabled
                }}
              />
              {meta.error && meta.touched && (
                <div className={styles.datepickerErrorMessage}>
                  {meta.error}
                </div>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default DatePickerField;
