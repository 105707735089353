import Portal from '../portal/Portal';
import styles from './Backdrop.module.scss';

const Backdrop = () => (
  <Portal style={{ zIndex: 1 }}>
    <div className={styles.backdrop} data-cy="backdrop" />
  </Portal>
);

export default Backdrop;
