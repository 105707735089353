import _ from 'lodash';
import { types } from 'legacy/actions/modalActions';
import { types as userTypes } from 'legacy/actions/userActions';

export const transitionTypes = {
  DEFAULT: 'DEFAULT',
  NEXT: 'NEXT',
  PREV: 'PREV'
};

export const initialState = {
  modalType: null,
  isOpen: false,
  modalProps: {},
  futureState: null,
  showTransition: transitionTypes.DEFAULT,
  history: []
};

const resetState = (state) => ({
  ...initialState,
  modalProps: state.modalProps,
  modalType: state.modalType
});

/**
 * Return corresponding history object for a given state
 *
 * History elements only need modalType, modalProps, and futureState
 *
 * @param {{modalType: string, modalProps: object, futureState: ?object, history: array<object>}} state
 * @returns {{modalType: string, modalProps: object, futureState: ?object}}
 */
export function createHistory(state) {
  return _.pick(state, ['modalType', 'modalProps', 'futureState']);
}

export function modalReducer(state = initialState, action) {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case userTypes.LOGOUT.success:
      return initialState;
    case types.SHOW_MODAL:
      if (newState.isOpen) {
        newState.showTransition = transitionTypes.NEXT;
        newState.history.push(createHistory(state));
      }
      newState.modalType = action.modalType;
      newState.modalProps = action.modalProps;
      newState.futureState = action.futureState || null;
      newState.isOpen = true;
      return newState;
    case types.CLEAR_MODAL:
      return initialState;
    case types.HIDE_MODAL:
      return resetState(state);
    case types.MODAL_BACK:
      if (state.history.length > 0) {
        newState.showTransition = transitionTypes.PREV;
        return { ...newState, ...newState.history.pop() };
      }
      return resetState(state);
    case types.MODAL_NEXT:
      if (newState.futureState !== null) {
        newState.history.push(createHistory(state));
        newState.showTransition = transitionTypes.NEXT;
        return { ...newState, ...newState.futureState };
      }
      return resetState(state);
    case types.UPDATE_MODAL_PROPS:
      newState.modalProps = {
        ...newState.modalProps,
        ...action.modalProps
      };
      return newState;
    case types.CLEAR_HISTORY:
      newState.history = [];
      return newState;
    default:
      return state;
  }
}

export default { initialState, reducer: modalReducer };
