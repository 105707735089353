import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/serviceActions';

import { toActionID } from '../util/util';

export const initialState = {
  contactSent: false,
  errors: _.mapValues(types, () => ({})),
  loading: _.mapValues(types, () => false)
};

const SISTER_TYPES = [];

export function serviceReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
  }
  switch (action.type) {
    case types.CONTACT_US.success:
      return {
        ...newState,
        contactSent: true
      };
    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: serviceReducer };
