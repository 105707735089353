import React, { Component } from 'react';
import styles from './ShareUrl.module.scss';
import config from '../../../../config';

import ModalBase from '../ModalBase/ModalBase';
import { ModalInput } from '../ModalUtils/index';

export class ShareUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  copyText = () => {
    const copyText = document.getElementById('shareable-url');
    /* Select the text field */
    copyText.select();
    /* Copy the text inside the text field */
    document.execCommand('copy');
  };

  render() {
    const { event } = this.props;
    let url = config.SITE_URL;
    if (event) {
      url += `events/${event._id}`;
      if (event.private) {
        url += `?invite=${event.shareToken}`;
      }
    }
    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        contentClassName="share-url"
      >
        <div className={styles.wrapper}>
          <div className={styles.input_row}>
            <ModalInput type="text" value={url} id="shareable-url" />
            <div
              className="copy"
              onClick={this.copyText}
              title="copy url to clipboard"
            >
              <i className="fas fa-clipboard" />
            </div>
          </div>
        </div>
      </ModalBase>
    );
  }
}
