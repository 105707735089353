import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

import * as stripe from 'legacy/common/utils/stripe';

type useStripeOptions = {
  onLoad: () => void;
};

function useStripe(country: string, options: useStripeOptions) {
  useEffect(() => {
    if (!country) {
      return;
    }

    if (country === stripe.getCountry()) {
      return;
    }

    stripe
      .init(country)
      .then(() => {
        options?.onLoad();
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, [country]);
}

export default useStripe;
