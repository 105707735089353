import { useEffect, useState } from 'react';

import * as datetime from 'legacy/common/utils/datetime';

import {
  clearRecentSearches,
  getRecentSearches,
  removeRecentSearchItem
} from 'legacy/search/service';
import styles from './RecentSearch.module.scss';

import Message from './Message';
import ResultList from './ResultList';

const RecentSearch = () => {
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    setRecentSearches(getRecentSearches());
  }, []);

  const onDelete = (id: string) => {
    removeRecentSearchItem(id);
    setRecentSearches(getRecentSearches());
  };

  return (
    <>
      <div className={styles.title}>
        <h1>Recent Searches</h1>
        {!!recentSearches?.length && (
          <div
            className={styles.clear}
            onClick={() => {
              clearRecentSearches();
              setRecentSearches([]);
            }}
          >
            Clear
          </div>
        )}
      </div>

      {!recentSearches?.length && <Message message="No recent searches" />}

      <ResultList
        id="results-recent"
        onDelete={onDelete}
        saveHistory={false}
        results={recentSearches?.map((result) => {
          const isOrganizer =
            result.metadata?.currentUser?.role === 'owner' ||
            result.metadata?.currentUser?.role === 'admin';

          if (result.type === 'event') {
            return {
              id: result._id,
              image: result.photo?.url || '/img/search/event.svg',
              value: result.title,
              subtitle: datetime.format(result.startDate),
              linkTo: result.isOrganizer
                ? `/events/${result._id}/dashboard`
                : `/events/${result._id}`
            };
          }

          if (result.type === 'group') {
            return {
              id: result.id,
              image: result.badgeImageUrl,
              value: result.name,
              subtitle: result.description,
              linkTo: isOrganizer
                ? `/organizations/${result.id}/dashboard`
                : `/organizations/${result.id}`,
              rawData: {
                ...result,
                type: 'group'
              }
            };
          }

          if (result.type === 'user') {
            return {
              id: result._id,
              image: result.photo?.url || '/img/search/user.svg',
              value: result.name,
              linkTo: `/profile/${result._id}`
            };
          }

          return null;
        })}
      />
    </>
  );
};

export default RecentSearch;
