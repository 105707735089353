import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';

import { useDebounce } from 'use-debounce';

import { useEventInviteListSearch } from 'legacy/search/hooks';
import { useInviteUsersToEventMutation } from 'legacy/events/hooks';

import { Dimmer, Loader } from 'semantic-ui-react';
import Spinner from '../../util/Spinner/Spinner';
import styles from './InviteUsersToEvent.module.scss';

import { hideModal } from 'legacy/actions/modalActions';
import * as toast from 'legacy/common/utils/toast';

import ModalBase from '../ModalBase/ModalBase';
import { ModalInput } from '../ModalUtils/index';

const formatAttendeeStatus = (attendeeStatus) =>
  attendeeStatus.substring(0, 1).toUpperCase() + attendeeStatus.substring(1);

const InviteUsersToEvent = ({
  _id: eventId,
  baseModalProps,
  hideModal,
  modalOpen
}) => {
  const [search, setSearch] = React.useState('');
  const [selectedUserIds, setSelectedUserIds] = React.useState([]);

  const [debouncedSearch] = useDebounce(search, 200);

  React.useEffect(() => {
    if (!modalOpen) {
      setSelectedUserIds([]);
      setSearch('');
    }
  }, [modalOpen]);

  const {
    data: inviteList,
    isLoading: isLoadingInviteList,
    isFetchingNextPage,
    fetchNextPage
  } = useEventInviteListSearch(eventId, debouncedSearch);

  const inviteUsersToEventMutation = useInviteUsersToEventMutation();

  const submit = async () => {
    if (isLoadingInviteList || inviteUsersToEventMutation.isLoading) {
      return;
    }

    inviteUsersToEventMutation.mutate(
      { eventId, userIds: selectedUserIds },
      {
        onSuccess: () => {
          hideModal();
          toast.success('Your selected users have been invited.');
        }
      }
    );
  };

  const select = (user) => {
    if (user.attendeeStatus !== 'joinable') {
      return;
    }

    const updatedSelectedUserIds =
      selectedUserIds.indexOf(user._id) > -1
        ? selectedUserIds.filter((id) => id !== user._id)
        : [...selectedUserIds, user._id];

    setSelectedUserIds(updatedSelectedUserIds);
  };

  // Allows fetching the next page of results after scrolling to the bottom of the modal
  // https://medium.com/fredwong-it/react-detect-scroll-to-bottom-event-7c18350c4ef6
  const handleScroll = ({ target }) => {
    if (isLoadingInviteList || isFetchingNextPage) {
      return;
    }

    const currentPageNumber = inviteList.pages.length - 1;
    const currentPage = inviteList.pages[currentPageNumber];
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      currentPage.length !== 0
    ) {
      fetchNextPage({ pageParam: currentPageNumber + 1 });
    }
  };

  const renderInviteList = () => {
    if (!inviteList || inviteList.pages[0].length === 0) {
      return <div className={styles.noSearchResults}>No Search Results</div>;
    }

    return _.flatten(inviteList.pages).map((user) => {
      const selected = selectedUserIds.indexOf(user._id) > -1;
      const disabled = user.attendeeStatus !== 'joinable';
      const classes = classnames(styles.user, {
        [styles.selected]: selected,
        [styles.disabled]: disabled
      });
      const iconClass = classnames(
        { 'fas fa-check-square': selected },
        { 'far fa-square': !selected }
      );

      return (
        <div className={classes} key={user._id} onClick={() => select(user)}>
          <div className={styles.profilePic}>
            {user.photo ? (
              <img src={user.photo.url} alt="" />
            ) : (
              <i className="fas fa-user-alt" />
            )}
          </div>
          <div className={styles.name}>
            <div>{`${user.firstName} ${user.lastName}`}</div>
            {user.attendeeStatus !== 'joinable' && (
              <div className={styles.statusLabel}>
                {formatAttendeeStatus(user.attendeeStatus)}
              </div>
            )}
          </div>
          <i className={iconClass} />
        </div>
      );
    });
  };

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={styles.InviteUsers}>
        <div className="search">
          <div className="icon">
            <i className="fas fa-search" />
          </div>
          <ModalInput
            name="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="users" onScroll={handleScroll}>
          {isLoadingInviteList ? (
            <div className={styles.loading}>
              <Spinner
                width={60}
                height={60}
                data-testid="invite-users-to-event-spinner"
              />
            </div>
          ) : (
            renderInviteList()
          )}
        </div>
        <div
          data-testid="button-invite"
          className={classnames('bn-btn primary', {
            disabled: selectedUserIds.length === 0
          })}
          onClick={submit}
          role="button"
        >
          INVITE
          {(inviteUsersToEventMutation.isLoading ||
            isLoadingInviteList ||
            isFetchingNextPage) && (
            <Dimmer active>
              <Loader active />
            </Dimmer>
          )}
        </div>
      </div>
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  modalOpen: state.modal.isOpen
});

const actions = {
  hideModal
};

export default connect(mapStateToProps, actions)(InviteUsersToEvent);
