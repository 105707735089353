import React from 'react';
import classnames from 'classnames';
import styles from './Spinner.module.scss';

const Spinner = ({ className = '', width, height, style = {}, ...props }) => (
  <div
    className={classnames(styles.Spinner, className)}
    style={{ height, width, ...style }}
    {...props}
  >
    <i style={{ fontSize: height }} className="fas fa-spinner" />
  </div>
);

export default Spinner;
