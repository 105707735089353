import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import styles from './Dropdown.module.scss';

export class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open
    };
  }

  componentDidUpdate = () => {
    if (this.props.open !== undefined && this.props.open !== this.state.open) {
      this.setState({ open: this.props.open });
    }
  };

  toggleOpen = () => {
    if (this.props.toggleDropdown) {
      this.props.toggleDropdown(!this.props.open);
    } else {
      this.setState((prevState) => ({ open: !prevState.open }));
    }
  };

  handleClickOutside = () => {
    if (this.props.toggleDropdown) {
      this.props.toggleDropdown(false);
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    const open = this.props.toggleDropdown ? this.props.open : this.state.open;

    const menuClass = classnames('dropdown', { open });
    const iconClass = classnames('icon', { open });
    const btnClass = classnames({ open }, 'dropdown-btn');
    const mainClass = classnames([styles.Dropdown], this.props.classes);
    const defaultIcon = classnames(
      'fas',
      { 'fa-caret-down': !open },
      { 'fa-caret-up': open }
    );

    const icon =
      this.props.icon === 'default' ? (
        <i className={defaultIcon} />
      ) : (
        this.props.icon
      );

    return (
      <div className={mainClass}>
        <div
          className={btnClass}
          data-testid="dropdown"
          onClick={this.toggleOpen}
        >
          <div className="btn_content">{this.props.btn_content}</div>
          <div
            className={iconClass}
            onClick={this.props.clickIcon}
            data-testid="dropdown-icon"
          >
            {icon}
          </div>
        </div>
        <div className={menuClass} onClick={this.toggleOpen}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const clickOutsidePropTypes = {
  eventTypes: PropTypes.any,
  outsideClickIgnoreClass: PropTypes.any,
  preventDefault: PropTypes.any,
  stopPropagation: PropTypes.any,
  disableOnClickOutside: PropTypes.any,
  enableOnClickOutside: PropTypes.any
};

Dropdown.propTypes = exact({
  toggleDropdown: PropTypes.func,
  clickIcon: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.any,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  btn_content: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  ...clickOutsidePropTypes
});

export default onClickOutside(Dropdown);
