/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactNode } from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { BsInstagram } from 'react-icons/bs';
import { RiFacebookFill } from 'react-icons/ri';
import { FaLinkedinIn, FaTiktok } from 'react-icons/fa';

import { isBounceWebview } from 'legacy/common/utils/webview';
import { SocialLinks } from 'legacy/common/constants/social-links';
import { useGetMe } from 'legacy/user/hooks';

const FooterV2: React.FC = () => {
  const currentYear = React.useMemo(() => new Date().getFullYear(), []);
  const router = useRouter();
  const { data: user } = useGetMe();

  const { pathname, query } = router;

  // Remove footer if it has a webview query param
  if (isBounceWebview(router.query)) {
    return null;
  }

  // Remove footer for create event page
  if (
    pathname.includes('/events/create') ||
    query.view === 'edit' ||
    router.pathname.includes('/events/[slug]/dashboard') ||
    router.pathname.includes('/events/[slug]/approval') ||
    router.pathname.includes('/organizations/create') ||
    router.pathname.includes('/organizations/[organizationId]/edit') ||
    (user && router.route === '/events/[slug]') ||
    (user && router.route === '/organizations/[organizationId]') ||
    (user && router.pathname.includes('/associations/[associationId]'))
  ) {
    return <div />;
  }

  return (
    <footer className="border-t-1 flex justify-center">
      <div className="lg:container px-6 py-20 flex flex-col gap-20 lg:gap-0 lg:flex-row justify-between">
        <div>
          <div className="w-32 md:w-48">
            <Image src="/img/Logo_Color.svg" width={1062} height={127} />
          </div>
          <span className="font-light mt-4 block md:mt-8">
            © {currentYear} Bounced Inc.
          </span>
        </div>

        <div className="flex text-lg gap-20 flex-wrap lg:flex-nowrap">
          <ul>
            <span className="font-bold">Product</span>
            <Li href="/product/host">Host</Li>
            <Li href="/product/attend">Attend</Li>
            <Li href="/social">Social Events</Li>
            <Li href="/academic">Academic Events</Li>
            <Li href="/greek">Greek Events</Li>
          </ul>

          <ul>
            <span className="font-bold">Company</span>
            <Li href="/our-story">About us</Li>
            <Li href="/student">Get involved</Li>
            <Li href="/contact" hard>
              Contact us
            </Li>
            <Li href="/student">Careers</Li>
          </ul>

          <div className="flex flex-col">
            <ul>
              <span className="font-bold">Resources</span>
              <Li href="/terms" hard>
                Terms and Conditions
              </Li>
              <Li href="/privacy" hard>
                Privacy Policy
              </Li>
            </ul>
            <div className="flex text-3xl gap-4 mt-6">
              <a href={SocialLinks.Instagram} target="_blank" rel="noreferrer">
                <BsInstagram />
              </a>
              <a href={SocialLinks.Tiktok} target="_blank" rel="noreferrer">
                <FaTiktok />
              </a>
              <a href={SocialLinks.Facebook} target="_blank" rel="noreferrer">
                <RiFacebookFill />
              </a>
              <a href={SocialLinks.Linkedin} target="_blank" rel="noreferrer">
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const Li: React.FC<{ href: string; hard?: boolean; children?: ReactNode }> = ({
  children,
  href,
  hard
}) => (
  <li className="underline underline-offset-2 font-light my-6">
    {hard ? (
      <a href={href}>{children}</a>
    ) : (
      <Link passHref href={href}>
        {children}
      </Link>
    )}
  </li>
);

export default FooterV2;
