import * as Sentry from '@sentry/nextjs';
import { store } from '../../store';
import { removeLoginData } from 'legacy/actions/userActions';

import storage from './storage';
import { STORAGE_KEYS } from 'legacy/common/constants/storage';

export interface ErrorResponse {
  type: string;
  code?: string;
  message: string;
}

export const NETWORK_ERROR = 'NetworkError';
export const DEFAULT_ERROR = 'DefaultError';

export function formatError(e: any): ErrorResponse {
  const error = e?.response?.data?.error;

  if (error) {
    return error;
  }

  if (e?.isAxiosError && e?.message === 'Network Error') {
    // This is a generic error from axios when it cannot reach the server.
    // We believe this is not anything on our end as the volume is relatively low, but rather a sporadic network issue from any given client.
    // For now we will log these as a warning in Sentry such that they are not completely lost, and re-investigate should they spike up any further.
    Sentry.captureMessage(
      `Encountered an unexpected network error from axios: ${e.message}`,
      { level: 'warning' }
    );

    return {
      type: NETWORK_ERROR,
      message:
        'We are having trouble connecting, please check your network connection and try again.'
    };
  }

  Sentry.captureException(e, {
    tags: { handled: 'false', showDefaultError: 'true' }
  });

  return {
    type: DEFAULT_ERROR,
    message:
      'Something went wrong, please try again. If the issue persists, you can contact us through www.bouncelife.com/contact'
  };
}

// TODO: We should show an alert to the user if we are logging them out during an active login session.
// Will be a lot easier to do once we remove all our redux usage and refactor the RedirectContainer.tsx logic.
export function handleAuthenticationError(error: any) {
  if (error?.type === 'JwtExpiredError') {
    removeLoginData({ forceRedirect: true });
    store.dispatch({ type: 'LOGOUT_SUCCESS' });
  } else if (
    // If user is logged in and gets an UnauthorzedError, we will log them out.
    storage.getItem(STORAGE_KEYS.AUTH_TOKEN) &&
    error?.type === 'UnauthorizedError'
  ) {
    Sentry.captureException(error, { tags: { unauthorizedError: true } });

    removeLoginData({ forceRedirect: true });
    store.dispatch({ type: 'LOGOUT_SUCCESS' });
  }

  return error;
}
