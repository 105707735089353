import React from 'react';

import useOnOutsideClick from 'legacy/common/hooks/useOnOutsideClick';

type DropdownProps = {
  testId?: string;
  label?: string | React.ReactNode;
  items: {
    label: string | React.ReactNode;
    value?: any;
    onClick: () => void;
  }[];
  selectedValue?: any;
};

const Dropdown: React.FC<DropdownProps> = ({
  testId,
  label,
  items,
  selectedValue
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const selectedLabel = items.find(
    (item) => item.value === selectedValue
  )?.label;

  const toggleMenu = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  useOnOutsideClick(dropdownRef, {
    isActive: isOpen,
    onOutsideClick: () => {
      setIsOpen(false);
    }
  });

  return (
    <div className="relative" ref={dropdownRef}>
      {typeof (label || selectedLabel) === 'string' ? (
        // If label prop is provided, use that. Else, use selected value's label.
        <div
          data-testid={testId}
          onClick={toggleMenu}
          className="w-[82px] py-1 text-center rounded border-1 border-gray-600 cursor-pointer"
        >
          <div className="text-xs text-gray-600">{label || selectedLabel}</div>
        </div>
      ) : (
        <div data-testid={testId} onClick={toggleMenu}>
          {label || selectedLabel}
        </div>
      )}
      {isOpen && (
        <div className="shadow bg-white h-auto whitespace-nowrap absolute right-0 mt-2 rounded-lg overflow-hidden z-20">
          <ul className="text-left">
            {items.map((item, i) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="px-3 py-2 text-gray-700 hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                  item.onClick();
                }}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
