import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withForm, ValidatedInput, SubmitButton } from '../../util/FormHelpers';
import { types, resetPasswordRequest } from 'legacy/actions/userActions';
import {
  isRequired,
  isEmail,
  composeValidators
} from '../../../util/validators';

import styles from './ResetPasswordRequest.module.scss';
import { ModalInput } from '../ModalUtils';

const ResetPasswordForm = ({
  loading,
  resetPasswordRequest,
  onSuccess,
  formProps,
  resetPasswordError
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (
      !loading &&
      hasSubmitted &&
      !formProps.submitting &&
      !formProps.hasSubmitErrors &&
      formProps.valid
    ) {
      onSuccess();
    }
  }, [loading, hasSubmitted, formProps, onSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasSubmitted(true);
    formProps.handleSubmit();
    if (formProps.valid || formProps.dirtySinceLastSubmit) {
      resetPasswordRequest(formProps.values.email);
    }
  };

  const getErrorMessage = () => {
    const { hasSubmitErrors, submitErrors } = formProps;
    if (!hasSubmitErrors) {
      return null;
    }
    if (resetPasswordError.message) {
      return resetPasswordError.message;
    }
    const { FORM_ERROR = {} } = submitErrors;
    const { error = {} } = FORM_ERROR;
    const { message } = error;
    if (message) {
      return message;
    }

    switch (submitErrors.FORM_ERROR) {
      case 'User not found':
        return 'No registered user has that e-mail.';
      default:
        return 'An error has occurred, please try again.';
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.title}>Forgot Your Password?</div>
      <div className="img-wrapper">
        <img src="/img/illustrations/forgotPassword.svg" alt="" />
      </div>
      <label htmlFor="email">Please enter your account email</label>
      <ValidatedInput
        type="text"
        validate={composeValidators(isEmail, isRequired)}
        name="email"
        placeholder="you@example.com"
        component={ModalInput}
      />
      <div className="fill-vertical" />

      <SubmitButton
        buttonText="Send password reset email"
        loading={loading}
        errorOpen={
          formProps.hasSubmitErrors &&
          !formProps.dirtySinceLastSubmit &&
          !formProps.submitting
        }
        errorContent={getErrorMessage()}
      />
    </form>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.user.loading.RESET_PASSWORD_REQUEST,
    resetPasswordError: state.user.errors.RESET_PASSWORD_REQUEST
  };
}

const actions = { resetPasswordRequest };

export default connect(
  mapStateToProps,
  actions
)(withForm(types.RESET_PASSWORD_REQUEST_FORM)(ResetPasswordForm));
