import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover } from 'antd';
import styles from './AllPhotos.module.scss';
import { uploadPhoto, deletePhoto } from 'legacy/actions/userActions';
import ScalableComponent from '../../util/ScalableComponent/ScalableComponent';

import ModalBase from '../ModalBase/ModalBase';
import { showModal, modalBack } from 'legacy/actions/modalActions';
import UploadGalleryPhotoButton from 'legacy/common/components/upload-photo/UploadGalleryPhotoButton';

class Gallery extends Component {
  constructor() {
    super();
    this.state = {
      uploadedPhotos: []
    };
  }

  render() {
    const { user } = this.props;
    const { allPhotos } = user;

    const photoDeleted = (id) => {
      this.props.deletePhoto(id).then(() => {
        this.setState((state) => ({
          uploadedPhotos: state.uploadedPhotos.filter(
            (photo) => photo._id !== id
          )
        }));
      });

      const i = this.props.localGallery.findIndex(
        (element) => element._id === id
      );
      if (i > -1) {
        this.props.setLocalGallery((current) => [
          ...current.slice(0, i),
          ...current.slice(i + 1)
        ]);
      }
    };

    const gallery = [...allPhotos, ...this.state.uploadedPhotos].map(
      ({ url, _id }, i) => (
        <Popover key={_id} content="Select this image to display">
          <ScalableComponent width="calc(100%/3)" ratio={0.67} key={i}>
            <div className={styles.removePic} onClick={() => photoDeleted(_id)}>
              <i className="fa fa-times" />
            </div>
            <div
              className={styles.picture}
              onClick={() => {
                this.props.setLocalGallery((gallery) => [
                  ...gallery,
                  { _id, url }
                ]);
                this.props.close();
              }}
              style={{
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
                height: '100%',
                width: '100%'
              }}
              data-cy="allPhotos-image"
            />
          </ScalableComponent>
        </Popover>
      )
    );

    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        // contentClassName="basic"
        contentStyle={{
          border: 'none',
          borderRadius: '8px',
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.14)',
          with: '80%',
          height: '100%',
          maxHeight: '500px'
        }}
        overlayStyle={{
          // Gallery Modal has a greater z-index than the Image Upload modal
          // Lowering just this particular modal so that it does not affect other modals
          zIndex: 998
        }}
        hideBackButton
      >
        <div className={styles.header}>
          <span>Your Photos</span>
          <img
            onClick={this.props.close}
            className={styles.exit}
            src="/img/buttons/xFade.svg"
            alt="close"
          />
        </div>
        <div className={styles.pics}>
          {gallery}
          <ScalableComponent ratio={0.67} width="calc(100%/3)">
            <UploadGalleryPhotoButton
              onUpload={(photo) => {
                this.setState((state) => ({
                  uploadedPhotos: [...state.uploadedPhotos, photo]
                }));
              }}
              ButtonComponent={({ onClick }) => (
                <div
                  onClick={onClick}
                  className={styles.preview}
                  style={{ background: 'rgb(58, 61, 101)' }}
                >
                  <div
                    style={{
                      backgroundImage: `url(/img/buttons/button_Upload.svg)`
                    }}
                  />
                </div>
              )}
            />
          </ScalableComponent>
        </div>
      </ModalBase>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.connect.loading,
    user: state.user.user,
    errors: state.connect.errors
  };
}

const actions = { uploadPhoto, deletePhoto, showModal, modalBack };

export default connect(mapStateToProps, actions)(Gallery);
