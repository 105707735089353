import { AxiosRequestConfig } from 'axios';

import storage from 'legacy/common/utils/storage';
import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import {
  formatError,
  handleAuthenticationError
} from 'legacy/common/utils/error';

import { STORAGE_KEYS } from 'legacy/common/constants/storage';
import { ERROR_CODES } from 'legacy/common/constants/errorCodes';

const AUTHORIZATION_HEADER = 'Authorization';

/**
 * Interceptor to add authentication header for all requests.
 */
function requestInterceptor(request: AxiosRequestConfig) {
  const accessToken = storage.getItem(STORAGE_KEYS.AUTH_TOKEN);

  if (accessToken && !request.headers[AUTHORIZATION_HEADER]) {
    request.headers[AUTHORIZATION_HEADER] = `Bearer ${accessToken}`;
  }

  return request;
}

export function handleMissingEmailVerificationInterceptor({
  onMissingEmailVerificationError
}) {
  bounceApiClient.interceptors.response.use(
    (response) => response,
    (error: any) => {
      if (error?.code === ERROR_CODES.MISSING_EMAIL_VERIFICATION) {
        onMissingEmailVerificationError(error);
      }

      return Promise.reject(error);
    }
  );
}

export function initInterceptors() {
  bounceApiClient.interceptors.request.use(requestInterceptor);
  bounceApiClient.interceptors.response.use(
    (response) => response,
    (error: any) => Promise.reject(formatError(error))
  );
  bounceApiClient.interceptors.response.use(
    (response) => response,
    (error: any) =>
      Promise.reject(
        handleAuthenticationError(error) // NOTE: This ends up being a no-op for non-authentication errors
      )
  );
}
