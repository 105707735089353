import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import ReactCodeInput from 'react-verification-code-input';

import { Popup } from 'semantic-ui-react';
import styles from '../AddPhone.module.scss';
import BounceButton from '../../../util/BounceButton/BounceButton';

function VerifyPhoneForm({
  loading,
  error,
  user,
  onSuccess,
  verifyActionType: VERIFY_ACTION_TYPE,
  onVerifyPhone,
  resendActionType: RESEND_ACTION_TYPE,
  onResend
}) {
  const { phoneVerified } = user;
  useEffect(() => {
    if (phoneVerified && onSuccess) {
      onSuccess();
    }
  }, [phoneVerified]);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const handleChange = () => {
    if (!hasChanged) {
      setHasChanged(true);
    }
  };
  const handleComplete = (code) => {
    setHasChanged(false);
    if (!hasSubmitted) {
      setHasSubmitted(true);
    }
    onVerifyPhone(code);
  };

  return (
    <div className={classnames(styles.AddPhone, 'component', 'custom')}>
      <h2 className="modalTitle">Verify Phone Number</h2>

      <img src="/img/illustrations/addPhone.svg" alt="" />
      <div className={styles.codeInput}>
        <label name="verificationCode">Verification Code</label>
        <Popup
          open={
            !hasChanged && hasSubmitted && !loading[VERIFY_ACTION_TYPE] && error
          }
          trigger={
            <ReactCodeInput
              fields={4}
              onComplete={handleComplete}
              onChange={handleChange}
              fieldWidth={45}
              fieldHeight={60}
              loading={loading[VERIFY_ACTION_TYPE]}
              style={{ fontSize: '24px' }}
            />
          }
          position="bottom center"
        >
          {error}
        </Popup>
      </div>
      <BounceButton
        className={styles.resendVerification}
        secondary
        loading={loading[RESEND_ACTION_TYPE]}
        onClick={onResend}
      >
        Re-send Verification Code
      </BounceButton>
    </div>
  );
}

export default VerifyPhoneForm;
