import React, { Component } from 'react';
import styles from './TierPricing.module.scss';
import ModalBase from '../ModalBase/ModalBase';
import TierGuests from './TierGuests';
import { currencyFormat } from 'legacy/common/utils/currency';

class TierPricing extends Component {
  constructor() {
    super();
    this.state = {};
  }

  toggleRow = (index) => {
    this.setState((prevState) => ({ [index]: !prevState[index] }));
  };

  render() {
    const { event } = this.props;

    const tierFields = event?.pricing?.tiers?.map((tier, i) => {
      const { name, price, shareToken } = tier;

      return (
        <div
          key={i}
          className={styles.tier}
          onClick={() => this.setState({ activeTier: i })}
        >
          <div className={styles.infoRow}>
            <span>{currencyFormat(parseFloat(price))}</span>
            <span className={styles.name}>{name}</span>
            {shareToken && (
              <div onClick={() => this.toggleRow(i)}>
                {this.state[i] ? 'Hide Code' : 'View Code'}
              </div>
            )}
            {shareToken && (
              <img className={styles.lock} src="/img/icons/lock.svg" alt="" />
            )}
          </div>

          <div className="px-3 pb-2 flex justify-between">
            <TierGuests tier={tier} />

            {this.state[i] && (
              <div className="font-bold" data-cy="tier-token">
                {shareToken}
              </div>
            )}
          </div>
        </div>
      );
    });

    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        contentClassName="basic"
      >
        <div className={styles.wrapper}>
          <h1 className="mb-2 text-lg">Pricing Tiers</h1>

          {tierFields}
        </div>
      </ModalBase>
    );
  }
}

export default TierPricing;
