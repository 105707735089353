import React from 'react';
import styles from './ViewTicketNotes.module.scss';
import ModalBase from '../ModalBase/ModalBase';

const TicketNotes = ({
  baseModalProps,
  ticketNotesRequest,
  guestBreakdown
}) => {
  const notes = guestBreakdown?.map((guest) => {
    const { user, ticketNotes, _id } = guest;
    const { firstName, lastName } = user;
    return (
      <div className={styles.answer} key={_id}>
        <div className={styles.note}>{ticketNotes}</div>
        <div className={styles.name}>{`${firstName} ${lastName}`}</div>
      </div>
    );
  });

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={styles.wrapper}>
        <h2 className="modalTitle">Question for Attendees</h2>

        <div className={styles.request}>{ticketNotesRequest}</div>

        <div className={styles.title}>Answers</div>
        {notes}
      </div>
    </ModalBase>
  );
};

export default TicketNotes;
