import _get from 'lodash/get';

import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import { ERROR_CODES } from 'legacy/common/constants/errorCodes';

export const resolveError = (err) => ({
  error: err
});

export const resolveFormError = (err) => err.message;

export const createAxiosRequest =
  ({
    action,
    url,
    params = {},
    dispatchType,
    formType,
    payload,
    generatePayload,
    resolveDelay,
    stateMap = {},
    options = {},
    onSuccess
  }) =>
  (dispatch, getState) => {
    const stateValues = Object.entries(stateMap).reduce(
      (values, [key, path]) => ({
        ...values,
        [key]: _get(getState(), path)
      }),
      {}
    );
    dispatch({ type: dispatchType.start });
    return bounceApiClient[action](url, params, options)
      .then((res) => {
        if (resolveDelay) {
          setTimeout(() => {
            dispatch({
              type: dispatchType.success,
              stateValues,
              payload:
                payload ||
                (generatePayload && generatePayload(res.data)) ||
                res.data
            });
          }, resolveDelay);
        } else {
          dispatch({
            type: dispatchType.success,
            stateValues,
            payload:
              payload ||
              (generatePayload && generatePayload(res.data)) ||
              res.data
          });
          if (formType) {
            dispatch({ type: formType.success });
          }
        }
        if (onSuccess) {
          onSuccess(res.data);
        }
      })
      .catch((err) => {
        const errData = resolveError(err);

        const payloadData =
          payload || (generatePayload && generatePayload(errData)) || errData;

        dispatch({
          type: dispatchType.error,
          stateValues,
          payload: {
            ...errData,
            ...payloadData
          }
        });

        if (errData.error.code === ERROR_CODES.MISSING_EMAIL_VERIFICATION) {
          return;
        }

        if (formType) {
          dispatch({
            type: formType.success,
            payload: {
              FORM_ERROR: resolveFormError(err)
            }
          });
        }
      });
  };
