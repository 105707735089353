import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

import { useResetPasswordRequestCommand } from 'legacy/auth/hooks';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';

import * as toast from 'legacy/common/utils/toast';
import Loader from 'legacy/common/components/layout/Loader';
import { useGetMe } from 'legacy/user/hooks';

type ConfirmPasswordProps = {
  errorMessage: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: { password: string }) => void;
};

const ConfirmPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required('Required')
});

const ConfirmPassword: React.FC<ConfirmPasswordProps> = ({
  errorMessage,
  isLoading,
  onClose,
  onSubmit
}) => {
  const { data: user } = useGetMe({
    enabled: false
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible);
  };

  const resetPasswordRequestCommand = useResetPasswordRequestCommand();

  const onResetPassword = () => {
    resetPasswordRequestCommand.mutate(user?.email, {
      onSuccess: () => {
        toast.success(
          'Email Sent',
          "We've sent you an email with a link to reset your password."
        );
      }
    });
  };

  return (
    <Modal
      visible
      onClose={onClose}
      type={ModalType.ALERT}
      shouldStopModalClose={isLoading || resetPasswordRequestCommand.isLoading}
    >
      <div className="px-8 py-6" data-testid="modal-confirm-password">
        <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
          Confirm Password
        </h1>
        <p className="mt-4 font-normal text-base text-gray-500 leading-loose">
          Enter your password to finish updating your email address.
        </p>

        {(resetPasswordRequestCommand.error?.message || errorMessage) && (
          <div className="mt-2 text-red-500">
            {resetPasswordRequestCommand.error?.message || errorMessage}
          </div>
        )}

        <Formik
          initialValues={{
            password: ''
          }}
          onSubmit={onSubmit}
          validationSchema={ConfirmPasswordValidationSchema}
        >
          {({ errors, touched, isValid, dirty }) => (
            <Form>
              <>
                <div className="mt-6 mb-2">
                  <p className="text-sm text-gray-400">Password</p>
                </div>

                <div className="relative">
                  <Field
                    id="password"
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    data-testid="input-password"
                    placeholder="Enter your password"
                    className="w-full h-full py-2 px-4 border-1 rounded-2xl text-base text-gray-600 placeholder:text-gray-400"
                  />

                  <div
                    className="absolute top-1 right-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (
                      <img src="/img/icons/eye.svg" />
                    ) : (
                      <img src="/img/icons/eye-slash.svg" />
                    )}
                  </div>
                </div>

                {errors.password && touched.password && (
                  <div className="mt-1 text-xs text-right text-red-500">
                    {errors.password}
                  </div>
                )}

                {resetPasswordRequestCommand.isLoading ? (
                  <div className="mt-6">
                    <Loader isLoading />
                  </div>
                ) : (
                  <div className="mt-6 text-sm text-center text-gray-400">
                    Forgot Password?{' '}
                    <span
                      className="cursor-pointer text-primary-500 font-semibold"
                      onClick={onResetPassword}
                    >
                      Reset
                    </span>
                  </div>
                )}

                <div className="pl-20 mt-8 flex justify-end">
                  <Button
                    disabled={
                      isLoading || resetPasswordRequestCommand.isLoading
                    }
                    type={ButtonType.Clear}
                    onClick={() => onClose()}
                  >
                    <span className="text-gray-400">Cancel</span>
                  </Button>
                  <Button
                    isSubmit
                    loading={isLoading}
                    testId="button-submit"
                    disabled={
                      !dirty ||
                      !isValid ||
                      resetPasswordRequestCommand.isLoading
                    }
                  >
                    Confirm
                  </Button>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ConfirmPassword;
