import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { saveAs } from 'file-saver';

import { useGetEventSummary, useExportEventGuests } from 'legacy/events/hooks';
import ModalBase from '../ModalBase/ModalBase';
import BounceButton from '../../util/BounceButton/BounceButton';
import styles from './ExportGuests.module.scss';

const ExportGuests = ({ _id: eventId, events, baseModalProps }) => {
  const event = events[eventId];

  const { refetch: fetchExcelData, isLoading } = useExportEventGuests(eventId, {
    isEnabled: false
  });

  const onClick = async () => {
    const { data: rawData } = await fetchExcelData();
    const blob = new Blob([rawData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    });

    saveAs(blob, 'Bounce-Guests.xlsx');
  };

  const { data: eventSummary } = useGetEventSummary(
    eventId,
    event?.isOrganizer
  );
  const scannedGuests = eventSummary?.totals?.scannedGuests || 0;
  const totalUnclaimedTickets = eventSummary?.totals?.unclaimedTickets || 0;
  const totalTicketsSold = eventSummary?.totals?.ticketsSold || 0;

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={classnames(styles.ExportGuests, 'component')}>
        <i className="fas fa-file-export" />
        <h2 className="modalTitle">Export Event Guests</h2>
        <div className={styles.grid}>
          <div className={styles.sectionLabel}>Number of Tickets Sold</div>
          <div>{totalTicketsSold}</div>
          <div className={styles.sectionLabel}>Number of Unclaimed Tickets</div>
          <div>{totalUnclaimedTickets}</div>
          <div className={styles.sectionLabel}>Number of Scanned Tickets</div>
          <div>{scannedGuests}</div>
        </div>
        <BounceButton
          className={styles.download}
          onClick={onClick}
          primary
          loading={isLoading}
        >
          Export to Excel
        </BounceButton>
      </div>
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  events: state.events.events
});

const ConnectedComponent = connect(mapStateToProps)(ExportGuests);

ConnectedComponent.propTypes = exact({
  nextComponent: PropTypes.node
});

export default ConnectedComponent;
