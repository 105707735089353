import { Component } from 'react';
import { connect } from 'react-redux';
import { addCard, types as paymentTypes } from 'legacy/actions/paymentActions';
import { clearHistory, clearModal } from 'legacy/actions/modalActions';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

import { withForm } from '../../util/FormHelpers';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import { store } from '../../../store';
import ModalContent from './components/ModalContent';

class AddCard extends Component {
  render() {
    return (
      <Modal
        type={ModalType.SMALL}
        visible
        disableAnimationOnWeb
        onClose={() => {
          store.dispatch(clearModal());
        }}
      >
        <ModalContent {...this.props} />
      </Modal>
    );
  }
}

const actions = {
  addCard,
  clearHistory
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  payment: state.payment,
  stripeHasLoaded: state.app.stripeHasLoaded,
  device: state.app.device
});

const ConnectedComponent = connect(
  mapStateToProps,
  actions
)(withForm(paymentTypes.ADD_CARD_FORM)(AddCard));

ConnectedComponent.propTypes = exact({
  nextComponent: PropTypes.node
});

export default ConnectedComponent;
