import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import classnames from 'classnames';

const preventDefaults = (e) => {
  e.preventDefault();
};

function BounceButton({
  as = 'button',
  loading,
  primary,
  secondary,
  danger,
  disabled,
  plain,
  notransform,
  children,
  className,
  onClick,
  ...props
}) {
  const Component = as;
  return (
    <Component
      className={classnames('bn-btn', className, {
        primary,
        secondary,
        danger,
        loading,
        disabled,
        plain,
        notransform
      })}
      onClick={disabled || loading ? preventDefaults : onClick}
      type="button"
      {...props}
    >
      {children}
      {loading && (
        <Dimmer active>
          <Loader active size="small" />
        </Dimmer>
      )}
    </Component>
  );
}

export default BounceButton;
