import React from 'react';
import { connect } from 'react-redux';
import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';
import { Formik, Field } from 'formik';
import { getEventGuests, updateTicketInfo } from 'legacy/actions/eventActions';

const TicketNotes = ({
  isOpen,
  updateTicketInfo,
  getEventGuests,
  guestQuestions,
  event,
  onSuccess,
  onClose
}) => {
  const handleOnSubmit = async (guestAnswers) => {
    await updateTicketInfo({ eventId: event._id, guestAnswers });
    onSuccess();
    getEventGuests(event._id);
  };

  return (
    <Formik initialValues={guestQuestions} onSubmit={handleOnSubmit}>
      {({ values, submitForm }) => {
        const answeredQuestion = values?.filter((value) => value.answer);
        return (
          <Modal
            type={ModalType.SMALL}
            onClose={onClose}
            visible={isOpen}
            icon={<img src="/img/chat.svg" className="w-6 h-6" />}
            title="Guest Questions"
            renderFooter={() => (
              <Button
                disabled={answeredQuestion?.length !== values?.length}
                type={ButtonType.Primary}
                onClick={submitForm}
              >
                {`Confirm ${answeredQuestion?.length}/${values?.length}`}
              </Button>
            )}
          >
            <div>
              <div className="text-left ml-4 mt-4 text-gray-500 text-sm font-normal">
                Before we dive into the festivities, we kindly ask you to answer
                the following questions to ensure a safe and enjoyable
                experience for everyone....
              </div>
            </div>
            {values?.map((note, index) => (
              <div
                key={index}
                className="text-left ml-4 mt-4 text-black text-sm font-normal flex flex-col"
              >
                {note?.question}
                <Field
                  label="Name"
                  className="h-11 border border-gray-300 rounded-md mt-1 mr-4 px-2"
                  id={`question-${index}`}
                  name={`[${index}].answer`}
                  placeholder="Write answer here..."
                />
              </div>
            ))}
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  errors: state.events.errors,
  loading: state.events.loading,
  tickets: state.events.tickets
});

const actions = { getEventGuests, updateTicketInfo };

export default connect(mapStateToProps, actions)(TicketNotes);
