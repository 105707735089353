export const EVENT_OPEN_PAGE = 'open_page';

export const EVENT_OPEN_MODAL = 'open_modal';
export const EVENT_CLOSE_MODAL = 'close_modal';

export const EVENT_CLICK_SEND_MONEY = 'click_send_money';
export const EVENT_CLICK_CREATE_EVENT = 'click_create_event';
export const EVENT_CLICK_CREATE_ORG = 'click_create_organization';
export const EVENT_CLICK_REQUEST_MONEY = 'click_request_money';
export const EVENT_CLICK_NEARBY_EVENT = 'nearby_event_clicked';
export const EVENT_CLICK_PROFILE_FROM_FEED = 'click_profile_from_feed';
export const EVENT_CLICK_EVENT_FROM_FEED = 'event_clicked_from_feed';
export const EVENT_CLICK_INVITE = 'click_invite';
export const EVENT_VIEW_EVENT = 'event_viewed';

export const EVENT_APP_BANNER_SHOWN = 'app_banner_shown';
export const EVENT_APP_BANNER_CLOSED = 'app_banner_closed';
export const EVENT_APP_BANNER_SWITCHED_TO_APP = 'app_banner_switched_to_app';

export const EVENT_DELETE_EVENT_SUCCESS = 'delete_event_success';

export const EVENT_CREATE_DUPLICATE_TIERS = 'create_duplicate_tiers';

export const EVENT_REQUEST_FULL_REFUND = 'request_full_refund';
export const EVENT_REQUEST_PARTIAL_REFUND = 'request_partial_refund';
export const EVENT_REDIRECT_ON_CHECKOUT_SESSION_COMPLETED_ERROR =
  'redirect_on_checkout_session_completed_error';

export enum AnalyticsEvent {
  CLICK_OTHER_LOGIN_METHODS = 'click_other_login_methods',
  CLICK_NEED_HELP = 'click_need_help',
  CLICK_SEND_CONFIRMATION_CODE_TO_EMAIL = 'click_send_confirmation_code_to_email',
  CLICK_SKIP_AUTH_MIGRATION = 'click_skip_auth_migration'
}
