import React, { useEffect, useState } from 'react';
import styles from './AcceptP2PRequest.module.scss';
import classnames from 'classnames';
import { connect } from 'react-redux';

import {
  hideModal,
  showModal,
  clearHistory
} from 'legacy/actions/modalActions';
import {
  types as p2pTypes,
  startAcceptRequestCheckoutSession,
  updateAcceptRequestCheckoutSession,
  confirmAcceptRequestCheckoutSession,
  getReceivedRequests,
  clearP2pCheckoutErrors
} from 'legacy/actions/p2pActions';

import { SubmitButton, withForm } from '../../util/FormHelpers';
import { cloneDeep, isEmpty } from 'lodash';

import Card from '../../util/Card/Card';
import Info from 'legacy/common/components/message/Info';

import ModalBase from '../ModalBase/ModalBase';
import { usePrevious } from '../../../util/hooks';
import { CheckoutPricing } from '../JoinEvent/components';
import { COUNTRY_CURRENCY } from 'legacy/common/constants/currency';
import Spinner, { SpinnerType } from 'legacy/common/components/spinner/Spinner';

const AcceptP2PRequest = ({
  baseModalProps,
  formProps,
  request,
  cards,
  loading,
  errors,
  acceptRequestCheckoutSession = {},
  showModal,
  clearHistory,
  getReceivedRequests,
  clearP2pCheckoutErrors,
  startAcceptRequestCheckoutSession,
  updateAcceptRequestCheckoutSession,
  confirmAcceptRequestCheckoutSession
}) => {
  const {
    paymentIntentId,
    requestId,
    pricing,
    card: cardId
  } = acceptRequestCheckoutSession;

  const [apiErrorMessage, setApiErrorMessage] = useState('');

  useEffect(() => {
    if (!paymentIntentId || requestId !== request._id) {
      startAcceptRequestCheckoutSession(request._id);
    }
  }, []);

  useEffect(() => {
    if (errors.START_ACCEPT_REQUEST_CHECKOUT_SESSION?.message) {
      showModal('ALERT', {
        message: errors.START_ACCEPT_REQUEST_CHECKOUT_SESSION.message,
        hideBackButton: true
      });
      clearP2pCheckoutErrors();
    }
  }, [errors.START_ACCEPT_REQUEST_CHECKOUT_SESSION]);

  useEffect(() => {
    if (
      errors.UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION?.message ||
      errors.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION?.message
    ) {
      const apiError =
        errors.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION?.message ||
        errors.UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION?.message;
      setApiErrorMessage(apiError);
      clearP2pCheckoutErrors();
    }
  }, [
    errors.UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION,
    errors.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION
  ]);

  const showConfirmation = () => {
    getReceivedRequests();
    showModal('TRANSFER_CONFIRMATION', {
      title: 'Money Has Been Sent!',
      message: `You have sent $${request.amount} to ${request.receiver.firstName}`
    });
    clearHistory();
  };

  const prevLoading = usePrevious(cloneDeep(loading));
  useEffect(() => {
    if (
      !loading.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION &&
      prevLoading?.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION
    ) {
      if (isEmpty(errors.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION)) {
        showConfirmation();
      }
    }
  }, [loading.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION]);

  const paymentCard = cards.find((card) => card._id === cardId);

  const handleSubmit = (e, formProps) => {
    e.preventDefault();
    formProps.handleSubmit();
    if (formProps.valid || formProps.dirtySinceLastSubmit) {
      confirmAcceptRequestCheckoutSession(requestId, paymentIntentId);
    }
  };

  const submitLoading =
    loading.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION ||
    loading.UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION ||
    loading.START_ACCEPT_REQUEST_CHECKOUT_SESSION;

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <form onSubmit={(e) => handleSubmit(e, formProps)}>
        {loading.START_ACCEPT_REQUEST_CHECKOUT_SESSION ? (
          <div className={styles.loading}>
            <Spinner type={SpinnerType.PRIMARY} />
          </div>
        ) : (
          <div className={classnames(styles.wrapper, 'component')}>
            <h2 className="modalTitle">
              {`Accept request from ${request.receiver.firstName}`}
            </h2>

            <div className="bn-row money">
              <span>{`$${request.amount}`}</span>
            </div>

            <CheckoutPricing
              loading={loading.UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION}
              pricing={pricing}
              showPricing={!isEmpty(pricing)}
              priceLabel="Transfer Amount"
              currency={request.currency}
            />

            {paymentCard && <Card card={paymentCard} width="80%" />}

            <div
              onClick={() =>
                showModal('CHOOSE_PAYMENT_METHOD', {
                  selectedCard: paymentCard,
                  onCardSelect: (card) => {
                    updateAcceptRequestCheckoutSession(
                      requestId,
                      paymentIntentId,
                      card._id
                    );
                    showModal('ACCEPT_P2P_REQUEST', { request });
                  }
                })
              }
              className="bn-btn plain"
            >
              USE ANOTHER CARD
            </div>

            {request?.currency &&
              paymentCard?.country &&
              COUNTRY_CURRENCY[paymentCard?.country]?.toLowerCase() !==
                request?.currency?.toLowerCase() && (
                <Info
                  message={`All amounts shown are in ${request?.currency}`}
                />
              )}

            <SubmitButton
              errorOpen={
                (formProps.hasSubmitErrors &&
                  !formProps.dirtySinceLastSubmit &&
                  !formProps.submitting) ||
                apiErrorMessage
              }
              buttonText="ACCEPT"
              errorContent={
                formProps.hasSubmitErrors
                  ? formProps.submitErrors.FORM_ERROR
                  : apiErrorMessage
              }
              loading={submitLoading}
              disabled={submitLoading}
            />
          </div>
        )}
      </form>
    </ModalBase>
  );
};

function mapStateToProps(state) {
  return {
    errors: state.p2p.errors,
    loading: state.p2p.loading,
    cards: state.payment.cards,
    acceptRequestCheckoutSession: state.p2p.acceptRequestCheckoutSession
  };
}

const actions = {
  startAcceptRequestCheckoutSession,
  updateAcceptRequestCheckoutSession,
  confirmAcceptRequestCheckoutSession,
  getReceivedRequests,
  hideModal,
  showModal,
  clearHistory,
  clearP2pCheckoutErrors
};

export default connect(
  mapStateToProps,
  actions
)(withForm(p2pTypes.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION)(AcceptP2PRequest));
