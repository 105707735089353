import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';

type AlertModalProps = {
  title?: string;
  message?: string;
  error?: string;
  submitLabel?: string;
  submitButtonType?: ButtonType;
  isSubmitting?: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
};

const AlertModal: React.FC<AlertModalProps> = ({
  title,
  message,
  error,
  onSubmit,
  onCancel,
  isSubmitting,
  submitLabel,
  submitButtonType
}) => (
  <Modal
    visible
    type={ModalType.ALERT}
    shouldStopModalClose={isSubmitting}
    style={{
      // We need this alert modal to be on top of the legacy model which has a z-index of 1000
      zIndex: '1001'
    }}
  >
    <div className="px-8 py-6" data-testid="modal-alert">
      <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
        {title || 'Alert'}
      </h1>
      <p className="mt-4 font-normal text-base text-gray-500 leading-loose">
        {message}
      </p>

      {error && <div className="mt-2 text-red-500">{error}</div>}

      <div className="mt-8 flex justify-end">
        {onCancel && (
          <Button type={ButtonType.Clear} onClick={() => onCancel()}>
            <span className="text-gray-500">Cancel</span>
          </Button>
        )}

        <Button
          type={submitButtonType || ButtonType.Danger}
          loading={isSubmitting}
          onClick={() => {
            onSubmit();
          }}
        >
          {submitLabel || 'Submit'}
        </Button>
      </div>
    </div>
  </Modal>
);

export default AlertModal;
