import classNames from 'classnames';
import { ChangeEvent, Dispatch } from 'react';

type SliderProps = {
  min: number;
  max: number;
  value: number;
  step?: number;
  disabled?: boolean;
  className?: string;
  onChange: Dispatch<ChangeEvent<HTMLInputElement>>;
};

const Slider: React.FC<SliderProps> = ({
  value,
  min,
  max,
  step,
  disabled,
  onChange,
  className
}) => {
  const getLinearGradientCSS = () => {
    const percent = ((value - min) / (max - min)) * 100;
    return `linear-gradient(to right, var(--tw-gradient-from) ${percent}%, var(--tw-gradient-to) ${percent}%)`;
  };

  return (
    <input
      type="range"
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange}
      disabled={disabled}
      className={classNames(
        'w-full h-2 outline-none rounded-full cursor-pointer appearance-none',
        {
          'from-primary-400 to-gray-200': !disabled,
          'from-gray-400 to-gray-500': disabled
        },
        className
      )}
      style={{
        backgroundImage: getLinearGradientCSS()
      }}
    />
  );
};

export default Slider;
