import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { LAUNCHDARKLY_USER_KEYS } from 'legacy/common/constants/launchdarkly';
import config from 'config';

type User = {
  id: string;
  email: string;
  name: string;
  country: string;
};

// NOTE: This ensures that the Cypress tests do not unnecessarily create users in LaunchDarkly and increase our MAU count.
const cypressTestAccountPattern =
  /.+(@cypresstest.com|@unverifiedcypresstest.com)/;
const getLaunchDarklyUser = (user: User) =>
  config.ENV !== 'production' && cypressTestAccountPattern.test(user.email)
    ? {
        key: LAUNCHDARKLY_USER_KEYS.CYPRESS_TESTS
      }
    : {
        key: user?.id,
        email: user?.email,
        name: user?.name,
        country: user?.country
      };

const useIdentifyLaunchDarklyUser = (user?: User) => {
  const client = useLDClient();

  useEffect(() => {
    if (!user?.id) {
      client?.identify({
        key: LAUNCHDARKLY_USER_KEYS.ANONYMOUS,
        anonymous: true
      });
      return;
    }

    const ldUser = getLaunchDarklyUser(user);
    client?.identify(ldUser);
  }, [user?.id]);
};

export default useIdentifyLaunchDarklyUser;
