import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';

import { hideModal, modalNext, modalBack } from 'legacy/actions/modalActions';

import { logEvent } from 'legacy/common/utils/amplitude';
import {
  EVENT_CLOSE_MODAL,
  EVENT_OPEN_MODAL
} from 'legacy/common/constants/events';

import AcceptP2PRequest from '../AcceptP2PRequest/AcceptP2PRequest';
import AddPhone from '../AddPhone/AddPhone';
import VerifyPhone from '../AddPhone/VerifyPhone';
import VerifyPhoneSuccess from '../AddPhone/VerifyPhoneSuccess';

import AddCard from '../AddCard/AddCard';
import AddBank from '../AddBank/AddBank';
import FindBankNumbers from '../AddBank/FindBankNumbers';
import ChoosePaymentMethod from '../ChoosePaymentMethod/ChoosePaymentMethod';
import InviteFriendsToBounce from '../InviteFriendsToBounce/InviteFriends';
import FillForm from '../JoinEvent/FormModal';
import Ticket from '../Ticket/Ticket';
import ResetPasswordRequest from '../ResetPasswordRequest/ResetPasswordContainer';
import InviteUsersToEvent from '../InviteUsersToEvent/InviteUsersToEvent';
import ViewGuests from '../ViewGuests/ViewGuests';
import ViewHosts from '../ViewHosts/ViewHosts';
import TicketValidation from '../TicketValidation/TicketValidation';
import { ShareUrl } from '../ShareUrl/ShareUrl';
import Confirmation from '../common/Confirmation/Confirmation';
import Alert from '../common/Alert/Alert';
import SendMoney from '../SendMoney/SendMoney';
import TransferConfirmation from '../TransferConfirmation/TransferConfirmation';
import RequestMoney from '../RequestMoney/RequestMoney';
import SelectFriend from '../SelectFriend/SelectFriend';
import PhotoUploadModal from '../PhotoUploadModal/PhotoUploadModal';
import ExportGuests from '../ExportGuests/ExportGuests';
import ConnectVerificationModal from '../ConnectVerificationModal/ConnectVerificationModal';
import TierPricing from '../TierPricing/TierPricing';
import TicketNotes from '../TicketNotes/TicketNotes';
import ViewTicketNotes from '../ViewTicketNotes/ViewTicketNotes';
import CreateConnect from '../CreateConnect/CreateConnect';
import ViewFriends from '../ViewFriends/ViewFriends';
import AllPhotos from '../AllPhotos/AllPhotos';
import ViewUserGallery from '../ViewUserGallery/ViewUserGallery';
import QuickViewProfile from '../QuickViewProfile/QuickViewProfile';
import ViewOrgMembers from '../ViewOrgMembers/ViewOrgMembers';
import Modal from '../ModalBlank/ModalBlank';

const MODAL_COMPONENTS = {
  ALL_PHOTOS: AllPhotos,
  ACCEPT_P2P_REQUEST: AcceptP2PRequest,
  ADD_PAYMENT_METHOD: AddCard,
  ADD_BANK: AddBank,
  FIND_BANK_NUMBERS: FindBankNumbers,
  ADD_PHONE: AddPhone,
  VERIFY_PHONE: VerifyPhone,
  VERIFY_PHONE_SUCCESS: VerifyPhoneSuccess,
  CHOOSE_PAYMENT_METHOD: ChoosePaymentMethod,
  CONNECT_VERIFICATION: ConnectVerificationModal,
  CREATE_CONNECT: CreateConnect,
  INVITE_TO_BOUNCE: InviteFriendsToBounce,
  INVITE_USERS_TO_EVENT: InviteUsersToEvent,
  PHOTO_UPLOAD: PhotoUploadModal,
  RESET_PASSWORD_REQUEST: ResetPasswordRequest,
  VIEW_GUESTS: ViewGuests,
  VIEW_HOSTS: ViewHosts,
  VIEW_TICKET: Ticket,
  VALIDATE_TICKET: TicketValidation,
  SHARE_URL: ShareUrl,
  SELECT_FRIEND: SelectFriend,
  SEND_MONEY: SendMoney,
  REQUEST_MONEY: RequestMoney,
  TRANSFER_CONFIRMATION: TransferConfirmation,
  EXPORT_GUESTS: ExportGuests,
  TIER_PRICING: TierPricing,
  TICKET_NOTES: TicketNotes,
  VIEW_TICKET_NOTES: ViewTicketNotes,
  FILL_FORM: FillForm,
  VIEW_FRIENDS: ViewFriends,
  VIEW_ORG_MEMBERS: ViewOrgMembers,
  VIEW_USER_GALLERY: ViewUserGallery,
  QUICKVIEW_PROFILE: QuickViewProfile,
  /* common modals */
  CONFIRMATION: Confirmation,
  ALERT: Alert,
  BASE: Modal
};

function ModalRoot({ router, modal, hideModal, modalNext, modalBack, device }) {
  const { modalType, modalProps, futureState, isOpen, showTransition } = modal;
  useEffect(() => {
    if (!modalType) {
      return;
    }

    logEvent(EVENT_OPEN_MODAL, {
      type: modalType
    });
  }, [modalType]);

  const onRequestClose = () => {
    logEvent(EVENT_CLOSE_MODAL, {
      type: modalType
    });

    if (modal.modalProps && modal.modalProps.onRequestClose) {
      modal.modalProps.onRequestClose();
    } else {
      hideModal();
    }
  };

  const onSuccess = (...args) => {
    if (modal.modalProps && modal.modalProps.onSuccess) {
      modal.modalProps.onSuccess(...args);
    } else {
      hideModal();
    }
  };

  if (!modalType) {
    return null; // after React v15 you can return null here
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  const baseModalProps = {
    onRequestClose,
    modalBack,
    isOpen,
    showTransition,
    device
  };

  return (
    <SpecificModal
      {...modalProps}
      isOpen={isOpen}
      router={router}
      future={futureState}
      onSuccess={onSuccess}
      baseModalProps={baseModalProps}
      close={hideModal}
      next={modalNext}
      device={device}
    />
  );
}

function mapStateToProps(state) {
  return {
    modal: state.modal,
    device: state.app.device
  };
}

const actions = { hideModal, modalNext, modalBack };

export default withRouter(connect(mapStateToProps, actions)(ModalRoot));
