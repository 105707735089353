import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import _ from 'lodash';
import classnames from 'classnames';
import NavLink from '../../util/NavLink/NavLink';
import styles from './Header.module.scss';
import { useWindowSize } from '../../../util/hooks';

// const COLLABSIBLE_MENU_BREAKPOINT = 750;

function Header({ device, user }) {
  const router = useRouter();
  const { pathname } = router;

  const [width, height] = useWindowSize();
  // const [open, toggleOpen] = useToggle(false);

  const underline = useRef(null);
  const slideLinks = ['features', 'pricing', 'about'];
  const activeLink = pathname.replace('/', '');
  function slide() {
    if (!slideLinks.includes(activeLink)) {
      return;
    }
    const item = document.querySelector(`#${activeLink}`);
    const rect = item.getBoundingClientRect();

    underline.current.style.visibility = 'visible';

    underline.current.style.left = `${rect.left}px`;
    underline.current.style.width = `${rect.width}px`;
  }

  // Update slide position whenever these change
  useEffect(slide, [width, height, user, pathname, device]);

  const links = (
    <div
      className={
        user
          ? `${styles.Navlinks} ${styles.DesktopOnly}`
          : `${styles.NavlinksUser} ${styles.DesktopOnly}`
      }
    >
      {slideLinks.map((link) => (
        <NavLink
          key={link}
          id={link}
          activeHref={link === 'home' ? '/' : `/${link}`}
        >
          {_.capitalize(link)}
        </NavLink>
      ))}

      {(pathname.startsWith('/features') ||
        pathname.startsWith('/pricing') ||
        pathname.startsWith('/about')) && (
        <div id="underline" ref={underline} />
      )}
    </div>
  );

  const [showMenu, setShowMenu] = useState(false);

  let menu;

  if (showMenu) {
    menu = (
      <div
        className={styles.MobileMenu}
        style={{ transform: 'translateX(0%)', transition: '0.2s' }}
      >
        <ul className={styles.NavManu}>
          <Link
            onClick={() => setShowMenu(!showMenu)}
            href="/features"
            legacyBehavior
          >
            <li style={{ color: '#6386fe' }}>Features</li>
          </Link>
          <Link
            onClick={() => setShowMenu(!showMenu)}
            href="/pricing"
            legacyBehavior
          >
            <li style={{ color: '#6386fe' }}>Pricing</li>
          </Link>
          <Link
            onClick={() => setShowMenu(!showMenu)}
            href="/about"
            legacyBehavior
          >
            <li style={{ color: '#6386fe' }}>About</li>
          </Link>
          <Link
            onClick={() => setShowMenu(!showMenu)}
            href={user ? '/dashboard' : '/login'}
            legacyBehavior
          >
            <li style={{ color: '#6386fe' }}>
              {user ? 'Dashboard' : 'Login'}
              <i className="fa fa-arrow-right" style={{ padding: '0 5px' }} />
            </li>
          </Link>
        </ul>
      </div>
    );
  } else {
    menu = (
      <div
        className={styles.MobileMenu}
        style={{ transform: 'translateX(115%)', transition: '0.2s' }}
      >
        <ul className={styles.NavManu}>
          <Link
            onClick={() => setShowMenu(!showMenu)}
            href="/features"
            legacyBehavior
          >
            <li style={{ color: '#6386fe' }}>Features</li>
          </Link>
          <Link
            onClick={() => setShowMenu(!showMenu)}
            href="/pricing"
            legacyBehavior
          >
            <li style={{ color: '#6386fe' }}>Pricing</li>
          </Link>
          <Link
            onClick={() => setShowMenu(!showMenu)}
            href="/about"
            legacyBehavior
          >
            <li style={{ color: '#6386fe' }}>About</li>
          </Link>
          <Link
            onClick={() => setShowMenu(!showMenu)}
            href={user ? '/dashboard' : '/login'}
            legacyBehavior
          >
            <li style={{ color: '#6386fe' }}>
              {user ? 'Dashboard' : 'Login'}
              <i className="fa fa-arrow-right" style={{ padding: '0 5px' }} />
            </li>
          </Link>
        </ul>
      </div>
    );
  }

  const [scroll, setScroll] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
  }, []);

  return (
    <div className={classnames(styles.Header, { [styles.active]: scroll })}>
      {/* absolute positioned div with gradient that fades with opacity */}
      <div className={styles.bg} />
      <div className={styles.headerContent}>
        <div className={styles.MaxWidth}>
          <div className={styles.NavBar}>
            <div className="logo">
              <div className="click-wrapper" onClick={() => setShowMenu(false)}>
                <a href="/">
                  <img
                    src={
                      scroll
                        ? '/img/Logo_Color.svg'
                        : '/img/logos/bounce-white.svg'
                    }
                    alt="Bounce"
                  />
                </a>
              </div>
            </div>
            {links}
          </div>
          <nav className={styles.DesktopOnly}>
            <div className={styles.Auth}>
              <Link href={user ? '/dashboard' : '/login'} legacyBehavior>
                <div className={styles.Dashboard}>
                  <button>{user ? 'Dashboard' : 'Log In'}</button>
                </div>
              </Link>
              {!user && (
                <Link href="/register" legacyBehavior>
                  <div className={styles.Dashboard}>
                    <button className={styles.ButtonBounce}>Sign Up</button>
                  </div>
                </Link>
              )}
            </div>
          </nav>
          <div className={scroll ? 'menu actived' : 'menu'}>
            <img
              alt="hamburger"
              src="/img/icons/hamburger.svg"
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
        </div>
        {menu}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  device: state.app.device
});

export default connect(mapStateToProps)(Header);
