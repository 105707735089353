import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/orgActions';
import { types as connectTypes } from 'legacy/actions/connectActions';
import { toActionID } from '../util/util';

export const initialState = {
  orgs: {},
  transactions: {},
  orgRequests: [],
  errors: _.mapValues(types, () => ({})),
  loading: _.mapValues(types, () => false)
};

const SISTER_TYPES = [
  connectTypes.ADD_BANK_MANUALLY.success,
  connectTypes.ADD_PLAID_BANK.success,
  connectTypes.DELETE_BANK.success,
  connectTypes.SET_DEFAULT_BANK.success
];

export function orgReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
  }
  switch (action.type) {
    case types.ADD_ORG_PHONE.success:
    case types.DELETE_ORG_PHONE.success:
    case types.EDIT_ORG.success:
    case types.CREATE_ORG.success:
    case types.GET_ORG.success:
    case types.ACCEPT_ORG_INVITATION.success:
    case types.DECLINE_ORG_INVITATION.success:
    case types.EDIT_ORG_MEMBER.success:
    case types.REMOVE_ORG_MEMBER.success:
      newState.orgs = {
        ...newState.orgs,
        [action.payload._id]: {
          hostedEvents: newState.orgs[action.payload._id]?.hostedEvents,
          ...action.payload
        }
      };
      return newState;
    case types.VERIFY_ORG_PHONE.success:
      if (action.payload.orgId) {
        newState.orgs[action.payload.orgId].phoneVerified = true;
      }
      return newState;
    case types.GET_ORG_HOSTED_EVENTS.success:
      newState.orgs = {
        ...newState.orgs,
        [action.payload.orgId]: {
          ...newState.orgs[action.payload.orgId],
          hostedEvents: action.payload.data
        }
      };
      return newState;
    case types.UPLOAD_ORG_PHOTO.success:
    case types.UPLOAD_ORG_EMBLEM.success:
      newState.orgs = {
        ...newState.orgs,
        [action.payload._id]: {
          ...newState.orgs[action.payload._id],
          ...action.payload.data
        }
      };
      return newState;
    case types.GET_INCOMING_ORG_TRANSACTIONS.success:
      newState.transactions = {
        ...newState.transactions,
        [action.payload._id]: action.payload.transactions
      };
      return newState;
    case connectTypes.ADD_BANK_MANUALLY.success:
    case connectTypes.ADD_PLAID_BANK.success:
    case connectTypes.DELETE_BANK.success:
      if (action.payload.orgId) {
        newState.orgs = {
          ...newState.orgs,
          [action.payload.orgId]: {
            ...newState.orgs[action.payload.orgId],
            banks: action.payload.banks
          }
        };
      }
      return newState;
    case connectTypes.SET_DEFAULT_BANK.success:
      if (action.payload.orgId) {
        newState.orgs[action.payload.orgId].banks = newState.orgs[
          action.payload.orgId
        ].banks.map((bank) => ({
          ...bank,
          default: bank._id === action.payload.defaultBank
        }));
      }
      return newState;
    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: orgReducer };
