import { ERROR_CODES } from 'legacy/common/constants/errorCodes';
import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import { omitBy, isUndefined } from 'lodash';

import { toActionObject } from '../util/util';
import {
  resolveError,
  resolveFormError,
  createAxiosRequest
} from './actionUtil';

export const actionIDs = [
  'SEND_MONEY_FORM',
  'REQUEST_MONEY',
  'REQUEST_MONEY_FORM',
  'GET_SENT_REQUESTS',
  'GET_RECEIVED_REQUESTS',
  'ACCEPT_TRANSFER_REQUEST',
  'DECLINE_TRANSFER_REQUEST',
  'CANCEL_TRANSFER_REQUEST',
  'START_P2P_CHECKOUT_SESSION',
  'CONFIRM_P2P_CHECKOUT_SESSION',
  'UPDATE_P2P_CHECKOUT_SESSION',
  'START_ACCEPT_REQUEST_CHECKOUT_SESSION',
  'CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION',
  'UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION',
  'CLEAR_P2P_CHECKOUT_ERRORS'
];

export const types = toActionObject(actionIDs);

/**
 * Requests money from another user
 * @param {*object} details the object containing request details
 */
export function requestMoney(details) {
  return function (dispatch) {
    dispatch({ type: types.REQUEST_MONEY.start });
    bounceApiClient
      .post('/api/p2p/requests', details)
      .then((res) => {
        dispatch({
          type: types.REQUEST_MONEY.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.REQUEST_MONEY.error,
          payload: resolveError(err)
        });

        if (err.code === ERROR_CODES.MISSING_EMAIL_VERIFICATION) {
          return;
        }

        dispatch({
          type: types.REQUEST_MONEY_FORM.success,
          payload: { FORM_ERROR: resolveFormError(err) }
        });
      });
  };
}

/**
 * Get pending direct transaction requests sent by user
 */
export function getSentRequests() {
  return function (dispatch) {
    dispatch({ type: types.GET_SENT_REQUESTS.start });
    bounceApiClient
      .get('/api/p2p/requests/sent/all')
      .then((res) => {
        dispatch({
          type: types.GET_SENT_REQUESTS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_SENT_REQUESTS.error,
          payload: resolveError(err)
        });
      });
  };
}

/**
 * Get pending direct transaction requests received by user
 */
export function getReceivedRequests() {
  return function (dispatch) {
    dispatch({ type: types.GET_RECEIVED_REQUESTS.start });
    bounceApiClient
      .get('/api/p2p/requests/received/all')
      .then((res) => {
        dispatch({
          type: types.GET_RECEIVED_REQUESTS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_RECEIVED_REQUESTS.error,
          payload: resolveError(err)
        });
      });
  };
}

/**
 * Accept a request to send a direct transaction to another user
 * @param {*string} requestId id of the request
 * @param {*string} paymentSource optional stripe card id
 */
export function acceptTransferRequest(requestId, paymentSource) {
  return function (dispatch) {
    dispatch({ type: types.ACCEPT_TRANSFER_REQUEST.start });
    bounceApiClient
      .post(`/api/p2p/requests/${requestId}/accept`, {
        paymentSource
      })
      .then((res) => {
        dispatch({
          type: types.ACCEPT_TRANSFER_REQUEST.success,
          payload: {
            ...res.data,
            _id: requestId,
            status: 'accepted'
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.ACCEPT_TRANSFER_REQUEST.error,
          payload: resolveError(err)
        });
      });
  };
}

/**
 * Decline a request to send a direct transaction to another user.
 * Returns the updated request object.
 * @param {*string} requestId id of the request
 */
export function declineTransferRequest(requestId) {
  return function (dispatch) {
    dispatch({ type: types.DECLINE_TRANSFER_REQUEST.start });
    bounceApiClient
      .post(`/api/p2p/requests/${requestId}/decline`)
      .then((res) => {
        dispatch({
          type: types.DECLINE_TRANSFER_REQUEST.success,
          payload: {
            ...res.data,
            _id: requestId,
            status: 'declined'
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.DECLINE_TRANSFER_REQUEST.error,
          payload: resolveError(err)
        });
      });
  };
}

/**
 * Cancel a request for a direct transaction from another user
 * @param {*string} requestId id of the request
 */
export function cancelTransferRequest(requestId) {
  return function (dispatch) {
    dispatch({ type: types.CANCEL_TRANSFER_REQUEST.start });
    bounceApiClient
      .post(`/api/p2p/requests/${requestId}/cancel`)
      .then((res) => {
        dispatch({
          type: types.CANCEL_TRANSFER_REQUEST.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.CANCEL_TRANSFER_REQUEST.error,
          payload: resolveError(err)
        });
      });
  };
}

export const startP2pCheckoutSession = (body) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/p2p/checkout`,
    params: omitBy(body, isUndefined),
    dispatchType: types.START_P2P_CHECKOUT_SESSION
  });

export const confirmP2pCheckoutSession = (paymentIntentId, body) =>
  createAxiosRequest({
    action: 'post',
    params: body,
    url: `/api/p2p/checkout/${paymentIntentId}/confirm`,
    dispatchType: types.CONFIRM_P2P_CHECKOUT_SESSION,
    formType: types.SEND_MONEY_FORM
  });

export const updateP2pCheckoutSession = (updates, paymentIntentId) =>
  createAxiosRequest({
    action: 'put',
    url: `/api/p2p/checkout/${paymentIntentId}`,
    params: omitBy(updates, isUndefined),
    dispatchType: types.UPDATE_P2P_CHECKOUT_SESSION
  });

export const startAcceptRequestCheckoutSession = (requestId) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/p2p/requests/${requestId}/checkout`,
    dispatchType: types.START_ACCEPT_REQUEST_CHECKOUT_SESSION
  });

export const confirmAcceptRequestCheckoutSession = (
  requestId,
  paymentIntentId
) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/p2p/requests/${requestId}/checkout/${paymentIntentId}/confirm`,
    dispatchType: types.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION
  });

export const updateAcceptRequestCheckoutSession = (
  requestId,
  paymentIntentId,
  card
) =>
  createAxiosRequest({
    action: 'put',
    params: { card },
    url: `/api/p2p/requests/${requestId}/checkout/${paymentIntentId}`,
    dispatchType: types.UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION
  });

export function clearP2pCheckoutErrors() {
  return {
    type: types.CLEAR_P2P_CHECKOUT_ERRORS.success
  };
}
