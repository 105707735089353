import packageInfo from '../package.json';

const config = {
  APP_VERSION: packageInfo.version,
  ENV: process.env.NEXT_PUBLIC_BUILD_ENV,
  FB_ID: process.env.NEXT_PUBLIC_FB_ID,
  APPLE_CLIENT_ID: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
  BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
  STRIPE_PK_CA: process.env.NEXT_PUBLIC_STRIPE_PK_CA,
  STRIPE_PK_US: process.env.NEXT_PUBLIC_STRIPE_PK_US,
  SITE_URL: process.env.NEXT_PUBLIC_PUBLIC_URL,
  PLAID_PUBLIC_KEY: process.env.NEXT_PUBLIC_PLAID_PUBLIC_KEY,
  PLAID_ENV: process.env.NEXT_PUBLIC_PLAID_ENV,
  AMPLITUDE_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_PUBLIC_KEY,
  GOOGLE_MAPS_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
  GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
  FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  FIREBASE_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  FIREBASE_VAPID_KEY: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
  LAUNCHDARKLY_CLIENT_ID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
  HEAP_ID: process.env.NEXT_PUBLIC_HEAP_ID,
  FULLSTORY_ORG_ID: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID
};

export default config;
