import React from 'react';
import styles from './BackButton.module.scss';

const BackButtonSimple = ({ goBack, img }) => (
  <div
    className={styles.BackButtonSimple}
    onClick={goBack}
    data-cy="modal-back-button"
  >
    <img src={`/img/buttons/${img}.svg`} alt="" />
  </div>
);

export default BackButtonSimple;
