import formStyles from 'legacy/common/components/form/Form.module.scss';

import { Component } from 'react';
import styles from './AddCard.module.scss';
import classNames from 'classnames';
import {
  composeValidators,
  isEmail,
  isEqualTo,
  isRequired,
  isValidZip
} from '../../../../../util/validators';

import { ValidatedInput, CheckboxField } from '../../../../util/FormHelpers';
import { Field } from 'react-final-form';

import { CURRENCY_CODES } from '../../../../../util/constants';

import * as stripe from 'legacy/common/utils/stripe';
import Button from 'legacy/common/components/button/Button';
import FadeIn from 'legacy/common/components/transition/FadeIn';
import { COUNTRY_OPTIONS } from 'legacy/common/constants/countries';

const cardBrandToClass = {
  visa: 'fa-cc-visa',
  mastercard: 'fa-cc-mastercard',
  amex: 'fa-cc-amex',
  discover: 'fa-cc-discover',
  diners: 'fa-cc-diners-club',
  jcb: 'fa-cc-jcb',
  unknown: 'fa-credit-card'
};

class AddCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      numberComplete: false,
      expiryComplete: false,
      cvcComplete: false,
      view: 'card'
    };
  }

  componentDidMount() {
    if (this.props.stripeHasLoaded) {
      this.mountStripeElements();
    }
    this.supportedDevice();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.payment.cards.length < this.props.payment.cards.length) {
      this.props.onSuccess(this.props.payment.cards);
    }
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ view: 'card' });
      this.mountStripeElements();
    }
    if (!prevProps.stripeHasLoaded && this.props.stripeHasLoaded) {
      this.mountStripeElements();
    }
    if (prevState.view !== 'card' && this.state.view === 'card') {
      this.mountStripeElements();
    }
  }

  mountStripeElements = () => {
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '13px',
        textAlign: 'left'
      }
    };

    const elements = stripe.getInstance()?.elements();

    if (!elements) {
      return;
    }

    this.setState(
      {
        cardNumber: elements.create('cardNumber', { style }),
        cardExpiry: elements.create('cardExpiry', { style }),
        cardCvc: elements.create('cardCvc', { style })
      },
      () => {
        this.state.cardNumber.mount('#card-number');
        this.state.cardExpiry.mount('#card-expiry');
        this.state.cardCvc.mount('#card-cvc');

        this.state.cardNumber.on('change', (event) => {
          event.brand && this.setBrandIcon(event.brand); // change displayed card brand
          this.setComplete(event, 'numberComplete');
        });
        this.state.cardExpiry.on('change', (event) => {
          this.setComplete(event, 'expiryComplete');
        });
        this.state.cardCvc.on('change', (event) => {
          this.setComplete(event, 'cvcComplete');
        });
      }
    );
  };

  supportedDevice = () => {
    const user = this.props.user;
    const paymentRequest = stripe.getInstance()?.paymentRequest({
      country: user.country,
      currency: CURRENCY_CODES[user.country].toLowerCase(),
      total: {
        label: 'Checking support',
        amount: 999
      }
    });

    if (!paymentRequest) {
      return;
    }

    // Check the availability of the Payment Request API
    paymentRequest.canMakePayment();
  };

  setBrandIcon = (brand) => {
    const brandIconElement = document.getElementById('brand-icon');

    if (!brandIconElement) {
      return;
    }

    let pfClass = 'fa-credit-card';

    if (brand in cardBrandToClass) {
      pfClass = cardBrandToClass[brand];
    }

    for (let i = brandIconElement?.classList?.length - 1; i >= 0; i--) {
      brandIconElement?.classList?.remove(brandIconElement?.classList[i]);
    }

    brand === 'unknown'
      ? brandIconElement?.classList?.add('far')
      : brandIconElement?.classList?.add('fab');
    brandIconElement?.classList?.add(pfClass);
    brandIconElement?.classList?.add('fa-2x');
  };

  // set complete status of number, expiry, or cvc elemnt
  // used in part to disable or enable submit button
  setComplete = (event, element) => {
    event.complete
      ? this.setState({ [element]: true })
      : this.setState({ [element]: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.formProps.handleSubmit();

    const { cardNumber } = this.state;
    const { country, postalCode, name, defaultCard, email } =
      this.props.formProps.values;

    if (email && !this.props.user?.email) {
      this.props.emailOptions.setEmail(email, () => {
        this.props.addCard({
          cardNumber,
          name,
          country,
          postalCode,
          defaultCard
        });
      });
    } else {
      this.props.addCard({
        cardNumber,
        name,
        country,
        postalCode,
        defaultCard
      });
    }
  };

  render() {
    const { formProps, user } = this.props;
    const { numberComplete, expiryComplete, cvcComplete } = this.state;

    return (
      <div className="h-full relative flex flex-col items-center justify-start overflow-auto pb-40">
        <form
          className="w-full p-8 pt-12 flex flex-col justify-between"
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <FadeIn>
            <h2 className="text-3xl">Add Payment Method</h2>

            <div className={formStyles.group}>
              <p className={formStyles.label}>Card Number</p>
              <div
                id="card-number"
                className={classNames(
                  classNames(formStyles.input, styles.stripeInput),
                  styles.stripeInput
                )}
              />
            </div>

            <div className={formStyles.row}>
              <div className={formStyles.col}>
                <p className={formStyles.label}>Expiry date</p>
                <div
                  id="card-expiry"
                  className={classNames(formStyles.input, styles.stripeInput)}
                />
              </div>
              <div className={formStyles.col}>
                <p className={formStyles.label}>CVC</p>
                <div
                  id="card-cvc"
                  className={classNames(formStyles.input, styles.stripeInput)}
                />
              </div>
            </div>
            <div className={formStyles.group}>
              <p className={formStyles.label}>Name on card</p>
              <ValidatedInput
                errorVisible={this.props.isOpen}
                validate={isRequired}
                type="text"
                name="name"
                placeholder="Name on Card"
                className={formStyles.input}
              />
            </div>
            <div className={formStyles.row}>
              <div className={formStyles.col}>
                <p className={formStyles.label}>Country</p>
                <Field
                  name="country"
                  component="select"
                  initialValue={user.country}
                  className={formStyles.select}
                  autoComplete="shipping-region"
                  style={{
                    width: '100%',
                    color: 'grey',
                    height: '40px'
                  }}
                >
                  {COUNTRY_OPTIONS.map((p) => (
                    <option key={p.key} value={p.value}>
                      {p.text}
                    </option>
                  ))}
                </Field>
              </div>
              <div className={formStyles.col}>
                <p className={formStyles.label}>Postal Code</p>
                <ValidatedInput
                  errorVisible={this.props.isOpen}
                  validate={composeValidators(isRequired, isValidZip)}
                  type="text"
                  name="postalCode"
                  placeholder="Zip/Postal Code"
                  autoComplete="shipping postal-code"
                  className={formStyles.input}
                />
              </div>
            </div>
            <div className={formStyles.group}>
              <CheckboxField
                name="defaultCard"
                form={formProps.form}
                text="Set as Default"
                initial
              />
            </div>
            {!user.email && (
              <div className="mb-5">
                <div className={formStyles.group}>
                  <p className={formStyles.label}>Email</p>
                  <ValidatedInput
                    errorVisible={this.props.isOpen}
                    validate={composeValidators(isRequired, isEmail)}
                    type="email"
                    name="email"
                    placeholder="Email"
                    className={formStyles.input}
                  />
                </div>
                <div className={formStyles.group}>
                  <p className={formStyles.label}>Confirm Email</p>
                  <ValidatedInput
                    errorVisible={this.props.isOpen}
                    validate={composeValidators(
                      isRequired,
                      isEqualTo(formProps.values.email, 'Email must match')
                    )}
                    type="email"
                    name="emailConfirmation"
                    placeholder="Confirm Email"
                    className={formStyles.input}
                  />
                </div>
              </div>
            )}
            {formProps?.submitErrors?.FORM_ERROR && (
              <div className="text-center text-red-500 mb-2">
                {formProps?.submitErrors?.FORM_ERROR}
              </div>
            )}

            <div className="mt-10">
              <Button
                buttonText="SAVE"
                errorContent={
                  formProps.hasSubmitErrors
                    ? formProps.submitErrors.FORM_ERROR
                    : null
                }
                loading={
                  this.props.payment.loading.ADD_CARD ||
                  this.props.emailOptions?.isSetEmailLoading
                }
                disabled={
                  (!formProps.valid && !formProps.dirtySinceLastSubmit) ||
                  !(numberComplete && expiryComplete && cvcComplete)
                }
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </div>
          </FadeIn>
        </form>
      </div>
    );
  }
}

export default AddCard;
