import cn from 'classnames';
import { useState } from 'react';

import Backdrop from 'legacy/common/components/backdrop/Backdrop';

import All from 'legacy/search/components/All';
import People from 'legacy/search/components/People';
import Groups from 'legacy/search/components/Groups';
import Events from 'legacy/search/components/Events';

import styles from './Results.module.scss';
import { FLAGS } from 'legacy/common/constants/launchdarkly';
import { useFlags } from 'launchdarkly-react-client-sdk';

const tabs = {
  all: All,
  people: People,
  events: Events,
  groups: Groups
};

type ResultsProps = {
  input: string;
  close: () => void;
};

const Results: React.FC<ResultsProps> = ({ input }) => {
  const [activeTab, setActiveTab] = useState('all');

  const ActiveTab = tabs[activeTab];
  const flags = useFlags();

  return (
    <>
      <div className={styles.results} data-testid="search-box">
        <div className={styles.tab}>
          <div
            data-testid="search-all"
            className={cn(styles.category, {
              [styles.active]: activeTab === 'all'
            })}
            onClick={() => {
              setActiveTab('all');
            }}
          >
            All
          </div>
          <div
            data-testid="search-people"
            className={cn(styles.category, {
              [styles.active]: activeTab === 'people'
            })}
            onClick={() => {
              setActiveTab('people');
            }}
          >
            People
          </div>
          <div
            data-testid="search-events"
            className={cn(styles.category, {
              [styles.active]: activeTab === 'events'
            })}
            onClick={() => {
              setActiveTab('events');
            }}
          >
            Events
          </div>

          {flags[FLAGS.ENABLE_GROUPS] && (
            <div
              data-testid="search-groups"
              className={cn(styles.category, {
                [styles.active]: activeTab === 'groups'
              })}
              onClick={() => {
                setActiveTab('groups');
              }}
            >
              Orgs
            </div>
          )}
        </div>

        <ActiveTab input={input} close={close} />
      </div>
      <Backdrop />
    </>
  );
};

export default Results;
