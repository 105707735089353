import React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import NavLink from '../../util/NavLink/NavLink';

import styles from './PrivateHeader.module.scss';

function PublicEventHeader() {
  const router = useRouter();
  return (
    <div className={classnames([styles.PublicEventHeader], 'bg-primary-400')}>
      <div className="h-full flex container mx-auto">
        {/* top bar */}
        <div className="bn-row">
          <div className="flex h-full items-center justify-center">
            <a href="/">
              <img className="w-36 mr-10" src="/img/logo.svg" alt="Bounce" />
            </a>
          </div>
          <NavLink
            id="login"
            activeHref={{
              pathname: '/login',
              query: {
                from: router.asPath
              }
            }}
          >
            Login <i className="fa fa-arrow-right" />
          </NavLink>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  device: state.app.device
});

export default connect(mapStateToProps)(PublicEventHeader);
