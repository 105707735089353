import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Field } from 'react-final-form';
import styles from './FormFields.module.scss';

const TimePickerField = ({
  name,
  formProps,
  fieldProps,
  required = true,
  disabledBefore, // only passed to endTime input field to disable dates before startTime
  disabledAfter,
  onChange,
  ...props
}) => {
  const currentValue = formProps?.values[name] || '';

  const change = onChange || formProps?.form.change;
  const maxTime = new Date();
  maxTime.setHours(23);
  maxTime.setMinutes(59);

  const handleChange = (value) => {
    const newValue = moment(value).format('HH:mm');
    if (newValue !== 'Invalid date') {
      change(name, newValue);
    }
  };
  return (
    <>
      <DatePicker
        onChange={handleChange}
        // Parse a time-only string into a valid date.
        selected={
          currentValue
            ? moment(currentValue, ['h:m a', 'H:m']).toDate()
            : undefined
        }
        autoComplete="nope"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        minTime={disabledBefore}
        maxTime={disabledAfter || maxTime}
        dateFormat="h:mm aa"
        required={required}
        className={styles.timepickerField}
        popperClassName={styles.popper}
        showPopperArrow={false}
        {...props}
      />

      {/* This hidden field ensures all of the react-final-form logic runs as expected when the date-picker is changed */}
      <Field name={name} type="hidden" component="input" {...fieldProps} />
    </>
  );
};

export default TimePickerField;
