import { useDebounce } from 'use-debounce';
import { useUsersSearch } from 'legacy/search/hooks';

import Message from './Message';
import ResultList from './ResultList';

type PeopleProps = {
  input: string;
};

const People: React.FC<PeopleProps> = ({ input }) => {
  const [debouncedInput] = useDebounce(input, 200);
  const isDebouncing = input !== debouncedInput;

  const { data: users, isLoading } = useUsersSearch(debouncedInput);

  if (!debouncedInput && !isDebouncing) {
    return <Message message="Search for people" />;
  }

  if (debouncedInput && !isLoading && !isDebouncing && !users?.length) {
    return <Message message={`No results for "${debouncedInput}"`} />;
  }

  return (
    <ResultList
      id="results-people"
      isLoading={isLoading || isDebouncing}
      results={users?.map((user) => ({
        id: user._id,
        value: user.name,
        image: user.photo?.url || '/img/search/user.svg',
        linkTo: `/profile/${user._id}`,
        rawData: {
          ...user,
          type: 'user'
        }
      }))}
    />
  );
};

export default People;
