import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import _uniq from 'lodash/uniq';

import { logEvent } from 'legacy/common/utils/amplitude';
import { AnalyticsEvent } from 'legacy/common/constants/events';

import withLoginCheck from 'legacy/auth/hoc/withLoginCheck';

import { MigrationRequirementsDto } from 'legacy/user/types';

import IntroModal from './IntroModal';
import {
  useGetAuthMigrationRequirementsCommand,
  useGetMe
} from 'legacy/user/hooks';

import UpdateEmailFlow from './UpdateEmailFlow';
import CreatePasswordFlow from './CreatePasswordFlow';
import AddUpdatePhoneNumberFlow from 'legacy/user/components/settings/phone/AddUpdatePhoneNumberFlow';
import withDisableInWebview from 'legacy/auth/hoc/withDisableInWebview';

enum Steps {
  INTRO = 'INTRO',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD'
}

const MigrationFlow = () => {
  useGetMe();

  const authMigrationRequirementsCommand =
    useGetAuthMigrationRequirementsCommand();

  const [migrationRequirements, setMigrationRequirements] =
    useState<MigrationRequirementsDto>();
  const [currentStep, setCurrentStep] = useState<Steps>(null);

  useEffect(() => {
    authMigrationRequirementsCommand.mutate(null, {
      onSuccess: (data) => {
        setMigrationRequirements(data);
      },
      onError: (error) => {
        // NOTE: This should never happen in practice, so if it does, log the error to Sentry for further investigation.
        Sentry.captureMessage(
          `Encountered an unexpected error while getting auth migration requirements: ${JSON.stringify(
            error
          )}`,
          { level: 'warning' }
        );
      }
    });
  }, []);

  const [completedSteps, setCompletedSteps] = useState<Steps[]>([]);
  const addToCompletedSteps = (completedStep: Steps) => {
    setCompletedSteps((completedSteps) =>
      _uniq([...completedSteps, completedStep])
    );
  };

  const handleModalFlow = (
    migrationRequirements: MigrationRequirementsDto,
    completedSteps: Steps[]
  ) => {
    if (
      !completedSteps.includes(Steps.INTRO) &&
      (migrationRequirements?.requirements?.email ||
        migrationRequirements?.requirements?.password ||
        migrationRequirements?.requirements?.phone)
    ) {
      setCurrentStep(Steps.INTRO);
      return;
    }

    if (
      migrationRequirements?.requirements?.phone &&
      !completedSteps.includes(Steps.PHONE)
    ) {
      setCurrentStep(Steps.PHONE);
      return;
    }

    if (
      migrationRequirements?.requirements?.email &&
      !completedSteps.includes(Steps.EMAIL)
    ) {
      setCurrentStep(Steps.EMAIL);
      return;
    }

    if (
      migrationRequirements?.requirements?.password &&
      !completedSteps.includes(Steps.PASSWORD)
    ) {
      setCurrentStep(Steps.PASSWORD);
      return;
    }

    setCurrentStep(null);
  };

  useEffect(() => {
    if (!migrationRequirements) {
      return;
    }

    handleModalFlow(migrationRequirements, completedSteps);
  }, [migrationRequirements, completedSteps]);

  if (currentStep === Steps.INTRO) {
    return (
      <IntroModal
        onSubmit={() => {
          addToCompletedSteps(Steps.INTRO);
        }}
        onSkip={() => {
          logEvent(AnalyticsEvent.CLICK_SKIP_AUTH_MIGRATION);
          setCurrentStep(null);
        }}
      />
    );
  }

  if (currentStep === Steps.PHONE) {
    return (
      <AddUpdatePhoneNumberFlow
        onClose={() => setCurrentStep(null)}
        onSuccess={() => {
          addToCompletedSteps(Steps.PHONE);
        }}
      />
    );
  }

  if (currentStep === Steps.EMAIL) {
    return (
      <UpdateEmailFlow
        migrationRequirements={migrationRequirements}
        onClose={() => setCurrentStep(null)}
        onSuccess={() => {
          addToCompletedSteps(Steps.EMAIL);
        }}
      />
    );
  }

  if (currentStep === Steps.PASSWORD) {
    return (
      <CreatePasswordFlow
        onClose={() => setCurrentStep(null)}
        onSuccess={() => {
          addToCompletedSteps(Steps.PASSWORD);
        }}
      />
    );
  }

  return null;
};

export default withDisableInWebview(withLoginCheck(MigrationFlow));
