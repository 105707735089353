import React, { useState } from 'react';

import { MigrationRequirementsDto } from 'legacy/user/types';
import {
  useEditMeCommand,
  useGetMe,
  useUpdateEmailCommand
} from 'legacy/user/hooks';

import * as toast from 'legacy/common/utils/toast';

import UpdateEmailModal from './UpdateEmailModal';
import ConfirmPassword from 'legacy/user/components/settings/ConfirmPasswordModal';

type UpdateEmailFlowTypes = {
  migrationRequirements: MigrationRequirementsDto;
  onClose: () => void;
  onSuccess: () => void;
};

const UpdateEmailFlow: React.FC<UpdateEmailFlowTypes> = ({
  migrationRequirements,
  onClose,
  onSuccess
}) => {
  const { data: user } = useGetMe({
    enabled: false
  });

  const [newEmail, setNewEmail] = useState<string>();
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(true);

  const editMeCommand = useEditMeCommand();
  const updateEmailCommand = useUpdateEmailCommand();

  const handleSubmit = ({ email }) => {
    // No need to send an update email request if the email is not changed.
    if (user?.email === email) {
      onSuccess();
      return;
    }

    if (migrationRequirements.requirements.password) {
      editMeCommand.mutate(
        {
          email
        },
        {
          onSuccess: () => {
            onSuccess();

            toast.success('Email Address Updated');
          }
        }
      );
      return;
    }

    setNewEmail(email);
    setShowUpdateEmailModal(false);
  };

  const handleConfirm = ({ password }: { password: string }) => {
    updateEmailCommand.mutate(
      {
        email: newEmail,
        password
      },
      {
        onSuccess: () => {
          onSuccess();

          toast.success('Email Address Updated');
        }
      }
    );
  };

  return (
    <>
      {showUpdateEmailModal && (
        <UpdateEmailModal
          onClose={onClose}
          onSubmit={handleSubmit}
          isLoading={editMeCommand.isLoading}
          migrationRequirements={migrationRequirements}
          errorMessage={editMeCommand.error?.message}
        />
      )}

      {newEmail && (
        <ConfirmPassword
          errorMessage={updateEmailCommand.error?.message}
          isLoading={updateEmailCommand.isLoading}
          onSubmit={handleConfirm}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default UpdateEmailFlow;
