import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import useFCM from 'legacy/common/hooks/useFCM';
import styles from './PrivatePage.module.scss';

import { useRouter } from 'next/router';
import { isBounceWebview } from 'legacy/common/utils/webview';

const PrivatePage = ({ pathname, device, children }) => {
  const router = useRouter();

  const getNumBanners = () => {
    if (process.browser) {
      return document.querySelectorAll('.bounce-banner').length;
    }
    return 0;
  };
  const [numBanners, setBanners] = useState(getNumBanners());
  const [hasSetObserver, setHasSetObserver] = useState(false);

  const callback = function (mutationsList) {
    // Use traditional 'for loops' for IE 11
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        setBanners(getNumBanners());
      }
    }
  };

  // Create an observer instance linked to the callback function
  const [observer, setObserver] = useState();

  useEffect(() => {
    const node = document.getElementById('bounce-header');

    if (node && !hasSetObserver && observer) {
      setHasSetObserver(true);
      const config = { attributes: true, childList: true, subtree: true };
      observer.observe(node, config);
      setBanners(getNumBanners());
    }
  });

  useEffect(() => {
    setObserver(new MutationObserver(callback));
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  let defaultPadding = device === 'desktop' ? 60 : 90;

  // The following screens don't have a header...
  // Unfortunately there's not an elegant solution for that right now.
  if (
    (router.query.view === 'discountCodes' ||
      router.query.view === 'analytics') &&
    isBounceWebview(router.query)
  ) {
    defaultPadding = 0;
  }

  useFCM();

  return (
    <>
      <div
        className={classnames(
          [styles.PrivatePage],
          device,
          pathname.split('/').pop()
        )}
        style={{
          paddingTop: numBanners
            ? `${numBanners * 30 + defaultPadding}px`
            : `${defaultPadding}px`
        }}
      >
        <div className={styles.main}>
          <div className={styles.pageContent}>{children}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  device: state.app.device
});

export default connect(mapStateToProps)(PrivatePage);
