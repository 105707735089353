import { handleMissingEmailVerificationInterceptor } from 'legacy/auth/interceptors';
import AlertModal from 'legacy/common/components/modal/AlertModal';
import { ErrorResponse } from 'legacy/common/utils/error';
import { useMemo, useState } from 'react';

/**
 * This component initializes a response interceptor that shows an alert when email is not verified.
 */
const UnverifiedEmailCheck = () => {
  const [unverifiedEmailError, setUnverifiedEmailError] =
    useState<ErrorResponse>();

  useMemo(() => {
    handleMissingEmailVerificationInterceptor({
      onMissingEmailVerificationError: (error: ErrorResponse) => {
        setUnverifiedEmailError(error);
      }
    });
  }, []);

  if (!unverifiedEmailError) {
    return null;
  }

  return (
    <AlertModal
      title="Verify your email"
      submitLabel="Okay"
      message={unverifiedEmailError?.message}
      onSubmit={() => {
        setUnverifiedEmailError(null);
      }}
    />
  );
};

export default UnverifiedEmailCheck;
