import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';
import ModalBase from '../../ModalBase/ModalBase';
import styles from './Confirmation.module.scss';

import Spinner from 'legacy/common/components/spinner/Spinner';

const Confirmation = ({
  baseModalProps,
  title,
  message,
  actionText,
  cancelText,
  action,
  hideBackButton,
  destructive,
  onCancel,
  onSuccess,
  close,
  type,
  showLoading,
  disableCloseOnCancel,
  disableCloseOnAction
}) => {
  const [isLoading, setLoading] = useState();

  const handleConfirm = () => {
    if (action) {
      action();
    }

    if (showLoading) {
      setLoading(true);
    } else {
      if (disableCloseOnAction) {
        return;
      }

      onSuccess({ close });
    }
  };

  const handleCancel = () => {
    if (!disableCloseOnCancel) {
      close();
    }

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <ModalBase
      baseModalProps={{
        ...baseModalProps,
        shouldCloseOnOverlayClick: !isLoading,
        shouldCloseOnEsc: !isLoading
      }}
      back_img="Back"
      contentClassName="confirmation"
      className={classnames({ success: type === 'success' })}
      hideBackButton={hideBackButton}
    >
      <div className="confirmationMessage">
        {title && <p className={styles.title}>{title}</p>}
        <p>{message}</p>
      </div>

      <div className="actions">
        <Button
          disabled={isLoading}
          onClick={handleCancel}
          data-cy="modal-cancel-button"
        >
          {cancelText}
        </Button>
        <Button
          disabled={isLoading}
          negative={destructive}
          primary={!destructive}
          onClick={handleConfirm}
          data-cy="modal-confirm-button"
        >
          {isLoading ? <Spinner /> : actionText}
        </Button>
      </div>
    </ModalBase>
  );
};

Confirmation.defaultProps = {
  destructive: false,
  actionText: 'Yes',
  cancelText: 'Cancel',
  message: 'Are you sure you want to do this?'
};

export default Confirmation;
