import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { AvatarGroupType, AvatarSizes } from '../../types';
import Avatar from '../Avatar';

import styles from './styles.module.scss';

interface Props {
  children: ReactNode[];
  additionalCount?: number;
  type?: AvatarGroupType;
  additionalCountSize?: AvatarSizes;
  onClick?: () => void;
}

function AvatarGroup({
  children,
  additionalCount = 0,
  type = AvatarGroupType.Stack,
  additionalCountSize = AvatarSizes.Lg,
  onClick
}: Props) {
  return (
    <div
      className={classNames(
        'flex items-center justify-center',
        type === AvatarGroupType.Grid ? 'gap-1' : styles['avatar-group--stack'],
        onClick && 'cursor-pointer'
      )}
      onClick={onClick || undefined}
    >
      {children}
      {additionalCount > 0 && (
        <Avatar label={`+${additionalCount}`} size={additionalCountSize} />
      )}
    </div>
  );
}

export default AvatarGroup;
