import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { getCallingCode, getCountryCode } from 'legacy/common/utils/country';

import { phoneNumberRegex } from 'legacy/auth/helpers';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';
import PhoneInput from 'legacy/common/components/phone-input/PhoneInput';

type AddUpdatePhoneNumberModalProps = {
  errorMessage: string;
  isLoading: boolean;
  phone: string;
  onClose: () => void;
  onSubmit: (options: {
    phoneNumber: string;
    callingCode: string;
    countryCode: string;
  }) => void;
};

const UpdatePhoneNumberSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(phoneNumberRegex, 'Phone number is invalid')
    .required('Required')
});

const AddUpdatePhoneNumberModal: React.FC<AddUpdatePhoneNumberModalProps> = ({
  errorMessage,
  isLoading,
  phone,
  onClose,
  onSubmit
}) => {
  const currentCountryCode = getCountryCode();
  const currentCallingCode = getCallingCode(currentCountryCode);

  return (
    <Modal
      visible
      onClose={onClose}
      type={ModalType.ALERT}
      shouldStopModalClose={isLoading}
    >
      <div className="px-8 py-6" data-testid="modal-update-phone-number">
        <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
          {phone ? 'Update Phone Number' : 'Add Phone Number'}
        </h1>
        <p className="mt-4 font-normal text-base text-gray-500 leading-loose">
          Your phone number will be linked to your account.
        </p>

        {errorMessage && (
          <div className="mt-2 text-red-500">{errorMessage}</div>
        )}
        <Formik
          initialValues={{
            phoneNumber: '',
            countryCode: currentCountryCode,
            callingCode: currentCallingCode
          }}
          onSubmit={onSubmit}
          validationSchema={UpdatePhoneNumberSchema}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            setFieldValue,
            handleBlur,
            handleChange
          }) => (
            <Form>
              <div className="mt-6 mb-2">
                <p className="text-sm text-gray-400">Phone Number</p>
              </div>
              <PhoneInput
                value={values.phoneNumber}
                hasError={errors.phoneNumber && touched.phoneNumber}
                testId="input-phone-number"
                countryCode={values.countryCode}
                onCountryChange={(selected) => {
                  setFieldValue('countryCode', selected.countryCode);
                  setFieldValue('callingCode', selected.value);
                }}
                onChange={handleChange('phoneNumber')}
                onBlur={handleBlur('phoneNumber')}
              />

              {errors.phoneNumber && touched.phoneNumber && (
                <div className="mt-2 text-red-500 text-right">
                  {errors.phoneNumber}
                </div>
              )}

              <div className="pl-20 mt-8 flex justify-end">
                <Button
                  disabled={isLoading}
                  type={ButtonType.Clear}
                  onClick={() => onClose()}
                >
                  <span className="text-gray-400">Cancel</span>
                </Button>
                <Button
                  isSubmit
                  testId="button-submit"
                  disabled={!isValid}
                  loading={isLoading}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddUpdatePhoneNumberModal;
