import styles from './FormFields.module.scss';

import cn from 'classnames';
import { Field } from 'react-final-form';

const ValidatedSelect = ({
  name,
  className,
  disabled,
  options,
  validate,
  placeholder,
  defaultValue,
  showErrorBeforeTouched
}) => (
  <Field
    name={name}
    validate={validate}
    disabled={disabled}
    defaultValue={defaultValue}
  >
    {({ input, meta }) => (
      <div className={cn(styles.fieldContainer)}>
        <select
          className={cn(styles.dropdown, className || 'ui selection dropdown', {
            [styles.dropdownError]:
              meta.error && (meta.touched || showErrorBeforeTouched)
          })}
          disabled={disabled}
          {...input}
        >
          <option selected value="">
            {placeholder}
          </option>
          {options.map((p) => (
            <option key={p.key} value={p.value}>
              {p.text}
            </option>
          ))}
        </select>
        {meta.error && (meta.touched || showErrorBeforeTouched) && (
          <div className={styles.error} data-test={`error-${name}`}>
            {meta.error}
          </div>
        )}
      </div>
    )}
  </Field>
);

export default ValidatedSelect;
