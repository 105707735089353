import qs from 'query-string';
import { createAxiosRequest } from './actionUtil';
import { toActionObject } from '../util/util';

export const actionIDs = [
  'GET_ORG',
  'CREATE_ORG',
  'EDIT_ORG',
  'ADD_ORG_PHONE',
  'DELETE_ORG_PHONE',
  'VERIFY_ORG_PHONE',
  'RESEND_ORG_PHONE_VERIFICATION',
  'ACCEPT_ORG_INVITATION',
  'DECLINE_ORG_INVITATION',
  'INVITE_ORG_MEMBER',
  'REMOVE_ORG_MEMBER',
  'UPDATE_BUSINESS_VERIFICATION',
  'GET_INCOMING_ORG_TRANSACTIONS',
  'EDIT_ORG_MEMBER',
  'UPLOAD_ORG_PHOTO',
  'UPLOAD_ORG_EMBLEM',
  'GET_ORG_HOSTED_EVENTS'
];

export const types = toActionObject(actionIDs);

export const getOrg = (orgId) =>
  createAxiosRequest({
    action: 'get',
    url: `/api/organizations/${orgId}`,
    dispatchType: types.GET_ORG
  });

export const createOrg = ({ description, email, name }) =>
  createAxiosRequest({
    action: 'post',
    url: '/api/organizations',
    dispatchType: types.CREATE_ORG,
    params: { description, email, name }
  });

export const editOrg = (orgId, params) =>
  createAxiosRequest({
    action: 'put',
    url: `/api/organizations/${orgId}`,
    dispatchType: types.EDIT_ORG,
    params
  });

export const acceptOrgInvitation = (orgId) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/organizations/${orgId}/invites/accept`,
    dispatchType: types.ACCEPT_ORG_INVITATION
  });

export const declineOrgInvitation = (orgId) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/organizations/${orgId}/invites/decline`,
    dispatchType: types.DECLINE_ORG_INVITATION
  });

export const updateBusinessVerification = (orgId, body) =>
  createAxiosRequest({
    action: 'put',
    url: `/api/organizations/${orgId}/verification`,
    dispatchType: types.UPDATE_BUSINESS_VERIFICATION,
    params: body
  });

export const addOrgPhone = (orgId, phone) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/organizations/${orgId}/phone`,
    dispatchType: types.ADD_ORG_PHONE,
    params: { phone }
  });

export const deleteOrgPhone = (orgId) =>
  createAxiosRequest({
    action: 'delete',
    url: `/api/organizations/${orgId}/phone`,
    dispatchType: types.DELETE_ORG_PHONE
  });

export const verifyOrgPhone = (orgId, token) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/organizations/${orgId}/phone/verify`,
    dispatchType: types.VERIFY_ORG_PHONE,
    params: { token },
    payload: { orgId }
  });

export const resendOrgPhoneVerification = (orgId) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/organizations/${orgId}/phone/resendVerfication`,
    dispatchType: types.RESEND_ORG_PHONE_VERIFICATION
  });

export const removeOrgMember = (orgId, userId) =>
  createAxiosRequest({
    action: 'delete',
    url: `/api/organizations/${orgId}/members/${userId}`,
    dispatchType: types.REMOVE_ORG_MEMBER
  });

export const editOrgMember = (orgId, memberId, params) =>
  createAxiosRequest({
    action: 'put',
    url: `/api/organizations/${orgId}/members/${memberId}`,
    dispatchType: types.EDIT_ORG_MEMBER,
    params
  });

export const getIncomingOrgTransactions = (orgId) =>
  createAxiosRequest({
    action: 'get',
    url: `/api/organizations/${orgId}/transactions/incoming`,
    dispatchType: types.GET_INCOMING_ORG_TRANSACTIONS,
    generatePayload: (data) => ({ _id: orgId, transactions: data })
  });

export const uploadPhoto = (orgId, formdata, crop) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/organizations/${orgId}/photos?${qs.stringify(crop)}`,
    params: formdata,
    dispatchType: types.UPLOAD_ORG_PHOTO,
    generatePayload: (data) => ({ _id: orgId, data })
  });

export const uploadEmblem = (orgId, formdata, crop) =>
  createAxiosRequest({
    action: 'post',
    url: `/api/organizations/${orgId}/emblem?${qs.stringify(crop)}`,
    params: formdata,
    dispatchType: types.UPLOAD_ORG_EMBLEM,
    generatePayload: (data) => ({ _id: orgId, data })
  });

export const getOrgHostedEvents = (orgId) =>
  createAxiosRequest({
    action: 'get',
    url: `/api/organizations/${orgId}/hostedEvents`,
    dispatchType: types.GET_ORG_HOSTED_EVENTS,
    generatePayload: (data) => ({ data, orgId })
  });
