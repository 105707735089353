import styles from './Modal.module.scss';
import { IoClose } from 'react-icons/io5';
import { MdArrowBack } from 'react-icons/md';

import classnames from 'classnames';

import Portal from 'legacy/common/components/portal/Portal';

export enum ModalType {
  /** Smaller width (420px x 670px) */
  SMALL = 'SMALL',
  /** Small height and width (420px x 500px) */
  VERY_SMALL = 'VERY_SMALL',
  /** Small width and Dynamic height (320px x auto) */
  ALERT = 'ALERT',
  /** Makes the modal full screen */
  FULL_SCREEN = 'FULL_SCREEN'
}

type ModalProps = {
  type?: ModalType;
  visible?: boolean;
  children: React.ReactNode;
  noPadding?: boolean;
  backdropClassName?: string;
  shouldStopModalClose?: boolean;
  transparent?: boolean;
  style?: Record<string, any>;
  autoHeightOnMobile?: boolean;
  disableAnimationOnWeb?: boolean;
  title?: string;
  icon?: React.ReactChild;
  isBehind?: boolean;
  autoHeight?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  onScroll?: () => void;
  renderFooter?: () => React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({
  type,
  visible,
  children,
  backdropClassName,
  shouldStopModalClose,
  transparent,
  style,
  autoHeightOnMobile,
  disableAnimationOnWeb,
  title,
  icon,
  isBehind,
  autoHeight,
  onClose,
  renderFooter,
  hideCloseButton,
  noPadding,
  onScroll,
  onBack
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Portal style={style}>
      <div
        className={classnames(styles.container, {
          [styles.small]: type === ModalType.SMALL,
          [styles.verySmall]: type === ModalType.VERY_SMALL,
          [styles.alert]: type === ModalType.ALERT,
          [styles.fullScreen]: type === ModalType.FULL_SCREEN,
          [styles.transparent]: transparent,
          [styles.autoHeightOnMobile]: autoHeightOnMobile,
          [styles.disableAnimationOnWeb]: disableAnimationOnWeb,
          [styles.isBehind]: isBehind,
          [styles.autoHeight]: autoHeight,
          [styles.noPadding]: noPadding
        })}
      >
        {title && (
          <div className="w-full p-4 h-17 border-b border-gray-200 flex items-center justify-between">
            <div className="flex items-center">
              {onBack && (
                <button
                  type="button"
                  aria-label="Back"
                  onClick={onBack}
                  className="mr-3 p-1 flex items-center justify-center"
                >
                  <MdArrowBack size={20} />
                </button>
              )}
              {!onBack && icon && (
                <div className="w-10 h-10 rounded-md mr-4 bg-primary-50 flex items-center justify-center text-primary-500 overflow-hidden">
                  {icon}
                </div>
              )}

              <h1 className="text-base font-semibold">{title}</h1>
            </div>

            {!hideCloseButton && (
              <div
                className="h-8 w-8 rounded-full flex items-center justify-center bg-primary-50 cursor-pointer"
                onClick={() => {
                  if (shouldStopModalClose) {
                    return;
                  }

                  onClose();
                }}
              >
                <IoClose className="text-gray-700" size={16} />
              </div>
            )}
          </div>
        )}

        {renderFooter ? (
          <div
            className="h-full pb-24 md:pb-16 overflow-auto max-h-[95vh] md:max-h-[600px]"
            onScroll={onScroll}
          >
            {children}
          </div>
        ) : (
          children
        )}

        {/* Close button */}
        {!title && !!onClose && !shouldStopModalClose && !hideCloseButton && (
          <div className={styles.closeButton}>
            <img
              data-testid="modal-close-button"
              src="/img/icons/cross.svg"
              onClick={() => {
                if (shouldStopModalClose) {
                  return;
                }

                onClose();
              }}
            />
          </div>
        )}

        {renderFooter && (
          <div className="absolute left-0 bottom-0 border-t border-gray-200 w-full p-4 bg-white pb-12 md:pb-4">
            {renderFooter()}
          </div>
        )}
      </div>

      {/* Backdrop */}
      <div
        className={classnames(styles.backdrop, backdropClassName)}
        onClick={() => {
          if (shouldStopModalClose) {
            return;
          }

          if (onClose) {
            onClose();
          }
        }}
      />
    </Portal>
  );
};

Modal.defaultProps = {
  visible: false
};

export default Modal;
