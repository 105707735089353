import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';

import Spinner, { SpinnerType } from '../spinner/Spinner';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  Danger = 'danger',
  Clear = 'clear',
  Text = 'text',
  Inverted = 'inverted',
  LargeCta = 'large-cta'
}

export enum ButtonSize {
  Small = 'small',
  Large = 'large'
}

type ButtonProps = {
  type?: ButtonType;
  icon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  isSubmit?: boolean;
  testId?: string;
  size?: ButtonSize;
  autoWidth?: boolean;
  mobileRounded?: boolean;
  newRadius?: boolean;
  rounded?: boolean;
  transparentBg?: boolean;
  fillContainer?: boolean;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
};

/**
 * This component is atrocious, we need to stop augmenting it and build a proper button.
 * TODO: Kickstart the design system by making a button component.
 */
const Button: React.FC<ButtonProps> = ({
  children,
  type,
  icon,
  loading,
  disabled,
  onClick,
  isSubmit,
  testId,
  size,
  autoWidth,
  className,
  mobileRounded,
  newRadius,
  rounded,
  fillContainer,
  transparentBg
}) => {
  let spinnerType: SpinnerType;

  switch (type) {
    case ButtonType.Primary:
      spinnerType = SpinnerType.DEFAULT;
      break;

    case ButtonType.Danger:
      spinnerType = SpinnerType.DANGER;
      break;

    default:
      spinnerType = SpinnerType.PRIMARY;
  }

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={loading || disabled}
      data-testid={testId}
      className={cn(
        styles.button,
        'transition-transform active:scale-95',
        {
          [styles.primary]: type === ButtonType.Primary,
          [styles.secondary]: type === ButtonType.Secondary,
          [styles.clear]: type === ButtonType.Clear,
          [styles.text]: type === ButtonType.Text,
          [styles.outline]: type === ButtonType.Outline,
          [styles.danger]: type === ButtonType.Danger,
          [styles.inverted]: type === ButtonType.Inverted,
          [styles.largeCta]: type === ButtonType.LargeCta,
          [styles.small]: size === ButtonSize.Small,
          [styles.large]: size === ButtonSize.Large,
          [styles.autoWidth]: autoWidth,
          [styles.mobileRounded]: mobileRounded,
          [styles.newRadius]: newRadius,
          [styles.rounded]: rounded,
          [styles.fillContainer]: fillContainer,
          [styles.transparentBg]: transparentBg
        },
        rounded && 'rounded-full',
        className
      )}
    >
      {loading ? (
        <Spinner type={spinnerType} />
      ) : (
        <>
          {icon ? (
            <div
              className={cn(
                // There's been some issues with padding inside buttons, so we're forcing margins and paddings to 0
                'flex flex-1 align-center justify-center gap-2 pt-0 mt-0 pb-0 mb-0 pl-0 ml-0 pr-0 mr-0',
                className
              )}
            >
              <span className={styles.icon}>{icon}</span>
              {children}
            </div>
          ) : (
            children
          )}
        </>
      )}
    </button>
  );
};

export default Button;
