import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/eventActions';
import { types as hostTypes } from 'legacy/actions/hostActions';
import { types as userTypes } from 'legacy/actions/userActions';
import { toActionID, updateIfPresent } from '../util/util';

export const initialState = {
  events: {},
  userHostedEvents: [],
  nearbyEvents: [],
  searchResults: [],
  geolocation: false,
  feed: [],
  tickets: [],
  loading: _.mapValues(types, () => false),
  errors: _.mapValues(types, () => ({})),
  inviteList: {}
};

// Allow specific sister types from other actions
const SISTER_TYPES = [
  userTypes.LOGOUT.success,
  userTypes.GET_USER_ATTENDED_EVENTS.success,
  userTypes.GET_USER_HOSTED_EVENTS.success,
  hostTypes.EDIT_EVENT.success,
  hostTypes.UPLOAD_COVER_PHOTO.success,
  hostTypes.DELETE_EVENT.success,
  hostTypes.ADD_OPERATORS.success,
  hostTypes.ADD_COHOST.success,
  hostTypes.REMOVE_COHOST.success,
  hostTypes.DELETE_OPERATOR.success,
  hostTypes.GET_GUEST_BREAKDOWN.success
];

export function eventReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
  }
  switch (action.type) {
    case types.CLEAR_EVENT_CHECKOUT_ERRORS.success:
      newState.errors[toActionID(types.CONFIRM_CHECKOUT_SESSION.error)] = {};
      newState.errors[toActionID(types.UPDATE_CHECKOUT_SESSION.error)] = {};
      newState.errors[toActionID(types.START_CHECKOUT_SESSION.error)] = {};
      return newState;

    case userTypes.LOGOUT.success:
      return initialState;

    case types.PUBLIC_GET_EVENT.success:
    case types.GET_EVENT.success:
      newState.events[action.payload._id] = {
        ...newState.events[action.payload._id],
        ...action.payload,
        maxAttendance: action.payload.maxAttendance || 0,
        cohost: action.payload.cohost || null
      };

      return newState;
    case types.GET_NEARBY_EVENTS.success:
      return { ...newState, nearbyEvents: action.payload };

    case types.GET_ACTIVE_TICKETS.success:
      return { ...newState, tickets: action.payload };

    case types.GET_LOCATION.success:
      return { ...newState, geolocation: action.payload };

    case types.SEARCH_EVENTS.success:
      return { ...newState, searchResults: action.payload };

    case types.START_CHECKOUT_SESSION.success:
    case types.UPDATE_CHECKOUT_SESSION.success:
      newState.events[action.payload.eventId].checkoutSession =
        action.payload.session;

      return newState;

    case types.CONFIRM_CHECKOUT_SESSION.success:
      newState.tickets = [...newState.tickets, action.payload.ticket];
      newState.events[action.payload.eventId].userStatus = 'attending';
      return newState;

    case types.UPDATE_TICKET_INFO.success:
      newState.events[action.payload._id] = {
        ...newState.events[action.payload._id],
        infoRequired: {
          ...(!action.payload.googleFormsAnswered && {
            googleForms: true
          }),
          ...(!action.payload.ticketNotes && { ticketNotes: true })
        }
      };
      return newState;

    case types.CHECK_TICKET_NOTES_STATUS.success:
      newState.events[action.payload._id] = {
        ...newState.events[action.payload._id],
        infoRequired: action.payload.infoRequired
      };
      return newState;

    case types.GET_EVENT_GUESTS.success:
      newState.events[action.payload._id] = {
        ...newState.events[action.payload._id],
        guests: action.payload.guests
      };
      return newState;

    case types.REMOVE_REFUNDED_GUEST.success: {
      const event = newState.events[action.payload._id];
      const guests =
        event.guests?.filter((g) => g._id !== action.payload.userId) || [];
      const guestPreview =
        event.guestPreview?.filter((g) => g._id !== action.payload.userId) ||
        [];
      const guestBreakdown =
        event.guestBreakdown?.filter(
          (g) => g.user?._id !== action.payload.userId
        ) || [];

      newState.events[action.payload._id] = {
        ...event,
        guests,
        totalGuests: event.totalGuests - 1,
        guestPreview,
        guestBreakdown
      };
      return newState;
    }

    case types.GET_PRICE_BREAKDOWN.success:
      newState.events[action.payload._id] = {
        ...newState.events[action.payload._id],
        priceBreakdown: _.merge(
          newState.events[action.payload._id].priceBreakdown,
          action.payload.priceBreakdown
        )
      };
      return newState;

    case hostTypes.GET_GUEST_BREAKDOWN.success:
      newState.events[action.payload._id] = {
        ...newState.events[action.payload._id],
        guestBreakdown: action.payload.guestBreakdown
      };
      return newState;

    case userTypes.GET_USER_ATTENDED_EVENTS.success:
    case userTypes.GET_USER_HOSTED_EVENTS.success:
      for (const event of action.payload.data) {
        if (newState.events[event._id]) {
          newState.events[event._id] = {
            ...newState.events[event._id],
            ...event
          };
        } else {
          newState.events[event._id] = event;
        }
      }
      return newState;
    case hostTypes.EDIT_EVENT.success:
      newState.nearbyEvents = updateIfPresent(
        newState.nearbyEvents,
        (ev) => ev._id === action.payload._id,
        action.payload
      );
      newState.events[action.payload._id] = {
        ...newState.events[action.payload._id],
        ...action.payload
      };
      // if endDate is removed then endDate just isn't returned and redux doesn't update until refresh
      if (
        newState.events[action.payload._id].endDate &&
        !action.payload.endDate
      ) {
        delete newState.events[action.payload._id].endDate;
      }
      return newState;

    case hostTypes.UPLOAD_COVER_PHOTO.success:
      // Only add photo url if the event is present in the state
      if (!newState.events[action.payload._id]) {
        return newState;
      }

      newState.events[action.payload._id].photo = {
        position: action.payload.photo.position,
        url: `${action.payload.photo.url}?hash=${uuid()}`
      };
      return newState;

    case hostTypes.DELETE_EVENT.success:
      // events is an object, use pickBy that acts as an object key filter
      newState.events = _.pickBy(
        newState.events,
        (event, id) => id !== action.payload
      );

      // nearbyEvents is an array, use standard Array.filter
      newState.nearbyEvents = newState.nearbyEvents.filter(
        (event) => event._id !== action.payload
      );
      return newState;

    case hostTypes.ADD_OPERATORS.success:
    case hostTypes.DELETE_OPERATOR.success:
      newState.events[action.eventId].operators = action.operators;
      return newState;
    case hostTypes.ADD_COHOST.success:
      newState.events[action.payload.eventId].cohost = action.payload.cohost;
      return newState;
    case hostTypes.REMOVE_COHOST.success:
      delete newState.events[action.payload.eventId].cohost;
      return newState;

    case types.VALIDATE_EVENT_URL.success:
      newState.events[action.payload._id] = action.payload;
      return newState;

    case types.UPLOAD_PHOTO_TO_GALLERY.success:
      newState.events[action.payload._id].gallery.push(action.payload.photo);
      return newState;

    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: eventReducer };
