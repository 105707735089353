import React from 'react';
import ModalBase from '../ModalBase/ModalBase';

const Modal = ({ children, ...props }) => (
  <ModalBase back_img="Back" {...props}>
    {children}
  </ModalBase>
);

export default Modal;
