import bounceApiClient from 'legacy/common/utils/bounceApiClient';
import qs from 'query-string';
import router from 'next/router';
import * as firebase from 'legacy/common/utils/firebase';
import { unregisterForFCM } from '../notification/service';
import {
  resolveError,
  resolveFormError,
  createAxiosRequest
} from './actionUtil';

import { toActionObject } from '../util/util';

import storage from 'legacy/common/utils/storage';
import { clearSentryUser } from 'legacy/common/utils/sentry';
import { stopUserSession } from 'legacy/common/utils/amplitude';
import { STORAGE_KEYS } from 'legacy/common/constants/storage';
import { queryClient } from 'legacy/common/utils/react-query';
import { isPublicRoute } from 'legacy/common/utils/routes';

export const actionIDs = [
  'INITIAL_LOGIN',
  'EDIT_ME',
  'EDIT_ME_FORM',
  'UPLOAD_PROFILE_PICTURE',
  'UPLOAD_PHOTO',
  'DELETE_PHOTO',
  'RESET_PASSWORD',
  'RESET_PASSWORD_FORM',
  'RESET_PASSWORD_REQUEST',
  'RESET_PASSWORD_REQUEST_FORM',
  'UPDATE_PASSWORD',
  'ADD_PHONE',
  'DELETE_PHONE',
  'VERIFY_PHONE',
  'RESEND_PHONE_VERIFICATION',
  'RESEND_VERIFICATION_EMAIL',
  'DELETE_ME',
  'SEARCH_USERS',
  'GET_MY_PROFILE',
  'GET_USER',
  'GET_USER_QUICKVIEW',
  'GET_NEWSFEED',
  'GET_OUTGOING_TRANSACTIONS',
  'GET_INCOMING_TRANSACTIONS',
  'GET_NOTIFICATIONS',
  'VERIFY_EMAIL',
  'CUSTOM_LOGIN_FORM',
  'APPLE_LOGIN',
  'CUSTOM_REGISTER',
  'CUSTOM_REGISTER_FORM',
  'APPLE_LOADED',
  'LOGOUT',
  'FETCH_CHAT_TOKEN',
  'JOIN_GROUP_CHAT',
  'GET_EDIT_SUGGESTIONS',
  'GET_USER_ORGANIZATIONS',
  'GET_USER_FRIENDS',
  'GET_USER_ATTENDED_EVENTS',
  'GET_USER_HOSTED_EVENTS',
  'PHONE_LOGIN',
  'PHONE_LOGIN_REQUEST',
  'UPDATE_PROFILE',
  'UNAUTHORIZED_USER'
];

export const types = toActionObject(actionIDs);

// TODO: move this to an auth helper
export function removeLoginData({ forceRedirect = false } = {}) {
  const token = storage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  if (token) {
    const pastDate = new Date(2000, 0, 0);
    document.cookie = `token=; path=/; expires=${pastDate}`;
  }
  storage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
  storage.removeItem(STORAGE_KEYS.RECENT_SEARCHES);

  queryClient.clear();
  stopUserSession();
  clearSentryUser();

  if (!isPublicRoute(router.pathname) || forceRedirect) {
    router.push('/login');
  }
}

export function attemptInitialJwtLogin({ isAuthenticated }) {
  if (!isAuthenticated) {
    // NOTE: This is required to show the public event header to unauthorized users
    return function (dispatch) {
      dispatch({
        type: types.UNAUTHORIZED_USER.success
      });
    };
  }

  return function (dispatch) {
    dispatch({ type: types.INITIAL_LOGIN.start });
    bounceApiClient
      .get(`/api/users/me`)
      .then((res) => {
        dispatch({
          type: types.INITIAL_LOGIN.success,
          payload: { user: res.data }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.INITIAL_LOGIN.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getMyProfile() {
  return function (dispatch) {
    dispatch({ type: types.GET_MY_PROFILE.start });
    bounceApiClient
      .get('/api/users/me')
      .then(({ data }) => {
        dispatch({
          type: types.GET_MY_PROFILE.success,
          payload: { user: data }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_MY_PROFILE.error,
          payload: resolveError(err)
        });
      });
  };
}

/**
 * Edit the users personal info
 * @param {Object} info an object containing any of the keys:
 * email, firstName, lastName, password(custom auth)
 * @returns
 */
export function editMe(info) {
  return createAxiosRequest({
    action: 'put',
    url: '/api/users/me',
    params: info,
    payload: info,
    dispatchType: types.EDIT_ME,
    formType: types.EDIT_ME_FORM
  });
}

export function uploadProfilePicture(formdata, crop) {
  return function (dispatch) {
    dispatch({ type: types.UPLOAD_PROFILE_PICTURE.start });
    bounceApiClient
      .post(`/api/users/me/photo?${qs.stringify(crop)}`, formdata)
      .then((res) => {
        dispatch({
          type: types.UPLOAD_PROFILE_PICTURE.success,
          payload: res.data.photo
        });
      })
      .catch((err) => {
        dispatch({
          type: types.UPLOAD_PROFILE_PICTURE.error,
          payload: resolveError(err)
        });
      });
  };
}

export function uploadPhoto(formdata, crop) {
  return createAxiosRequest({
    action: 'post',
    url: `/api/users/me/photos?${qs.stringify(crop)}`,
    params: formdata,
    dispatchType: types.UPLOAD_PHOTO
  });
}

export function deletePhoto(photoId) {
  return createAxiosRequest({
    action: 'delete',
    url: `/api/users/me/photos/${photoId}`,
    dispatchType: types.DELETE_PHOTO
  });
}

export function addPhone(phone) {
  return function (dispatch) {
    dispatch({ type: types.ADD_PHONE.start });
    bounceApiClient
      .post('/api/users/me/phone', { phone })
      .then((res) => {
        dispatch({ type: types.ADD_PHONE.success, payload: res.data });
      })
      .catch((err) => {
        dispatch({
          type: types.ADD_PHONE.error,
          payload: resolveError(err)
        });
      });
  };
}

export const updatePassword = ({ oldPassword, newPassword }) =>
  createAxiosRequest({
    action: 'put',
    url: 'api/users/me/password',
    dispatchType: types.UPDATE_PASSWORD,
    params: { oldPassword, newPassword }
  });

export function resetPasswordRequest(email) {
  return function (dispatch) {
    dispatch({ type: types.RESET_PASSWORD_REQUEST.start });
    return bounceApiClient
      .post(`/auth/resetPasswordRequest`, { email })
      .then((res) => {
        dispatch({
          type: types.RESET_PASSWORD_REQUEST.success,
          payload: res.data
        });
        dispatch({ type: types.RESET_PASSWORD_REQUEST_FORM.success });
      })
      .catch((err) => {
        dispatch({
          type: types.RESET_PASSWORD_REQUEST.error,
          payload: resolveError(err)
        });
        dispatch({
          type: types.RESET_PASSWORD_REQUEST_FORM.success,
          payload: {
            FORM_ERROR: resolveFormError(err)
          }
        });

        throw resolveError(err).error;
      });
  };
}

export function resetPassword(token, password) {
  return function (dispatch) {
    dispatch({ type: types.RESET_PASSWORD.start });
    bounceApiClient
      .post(`/auth/resetPassword/${token}`, { password })
      .then((res) => {
        dispatch({
          type: types.RESET_PASSWORD.success,
          payload: res.data
        });
        dispatch({ type: types.RESET_PASSWORD_FORM.success });
      })
      .catch((err) => {
        dispatch({
          type: types.RESET_PASSWORD.error,
          payload: resolveError(err)
        });
        dispatch({
          type: types.RESET_PASSWORD_FORM.success,
          payload: {
            FORM_ERROR: resolveFormError(err)
          }
        });
      });
  };
}

export function verifyPhone(token) {
  return function (dispatch) {
    dispatch({ type: types.VERIFY_PHONE.start });
    bounceApiClient
      .post('/api/users/me/phone/verify', { token })
      .then((res) => {
        dispatch({
          type: types.VERIFY_PHONE.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.VERIFY_PHONE.error,
          payload: resolveError(err)
        });
      });
  };
}

export function resendPhoneVerification() {
  return function (dispatch) {
    dispatch({ type: types.RESEND_PHONE_VERIFICATION.start });
    bounceApiClient
      .post('/api/users/me/phone/resendVerification')
      .then((res) => {
        dispatch({
          type: types.RESEND_PHONE_VERIFICATION.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.RESEND_PHONE_VERIFICATION.error,
          payload: resolveError(err)
        });
      });
  };
}

/**
 * Delete the current user and their stripe customer + connect account
 * Connect balance must be 0
 * Show warning in Front-end before confirmation of action
 * @returns
 */
export function deleteMe() {
  return function (dispatch) {
    dispatch({ type: types.DELETE_ME.start });
    bounceApiClient
      .delete('/api/users/me')
      .then(() => {
        logout();
        dispatch({ type: types.DELETE_ME.success });
      })
      .catch((err) => {
        dispatch({
          type: types.DELETE_ME.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getUser(uid) {
  return function (dispatch) {
    dispatch({ type: types.GET_USER.start });
    bounceApiClient
      .get(`/api/users/${uid}`)
      .then((res) => {
        dispatch({ type: types.GET_USER.success, payload: res.data });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_USER.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getUserQuickView(uid) {
  return createAxiosRequest({
    action: 'get',
    url: `/api/users/${uid}/quickview`,
    dispatchType: types.GET_USER_QUICKVIEW,
    generatePayload: (data) => data
  });
}

export function getNewsFeed() {
  return function (dispatch) {
    bounceApiClient
      .get('/api/users/me/newsFeed')
      .then((res) => {
        dispatch({
          type: types.GET_NEWSFEED.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_NEWSFEED.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getOutgoingTransactions() {
  return function (dispatch) {
    bounceApiClient
      .get('/api/users/me/transactions/outgoing')
      .then((res) => {
        dispatch({
          type: types.GET_OUTGOING_TRANSACTIONS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_OUTGOING_TRANSACTIONS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getIncomingTransactions() {
  return function (dispatch) {
    bounceApiClient
      .get('/api/users/me/transactions/incoming')
      .then((res) => {
        dispatch({
          type: types.GET_INCOMING_TRANSACTIONS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_INCOMING_TRANSACTIONS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function getNotifications() {
  return function (dispatch) {
    bounceApiClient
      .get('/api/users/me/notifications')
      .then((res) => {
        dispatch({
          type: types.GET_NOTIFICATIONS.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_NOTIFICATIONS.error,
          payload: resolveError(err)
        });
      });
  };
}

export function verifyEmail(token) {
  return function (dispatch) {
    dispatch({ type: types.VERIFY_EMAIL.start });
    bounceApiClient
      .get(`/auth/verifyEmail/${token}?redirect=0`)
      .then((res) => {
        dispatch({
          type: types.VERIFY_EMAIL.success,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: types.VERIFY_EMAIL.error,
          payload: resolveError(err)
        });
      });
  };
}

export function resendVerificationEmail(email) {
  return function (dispatch) {
    dispatch({ type: types.RESEND_VERIFICATION_EMAIL.start });
    bounceApiClient
      .post('/auth/resendVerification', { email })
      .then(() => {
        dispatch({ type: types.RESEND_VERIFICATION_EMAIL.success });
      })
      .catch((err) => {
        dispatch({
          type: types.RESEND_VERIFICATION_EMAIL.error,
          payload: resolveError(err)
        });
      });
  };
}

export function login(data) {
  return {
    type: types.INITIAL_LOGIN.success,
    payload: data
  };
}

export function appleUpdateStatus() {
  return function (dispatch) {
    dispatch({ type: types.APPLE_LOADED.success });
  };
}

export function logout() {
  return async function (dispatch) {
    if ('Notification' in window && Notification?.permission === 'granted') {
      try {
        const { token } = await firebase.getToken();

        await unregisterForFCM(token);
      } catch (e) {
        // For showing error messages if needed
      }
    }

    removeLoginData({ forceRedirect: true });
    dispatch({ type: types.LOGOUT.success });
  };
}

export function joinGroupChat(eventId) {
  return function (dispatch) {
    dispatch({ type: types.JOIN_GROUP_CHAT.start });
    bounceApiClient
      .post(`/api/events/${eventId}/groupchat`, {
        device: 'browser'
      })
      .then((res) => {
        dispatch({
          type: types.JOIN_GROUP_CHAT.success,
          payload: { ...res.data, eventId }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.JOIN_GROUP_CHAT.error,
          payload: resolveError(err)
        });
      });
  };
}

export function fetchChatToken(eventId) {
  return function (dispatch) {
    dispatch({ type: types.FETCH_CHAT_TOKEN.start });
    bounceApiClient
      .post(`/api/events/${eventId}/groupchat/token`, {
        device: 'browser'
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_CHAT_TOKEN.success,
          payload: { ...res.data, eventId }
        });
      })
      .catch((err) => {
        dispatch({
          type: types.FETCH_CHAT_TOKEN.error,
          payload: resolveError(err)
        });
      });
  };
}

export const getEditSuggestions = () =>
  createAxiosRequest({
    action: 'get',
    url: '/api/users/me/editSuggestions',
    dispatchType: types.GET_EDIT_SUGGESTIONS
  });

export const getUserOrganizations = (userId) =>
  createAxiosRequest({
    action: 'get',
    url: `/api/groups/me`,
    dispatchType: types.GET_USER_ORGANIZATIONS,
    generatePayload: (data) => ({ data, userId })
  });

export const getUserFriends = (userId) =>
  createAxiosRequest({
    action: 'get',
    url: `/api/users/${userId || 'me'}/friends`,
    dispatchType: types.GET_USER_FRIENDS,
    generatePayload: (data) => ({ data, userId })
  });

export const getUserAttendedEvents = (userId) =>
  createAxiosRequest({
    action: 'get',
    url: `/api/users/${userId || 'me'}/attendedEvents`,
    dispatchType: types.GET_USER_ATTENDED_EVENTS,
    generatePayload: (data) => ({ data, userId })
  });

export const getUserHostedEvents = (userId) =>
  createAxiosRequest({
    action: 'get',
    url: `/api/users/${userId || 'me'}/hostedEvents`,
    dispatchType: types.GET_USER_HOSTED_EVENTS,
    generatePayload: (data) => ({ data, userId })
  });

export function updateProfile(user) {
  return function (dispatch) {
    dispatch({ type: types.UPDATE_PROFILE.start });
    bounceApiClient
      .put(`/api/users/me`, user)
      .then((res) => {
        dispatch({
          type: types.UPDATE_PROFILE.success,
          payload: res.data
        });
      })
      .catch((err) => {
        const { error } = resolveError(err);
        dispatch({
          type: types.UPDATE_PROFILE.error,
          payload: { error }
        });
      });
  };
}
