import React from 'react';
import Link from 'next/link';

import Button, {
  ButtonSize,
  ButtonType
} from 'legacy/common/components/button/Button';

import {
  AvatarGroup,
  AvatarGroupType,
  AvatarSizes,
  Avatar
} from 'components/Avatar';

import { requestJoinGroup } from '../hooks';
import { saveSearchItem } from '../service';

const ResultItemOrg = ({ result, saveHistory, onDelete, refetch }) => {
  const { mutate: requestJoinOrgCommand } = requestJoinGroup();

  const userMembershipStatus = result.rawData?.metadata.currentUser?.status;
  const canUserJoin =
    userMembershipStatus !== 'pending' &&
    userMembershipStatus !== 'rejected' &&
    userMembershipStatus !== 'approved';

  const onItemClick = () => {
    if (saveHistory) {
      saveSearchItem(result.rawData);
    }
  };

  const onJoinClick = () => {
    requestJoinOrgCommand(
      { groupId: result.id },
      { onSuccess: () => refetch() }
    );
  };

  const onDeleteClick = (event) => {
    event.stopPropagation();
    onDelete(result.id);
  };

  return (
    <div className="flex w-full gap-2 hover:bg-primary-200">
      <Link
        href={result.linkTo}
        key={result.id}
        onClick={onItemClick}
        className="flex gap-2 pt-3 pb-3 pl-1 pr-1 overflow-hidden w-full cursor-pointer"
      >
        <div className="bg-primary-200 bg-cover mx-auto w-[58px] h-[58px] align-center justify-center flex rounded-2xl border-white border-2 overflow-hidden shadow-md shadow-primary-200">
          <img src={result.image} alt={`${result.value}'s badge`} />
        </div>
        <div className="flex flex-col flex-1 justify-center items-start overflow-hidden">
          <div className="text-sm font-bold overflow-hidden whitespace-nowrap text-ellipsis w-full">
            {result.value}
          </div>
          <div className="text-sm font-normal text-neutral-500">
            {`${result.rawData.metadata.memberCount} ${
              result.rawData.metadata.memberCount > 1 ? 'members' : 'member'
            }`}
          </div>
          <AvatarGroup type={AvatarGroupType.Stack}>
            {result.rawData.metadata.memberPreview.map((member) => (
              <Avatar
                key={member.id}
                size={AvatarSizes.Xs}
                src={member.photoUrl}
              />
            ))}
          </AvatarGroup>
        </div>
      </Link>
      {(onDelete || canUserJoin) && (
        <div className="items-center flex pr-2">
          {onDelete ? (
            <img
              src="/img/icons/cross.svg"
              className="cursor-pointer"
              onClick={onDeleteClick}
            />
          ) : (
            <Button
              type={ButtonType.Inverted}
              size={ButtonSize.Small}
              onClick={onJoinClick}
              newRadius
              autoWidth
            >
              Join
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ResultItemOrg;
