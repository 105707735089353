import { ZIP_CODES_REGEX } from './constants';
import isEmailValid from 'validator/lib/isEmail';

import { isNumeric } from './util';

export const composeValidators =
  (...validators) =>
  (value, allValues) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues),
      undefined
    );

export const isRequired = (value) => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;

  if (!trimmedValue) {
    return 'Required';
  }

  return undefined;
};

export const isEmail = (value) =>
  isEmailValid(value) ? undefined : 'Invalid Email';

export const isPostalCode = (value) =>
  /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value)
    ? undefined
    : 'Invalid Postal Code';

export const isValidZip = (value, allValues) => {
  if (!ZIP_CODES_REGEX?.[allValues.country]) {
    return undefined;
  }

  return ZIP_CODES_REGEX[allValues.country].test(value)
    ? undefined
    : 'Invalid Zip/Postal Code';
};

export const minLength = (minLength) => (value) =>
  typeof value === 'string' && value.trim().length >= minLength
    ? undefined
    : `Value must be at least ${minLength} characters`;

export const maxLength = (maxLength) => (value) =>
  typeof value === 'string' && value.trim().length <= maxLength
    ? undefined
    : `Value can have at most ${maxLength} characters`;

export const minValue = (min, msg) => (value) =>
  isNumeric(value) && parseFloat(value) >= min
    ? undefined
    : msg || `Minimum value is ${min}`;

export const maxValue = (max, msg) => (value) =>
  isNumeric(value) && parseFloat(value) <= max
    ? undefined
    : msg || `Max value is ${max}`;

export const isBetween = (min, max, msg) => (value) => {
  const formattedMessage = msg || `Value must be between ${min} and ${max}.`;
  if (!isNumeric(value)) {
    return formattedMessage;
  }

  const parsedValue = parseInt(value, 10);
  return parsedValue >= min && parsedValue <= max
    ? undefined
    : formattedMessage;
};

export const isEqualTo = (other, message) => (value) =>
  value === other ? undefined : message;

export const isNotEqualTo = (val) => (value) =>
  val !== value ? undefined : 'Needs Modification';

export const isChanged = (keys, prevVal) => (value, allValues) => {
  for (const key of keys) {
    if (allValues[key] && prevVal[key] !== allValues[key]) {
      return undefined;
    }
  }

  return 'Needs Modification';
};
