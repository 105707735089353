import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './TransferConfirmation.module.scss';

import ModalBase from '../ModalBase/ModalBase';

class TransferConfirmation extends Component {
  render() {
    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        contentClassName="basic"
      >
        <div className={classnames(styles.TransferConfirmation, 'component')}>
          <img
            src="/img/illustrations/transfer.svg"
            alt="Transfer Completed Logo"
          />
          <h2 className="modalTitle">{this.props.title}</h2>
          <div>{this.props.message}</div>
          <div onClick={this.props.close} className="bn-btn primary">
            DONE
          </div>
        </div>
      </ModalBase>
    );
  }
}
export default TransferConfirmation;
