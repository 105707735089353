import classnames from 'classnames';

import { Impression } from 'legacy/events/types';

type EmojiRatingProps = {
  rating: Impression;
  onSelect: (rating: Impression) => void;
};

const EmojiRating: React.FC<EmojiRatingProps> = ({ rating, onSelect }) => (
  <div className="flex justify-around">
    {[Impression.BAD, Impression.OK, Impression.GOOD].map((smileyRating) => (
      <div
        data-testid={`smiley-${smileyRating}`}
        key={smileyRating}
        onClick={() => onSelect(smileyRating)}
        className={classnames(
          `hover:scale-125 transition-transform cursor-pointer ${
            rating === smileyRating ? '' : 'opacity-40'
          }`
        )}
      >
        <img
          className="w-14 h-14"
          src={`/img/events/ratings/${smileyRating}.svg`}
        />
      </div>
    ))}
  </div>
);

export default EmojiRating;
