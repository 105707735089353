import React from 'react';
import classnames from 'classnames';
import styles from './AddBank.module.scss';

import ModalBase from '../ModalBase/ModalBase';

function FindBankNumbers({ baseModalProps }) {
  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={classnames(styles.FindBankNumbers, 'component')}>
        <h2 className="modalTitle">On Direct Deposit Form</h2>
        <img
          src="/img/illustrations/direct_deposit.jpg"
          alt="Phone verification illustration"
        />
        <h2 className="modalTitle">On Void Cheque</h2>
        <img
          src="/img/illustrations/void_cheque.svg"
          alt="Phone verification illustration"
        />
      </div>
    </ModalBase>
  );
}

export default FindBankNumbers;
