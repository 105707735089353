import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import styles from './RequestMoney.module.scss';

import { requestMoney, types as p2pTypes } from 'legacy/actions/p2pActions';
import {
  hideModal,
  showModal,
  clearHistory
} from 'legacy/actions/modalActions';
import {
  SubmitButton,
  ValidatedInput,
  withForm,
  CheckboxField
} from '../../util/FormHelpers';
import {
  composeValidators,
  isRequired,
  maxLength,
  maxValue,
  minValue
} from '../../../util/validators';

import ModalBase from '../ModalBase/ModalBase';
import { MIN_P2P_AMOUNT, MAX_P2P_AMOUNT } from 'legacy/common/constants/p2p';

class RequestMoney extends Component {
  constructor(props) {
    super(props);
    const initialFormState = {
      amount: 0,
      reason: '',
      private: false
    };
    props.formProps.form.batch(() => {
      _.forEach(initialFormState, (val, key) => {
        props.formProps.form.change(key, val);
      });
    });
  }

  requestMoney = (formData) => {
    const info = {
      sender: this.props.sender._id,
      amount: Number(formData.amount),
      private: formData.private
    };
    if (formData.reason) {
      info.reason = formData.reason;
    }
    this.props.requestMoney(info);
  };

  handleSubmit = (e, formProps) => {
    e.preventDefault();
    formProps.handleSubmit();
    if (formProps.valid || formProps.dirtySinceLastSubmit) {
      this.requestMoney(formProps.values);
    }
  };

  showConfirmation = () => {
    this.props.showModal('TRANSFER_CONFIRMATION', {
      title: 'Request Has Been Sent!',
      message: `You have requested $${
        this.props.formProps.values.amount
      } from ${this.props.sender.firstName || this.props.sender.name}`
    });

    this.props.clearHistory();
  };

  componentDidUpdate(prevProps) {
    if (!this.props.loading && prevProps.loading) {
      if (_.isEmpty(this.props.errors.REQUEST_MONEY)) {
        this.showConfirmation();
      }
    }
  }

  componentDidMount = () => {
    this.props.formProps.form.change('amount', 5); // Default amount = 5
  };

  setAmount = (amount) => {
    this.props.formProps.form.change('amount', amount);
  };

  render() {
    const { sender, formProps, isOpen } = this.props;

    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        contentClassName="p2p"
      >
        <form
          className={classnames(styles.RequestMoney, 'component')}
          onSubmit={(e) => this.handleSubmit(e, formProps)}
        >
          <h2 className="modalTitle">
            {`Request Money from ${sender.firstName || sender.name}`}
          </h2>

          <div className="info">
            Transfers can be ${MIN_P2P_AMOUNT} - ${MAX_P2P_AMOUNT}.
          </div>

          <div className="bn-row money">
            <span>$</span>
            <div className="wrapper">
              <ValidatedInput
                errorVisible={isOpen}
                type="number"
                name="amount"
                validate={composeValidators(
                  isRequired,
                  minValue(
                    MIN_P2P_AMOUNT,
                    `Minimum value is $${MIN_P2P_AMOUNT}`
                  ),
                  maxValue(
                    MAX_P2P_AMOUNT,
                    `Maximum value is $${MAX_P2P_AMOUNT}`
                  )
                )}
                inputProps={{
                  step: '0.01'
                }}
              />
            </div>
          </div>

          <div className="quickSendWrapper">
            {[5, 10, 20].map((amount) => (
              <div
                key={amount}
                className="bn-btn quickSend"
                onClick={() => this.setAmount(amount)}
              >
                $<span>{amount}</span>
              </div>
            ))}
          </div>

          <ValidatedInput
            id="textArea"
            placeholder="Reason: (optional)"
            name="reason"
            type="text"
            component="textarea"
            validate={maxLength(200)}
            inputProps={{
              rows: 1,
              wrap: 'soft'
            }}
          />

          <div className="bn-row private">
            <CheckboxField name="private" form={formProps.form} />
            <p>PRIVATE (WILL NOT SHOW IN FEED)</p>
          </div>

          <SubmitButton
            errorOpen={
              // isOpen &&
              formProps.hasSubmitErrors &&
              !formProps.dirtySinceLastSubmit &&
              !formProps.submitting
            }
            buttonText="REQUEST"
            errorContent={
              formProps.hasSubmitErrors
                ? formProps.submitErrors.FORM_ERROR
                : null
            }
            errorActionText="Ok"
            loading={this.props.loading}
            // dont re-open request modal if error occurs
            onSuccess={this.props.hideModal}
          />
        </form>
      </ModalBase>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.p2p.errors,
    loading: state.p2p.loading.REQUEST_MONEY,
    cards: state.payment.cards
  };
}

const actions = {
  requestMoney,
  hideModal,
  showModal,
  clearHistory
};

export default connect(
  mapStateToProps,
  actions
)(withForm(p2pTypes.REQUEST_MONEY_FORM)(RequestMoney));
