import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';

import styles from './ConnectVerificationModal.module.scss';

import ModalBase from '../ModalBase/ModalBase';
import { updateAccountVerification } from 'legacy/actions/connectActions';
import { updateBusinessVerification } from 'legacy/actions/orgActions';
import { usePrevious } from '../../../util/hooks';
import { getMyProfile } from 'legacy/actions/userActions';
import { validateSIN, validateSSN } from 'legacy/common/utils/identification';

function ConnectVerificationModal({
  baseModalProps,
  stripeHasLoaded,
  updateAccountVerification,
  actionError,
  loading,
  onSuccess,
  orgId,
  updateBusinessVerification,
  // only passed if opening in a react native webview
  onBack,
  getMyProfile,
  close,
  user
}) {
  const [error, setError] = useState();
  const backendErrorMessage = actionError.message;
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (backendErrorMessage) {
      setError(backendErrorMessage);
    }
  }, [backendErrorMessage]);

  useEffect(() => {
    if (prevLoading && !loading && !backendErrorMessage) {
      onSuccess();
      close();
    }
  }, [backendErrorMessage, loading, prevLoading]);

  // Unset any errors on update.
  const handleChange = () => setError('');

  // Validate SIN and call updateConnect if it is potentially valid.
  const handleComplete = (val) => {
    if (orgId) {
      updateBusinessVerification(orgId, { taxId: val });
    } else {
      const isValidSin =
        user.country === 'CA' ? validateSIN(val) : validateSSN(val);

      if (isValidSin) {
        updateAccountVerification({
          sin: val,
          onSuccess: () => {
            getMyProfile();
            setTimeout(() => {
              getMyProfile();
            }, 3000);
          }
        });
      } else {
        setError(`Invalid ${user.country === 'CA' ? 'SIN' : 'SSN'}`);
      }
    }
  };

  let message = 'Verify SSN';

  if (user.country === 'CA') {
    message = 'Verify SIN';
  }

  if (orgId) {
    message = 'Verify Tax ID';
  }

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      onBack={onBack}
      back_img="Back"
      contentClassName="autoHeight"
    >
      <h2 className="modalTitle">{message}</h2>
      <div className={styles.wrapper}>
        <ReactCodeInput
          fields={9}
          onComplete={handleComplete}
          onChange={handleChange}
          fieldWidth={25}
          fieldHeight={45}
          loading={!stripeHasLoaded || loading}
        />

        <div
          className={classnames(styles.error, {
            [styles.visible]: error
          })}
        >
          {error || 'fillerText'}
        </div>
      </div>
    </ModalBase>
  );
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user.user,
  payment: state.payment,
  loading: ownProps.orgId
    ? state.orgs.loading.UPDATE_BUSINESS_VERIFICATION
    : state.connect.loading.UPDATE_ACCOUNT_VERIFICATION,
  actionError: ownProps.orgId
    ? state.orgs.errors.UPDATE_BUSINESS_VERIFICATION
    : state.connect.errors.UPDATE_ACCOUNT_VERIFICATION,
  stripeHasLoaded: state.app.stripeHasLoaded
});

const actions = {
  updateAccountVerification,
  updateBusinessVerification,
  getMyProfile
};

export default connect(mapStateToProps, actions)(ConnectVerificationModal);
