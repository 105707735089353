import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { handleRedirect } from '../helpers';

import storage from 'legacy/common/utils/storage';
import { STORAGE_KEYS } from 'legacy/common/constants/storage';

import Wrapper from 'legacy/common/components/layout/BackgroundWrapper';
import Spinner, { SpinnerType } from 'legacy/common/components/spinner/Spinner';
import { getMe } from 'legacy/user/service';
import { isAuthRoute, isPrivateAuthRoute } from 'legacy/common/utils/routes';

/**
 * This HOC handles redirects in the auth routes only in the *first mount*.
 * Specifically, if a user is already logged in and goes over to these routes,
 * they will be redirected appropriately on the basis of their signUpRequirements.
 */
const withAuthRedirect = (WrappedComponent: React.FC) => (props: any) => {
  const router = useRouter();
  const { pathname } = router;
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserAndRedirect = async () => {
    setIsLoading(true);
    const user = await getMe();

    handleRedirect(user);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isAuthRoute(pathname) && storage.getItem(STORAGE_KEYS.AUTH_TOKEN)) {
      fetchUserAndRedirect();
      return;
    }

    // If the route is private auth route, and the user is not logged in, redirect to login.
    if (
      isPrivateAuthRoute(pathname) &&
      !storage.getItem(STORAGE_KEYS.AUTH_TOKEN)
    ) {
      router.push('/login');
    }
  }, []);

  if (isLoading && isAuthRoute) {
    return (
      <Wrapper>
        <Spinner type={SpinnerType.PRIMARY} />
      </Wrapper>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...props} />;
};

export default withAuthRedirect;
