/**
 * A collection of utility functions to import anywhere they are needed
 */

/**
 * Format a string to produce a possessive
 *
 * Eg: Austin -> Austin's, Ross -> Ross'
 * @export
 * @param {String} s string to format
 * @returns Possessive version of the string
 */
export function formatPossessive(s) {
  // Don't format empty string
  if (!s) {
    return s;
  }
  if (s.toLowerCase().endsWith('s')) {
    return `${s}'`;
  }
  return `${s}'s`;
}

//
/**
 * Turn list of action IDs into object with a key for each element that maps
 * to an object with id, start, error, and success properties
 *
 * @export
 * @param {Array<string>} actionIDs
 * @returns {Object}
 * @example
 ```
toActionObject(['CREATE_THING", "DO_THING"]):
//returns
{
    CREATE_THING: {
        id: 'CREATE_THING',
        start: 'CREATE_THING_START',
        success: 'CREATE_THING_SUCCESS',
        error: 'CREATE_THING_ERROR'
    },
    DO_THING: {
        id: 'DO_THING',
        start: 'DO_THING_START',
        success: 'DO_THING_SUCCESS',
        error: 'DO_THING_ERROR'
    }
}
  ```
 */
export function toActionObject(actionIDs) {
  const obj = {};
  for (const t of actionIDs) {
    obj[t] = {
      id: t,
      start: `${t}_START`,
      success: `${t}_SUCCESS`,
      error: `${t}_ERROR`
    };
  }
  return obj;
}

/**
 * Map full action type to its root (eg CREATE_THING_SUCCESS => CREATE_THING)
 *
 * @export
 * @param {string} actionType
 * @returns {string} ActionID
 * @example
 * //returns 'DO_SOMETHING'
 * toActionID('DO_SOMETHING_ERROR')
 */
export function toActionID(actionType) {
  const tokens = actionType.split('_');
  const lastToken = tokens[tokens.length - 1];
  if (['SUCCESS', 'START', 'ERROR'].includes(lastToken)) {
    return tokens.slice(0, -1).join('_');
  }
  return actionType;
}

/**
 * Check if a value is numeric.
 *
 * @export
 * @param {any} s value to check
 * @returns Boolean: whether or not value is numeric
 */
export function isNumeric(s) {
  return !isNaN(parseFloat(s)) && isFinite(s);
}

/**
 * Update an element of an array, if present (based on predicate function)
 *
 * @export
 * @param {Array<Object>} arr original array
 * @param {function(Object, Number, Array<Object>): Boolean} predicate
 * @param {Object} updateWith
 * @returns
 */
export function updateIfPresent(arr, predicate, updateWith) {
  const index = arr.findIndex(predicate);
  if (index !== -1) {
    return [
      ...arr.slice(0, index),
      {
        ...arr[index],
        ...updateWith
      },
      ...arr.slice(index + 1)
    ];
  }
  return arr;
}

/**
 * Add ... to end of string if it exceeds a certain length.
 * @param {String} s string to potentially modify
 * @param {Number} length maximum length
 *
 * @returns {String} modified string
 */
export function addElipsesAfterLength(s, length) {
  if (s.length > length) {
    return `${s.slice(0, length)}...`;
  }
  return s;
}
