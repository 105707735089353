import bounceApiClient from 'legacy/common/utils/bounceApiClient';

import storage from 'legacy/common/utils/storage';
import { STORAGE_KEYS } from 'legacy/common/constants/storage';

import { UserLean, EventInviteListItem } from 'legacy/user/types';
import {
  CombinedSearchResult,
  EventBasic,
  EventGuestBreakdown,
  EventGuestsCategories,
  EventGuestsSearchResults,
  Group
} from './types';

export function getRecentSearches(): CombinedSearchResult[] {
  return storage.getItem(STORAGE_KEYS.RECENT_SEARCHES) || [];
}

export function saveSearchItem(search: CombinedSearchResult) {
  const id =
    (search as EventBasic)._id ||
    (search as UserLean)._id ||
    (search as Group).id;
  const recentSearches = getRecentSearches().filter(
    (item) =>
      (item as EventBasic)._id !== id &&
      (item as UserLean)._id !== id &&
      (item as Group).id !== id
  );

  recentSearches.unshift(search);

  storage.setItem(STORAGE_KEYS.RECENT_SEARCHES, recentSearches);
}

export function clearRecentSearches() {
  storage.removeItem(STORAGE_KEYS.RECENT_SEARCHES);
}

export function removeRecentSearchItem(id: string) {
  const items = getRecentSearches();

  storage.setItem(
    STORAGE_KEYS.RECENT_SEARCHES,
    items.filter(
      (item) =>
        (item as EventBasic)._id !== id &&
        (item as UserLean)._id !== id &&
        (item as Group).id !== id
    )
  );
}

export async function searchCombined(
  name: string
): Promise<{ results: CombinedSearchResult[] }> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/search/combined',
    params: {
      name
    }
  });

  return data || [];
}

export async function searchGroups(name: string): Promise<Group[]> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/search/groups/',
    params: {
      name
    }
  });

  return data || [];
}

export async function searchEvents(name: string): Promise<EventBasic[]> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/search/events',
    params: {
      name
    }
  });

  return data || [];
}

export async function searchUsers(name: string): Promise<UserLean[]> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/search/users',
    params: {
      name
    }
  });

  return data || [];
}

export async function searchEventInviteList(
  eventId: string,
  name: string,
  pageNumber: number
): Promise<EventInviteListItem[]> {
  const params = {
    name,
    pageNumber
  };
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/inviteList`,
    {
      params
    }
  );

  return data || [];
}

export const DEFAULT_PAGE_LIMIT = 20;
export async function searchEventGuestListBreakdown(
  eventId: string,
  name: string,
  pageNumber: number
): Promise<EventGuestBreakdown[]> {
  const params = {
    name,
    pageNumber,
    limit: DEFAULT_PAGE_LIMIT
  };
  const { data } = await bounceApiClient.get(
    `/api/hosts/events/${eventId}/guests/search`,
    {
      params
    }
  );

  return data || [];
}

export async function searchEventGuestList(
  eventId: string,
  name: string,
  pageNumber: number
): Promise<UserLean[]> {
  const params = {
    name,
    pageNumber
  };
  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/guests/search`,
    {
      params
    }
  );

  return data || [];
}

export async function searchMultiTierGuestList(
  eventId: string,
  name: string,
  pageNumber: number,
  category: EventGuestsCategories
): Promise<EventGuestsSearchResults> {
  const params = {
    name,
    pageNumber,
    category
  };

  const { data } = await bounceApiClient.get(
    `/api/events/${eventId}/users/search`,
    {
      params
    }
  );

  return data || [];
}
