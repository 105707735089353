import React from 'react';
import ReduxPromiseListener from 'react-redux-promise-listener';
import { Form } from 'react-final-form';
import { promiseListener } from '../../../store';

const withForm =
  (actionType, listenerProps, formProps) => (Component) => (props) =>
    (
      <ReduxPromiseListener
        listener={promiseListener}
        start={actionType.start}
        resolve={actionType.success}
        {...listenerProps}
      >
        {(onSubmit) => (
          <Form
            onSubmit={onSubmit}
            render={(form) => <Component formProps={form} {...props} />}
            {...formProps}
          />
        )}
      </ReduxPromiseListener>
    );

export default withForm;
