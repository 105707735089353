import React from 'react';
import { connect } from 'react-redux';

import {
  verifyPhone,
  resendPhoneVerification
} from 'legacy/actions/userActions';
import {
  verifyOrgPhone,
  resendOrgPhoneVerification
} from 'legacy/actions/orgActions';

import ModalBase from '../ModalBase/ModalBase';
import VerifyPhoneForm from './components/VerifyPhoneForm';

function VerifyPhone({
  loading,
  error,
  user,
  verifyPhone,
  baseModalProps,
  onSuccess,
  resendPhoneVerification,
  verifyOrgPhone,
  resendOrgPhoneVerification,
  orgId,
  overrideBaseModalProps,
  verifyActionType,
  resendActionType
}) {
  const handleSubmit = (code) => {
    if (orgId) {
      verifyOrgPhone(orgId, code);
    } else {
      verifyPhone(code);
    }
  };

  const handleResend = () => {
    if (orgId) {
      resendOrgPhoneVerification(orgId);
    } else {
      resendPhoneVerification();
    }
  };

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      contentClassName="basic"
      hideBackButton
      {...overrideBaseModalProps}
    >
      <VerifyPhoneForm
        user={user}
        loading={loading}
        error={error}
        verifyActionType={verifyActionType}
        onVerifyPhone={handleSubmit}
        resendActionType={resendActionType}
        onResend={handleResend}
        onSuccess={onSuccess}
      />
    </ModalBase>
  );
}

const actions = {
  verifyPhone,
  resendPhoneVerification,
  verifyOrgPhone,
  resendOrgPhoneVerification
};

const mapStateToProps = (state, { orgId }) => {
  const selector = orgId ? 'orgs' : 'user';
  const verifyActionType = orgId ? 'VERIFY_ORG_PHONE' : 'VERIFY_PHONE';
  const resendActionType = orgId ? 'VERIFY_ORG_PHONE' : 'VERIFY_PHONE';
  return {
    user: orgId ? state.orgs.orgs[orgId] : state.user.user,
    error: state[selector].errors[verifyActionType].message,
    loading: state[selector].loading,
    verifyActionType,
    resendActionType
  };
};

export default connect(mapStateToProps, actions)(VerifyPhone);
