import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  appleUpdateStatus,
  getNewsFeed,
  getMyProfile,
  getUserOrganizations,
  getUserFriends
} from './actions/userActions';
import {
  getFriends,
  getReceivedFriendRequests,
  getSentFriendRequests
} from './actions/friendActions';
import { getUserLocation, getActiveTickets } from './actions/eventActions';
import { getNotifications } from './actions/notificationActions';
import { reportDevice, stripeHasLoaded } from './actions/appActions';

import { useWindowSize, usePrevious } from './util/hooks';
import { clearModal } from './actions/modalActions';

import ModalRoot from './components/modals/ModalRoot/ModalRoot';
import Header from './components/pageLayout/Header/Header';
import PrivateHeader from './components/pageLayout/PrivateHeader/PrivateHeader';
import PublicEventHeader from './components/pageLayout/PrivateHeader/PublicEventHeader';
import ScrollToTop from './ScrollToTop';
import { useRouter } from 'next/router';
import useStripe from './common/hooks/useStripe';
import UseAppBanner from './common/components/banners/UseAppBanner';
import useIdentifyLaunchDarklyUser from './common/hooks/useIdentifyLaunchDarklyUser';
import { isBounceWebview } from './common/utils/webview';
import useHeap from './common/hooks/useHeap';
import useFullStory from './common/hooks/useFullStory';

function App({
  stripeHasLoaded,
  user,
  events,
  getUserLocation,
  device,
  reportDevice,
  getActiveTickets,
  getNewsFeed,
  getFriends,
  getNotifications,
  getUserOrganizations,
  getUserFriends,
  clearModal,
  isModalOpen,
  getMyProfile,
  children,
  isPublic,
  isEventPage,
  showMobileAppPrompt,
  userLoading,
  showLandingHeader
}) {
  const checkDevice = useCallback((width) => {
    if (width < 768) {
      return 'mobile';
    }
    if (width < 1024) {
      return 'tablet';
    }
    return 'desktop';
  }, []);
  const [width] = useWindowSize();
  useEffect(() => {
    const currentDevice = checkDevice(width);
    if (currentDevice !== device) {
      reportDevice(currentDevice);
    }
  }, [device, width]);

  const router = useRouter();
  const getAllData = useCallback(() => {
    getActiveTickets();
    getNewsFeed();
    getFriends();
    getUserOrganizations();
    getUserFriends();

    getNotifications();
    getReceivedFriendRequests();
    getSentFriendRequests();

    if (!isBounceWebview(router.query)) {
      getUserLocation();
    }
  }, [user]);

  useStripe(user.platformCountry || user.country, {
    onLoad: stripeHasLoaded
  });

  useIdentifyLaunchDarklyUser({
    id: user?._id,
    email: user?.email,
    name: user?.name,
    country: user?.country
  });

  useHeap({
    id: user?._id,
    email: user?.email,
    name: user?.name,
    country: user?.country
  });

  useFullStory({
    id: user?._id,
    email: user?.email,
    name: user?.name,
    country: user?.country
  });

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      // Ensures that any open modals are always cleared from the state when navigating away from a page, e.g. hitting the browser back button
      if (isModalOpen) {
        clearModal();
      }
    });
  }, [isModalOpen]);

  const isVerifiedUser = user && user.signupRequirements?.length === 0;

  useEffect(() => {
    if (isVerifiedUser) {
      getAllData();
    }
  }, [isVerifiedUser]);

  const prevEvents = usePrevious(events);
  useEffect(() => {
    if (isEmpty(events) !== isEmpty(prevEvents)) {
      setTimeout(() => {
        getMyProfile();
      }, 3000);
    }
  }, [events]);

  return (
    <div id="app" className={device}>
      <>
        <ScrollToTop />
        <ModalRoot />
        {/* show prompt to use our app on mobile */}
        {showMobileAppPrompt && <UseAppBanner />}

        {/* Menu shown on landing pages (features, pricing, about, login/dashboard pill) */}
        {showLandingHeader && <Header />}

        {/* Shows private header when user is logged in, else shows public header for event page. Dont show anything for landing pages */}
        {(!isPublic || isEventPage) &&
          !userLoading.INITIAL_LOGIN &&
          (user ? <PrivateHeader /> : <PublicEventHeader />)}
        {children}
      </>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userLoading: state.user.loading,
  user: state.user.user,
  device: state.app.device,
  events: state.events.events,
  isModalOpen: state.modal.isOpen
});

const actions = {
  // App
  reportDevice,
  stripeHasLoaded,
  // User
  getMyProfile,
  getUserOrganizations,
  getUserFriends,
  // friends
  getFriends,
  getReceivedFriendRequests,
  getSentFriendRequests,

  appleUpdateStatus,
  getNotifications,
  getNewsFeed,
  // Events
  getUserLocation,
  getActiveTickets,
  clearModal
};

export default connect(mapStateToProps, actions)(App);
