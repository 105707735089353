import { useEffect } from 'react';
import { useRouter } from 'next/router';

import * as ga from 'legacy/common/utils/ga';
import { logEvent } from 'legacy/common/utils/amplitude';

import { EVENT_OPEN_PAGE } from 'legacy/common/constants/events';

const handleRouteChange = (url: string) => {
  ga.pageview(url);
  logEvent(EVENT_OPEN_PAGE, { url });
};

const usePageTracking = () => {
  const router = useRouter();

  // Initial page mount
  useEffect(() => {
    logEvent(EVENT_OPEN_PAGE, {
      url: window?.location?.pathname
    });
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};

export default usePageTracking;
