import * as Sentry from '@sentry/nextjs';
import { UserMe } from 'legacy/user/types';

export const setSentryUser = (user: UserMe) => {
  Sentry.configureScope((scope) => {
    scope.setUser({
      email: user.email,
      id: user._id
    });
  });
};

export const clearSentryUser = () => {
  Sentry.configureScope((scope) => {
    scope.setUser({});
  });
};
