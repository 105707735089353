import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './TicketValidation.module.scss';

import { scanTicket } from 'legacy/actions/hostActions';
import Spinner from '../../util/Spinner/Spinner';

import ModalBase from '../ModalBase/ModalBase';
import BounceButton from '../../util/BounceButton/BounceButton';

class TicketValidation extends Component {
  componentDidMount() {
    if (this.props.qrData) {
      this.props.scanTicket(this.props.eventId, this.props.qrData);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.qrData !== this.props.qrData) {
      this.props.scanTicket(this.props.eventId, this.props.qrData);
    }
  }

  scanAgain = () => {
    this.props.close();
    this.props.scanCallback();
  };

  render() {
    const eventTitle = this.props.events[this.props.eventId]
      ? this.props.events[this.props.eventId].title
      : 'the event';
    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        contentClassName="basic"
      >
        <div className={styles.TicketValidation}>
          {!this.props.loading.SCAN_TICKET ? (
            <>
              <div>
                {this.props.validated
                  ? `Guest has paid for ${eventTitle}`
                  : this.props.error
                  ? this.props.error.message
                  : 'Could not validate ticket'}
              </div>
              <BounceButton className="scan" primary onClick={this.scanAgain}>
                SCAN ANOTHER
              </BounceButton>
            </>
          ) : (
            <Spinner height={60} />
          )}
        </div>
      </ModalBase>
    );
  }
}

function mapStateToProps(state) {
  return {
    validated: state.host.validated,
    events: state.events.events,
    loading: state.host.loading,
    error: state.host.errors.SCAN_TICKET
  };
}

const actions = { scanTicket };

export default connect(mapStateToProps, actions)(TicketValidation);
