import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/p2pActions';
import { types as userTypes } from 'legacy/actions/userActions';
import { toActionID } from '../util/util';

export const initialState = {
  sentRequests: [],
  receivedRequests: [],
  errors: _.mapValues(types, () => ({})),
  loading: _.mapValues(types, () => false)
};

// Allow specific sister types from other actions
const SISTER_TYPES = [userTypes.LOGOUT.success];

export function p2pReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
  }

  switch (action.type) {
    case userTypes.LOGOUT.success:
      return initialState;

    case types.START_P2P_CHECKOUT_SESSION.success:
    case types.UPDATE_P2P_CHECKOUT_SESSION.success:
      newState.checkoutSession = action.payload;
      return newState;
    case types.CONFIRM_P2P_CHECKOUT_SESSION.success:
      newState.checkoutSession = undefined;
      return newState;

    case types.START_ACCEPT_REQUEST_CHECKOUT_SESSION.success:
    case types.UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION.success:
      newState.acceptRequestCheckoutSession = action.payload;
      return newState;
    case types.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION.success:
      newState.acceptRequestCheckoutSession = undefined;
      return newState;

    case types.REQUEST_MONEY.success:
      newState.sentRequests = [...newState.sentRequests, action.payload];
      return newState;
    case types.GET_SENT_REQUESTS.success:
      newState.sentRequests = action.payload;
      return newState;
    case types.GET_RECEIVED_REQUESTS.success:
      newState.receivedRequests = action.payload;
      return newState;
    case types.DECLINE_TRANSFER_REQUEST.success:
      newState.receivedRequests = newState.receivedRequests.map((req) =>
        req._id === action.payload._id
          ? { ...req, status: action.payload.status }
          : req
      );

      return newState;
    case types.CANCEL_TRANSFER_REQUEST.success:
      newState.sentRequests = newState.sentRequests.map((req) =>
        req._id === action.payload._id
          ? { ...req, status: action.payload.status }
          : req
      );

      return newState;
    case types.CLEAR_P2P_CHECKOUT_ERRORS.success:
      newState.errors[toActionID(types.START_P2P_CHECKOUT_SESSION.error)] = {};
      newState.errors[toActionID(types.UPDATE_P2P_CHECKOUT_SESSION.error)] = {};
      newState.errors[
        toActionID(types.START_ACCEPT_REQUEST_CHECKOUT_SESSION.error)
      ] = {};
      newState.errors[
        toActionID(types.UPDATE_ACCEPT_REQUEST_CHECKOUT_SESSION.error)
      ] = {};
      newState.errors[
        toActionID(types.CONFIRM_ACCEPT_REQUEST_CHECKOUT_SESSION.error)
      ] = {};
      newState.errors[toActionID(types.CONFIRM_P2P_CHECKOUT_SESSION.error)] =
        {};
      return newState;

    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: p2pReducer };
