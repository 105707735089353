// Todo: Find a solution to properly import this or an alternative to amplitude-js

import { AnalyticsEvent } from '../constants/events';

const amplitude =
  // eslint-disable-next-line
  typeof window !== 'undefined' ? require('amplitude-js').default : null;

function shouldTrack() {
  return amplitude && process.env.NEXT_PUBLIC_BUILD_ENV !== 'test';
}

export function trackUserSession(userId: string) {
  if (!shouldTrack()) {
    return;
  }

  amplitude.getInstance().setUserId(userId);

  const identify = new amplitude.Identify().set('user_id', userId);
  amplitude.getInstance().identify(identify);
}

export function stopUserSession() {
  if (!shouldTrack()) {
    return;
  }

  amplitude.getInstance().setUserId(null);

  const identify = new amplitude.Identify().set('user_id', null);
  amplitude.getInstance().identify(identify);
}

export function logEvent(
  eventName: string,
  properties: Record<string | AnalyticsEvent, any> = {}
) {
  if (!shouldTrack()) {
    return;
  }

  amplitude.getInstance().logEvent(eventName, properties);
}

export function initializeAmplitude(key: string, { appVersion, userId }) {
  if (!shouldTrack()) {
    return;
  }
  amplitude.getInstance().init(key);

  const identify = new amplitude.Identify().set('app_version', appVersion);
  amplitude.getInstance().identify(identify);

  if (userId) {
    trackUserSession(userId);
  }
}
