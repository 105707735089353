import React, { Component } from 'react';
import styles from './PublicPage.module.scss';
import FooterV2 from 'legacy/common/components/footer-v2/FooterV2';

export default class PublicPage extends Component {
  render() {
    const { showFooter } = this.props;

    return (
      <div className={styles.PublicPage}>
        {this.props.children}
        {showFooter && <FooterV2 />}
      </div>
    );
  }
}
