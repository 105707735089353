import styles from './UseAppBanner.module.scss';

import { useEffect, useState } from 'react';

import { STORAGE_KEYS } from 'legacy/common/constants/storage';

import { sessionStorage } from 'legacy/common/utils/storage';
import {
  getMobilePlatform,
  PLATFORM_ANDROID,
  PLATFORM_IOS
} from 'legacy/common/utils/platform';

import useParams from 'legacy/common/hooks/useParams';
import Button, { ButtonType } from 'legacy/common/components/button/Button';
import { logEvent } from 'legacy/common/utils/amplitude';
import { isBounceWebview } from 'legacy/common/utils/webview';
import {
  EVENT_APP_BANNER_SHOWN,
  EVENT_APP_BANNER_CLOSED,
  EVENT_APP_BANNER_SWITCHED_TO_APP
} from 'legacy/common/constants/events';
import { useRouter } from 'next/router';

const appUrl = {
  [PLATFORM_IOS]: 'https://bouncelife.com/ios',
  [PLATFORM_ANDROID]: 'https://bouncelife.com/android'
};

const UseAppBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const params = useParams<{ webview: string }>();
  const router = useRouter();

  useEffect(() => {
    if (sessionStorage.getItem(STORAGE_KEYS.HIDE_APP_BANNER)) {
      return;
    }

    const platform = getMobilePlatform();
    if (platform === PLATFORM_ANDROID || platform === PLATFORM_IOS) {
      setIsVisible(true);
      logEvent(EVENT_APP_BANNER_SHOWN);
      return;
    }

    setIsVisible(false);
  }, []);

  const switchToApp = () => {
    logEvent(EVENT_APP_BANNER_SWITCHED_TO_APP);
    window.location.href = `${
      appUrl[getMobilePlatform()] || appUrl[PLATFORM_IOS]
    }?redirectTo=${router.asPath}`;
  };

  const hide = () => {
    logEvent(EVENT_APP_BANNER_CLOSED);
    setIsVisible(false);
    sessionStorage.setItem(STORAGE_KEYS.HIDE_APP_BANNER, true);
  };

  if (!isVisible || isBounceWebview(params)) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <h1 className={styles.title}>Bounce is better on the app!</h1>
      <p className={styles.message}>
        Open in the app to get the full experience and never miss out on an
        event.
      </p>
      <Button onClick={switchToApp}>Switch to the app</Button>

      <div className={styles.dismiss}>
        <Button onClick={hide} type={ButtonType.Clear}>
          Not now
        </Button>
      </div>
    </div>
  );
};

export default UseAppBanner;
