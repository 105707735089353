import React from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';
import styles from './ModalBase.module.scss';

import BackButtonSimple from '../../util/BackButton/BackButtonSimple';

const ModalBase = ({
  className,
  overlayClassName,
  contentClassName,
  baseModalProps,
  hideBackButton,
  large,
  tall,
  onRequestClose,
  overlayStyle,
  contentStyle,
  back_img: backImg,
  onBack,
  children
}) => {
  const { modalBack, device, isOpen, showTransition } = baseModalProps;

  let modalOverlayClassName =
    styles[`${device}-${overlayClassName}`] ||
    styles[overlayClassName] ||
    styles.defaultOverlay;
  let modalClassName =
    styles[`${device}-${contentClassName}`] ||
    styles[contentClassName] ||
    styles.defaultContent;

  modalClassName = classnames(
    styles.wrapper,
    className,
    modalClassName,
    large && device !== 'mobile' && styles['large-modal'],
    tall && device !== 'mobile' && styles['tall-modal'],
    hideBackButton && styles['hide-backbtn'],
    `transition-${showTransition.toLowerCase()}`
  );
  modalOverlayClassName = classnames(
    modalOverlayClassName,
    `transition-${showTransition.toLowerCase()}`
  );

  window.addEventListener('popstate', onRequestClose);

  return (
    <Modal
      {...baseModalProps}
      onRequestClose={onRequestClose || baseModalProps.onRequestClose}
      className={modalClassName}
      overlayClassName={modalOverlayClassName}
      appElement={document.getElementById('app-content')}
      closeTimeoutMS={350}
      isOpen={isOpen}
      style={{
        overlay: {
          ...overlayStyle
        },
        content: {
          ...contentStyle
        }
      }}
    >
      {hideBackButton ? (
        <div />
      ) : (
        <div className={styles.back}>
          <BackButtonSimple goBack={onBack || modalBack} img={backImg} />
        </div>
      )}
      {children}
    </Modal>
  );
};

export default ModalBase;
