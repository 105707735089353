import React from 'react';
import Spinner, { SpinnerType } from 'legacy/common/components/spinner/Spinner';
import styles from './ResultList.module.scss';
import { ResultItem } from './ResultItem';

type ResultsProps = {
  id?: string;
  isLoading?: boolean;
  onDelete?: (id: string) => void;
  saveHistory?: boolean;
  refetch?: () => void;
  results: {
    id?: string;
    image?: string;
    value?: string;
    linkTo?: string;
    subtitle?: string;
    rawData?: any;
    onClick?: any;
  }[];
};

const ResultList: React.FC<ResultsProps> = ({
  id,
  results,
  onDelete,
  isLoading,
  saveHistory = true,
  refetch
}) => (
  <>
    <div className={styles.container} data-testid={id}>
      <div className={styles.list}>
        {!!results?.length &&
          results?.map((result) => (
            <ResultItem
              refetch={refetch}
              result={result}
              onDelete={onDelete}
              saveHistory={saveHistory}
              key={result.id}
            />
          ))}
      </div>

      {isLoading && (
        <div className={styles.loader}>
          <Spinner type={SpinnerType.PRIMARY} />
        </div>
      )}
    </div>
  </>
);

export default ResultList;
