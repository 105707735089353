import Select from 'react-select';
import React, { useRef } from 'react';
import classNames from 'classnames';

import { COUNTRIES, DEFAULT_COUNTRY_CODE } from 'legacy/common/utils/country';

const options = Object.keys(COUNTRIES).map((countryCode) => {
  const country = COUNTRIES[countryCode];

  return {
    value: country.callingCode,
    label: `${country.flag} ${country.name} (+${country.callingCode})`,
    flag: country.flag,
    countryCode
  };
});

type PhoneInputProps = {
  hasError: boolean;
  testId?: string;
  countryCode: string;
  onCountryChange: (selectedCountry: {
    countryCode: string;
    value: string;
  }) => void;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  value: string;
  autoFocus?: boolean;
};

const PhoneInput: React.FC<PhoneInputProps> = ({
  hasError,
  testId,
  countryCode,
  onCountryChange,
  onChange,
  onBlur,
  value,
  autoFocus
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const selectedValue = options.find(
    (option) => option.countryCode === (countryCode || DEFAULT_COUNTRY_CODE)
  );

  return (
    <div className="flex w-full items-center">
      <Select
        value={selectedValue}
        onChange={(selected) => {
          onCountryChange(selected);
          inputRef.current.focus();
        }}
        options={options}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '62px',
            flexShrink: 0,
            borderRadius: '16px 0 0 16px'
          }),
          control: (provided) => ({
            ...provided,
            height: '40px',
            borderRadius: '16px 0 0 16px'
          }),
          singleValue: (provided) => ({
            ...provided,
            fontSize: '16px'
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingRight: 0
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
          }),

          dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
              width: '14px',
              height: '14px'
            }
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999999999,
            width: '250px',
            textAlign: 'left'
          })
        }}
        menuPortalTarget={document.body}
        formatOptionLabel={(option, { context }) =>
          context === 'menu' ? option.label : option.flag
        }
      />
      <div
        className={classNames(
          'flex w-full first-line:items-center border-1 p-2 text-base rounded-r-2xl h-[40px] items-center',
          {
            'border-red-700': hasError
          }
        )}
      >
        <div className="mr-2 rounded-r-2xl font-semibold">
          +{selectedValue.value}
        </div>
        <input
          ref={inputRef}
          value={value}
          data-testid={testId}
          placeholder="Enter your number"
          className="w-full placeholder:italic placeholder:font-light focus:outline-none"
          onChange={onChange}
          onBlur={onBlur}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
