import { v4 as uuid } from 'uuid';

type StarRatingProps = {
  rating: number;
  onSelect: (rating: number) => void;
};

const StarRating: React.FC<StarRatingProps> = ({ rating, onSelect }) => (
  <div className="flex justify-between">
    {[...Array(5)].map((_, index) => {
      if (index <= rating - 1) {
        return (
          <div
            key={uuid()}
            className="transition-transform"
            onClick={() => onSelect(index + 1)}
          >
            <img className="h-12" src="/img/events/ratings/star-active.svg" />
          </div>
        );
      }

      return (
        <div
          key={uuid()}
          data-testid={`star-${index}`}
          className="hover:scale-125 transition-transform"
          onClick={() => onSelect(index + 1)}
        >
          <img className="h-12" src="/img/events/ratings/star-inactive.svg" />
        </div>
      );
    })}
  </div>
);

export default StarRating;
