import styles from './SendMoney.module.scss';
import formStyles from 'legacy/common/components/form/Form.module.scss';

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';

import {
  types as p2pTypes,
  startP2pCheckoutSession,
  updateP2pCheckoutSession,
  confirmP2pCheckoutSession,
  clearP2pCheckoutErrors
} from 'legacy/actions/p2pActions';
import {
  hideModal,
  showModal,
  clearHistory
} from 'legacy/actions/modalActions';
import {
  SubmitButton,
  ValidatedInput,
  withForm,
  CheckboxField
} from '../../util/FormHelpers';
import {
  isRequired,
  maxLength,
  minValue,
  composeValidators,
  maxValue
} from '../../../util/validators';
import { cloneDeep, isEmpty, forEach, pick } from 'lodash';

import Card from '../../util/Card/Card';

import ModalBase from '../ModalBase/ModalBase';
import { usePrevious } from '../../../util/hooks';
import { CheckoutPricing } from '../JoinEvent/components';
import { ERROR_CODES } from 'legacy/common/constants/errorCodes';
import { MIN_P2P_AMOUNT, MAX_P2P_AMOUNT } from 'legacy/common/constants/p2p';

const SendMoney = ({
  formProps,
  showModal,
  clearHistory,
  startP2pCheckoutSession,
  updateP2pCheckoutSession,
  confirmP2pCheckoutSession,
  loading,
  errors,
  isOpen,
  cards,
  baseModalProps,
  initialFormState = {},
  receiver = {},
  checkoutSession = {},
  clearP2pCheckoutErrors
}) => {
  const { paymentIntentId, pricing, card: cardId, currency } = checkoutSession;

  const checkoutUpdates = pick(checkoutSession, ['price', 'card']);

  useEffect(() => {
    const defaultState = {
      price: checkoutSession.price || 5,
      reason: initialFormState.reason || '',
      private: initialFormState.private || false
    };
    formProps.form.batch(() => {
      forEach(defaultState, (val, key) => {
        formProps.form.change(key, val);
      });
    });

    // no paymentIntent means we dont have existing checkoutSession, or start a new session if the receiver changed
    if (!paymentIntentId || receiver._id !== checkoutSession.receiver) {
      startP2pCheckoutSession({
        price: defaultState.price,
        receiver: receiver._id
      });
    }
  }, []);

  useEffect(() => {
    if (
      errors.START_P2P_CHECKOUT_SESSION.code ===
      ERROR_CODES.MISSING_EMAIL_VERIFICATION
    ) {
      return;
    }

    if (errors.START_P2P_CHECKOUT_SESSION?.message) {
      showModal('ALERT', {
        message: errors.START_P2P_CHECKOUT_SESSION.message,
        hideBackButton: true
      });
      clearP2pCheckoutErrors();
    }
  }, [errors.START_P2P_CHECKOUT_SESSION]);

  useEffect(() => {
    if (
      errors.UPDATE_P2P_CHECKOUT_SESSION?.message ||
      errors.CONFIRM_P2P_CHECKOUT_SESSION?.message
    ) {
      const apiError =
        errors.CONFIRM_P2P_CHECKOUT_SESSION?.message ||
        errors.UPDATE_P2P_CHECKOUT_SESSION?.message;
      setApiErrorMessage(apiError);
      clearP2pCheckoutErrors();
    }
  }, [errors.UPDATE_P2P_CHECKOUT_SESSION, errors.CONFIRM_P2P_CHECKOUT_SESSION]);

  const [debouncedPrice] = useDebounce(formProps.values.price, 500);

  useEffect(() => {
    setApiErrorMessage('');
    clearP2pCheckoutErrors();

    if (debouncedPrice < MIN_P2P_AMOUNT || debouncedPrice > MAX_P2P_AMOUNT) {
      return;
    }

    if (
      paymentIntentId &&
      formProps.values.price &&
      checkoutUpdates.price !== Number(formProps.values.price)
    ) {
      updateP2pCheckoutSession(
        {
          ...checkoutUpdates,
          price: Number(formProps.values.price)
        },
        paymentIntentId
      );
    }
  }, [debouncedPrice]);

  const sendMoney = () => {
    confirmP2pCheckoutSession(
      paymentIntentId,
      pick(formProps.values, ['reason', 'private'])
    );
  };

  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const handleSubmit = (e, formProps) => {
    e.preventDefault();
    formProps.handleSubmit();

    if (formProps.valid) {
      sendMoney();
    }

    // Scroll error into view
    setTimeout(() => {
      const el = document.querySelector('.form-error');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 5);
  };

  const showConfirmation = () => {
    showModal('TRANSFER_CONFIRMATION', {
      title: 'Money Has Been Sent!',
      message: `You have sent $${formProps.values.price} to ${
        receiver.firstName || receiver.name
      }`
    });

    clearHistory();
  };

  const prevLoading = usePrevious(cloneDeep(loading));
  useEffect(() => {
    if (!loading.CONFIRM_P2P_CHECKOUT_SESSION && prevLoading) {
      if (isEmpty(errors.CONFIRM_P2P_CHECKOUT_SESSION)) {
        showConfirmation();
      }
    }
  }, [loading.CONFIRM_P2P_CHECKOUT_SESSION]);

  const setState = (name, value) => {
    formProps.form.change(name, value);
  };

  const paymentCard = cards.find((card) => card._id === cardId);
  const submitLoading =
    loading.CONFIRM_P2P_CHECKOUT_SESSION ||
    loading.UPDATE_P2P_CHECKOUT_SESSION ||
    loading.START_P2P_CHECKOUT_SESSION;

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="p2p"
    >
      <form
        className={classnames(styles.SendMoney, 'component')}
        onSubmit={(e) => handleSubmit(e, formProps)}
      >
        <h2 className="modalTitle">
          {`Send Money to ${receiver.firstName || receiver.name}`}
        </h2>
        <div className="info">
          Transfers can be ${MIN_P2P_AMOUNT} - ${MAX_P2P_AMOUNT}.
        </div>
        <div className="bn-row money">
          <span>$</span>
          <div className="wrapper">
            <ValidatedInput
              errorVisible={isOpen}
              showErrorBeforeTouched
              validate={composeValidators(
                isRequired,
                minValue(MIN_P2P_AMOUNT, `Minimum value is $${MIN_P2P_AMOUNT}`),
                maxValue(MAX_P2P_AMOUNT, `Maximum value is $${MAX_P2P_AMOUNT}`)
              )}
              type="number"
              name="price"
              inputProps={{
                step: '0.01'
              }}
            />
          </div>
        </div>

        <div className="quickSendWrapper">
          {[5, 10, 20].map((amount) => (
            <div
              key={amount}
              className="bn-btn quickSend"
              onClick={() => setState('price', amount)}
            >
              $<span>{amount}</span>
            </div>
          ))}
        </div>

        <ValidatedInput
          id="textArea"
          placeholder="Reason: (optional)"
          name="reason"
          type="text"
          component="textarea"
          validate={maxLength(200)}
          inputProps={{
            rows: 1,
            wrap: 'soft'
          }}
        />

        <CheckoutPricing
          loading={loading.UPDATE_P2P_CHECKOUT_SESSION}
          pricing={pricing}
          currency={currency}
          showPricing={!isEmpty(pricing)}
          priceLabel="Transfer Amount"
        />
        <div className={formStyles.formError}>{apiErrorMessage}</div>

        {paymentCard && <Card card={paymentCard} width="75%" />}

        <div
          onClick={() =>
            showModal('CHOOSE_PAYMENT_METHOD', {
              selectedCard: paymentCard,
              onCardSelect: (card) => {
                updateP2pCheckoutSession(
                  { ...checkoutUpdates, card: card._id },
                  paymentIntentId
                );
                showModal('SEND_MONEY', {
                  receiver,
                  initialFormState: {
                    reason: formProps.values.reason,
                    private: formProps.values.private
                  }
                });
              }
            })
          }
          className="bn-btn plain change-card"
        >
          USE ANOTHER CARD
        </div>

        <div className="bn-row private">
          <CheckboxField name="private" form={formProps.form} />
          <p>PRIVATE (WILL NOT SHOW IN FEED)</p>
        </div>
        <SubmitButton
          errorOpen={
            formProps.hasSubmitErrors &&
            !formProps.dirtySinceLastSubmit &&
            !formProps.submitting
          }
          buttonText="SEND"
          errorContent={
            formProps.hasSubmitErrors
              ? formProps.submitErrors.FORM_ERROR
              : apiErrorMessage
          }
          loading={submitLoading}
          disabled={submitLoading}
        />
      </form>
    </ModalBase>
  );
};

function mapStateToProps(state) {
  return {
    errors: state.p2p.errors,
    loading: state.p2p.loading,
    cards: state.payment.cards,
    user: state.user.user,
    checkoutSession: state.p2p.checkoutSession
  };
}

const actions = {
  hideModal,
  showModal,
  clearHistory,
  startP2pCheckoutSession,
  updateP2pCheckoutSession,
  confirmP2pCheckoutSession,
  clearP2pCheckoutErrors
};

export default connect(
  mapStateToProps,
  actions
)(withForm(p2pTypes.CONFIRM_P2P_CHECKOUT_SESSION)(SendMoney));
