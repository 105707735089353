import { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';

export function useWindowSize() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(Math.min(window.innerWidth, window.screen.width));
      setHeight(Math.min(window.innerHeight, window.screen.height));
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return [width, height];
}

export function useToggle(defaultState = false) {
  const [val, setVal] = useState(defaultState);
  const toggleVal = useCallback(() => {
    setVal(!val);
  }, [val, setVal]);
  return [val, toggleVal];
}

export function useTouchStatus() {
  const [hasTouch, setTouch] = useState(false);
  useEffect(() => {
    const handleTouch = () => setTouch(true);
    document.addEventListener('touchstart', handleTouch);
    return () => document.removeEventListener('touchstart', handleTouch);
  }, []);
  return hasTouch;
}

export function useDesktopOnlyHover() {
  const [hover, setHover] = useState(false);
  const touchEnabled = useTouchStatus();
  const onEnter = useCallback(() => {
    if (!touchEnabled) {
      setHover(true);
    }
  }, [setHover, touchEnabled]);
  const onLeave = useCallback(() => {
    setHover(false);
  }, [setHover]);
  return [hover, onEnter, onLeave];
}

export function useEffectOnce(func, condition, deps) {
  const [hasUsed, setHasUsed] = useState(false);
  useEffect(() => {
    if (condition && !hasUsed) {
      func();
      setHasUsed(true);
    }
  }, deps);
}

export function useConditionalLoginEffect(func, user, deps, condition = true) {
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (condition && user && !loggedIn) {
      func();
      setLoggedIn(true);
    } else if (!user && loggedIn) {
      setLoggedIn(false);
    }
  }, deps);
}

export function useMountEffect(func) {
  useEffect(func, []);
}

export function useTitle(title) {
  useEffect(() => {
    if (title) {
      document.title = `${title} | Bounce`;
    } else {
      document.title = 'Bounce';
    }
  }, [title]);
}

export function useCompare(val) {
  const valRef = useRef(val);
  const prev = valRef.current;
  valRef.current = val;
  return [prev, valRef.current];
}

/**
 * Get the previous value of some object.
 * @param {*} val The object to get the prev value of
 */
export function usePrevious(val) {
  const ref = useRef();
  useEffect(() => {
    ref.current = val;
  }, [val]);

  return ref.current;
}

/**
 * Return if an object has changed (deep compare) since the last render
 *
 * Useful if you have a deeply.nested.object whose attributes may stay the same when
 * the object reference may change.
 * @param {*} value
 */
export function useHasChangedDeep(value) {
  const prev = usePrevious(value);
  return !_.isEqual(value, prev);
}

/**
 * Memoize an object until it has changed (via deep compare)
 *
 * Useful if you have a deeply.nested.object whose attributes may stay the same when
 * the object reference may change.
 * @param {*} value
 * @returns {*} Memoized value if nothing has changed, new value if it has.
 */
export function useDeepCompareMemoize(value) {
  const ref = useRef(value);
  const hasChanged = useHasChangedDeep(value);
  if (hasChanged) {
    ref.current = value;
  }
  return ref.current;
}

/**
 * Continuously execute callback after delay of specified milliseconds
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return undefined;
  }, [delay]);
}
