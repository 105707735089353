import React from 'react';

import styles from './ResetPasswordRequest.module.scss';

const ResetPasswordSuccess = ({ close }) => (
  <div className={styles.success}>
    <div className={styles.title}>Success!</div>
    <div className="img-wrapper">
      <img src="/img/illustrations/Illustration_Email Sent.svg" alt="" />
    </div>
    <div className={styles.message}>
      We&apos;ve sent you an email with a link to reset your password.
    </div>
    <div className="fill-vertical" />

    <button className="bn-btn primary" onClick={close}>
      Finish
    </button>
  </div>
);

export default ResetPasswordSuccess;
