export const PLATFORM_IOS = 'iOS';
export const PLATFORM_ANDROID = 'Android';
export const PLATFORM_WINDOWS = 'Windows Phone';

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * Ref: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
 */
export function getMobilePlatform() {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return PLATFORM_WINDOWS;
  }

  if (/android/i.test(userAgent)) {
    return PLATFORM_ANDROID;
  }

  // Ref: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return PLATFORM_IOS;
  }

  return 'unknown';
}

const appUrl = {
  [PLATFORM_IOS]: 'https://bouncelife.com/ios',
  [PLATFORM_ANDROID]: 'https://bouncelife.com/android'
};

export function getAppStoreLink() {
  return appUrl[getMobilePlatform()] || appUrl[PLATFORM_IOS];
}
