import React from 'react';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';

type IntroModalProps = {
  onSkip: () => void;
  onSubmit: () => void;
};

const IntroModal: React.FC<IntroModalProps> = ({ onSkip, onSubmit }) => (
  <Modal visible type={ModalType.ALERT}>
    <div className="px-8 py-6" data-testid="modal-migration-intro">
      <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
        Hi there! 👋
      </h1>
      <p className="mt-4 font-normal text-base text-gray-500 leading-loose">
        We are making some improvements to our sign in process on Bounce. To
        keep your account secure, we need some additional information from you.
      </p>

      <div className="pl-20 mt-8 flex justify-end">
        <Button
          testId="button-skip"
          type={ButtonType.Clear}
          onClick={() => onSkip()}
        >
          <span className="text-gray-500">Skip</span>
        </Button>
        <Button
          testId="button-next"
          onClick={() => {
            onSubmit();
          }}
        >
          Next
        </Button>
      </div>
    </div>
  </Modal>
);

export default IntroModal;
