import React from 'react';
import styles from './ErrorPage.module.scss';

const ErrorPage = ({ message }) => (
  <div
    className={styles.errorPage}
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}
  >
    <h2 style={{ padding: '20px', marginTop: '100px' }}>{message}</h2>

    <i style={{ fontSize: '20px' }} className="fas fa-toolbox" />
    <a className="bn-btn primary errorPage" href="/dashboard">
      Go Back
    </a>
  </div>
);

ErrorPage.defaultProps = {
  message: 'Sorry, something has gone wrong. We are working to fix it!'
};

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorPage;
