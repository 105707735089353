const TIMEZONE_COUNTRY_CODES = {
  'Africa/Bamako': 'ML',
  'Africa/Banjul': 'GM',
  'Africa/Conakry': 'GN',
  'Africa/Dakar': 'SN',
  'Africa/Freetown': 'SL',
  'Africa/Lome': 'TG',
  'Africa/Nouakchott': 'MR',
  'Africa/Ouagadougou': 'BF',
  'Atlantic/St_Helena': 'SH',
  'Africa/Maseru': 'LS',
  'Africa/Mbabane': 'SZ',
  'Africa/Bangui': 'CF',
  'Africa/Brazzaville': 'CG',
  'Africa/Douala': 'CM',
  'Africa/Kinshasa': 'CD',
  'Africa/Libreville': 'GA',
  'Africa/Luanda': 'AO',
  'Africa/Malabo': 'GQ',
  'Africa/Niamey': 'NE',
  'Africa/Porto-Novo': 'BJ',
  'Africa/Blantyre': 'MW',
  'Africa/Bujumbura': 'BI',
  'Africa/Gaborone': 'BW',
  'Africa/Harare': 'ZW',
  'Africa/Kigali': 'RW',
  'Africa/Lubumbashi': 'CD',
  'Africa/Lusaka': 'ZM',
  'Africa/Addis_Ababa': 'ET',
  'Africa/Asmara': 'ER',
  'Africa/Dar_es_Salaam': 'TZ',
  'Africa/Djibouti': 'DJ',
  'Africa/Kampala': 'UG',
  'Africa/Mogadishu': 'SO',
  'Indian/Antananarivo': 'MG',
  'Indian/Comoro': 'KM',
  'Indian/Mayotte': 'YT',
  'America/Aruba': 'AW',
  'America/Kralendijk': 'BQ',
  'America/Lower_Princes': 'SX',
  'America/Indiana/Indianapolis': 'US',
  'America/Indianapolis': 'US',
  'US/East-Indiana': 'US',
  'America/Nuuk': 'GL',
  'America/Cayman': 'KY',
  'America/Anguilla': 'AI',
  'America/Antigua': 'AG',
  'America/Dominica': 'DM',
  'America/Grenada': 'GD',
  'America/Guadeloupe': 'GP',
  'America/Marigot': 'MF',
  'America/Montserrat': 'MS',
  'America/St_Barthelemy': 'BL',
  'America/St_Kitts': 'KN',
  'America/St_Lucia': 'LC',
  'America/St_Thomas': 'VI',
  'America/St_Vincent': 'VC',
  'America/Tortola': 'VG',
  'Asia/Phnom_Penh': 'KH',
  'Asia/Vientiane': 'LA',
  'Asia/Muscat': 'OM',
  'Asia/Singapore': 'SG',
  'Asia/Bahrain': 'BH',
  'Asia/Yangon': 'MM',
  'Asia/Aden': 'YE',
  'Asia/Kuwait': 'KW',
  'Europe/Ljubljana': 'SI',
  'Europe/Podgorica': 'ME',
  'Europe/Sarajevo': 'BA',
  'Europe/Skopje': 'MK',
  'Europe/Zagreb': 'HR',
  'Europe/Mariehamn': 'AX',
  'Europe/Guernsey': 'GG',
  'Europe/Isle_of_Man': 'IM',
  'Europe/Jersey': 'JE',
  'Arctic/Longyearbyen': 'SJ',
  'Europe/Bratislava': 'SK',
  'Europe/San_Marino': 'SM',
  'Europe/Vatican': 'VA',
  'Europe/Busingen': 'DE',
  'Europe/Vaduz': 'LI',
  'Antarctica/McMurdo': 'AQ',
  'Pacific/Saipan': 'MP',
  'Pacific/Midway': 'UM',
  'Pacific/Funafuti': 'TV',
  'Pacific/Wake': 'UM',
  'Pacific/Wallis': 'WF',
  'Africa/Abidjan': 'CI',
  'Africa/Accra': 'GH',
  'Africa/Nairobi': 'KE',
  'Africa/Algiers': 'DZ',
  'Africa/Lagos': 'NG',
  'Africa/Bissau': 'GW',
  'Africa/Maputo': 'MZ',
  'Africa/Cairo': 'EG',
  'Africa/Casablanca': 'MA',
  'Africa/Ceuta': 'ES',
  'Africa/El_Aaiun': 'EH',
  'Africa/Johannesburg': 'ZA',
  'Africa/Juba': 'SS',
  'Africa/Khartoum': 'SD',
  'Africa/Monrovia': 'LR',
  'Africa/Ndjamena': 'TD',
  'Africa/Sao_Tome': 'ST',
  'Africa/Tripoli': 'LY',
  'Africa/Tunis': 'TN',
  'Africa/Windhoek': 'NA',
  'America/Adak': 'US',
  'America/Anchorage': 'US',
  'America/Port_of_Spain': 'TT',
  'America/Araguaina': 'BR',
  'America/Argentina/Buenos_Aires': 'AR',
  'America/Argentina/Catamarca': 'AR',
  'America/Argentina/Cordoba': 'AR',
  'America/Argentina/Jujuy': 'AR',
  'America/Argentina/La_Rioja': 'AR',
  'America/Argentina/Mendoza': 'AR',
  'America/Argentina/Rio_Gallegos': 'AR',
  'America/Argentina/Salta': 'AR',
  'America/Argentina/San_Juan': 'AR',
  'America/Argentina/San_Luis': 'AR',
  'America/Argentina/Tucuman': 'AR',
  'America/Argentina/Ushuaia': 'AR',
  'America/Curacao': 'CW',
  'America/Asuncion': 'PY',
  'America/Atikokan': 'CA',
  'America/Bahia_Banderas': 'MX',
  'America/Bahia': 'BR',
  'America/Barbados': 'BB',
  'America/Belem': 'BR',
  'America/Belize': 'BZ',
  'America/Blanc-Sablon': 'CA',
  'America/Boa_Vista': 'BR',
  'America/Bogota': 'CO',
  'America/Boise': 'US',
  'America/Cambridge_Bay': 'CA',
  'America/Campo_Grande': 'BR',
  'America/Cancun': 'MX',
  'America/Caracas': 'VE',
  'America/Cayenne': 'GF',
  'America/Panama': 'PA',
  'America/Chicago': 'US',
  'America/Chihuahua': 'MX',
  'America/Costa_Rica': 'CR',
  'America/Creston': 'CA',
  'America/Cuiaba': 'BR',
  'America/Danmarkshavn': 'GL',
  'America/Dawson_Creek': 'CA',
  'America/Dawson': 'CA',
  'America/Denver': 'US',
  'America/Detroit': 'US',
  'America/Edmonton': 'CA',
  'America/Eirunepe': 'BR',
  'America/El_Salvador': 'SV',
  'America/Tijuana': 'MX',
  'America/Fort_Nelson': 'CA',
  'America/Fort_Wayne': 'US',
  'America/Fortaleza': 'BR',
  'America/Glace_Bay': 'CA',
  'America/Goose_Bay': 'CA',
  'America/Grand_Turk': 'TC',
  'America/Guatemala': 'GT',
  'America/Guayaquil': 'EC',
  'America/Guyana': 'GY',
  'America/Halifax': 'CA',
  'America/Havana': 'CU',
  'America/Hermosillo': 'MX',
  'America/Indiana/Knox': 'US',
  'America/Indiana/Marengo': 'US',
  'America/Indiana/Petersburg': 'US',
  'America/Indiana/Tell_City': 'US',
  'America/Indiana/Vevay': 'US',
  'America/Indiana/Vincennes': 'US',
  'America/Indiana/Winamac': 'US',
  'America/Inuvik': 'CA',
  'America/Iqaluit': 'CA',
  'America/Jamaica': 'JM',
  'America/Juneau': 'US',
  'America/Kentucky/Louisville': 'US',
  'America/Kentucky/Monticello': 'US',
  'America/La_Paz': 'BO',
  'America/Lima': 'PE',
  'America/Los_Angeles': 'US',
  'America/Maceio': 'BR',
  'America/Managua': 'NI',
  'America/Manaus': 'BR',
  'America/Martinique': 'MQ',
  'America/Matamoros': 'MX',
  'America/Mazatlan': 'MX',
  'America/Menominee': 'US',
  'America/Merida': 'MX',
  'America/Metlakatla': 'US',
  'America/Mexico_City': 'MX',
  'America/Miquelon': 'PM',
  'America/Moncton': 'CA',
  'America/Monterrey': 'MX',
  'America/Montevideo': 'UY',
  'America/Toronto': 'CA',
  'America/Nassau': 'BS',
  'America/New_York': 'US',
  'America/Nipigon': 'CA',
  'America/Nome': 'US',
  'America/Noronha': 'BR',
  'America/North_Dakota/Beulah': 'US',
  'America/North_Dakota/Center': 'US',
  'America/North_Dakota/New_Salem': 'US',
  'America/Ojinaga': 'MX',
  'America/Pangnirtung': 'CA',
  'America/Paramaribo': 'SR',
  'America/Phoenix': 'US',
  'America/Port-au-Prince': 'HT',
  'America/Rio_Branco': 'BR',
  'America/Porto_Velho': 'BR',
  'America/Puerto_Rico': 'PR',
  'America/Punta_Arenas': 'CL',
  'America/Rainy_River': 'CA',
  'America/Rankin_Inlet': 'CA',
  'America/Recife': 'BR',
  'America/Regina': 'CA',
  'America/Resolute': 'CA',
  'America/Santarem': 'BR',
  'America/Santiago': 'CL',
  'America/Santo_Domingo': 'DO',
  'America/Sao_Paulo': 'BR',
  'America/Scoresbysund': 'GL',
  'America/Sitka': 'US',
  'America/St_Johns': 'CA',
  'America/Swift_Current': 'CA',
  'America/Tegucigalpa': 'HN',
  'America/Thule': 'GL',
  'America/Thunder_Bay': 'CA',
  'America/Vancouver': 'CA',
  'America/Whitehorse': 'CA',
  'America/Winnipeg': 'CA',
  'America/Yakutat': 'US',
  'America/Yellowknife': 'CA',
  'Antarctica/Casey': 'AQ',
  'Antarctica/Davis': 'AQ',
  'Antarctica/DumontDUrville': 'AQ',
  'Antarctica/Macquarie': 'AU',
  'Antarctica/Mawson': 'AQ',
  'Pacific/Auckland': 'NZ',
  'Antarctica/Palmer': 'AQ',
  'Antarctica/Rothera': 'AQ',
  'Antarctica/Syowa': 'AQ',
  'Antarctica/Troll': 'AQ',
  'Antarctica/Vostok': 'AQ',
  'Europe/Oslo': 'NO',
  'Asia/Riyadh': 'SA',
  'Asia/Almaty': 'KZ',
  'Asia/Amman': 'JO',
  'Asia/Anadyr': 'RU',
  'Asia/Aqtau': 'KZ',
  'Asia/Aqtobe': 'KZ',
  'Asia/Ashgabat': 'TM',
  'Asia/Atyrau': 'KZ',
  'Asia/Baghdad': 'IQ',
  'Asia/Qatar': 'QA',
  'Asia/Baku': 'AZ',
  'Asia/Bangkok': 'TH',
  'Asia/Barnaul': 'RU',
  'Asia/Beirut': 'LB',
  'Asia/Bishkek': 'KG',
  'Asia/Brunei': 'BN',
  'Asia/Kolkata': 'IN',
  'Asia/Calcutta': 'IN',
  'Asia/Chita': 'RU',
  'Asia/Choibalsan': 'MN',
  'Asia/Shanghai': 'CN',
  'Asia/Colombo': 'LK',
  'Asia/Dhaka': 'BD',
  'Asia/Damascus': 'SY',
  'Asia/Dili': 'TL',
  'Asia/Dubai': 'AE',
  'Asia/Dushanbe': 'TJ',
  'Asia/Famagusta': 'CY',
  'Asia/Gaza': 'PS',
  'Asia/Hebron': 'PS',
  'Asia/Ho_Chi_Minh': 'VN',
  'Asia/Hong_Kong': 'HK',
  'Asia/Hovd': 'MN',
  'Asia/Irkutsk': 'RU',
  'Europe/Istanbul': 'TR',
  'Asia/Jakarta': 'ID',
  'Asia/Jayapura': 'ID',
  'Asia/Jerusalem': 'IL',
  'Asia/Kabul': 'AF',
  'Asia/Kamchatka': 'RU',
  'Asia/Karachi': 'PK',
  'Asia/Urumqi': 'CN',
  'Asia/Kathmandu': 'NP',
  'Asia/Katmandu': 'NP',
  'Asia/Khandyga': 'RU',
  'Asia/Krasnoyarsk': 'RU',
  'Asia/Kuala_Lumpur': 'MY',
  'Asia/Kuching': 'MY',
  'Asia/Macau': 'MO',
  'Asia/Magadan': 'RU',
  'Asia/Makassar': 'ID',
  'Asia/Manila': 'PH',
  'Asia/Nicosia': 'CY',
  'Asia/Novokuznetsk': 'RU',
  'Asia/Novosibirsk': 'RU',
  'Asia/Omsk': 'RU',
  'Asia/Oral': 'KZ',
  'Asia/Pontianak': 'ID',
  'Asia/Pyongyang': 'KP',
  'Asia/Qostanay': 'KZ',
  'Asia/Qyzylorda': 'KZ',
  'Asia/Rangoon': 'MM',
  'Asia/Sakhalin': 'RU',
  'Asia/Samarkand': 'UZ',
  'Asia/Seoul': 'KR',
  'Asia/Srednekolymsk': 'RU',
  'Asia/Taipei': 'TW',
  'Asia/Tashkent': 'UZ',
  'Asia/Tbilisi': 'GE',
  'Asia/Tehran': 'IR',
  'Asia/Thimphu': 'BT',
  'Asia/Tokyo': 'JP',
  'Asia/Tomsk': 'RU',
  'Asia/Ulaanbaatar': 'MN',
  'Asia/Ust-Nera': 'RU',
  'Asia/Vladivostok': 'RU',
  'Asia/Yakutsk': 'RU',
  'Asia/Yekaterinburg': 'RU',
  'Asia/Yerevan': 'AM',
  'Atlantic/Azores': 'PT',
  'Atlantic/Bermuda': 'BM',
  'Atlantic/Canary': 'ES',
  'Atlantic/Cape_Verde': 'CV',
  'Atlantic/Faroe': 'FO',
  'Atlantic/Madeira': 'PT',
  'Atlantic/Reykjavik': 'IS',
  'Atlantic/South_Georgia': 'GS',
  'Atlantic/Stanley': 'FK',
  'Australia/Sydney': 'AU',
  'Australia/Adelaide': 'AU',
  'Australia/Brisbane': 'AU',
  'Australia/Broken_Hill': 'AU',
  'Australia/Currie': 'AU',
  'Australia/Darwin': 'AU',
  'Australia/Eucla': 'AU',
  'Australia/Hobart': 'AU',
  'Australia/Lord_Howe': 'AU',
  'Australia/Lindeman': 'AU',
  'Australia/Melbourne': 'AU',
  'Australia/Perth': 'AU',
  'Pacific/Easter': 'CL',
  'Europe/Dublin': 'IE',
  'Pacific/Port_Moresby': 'PG',
  'Pacific/Tarawa': 'KI',
  'Indian/Christmas': 'CX',
  'Pacific/Palau': 'PW',
  'Europe/Amsterdam': 'NL',
  'Europe/Andorra': 'AD',
  'Europe/Astrakhan': 'RU',
  'Europe/Athens': 'GR',
  'Europe/London': 'GB',
  'Europe/Belgrade': 'RS',
  'Europe/Berlin': 'DE',
  'Europe/Prague': 'CZ',
  'Europe/Brussels': 'BE',
  'Europe/Bucharest': 'RO',
  'Europe/Budapest': 'HU',
  'Europe/Zurich': 'CH',
  'Europe/Chisinau': 'MD',
  'Europe/Copenhagen': 'DK',
  'Europe/Gibraltar': 'GI',
  'Europe/Helsinki': 'FI',
  'Europe/Kaliningrad': 'RU',
  'Europe/Kiev': 'UA',
  'Europe/Kirov': 'RU',
  'Europe/Lisbon': 'PT',
  'Europe/Luxembourg': 'LU',
  'Europe/Madrid': 'ES',
  'Europe/Malta': 'MT',
  'Europe/Minsk': 'BY',
  'Europe/Monaco': 'MC',
  'Europe/Moscow': 'RU',
  'Europe/Paris': 'FR',
  'Europe/Riga': 'LV',
  'Europe/Rome': 'IT',
  'Europe/Samara': 'RU',
  'Europe/Saratov': 'RU',
  'Europe/Simferopol': 'UA',
  'Europe/Sofia': 'BG',
  'Europe/Stockholm': 'SE',
  'Europe/Tallinn': 'EE',
  'Europe/Tirane': 'AL',
  'Europe/Ulyanovsk': 'RU',
  'Europe/Uzhgorod': 'UA',
  'Europe/Vienna': 'AT',
  'Europe/Vilnius': 'LT',
  'Europe/Volgograd': 'RU',
  'Europe/Warsaw': 'PL',
  'Europe/Zaporozhye': 'UA',
  'Indian/Chagos': 'IO',
  'Indian/Cocos': 'CC',
  'Indian/Kerguelen': 'TF',
  'Indian/Mahe': 'SC',
  'Indian/Maldives': 'MV',
  'Indian/Mauritius': 'MU',
  'Indian/Reunion': 'RE',
  'Pacific/Kwajalein': 'MH',
  'Pacific/Chatham': 'NZ',
  'Pacific/Apia': 'WS',
  'Pacific/Bougainville': 'PG',
  'Pacific/Chuuk': 'FM',
  'Pacific/Efate': 'VU',
  'Pacific/Enderbury': 'KI',
  'Pacific/Fakaofo': 'TK',
  'Pacific/Fiji': 'FJ',
  'Pacific/Galapagos': 'EC',
  'Pacific/Gambier': 'PF',
  'Pacific/Guadalcanal': 'SB',
  'Pacific/Guam': 'GU',
  'Pacific/Honolulu': 'US',
  'Pacific/Kiritimati': 'KI',
  'Pacific/Kosrae': 'FM',
  'Pacific/Majuro': 'MH',
  'Pacific/Marquesas': 'PF',
  'Pacific/Pago_Pago': 'AS',
  'Pacific/Nauru': 'NR',
  'Pacific/Niue': 'NU',
  'Pacific/Norfolk': 'NF',
  'Pacific/Noumea': 'NC',
  'Pacific/Pitcairn': 'PN',
  'Pacific/Pohnpei': 'FM',
  'Pacific/Rarotonga': 'CK',
  'Pacific/Tahiti': 'PF',
  'Pacific/Tongatapu': 'TO'
};

export const COUNTRIES = {
  AD: { code: 'AD', name: 'Andorra', flag: '🇦🇩', callingCode: '376' },
  AE: {
    code: 'AE',
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    callingCode: '971'
  },
  AF: { code: 'AF', name: 'Afghanistan', flag: '🇦🇫', callingCode: '93' },
  AG: {
    code: 'AG',
    name: 'Antigua & Barbuda',
    flag: '🇦🇬',
    callingCode: '1268'
  },
  AI: { code: 'AI', name: 'Anguilla', flag: '🇦🇮', callingCode: '1264' },
  AL: { code: 'AL', name: 'Albania', flag: '🇦🇱', callingCode: '355' },
  AM: { code: 'AM', name: 'Armenia', flag: '🇦🇲', callingCode: '374' },
  AO: { code: 'AO', name: 'Angola', flag: '🇦🇴', callingCode: '244' },
  AQ: { code: 'AQ', name: 'Antarctica', flag: '🇦🇶', callingCode: '672' },
  AR: { code: 'AR', name: 'Argentina', flag: '🇦🇷', callingCode: '54' },
  AS: { code: 'AS', name: 'American Samoa', flag: '🇦🇸', callingCode: '1684' },
  AT: { code: 'AT', name: 'Austria', flag: '🇦🇹', callingCode: '43' },
  AU: { code: 'AU', name: 'Australia', flag: '🇦🇺', callingCode: '61' },
  AW: { code: 'AW', name: 'Aruba', flag: '🇦🇼', callingCode: '297' },
  AX: { code: 'AX', name: 'Åland Islands', flag: '🇦🇽', callingCode: '358' },
  AZ: { code: 'AZ', name: 'Azerbaijan', flag: '🇦🇿', callingCode: '994' },
  BA: {
    code: 'BA',
    name: 'Bosnia & Herzegovina',
    flag: '🇧🇦',
    callingCode: '387'
  },
  BB: { code: 'BB', name: 'Barbados', flag: '🇧🇧', callingCode: '1246' },
  BD: { code: 'BD', name: 'Bangladesh', flag: '🇧🇩', callingCode: '880' },
  BE: { code: 'BE', name: 'Belgium', flag: '🇧🇪', callingCode: '32' },
  BF: { code: 'BF', name: 'Burkina Faso', flag: '🇧🇫', callingCode: '226' },
  BG: { code: 'BG', name: 'Bulgaria', flag: '🇧🇬', callingCode: '359' },
  BH: { code: 'BH', name: 'Bahrain', flag: '🇧🇭', callingCode: '973' },
  BI: { code: 'BI', name: 'Burundi', flag: '🇧🇮', callingCode: '257' },
  BJ: { code: 'BJ', name: 'Benin', flag: '🇧🇯', callingCode: '229' },
  BL: { code: 'BL', name: 'St. Barthélemy', flag: '🇧🇱', callingCode: '590' },
  BM: { code: 'BM', name: 'Bermuda', flag: '🇧🇲', callingCode: '1441' },
  BN: { code: 'BN', name: 'Brunei', flag: '🇧🇳', callingCode: '673' },
  BO: { code: 'BO', name: 'Bolivia', flag: '🇧🇴', callingCode: '591' },
  BR: { code: 'BR', name: 'Brazil', flag: '🇧🇷', callingCode: '55' },
  BS: { code: 'BS', name: 'Bahamas', flag: '🇧🇸', callingCode: '1242' },
  BT: { code: 'BT', name: 'Bhutan', flag: '🇧🇹', callingCode: '975' },
  BW: { code: 'BW', name: 'Botswana', flag: '🇧🇼', callingCode: '267' },
  BY: { code: 'BY', name: 'Belarus', flag: '🇧🇾', callingCode: '375' },
  BZ: { code: 'BZ', name: 'Belize', flag: '🇧🇿', callingCode: '501' },
  CA: { code: 'CA', name: 'Canada', flag: '🇨🇦', callingCode: '1' },
  CC: {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    callingCode: '61'
  },
  CD: { code: 'CD', name: 'Congo - Kinshasa', flag: '🇨🇩', callingCode: '243' },
  CF: {
    code: 'CF',
    name: 'Central African Republic',
    flag: '🇨🇫',
    callingCode: '236'
  },
  CG: {
    code: 'CG',
    name: 'Congo - Brazzaville',
    flag: '🇨🇬',
    callingCode: '242'
  },
  CH: { code: 'CH', name: 'Switzerland', flag: '🇨🇭', callingCode: '41' },
  CI: { code: 'CI', name: 'Côte d’Ivoire', flag: '🇨🇮', callingCode: '225' },
  CK: { code: 'CK', name: 'Cook Islands', flag: '🇨🇰', callingCode: '682' },
  CL: { code: 'CL', name: 'Chile', flag: '🇨🇱', callingCode: '56' },
  CM: { code: 'CM', name: 'Cameroon', flag: '🇨🇲', callingCode: '237' },
  CN: { code: 'CN', name: 'China', flag: '🇨🇳', callingCode: '86' },
  CO: { code: 'CO', name: 'Colombia', flag: '🇨🇴', callingCode: '57' },
  CR: { code: 'CR', name: 'Costa Rica', flag: '🇨🇷', callingCode: '506' },
  CU: { code: 'CU', name: 'Cuba', flag: '🇨🇺', callingCode: '53' },
  CV: { code: 'CV', name: 'Cape Verde', flag: '🇨🇻', callingCode: '238' },
  CX: { code: 'CX', name: 'Christmas Island', flag: '🇨🇽', callingCode: '61' },
  CY: { code: 'CY', name: 'Cyprus', flag: '🇨🇾', callingCode: '357' },
  CZ: { code: 'CZ', name: 'Czechia', flag: '🇨🇿', callingCode: '420' },
  DE: { code: 'DE', name: 'Germany', flag: '🇩🇪', callingCode: '49' },
  DJ: { code: 'DJ', name: 'Djibouti', flag: '🇩🇯', callingCode: '253' },
  DK: { code: 'DK', name: 'Denmark', flag: '🇩🇰', callingCode: '45' },
  DM: { code: 'DM', name: 'Dominica', flag: '🇩🇲', callingCode: '1767' },
  DO: {
    code: 'DO',
    name: 'Dominican Republic',
    flag: '🇩🇴',
    callingCode: '1849'
  },
  DZ: { code: 'DZ', name: 'Algeria', flag: '🇩🇿', callingCode: '213' },
  EC: { code: 'EC', name: 'Ecuador', flag: '🇪🇨', callingCode: '593' },
  EE: { code: 'EE', name: 'Estonia', flag: '🇪🇪', callingCode: '372' },
  EG: { code: 'EG', name: 'Egypt', flag: '🇪🇬', callingCode: '20' },
  ER: { code: 'ER', name: 'Eritrea', flag: '🇪🇷', callingCode: '291' },
  ES: { code: 'ES', name: 'Spain', flag: '🇪🇸', callingCode: '34' },
  ET: { code: 'ET', name: 'Ethiopia', flag: '🇪🇹', callingCode: '251' },
  FI: { code: 'FI', name: 'Finland', flag: '🇫🇮', callingCode: '358' },
  FJ: { code: 'FJ', name: 'Fiji', flag: '🇫🇯', callingCode: '679' },
  FK: { code: 'FK', name: 'Falkland Islands', flag: '🇫🇰', callingCode: '500' },
  FM: { code: 'FM', name: 'Micronesia', flag: '🇫🇲', callingCode: '691' },
  FO: { code: 'FO', name: 'Faroe Islands', flag: '🇫🇴', callingCode: '298' },
  FR: { code: 'FR', name: 'France', flag: '🇫🇷', callingCode: '33' },
  GA: { code: 'GA', name: 'Gabon', flag: '🇬🇦', callingCode: '241' },
  GB: { code: 'GB', name: 'United Kingdom', flag: '🇬🇧', callingCode: '44' },
  GD: { code: 'GD', name: 'Grenada', flag: '🇬🇩', callingCode: '1473' },
  GE: { code: 'GE', name: 'Georgia', flag: '🇬🇪', callingCode: '995' },
  GF: { code: 'GF', name: 'French Guiana', flag: '🇬🇫', callingCode: '594' },
  GG: { code: 'GG', name: 'Guernsey', flag: '🇬🇬', callingCode: '44' },
  GH: { code: 'GH', name: 'Ghana', flag: '🇬🇭', callingCode: '233' },
  GI: { code: 'GI', name: 'Gibraltar', flag: '🇬🇮', callingCode: '350' },
  GL: { code: 'GL', name: 'Greenland', flag: '🇬🇱', callingCode: '299' },
  GM: { code: 'GM', name: 'Gambia', flag: '🇬🇲', callingCode: '220' },
  GN: { code: 'GN', name: 'Guinea', flag: '🇬🇳', callingCode: '224' },
  GP: { code: 'GP', name: 'Guadeloupe', flag: '🇬🇵', callingCode: '590' },
  GQ: {
    code: 'GQ',
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
    callingCode: '240'
  },
  GR: { code: 'GR', name: 'Greece', flag: '🇬🇷', callingCode: '30' },
  GS: {
    code: 'GS',
    name: 'South Georgia & South Sandwich Islands',
    flag: '🇬🇸',
    callingCode: '500'
  },
  GT: { code: 'GT', name: 'Guatemala', flag: '🇬🇹', callingCode: '502' },
  GU: { code: 'GU', name: 'Guam', flag: '🇬🇺', callingCode: '1671' },
  GW: { code: 'GW', name: 'Guinea-Bissau', flag: '🇬🇼', callingCode: '245' },
  GY: { code: 'GY', name: 'Guyana', flag: '🇬🇾', callingCode: '595' },
  HK: {
    code: 'HK',
    name: 'Hong Kong SAR China',
    flag: '🇭🇰',
    callingCode: '852'
  },
  HN: { code: 'HN', name: 'Honduras', flag: '🇭🇳', callingCode: '504' },
  HR: { code: 'HR', name: 'Croatia', flag: '🇭🇷', callingCode: '385' },
  HT: { code: 'HT', name: 'Haiti', flag: '🇭🇹', callingCode: '509' },
  HU: { code: 'HU', name: 'Hungary', flag: '🇭🇺', callingCode: '36' },
  ID: { code: 'ID', name: 'Indonesia', flag: '🇮🇩', callingCode: '62' },
  IE: { code: 'IE', name: 'Ireland', flag: '🇮🇪', callingCode: '353' },
  IL: { code: 'IL', name: 'Israel', flag: '🇮🇱', callingCode: '972' },
  IM: { code: 'IM', name: 'Isle of Man', flag: '🇮🇲', callingCode: '44' },
  IN: { code: 'IN', name: 'India', flag: '🇮🇳', callingCode: '91' },
  IO: {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    callingCode: '246'
  },
  IQ: { code: 'IQ', name: 'Iraq', flag: '🇮🇶', callingCode: '964' },
  IR: { code: 'IR', name: 'Iran', flag: '🇮🇷', callingCode: '98' },
  IS: { code: 'IS', name: 'Iceland', flag: '🇮🇸', callingCode: '354' },
  IT: { code: 'IT', name: 'Italy', flag: '🇮🇹', callingCode: '39' },
  JE: { code: 'JE', name: 'Jersey', flag: '🇯🇪', callingCode: '44' },
  JM: { code: 'JM', name: 'Jamaica', flag: '🇯🇲', callingCode: '1876' },
  JO: { code: 'JO', name: 'Jordan', flag: '🇯🇴', callingCode: '962' },
  JP: { code: 'JP', name: 'Japan', flag: '🇯🇵', callingCode: '81' },
  KE: { code: 'KE', name: 'Kenya', flag: '🇰🇪', callingCode: '254' },
  KG: { code: 'KG', name: 'Kyrgyzstan', flag: '🇰🇬', callingCode: '996' },
  KH: { code: 'KH', name: 'Cambodia', flag: '🇰🇭', callingCode: '855' },
  KI: { code: 'KI', name: 'Kiribati', flag: '🇰🇮', callingCode: '686' },
  KM: { code: 'KM', name: 'Comoros', flag: '🇰🇲', callingCode: '269' },
  KN: {
    code: 'KN',
    name: 'St. Kitts & Nevis',
    flag: '🇰🇳',
    callingCode: '1869'
  },
  KP: { code: 'KP', name: 'North Korea', flag: '🇰🇵', callingCode: '850' },
  KR: { code: 'KR', name: 'South Korea', flag: '🇰🇷', callingCode: '82' },
  KW: { code: 'KW', name: 'Kuwait', flag: '🇰🇼', callingCode: '965' },
  KY: { code: 'KY', name: 'Cayman Islands', flag: '🇰🇾', callingCode: ' 345' },
  KZ: { code: 'KZ', name: 'Kazakhstan', flag: '🇰🇿', callingCode: '77' },
  LA: { code: 'LA', name: 'Laos', flag: '🇱🇦', callingCode: '856' },
  LB: { code: 'LB', name: 'Lebanon', flag: '🇱🇧', callingCode: '961' },
  LC: { code: 'LC', name: 'St. Lucia', flag: '🇱🇨', callingCode: '1758' },
  LI: { code: 'LI', name: 'Liechtenstein', flag: '🇱🇮', callingCode: '423' },
  LK: { code: 'LK', name: 'Sri Lanka', flag: '🇱🇰', callingCode: '94' },
  LR: { code: 'LR', name: 'Liberia', flag: '🇱🇷', callingCode: '231' },
  LS: { code: 'LS', name: 'Lesotho', flag: '🇱🇸', callingCode: '266' },
  LT: { code: 'LT', name: 'Lithuania', flag: '🇱🇹', callingCode: '370' },
  LU: { code: 'LU', name: 'Luxembourg', flag: '🇱🇺', callingCode: '352' },
  LV: { code: 'LV', name: 'Latvia', flag: '🇱🇻', callingCode: '371' },
  LY: { code: 'LY', name: 'Libya', flag: '🇱🇾', callingCode: '218' },
  MA: { code: 'MA', name: 'Morocco', flag: '🇲🇦', callingCode: '212' },
  MC: { code: 'MC', name: 'Monaco', flag: '🇲🇨', callingCode: '377' },
  MD: { code: 'MD', name: 'Moldova', flag: '🇲🇩', callingCode: '373' },
  ME: { code: 'ME', name: 'Montenegro', flag: '🇲🇪', callingCode: '382' },
  MF: { code: 'MF', name: 'St. Martin', flag: '🇲🇫', callingCode: '590' },
  MG: { code: 'MG', name: 'Madagascar', flag: '🇲🇬', callingCode: '261' },
  MH: { code: 'MH', name: 'Marshall Islands', flag: '🇲🇭', callingCode: '692' },
  MK: { code: 'MK', name: 'Macedonia', flag: '🇲🇰', callingCode: '389' },
  ML: { code: 'ML', name: 'Mali', flag: '🇲🇱', callingCode: '223' },
  MM: { code: 'MM', name: 'Myanmar (Burma)', flag: '🇲🇲', callingCode: '95' },
  MN: { code: 'MN', name: 'Mongolia', flag: '🇲🇳', callingCode: '976' },
  MO: { code: 'MO', name: 'Macau SAR China', flag: '🇲🇴', callingCode: '853' },
  MP: {
    code: 'MP',
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    callingCode: '1670'
  },
  MQ: { code: 'MQ', name: 'Martinique', flag: '🇲🇶', callingCode: '596' },
  MR: { code: 'MR', name: 'Mauritania', flag: '🇲🇷', callingCode: '222' },
  MS: { code: 'MS', name: 'Montserrat', flag: '🇲🇸', callingCode: '1664' },
  MT: { code: 'MT', name: 'Malta', flag: '🇲🇹', callingCode: '356' },
  MU: { code: 'MU', name: 'Mauritius', flag: '🇲🇺', callingCode: '230' },
  MV: { code: 'MV', name: 'Maldives', flag: '🇲🇻', callingCode: '960' },
  MW: { code: 'MW', name: 'Malawi', flag: '🇲🇼', callingCode: '265' },
  MX: { code: 'MX', name: 'Mexico', flag: '🇲🇽', callingCode: '52' },
  MY: { code: 'MY', name: 'Malaysia', flag: '🇲🇾', callingCode: '60' },
  MZ: { code: 'MZ', name: 'Mozambique', flag: '🇲🇿', callingCode: '258' },
  NA: { code: 'NA', name: 'Namibia', flag: '🇳🇦', callingCode: '264' },
  NC: { code: 'NC', name: 'New Caledonia', flag: '🇳🇨', callingCode: '687' },
  NE: { code: 'NE', name: 'Niger', flag: '🇳🇪', callingCode: '227' },
  NF: { code: 'NF', name: 'Norfolk Island', flag: '🇳🇫', callingCode: '672' },
  NG: { code: 'NG', name: 'Nigeria', flag: '🇳🇬', callingCode: '234' },
  NI: { code: 'NI', name: 'Nicaragua', flag: '🇳🇮', callingCode: '505' },
  NL: { code: 'NL', name: 'Netherlands', flag: '🇳🇱', callingCode: '31' },
  NO: { code: 'NO', name: 'Norway', flag: '🇳🇴', callingCode: '47' },
  NP: { code: 'NP', name: 'Nepal', flag: '🇳🇵', callingCode: '977' },
  NR: { code: 'NR', name: 'Nauru', flag: '🇳🇷', callingCode: '674' },
  NU: { code: 'NU', name: 'Niue', flag: '🇳🇺', callingCode: '683' },
  NZ: { code: 'NZ', name: 'New Zealand', flag: '🇳🇿', callingCode: '64' },
  OM: { code: 'OM', name: 'Oman', flag: '🇴🇲', callingCode: '968' },
  PA: { code: 'PA', name: 'Panama', flag: '🇵🇦', callingCode: '507' },
  PE: { code: 'PE', name: 'Peru', flag: '🇵🇪', callingCode: '51' },
  PF: { code: 'PF', name: 'French Polynesia', flag: '🇵🇫', callingCode: '689' },
  PG: { code: 'PG', name: 'Papua New Guinea', flag: '🇵🇬', callingCode: '675' },
  PH: { code: 'PH', name: 'Philippines', flag: '🇵🇭', callingCode: '63' },
  PK: { code: 'PK', name: 'Pakistan', flag: '🇵🇰', callingCode: '92' },
  PL: { code: 'PL', name: 'Poland', flag: '🇵🇱', callingCode: '48' },
  PM: {
    code: 'PM',
    name: 'St. Pierre & Miquelon',
    flag: '🇵🇲',
    callingCode: '508'
  },
  PN: { code: 'PN', name: 'Pitcairn Islands', flag: '🇵🇳', callingCode: '872' },
  PR: { code: 'PR', name: 'Puerto Rico', flag: '🇵🇷', callingCode: '1939' },
  PS: {
    code: 'PS',
    name: 'Palestinian Territories',
    flag: '🇵🇸',
    callingCode: '970'
  },
  PT: { code: 'PT', name: 'Portugal', flag: '🇵🇹', callingCode: '351' },
  PW: { code: 'PW', name: 'Palau', flag: '🇵🇼', callingCode: '680' },
  PY: { code: 'PY', name: 'Paraguay', flag: '🇵🇾', callingCode: '595' },
  QA: { code: 'QA', name: 'Qatar', flag: '🇶🇦', callingCode: '974' },
  RE: { code: 'RE', name: 'Réunion', flag: '🇷🇪', callingCode: '262' },
  RO: { code: 'RO', name: 'Romania', flag: '🇷🇴', callingCode: '40' },
  RS: { code: 'RS', name: 'Serbia', flag: '🇷🇸', callingCode: '381' },
  RU: { code: 'RU', name: 'Russia', flag: '🇷🇺', callingCode: '7' },
  RW: { code: 'RW', name: 'Rwanda', flag: '🇷🇼', callingCode: '250' },
  SA: { code: 'SA', name: 'Saudi Arabia', flag: '🇸🇦', callingCode: '966' },
  SB: { code: 'SB', name: 'Solomon Islands', flag: '🇸🇧', callingCode: '677' },
  SC: { code: 'SC', name: 'Seychelles', flag: '🇸🇨', callingCode: '248' },
  SD: { code: 'SD', name: 'Sudan', flag: '🇸🇩', callingCode: '249' },
  SE: { code: 'SE', name: 'Sweden', flag: '🇸🇪', callingCode: '46' },
  SG: { code: 'SG', name: 'Singapore', flag: '🇸🇬', callingCode: '65' },
  SH: { code: 'SH', name: 'St. Helena', flag: '🇸🇭', callingCode: '290' },
  SI: { code: 'SI', name: 'Slovenia', flag: '🇸🇮', callingCode: '386' },
  SJ: {
    code: 'SJ',
    name: 'Svalbard & Jan Mayen',
    flag: '🇸🇯',
    callingCode: '47'
  },
  SK: { code: 'SK', name: 'Slovakia', flag: '🇸🇰', callingCode: '421' },
  SL: { code: 'SL', name: 'Sierra Leone', flag: '🇸🇱', callingCode: '232' },
  SM: { code: 'SM', name: 'San Marino', flag: '🇸🇲', callingCode: '378' },
  SN: { code: 'SN', name: 'Senegal', flag: '🇸🇳', callingCode: '221' },
  SO: { code: 'SO', name: 'Somalia', flag: '🇸🇴', callingCode: '252' },
  SR: { code: 'SR', name: 'Suriname', flag: '🇸🇷', callingCode: '597' },
  SS: { code: 'SS', name: 'South Sudan', flag: '🇸🇸', callingCode: '211' },
  ST: {
    code: 'ST',
    name: 'São Tomé & Príncipe',
    flag: '🇸🇹',
    callingCode: '239'
  },
  SV: { code: 'SV', name: 'El Salvador', flag: '🇸🇻', callingCode: '503' },
  SY: { code: 'SY', name: 'Syria', flag: '🇸🇾', callingCode: '963' },
  SZ: { code: 'SZ', name: 'Swaziland', flag: '🇸🇿', callingCode: '268' },
  TC: {
    code: 'TC',
    name: 'Turks & Caicos Islands',
    flag: '🇹🇨',
    callingCode: '1649'
  },
  TD: { code: 'TD', name: 'Chad', flag: '🇹🇩', callingCode: '235' },
  TG: { code: 'TG', name: 'Togo', flag: '🇹🇬', callingCode: '228' },
  TH: { code: 'TH', name: 'Thailand', flag: '🇹🇭', callingCode: '66' },
  TJ: { code: 'TJ', name: 'Tajikistan', flag: '🇹🇯', callingCode: '992' },
  TK: { code: 'TK', name: 'Tokelau', flag: '🇹🇰', callingCode: '690' },
  TL: { code: 'TL', name: 'Timor-Leste', flag: '🇹🇱', callingCode: '670' },
  TM: { code: 'TM', name: 'Turkmenistan', flag: '🇹🇲', callingCode: '993' },
  TN: { code: 'TN', name: 'Tunisia', flag: '🇹🇳', callingCode: '216' },
  TO: { code: 'TO', name: 'Tonga', flag: '🇹🇴', callingCode: '676' },
  TR: { code: 'TR', name: 'Turkey', flag: '🇹🇷', callingCode: '90' },
  TT: {
    code: 'TT',
    name: 'Trinidad & Tobago',
    flag: '🇹🇹',
    callingCode: '1868'
  },
  TV: { code: 'TV', name: 'Tuvalu', flag: '🇹🇻', callingCode: '688' },
  TW: { code: 'TW', name: 'Taiwan', flag: '🇹🇼', callingCode: '886' },
  TZ: { code: 'TZ', name: 'Tanzania', flag: '🇹🇿', callingCode: '255' },
  UA: { code: 'UA', name: 'Ukraine', flag: '🇺🇦', callingCode: '380' },
  UG: { code: 'UG', name: 'Uganda', flag: '🇺🇬', callingCode: '256' },
  US: { code: 'US', name: 'United States', flag: '🇺🇸', callingCode: '1' },
  UY: { code: 'UY', name: 'Uruguay', flag: '🇺🇾', callingCode: '598' },
  UZ: { code: 'UZ', name: 'Uzbekistan', flag: '🇺🇿', callingCode: '998' },
  VA: { code: 'VA', name: 'Vatican City', flag: '🇻🇦', callingCode: '379' },
  VC: {
    code: 'VC',
    name: 'St. Vincent & Grenadines',
    flag: '🇻🇨',
    callingCode: '1784'
  },
  VE: { code: 'VE', name: 'Venezuela', flag: '🇻🇪', callingCode: '58' },
  VG: {
    code: 'VG',
    name: 'British Virgin Islands',
    flag: '🇻🇬',
    callingCode: '1284'
  },
  VI: {
    code: 'VI',
    name: 'U.S. Virgin Islands',
    flag: '🇻🇮',
    callingCode: '1340'
  },
  VN: { code: 'VN', name: 'Vietnam', flag: '🇻🇳', callingCode: '84' },
  VU: { code: 'VU', name: 'Vanuatu', flag: '🇻🇺', callingCode: '678' },
  WF: { code: 'WF', name: 'Wallis & Futuna', flag: '🇼🇫', callingCode: '681' },
  WS: { code: 'WS', name: 'Samoa', flag: '🇼🇸', callingCode: '685' },
  YE: { code: 'YE', name: 'Yemen', flag: '🇾🇪', callingCode: '967' },
  YT: { code: 'YT', name: 'Mayotte', flag: '🇾🇹', callingCode: '262' },
  ZA: { code: 'ZA', name: 'South Africa', flag: '🇿🇦', callingCode: '27' },
  ZM: { code: 'ZM', name: 'Zambia', flag: '🇿🇲', callingCode: '260' },
  ZW: { code: 'ZW', name: 'Zimbabwe', flag: '🇿🇼', callingCode: '263' }
};

export const DEFAULT_COUNTRY_CODE = 'CA';
export function getCountryCode(): string {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const countryCode = TIMEZONE_COUNTRY_CODES[timezone];

    return countryCode || DEFAULT_COUNTRY_CODE;
  } catch (e) {
    return DEFAULT_COUNTRY_CODE;
  }
}

export function getCallingCode(countryCode) {
  return (
    COUNTRIES[countryCode]?.callingCode ||
    COUNTRIES[DEFAULT_COUNTRY_CODE].callingCode
  );
}
