import { createPortal } from 'react-dom';
import { useRef, useEffect, useState } from 'react';
import styles from './Portal.module.scss';

type PortalProps = {
  style?: Record<string, unknown>;
  portalId?: string;
  children: React.ReactNode;
};

const Portal: React.FC<PortalProps> = ({ portalId, children, style }) => {
  const ref = useRef<HTMLElement>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    let portalRoot = document.getElementById(portalId);

    if (!portalRoot) {
      portalRoot = document.createElement('div');
      portalRoot.setAttribute('id', portalId);
      document.body.appendChild(portalRoot);
    }

    ref.current = portalRoot;

    setMounted(true);
  }, []);

  // Prevents background scroll when portal is open
  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;

    return () => {
      const scrollY = document.body.style.top;

      document.body.style.position = '';
      document.body.style.top = '';

      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  }, []);

  return mounted
    ? createPortal(
        <div className={styles.container} style={style}>
          {children}
        </div>,
        ref.current
      )
    : null;
};

Portal.defaultProps = {
  portalId: 'portal'
};

export default Portal;
