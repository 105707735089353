import styles from './BackgroundWrapper.module.scss';

type BackgroundWrapperProps = {
  children: React.ReactNode;
};

const BackgroundWrapper: React.FC<BackgroundWrapperProps> = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.background}>
      {children}

      <div className={styles.footer}>
        <div className={styles.logo}>
          <a href="/">
            <img
              width="148px"
              alt="Bounce logo"
              src="/img/logos/bounce-white.svg"
            />
          </a>
          <div>&#169; {new Date().getFullYear()} Bounced Inc.</div>
        </div>

        <ul className={styles.links}>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/terms">Terms and Conditions</a>
          </li>
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default BackgroundWrapper;
