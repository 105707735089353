import { useRef, useState } from 'react';
import Button from '../button/Button';

import UploadPhotoModal from './UploadPhotoModal';

type UploadPhotoButtonProps = {
  label?: string;
  isLoading?: boolean;
  errorMessage?: string;
  aspectRatio?: number;
  cropShape?: 'round' | 'rect';
  children?: React.ReactNode;
  isUploadButtonDisabled?: boolean;
  uploadPhoto: (blob: Blob, options: { onSuccess: () => void }) => void;
  ButtonComponent?: React.FC<{ onClick: () => void }>;
  onSetError?: (error: string) => void;
};

const MAX_SIZE_MB = 10;

const UploadPhotoButton: React.FC<UploadPhotoButtonProps> = ({
  label,
  isLoading,
  cropShape,
  aspectRatio,
  errorMessage,
  ButtonComponent,
  children,
  isUploadButtonDisabled,
  uploadPhoto,
  onSetError
}) => {
  const [preview, setPreview] = useState<string>();
  const [error, setError] = useState<string>(null);
  const inputRef = useRef<HTMLInputElement>();
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);

  const openSelectFile = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const setErrorCompat = onSetError || setError;

    setErrorCompat(null);

    if (!file) {
      return;
    }

    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      e.target.value = '';
      setErrorCompat(
        `File of type "${file.type}" is not supported. Please use a JPG or PNG file.`
      );

      return;
    }

    if (file.size > MAX_SIZE_MB * 1024 * 1024) {
      e.target.value = '';
      setErrorCompat(`Please use an image below ${MAX_SIZE_MB} MB`);

      return;
    }

    e.target.value = '';

    setPreview(URL.createObjectURL(file));
    setShowUploadImageModal(true);
  };

  return (
    <>
      {ButtonComponent ? (
        <ButtonComponent onClick={openSelectFile} />
      ) : (
        <Button onClick={openSelectFile}>{label || 'Upload Photo'}</Button>
      )}
      {error && <div className="mt-2 text-red-500 text-center">{error}</div>}

      <input
        type="file"
        ref={inputRef}
        id="upload-image"
        className="hidden"
        accept=".png,.jpeg,.jpg"
        onChange={handleFileChange}
      />

      {showUploadImageModal && (
        <UploadPhotoModal
          preview={preview}
          cropShape={cropShape}
          isLoading={isLoading}
          aspectRatio={aspectRatio}
          isUploadButtonDisabled={isUploadButtonDisabled}
          errorMessage={errorMessage}
          onClose={() => {
            setShowUploadImageModal(false);
          }}
          uploadPhoto={uploadPhoto}
        >
          {children}
        </UploadPhotoModal>
      )}
    </>
  );
};

export default UploadPhotoButton;
