import isUaWebview from 'is-ua-webview';
import { NextRouter } from 'next/router';

export const isWebview = (userAgent: string): boolean =>
  // The `is-ua-webview` library fails to detect Snapchat webviews on iOS, so we need to check for it explicitly.
  isUaWebview(userAgent) || isSnapchatWebview(userAgent);

export const isSnapchatWebview = (userAgent: string): boolean =>
  userAgent.toLowerCase().includes('snapchat');

export const isInstagramWebview = (userAgent: string): boolean =>
  userAgent.toLowerCase().includes('instagram');

export function postMessageToRN(type: string, data?: Record<string, any>) {
  (window as any).ReactNativeWebView?.postMessage(
    JSON.stringify({
      type,
      data: data || {}
    })
  );
}

/**
 * Returns a boolean indicating if the current browser is running in a Bounce webview. This is currently used for event creation & editing.
 * @param {Object} query NextJS router.query object
 * @returns {boolean}
 */
export function isBounceWebview(query?: NextRouter['query']) {
  if (typeof window === 'undefined') {
    return false;
  }

  return query?.webview === '1' || (window as any).isBounceWebview === true;
}
