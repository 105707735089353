import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/connectActions';
import { types as userTypes } from 'legacy/actions/userActions';
import { toActionID } from '../util/util';

export const initialState = {
  defaultPayout: false,
  banks: [],
  loading: _.mapValues(types, () => false),
  errors: _.mapValues(types, () => ({})),
  attempted: _.mapValues(types, () => false)
};

// Allow specific sister types from other actions
const SISTER_TYPES = [
  userTypes.LOGOUT.success,
  userTypes.GET_MY_PROFILE.success,
  userTypes.CUSTOM_REGISTER.success,
  userTypes.INITIAL_LOGIN.success,
  userTypes.APPLE_LOGIN.success
];

export function connectReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
    if (action.type.endsWith('_SUCCESS') || action.type.endsWith('_ERROR')) {
      newState.attempted[actionID] = true;
    }
  }
  switch (action.type) {
    case userTypes.LOGOUT.success:
      return initialState;
    case userTypes.GET_MY_PROFILE.success:
    case userTypes.CUSTOM_REGISTER.success:
    case userTypes.INITIAL_LOGIN.success:
    case userTypes.APPLE_LOGIN.success:
      newState.banks = action.payload.user.banks || [];
      if (newState.banks.length > 0) {
        newState.defaultPayout = newState.banks.filter(
          (bank) => bank.default
        )[0]._id;
      } else {
        newState.defaultPayout = initialState.defaultPayout;
      }
      return newState;
    case types.ADD_PLAID_BANK.success:
    case types.ADD_BANK_MANUALLY.success:
    case types.DELETE_BANK.success:
      if (!action.payload.orgId) {
        newState.banks = action.payload.banks;
        if (action.payload.banks.length > 0) {
          newState.defaultPayout = action.payload.banks.filter(
            (bank) => bank.default
          )[0]._id;
        } else {
          newState.defaultPayout = initialState.defaultPayout;
        }
      }
      return newState;
    case types.SET_DEFAULT_BANK.success:
      if (!action.payload.orgId) {
        newState.banks = newState.banks.map((bank) => {
          if (bank._id === action.payload.defaultBank) {
            bank.default = true;
          } else {
            bank.default = false;
          }
          return bank;
        });
        newState.defaultPayout = action.payload.defaultBank;
      }
      return newState;
    case types.STRIPE_BANK.error:
      newState.errors.STRIPE_BANK = action.payload.error;
      newState.loading.ADD_BANK_MANUALLY = false;
      return newState;
    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: connectReducer };
