import { STORAGE_KEYS } from 'legacy/common/constants/storage';
import config from 'config';

import { initInterceptors } from 'legacy/auth/interceptors';
import { initializeAmplitude } from 'legacy/common/utils/amplitude';
import storage from 'legacy/common/utils/storage';
import { decodeJWT } from 'legacy/auth/helpers';
import { trackUserSessionOnGa } from 'legacy/common/utils/ga';

export function initAmplitude() {
  let userId = null;
  const token = storage.getItem(STORAGE_KEYS.AUTH_TOKEN);

  if (token) {
    userId = decodeJWT(token)._id;
  }

  trackUserSessionOnGa(userId);

  initializeAmplitude(config.AMPLITUDE_KEY, {
    userId,
    appVersion: config.APP_VERSION
  });
}

/**
 * Initialize API client config.
 */
export function initApiClient() {
  initInterceptors();
}
