import { useQuery, useInfiniteQuery, useMutation } from 'react-query';

import { UserLean, EventInviteListItem } from 'legacy/user/types';
import { ErrorResponse } from 'legacy/common/utils/error';
import {
  CombinedSearchResult,
  EventBasic,
  EventGuestBreakdown,
  EventGuestsCategories,
  EventGuestsSearchResults,
  Group
} from './types';

import {
  searchCombined,
  searchEvents,
  searchUsers,
  searchEventInviteList,
  searchEventGuestList,
  searchEventGuestListBreakdown,
  searchGroups,
  searchMultiTierGuestList
} from './service';
import {
  requestAssociationMembership,
  requestMembership
} from 'legacy/groups/service';

export function useCombinedSearch(name: string) {
  return useQuery<{ results: CombinedSearchResult[] }, ErrorResponse>(
    ['search/combined', name],
    () => searchCombined(name),
    {
      enabled: !!name
    }
  );
}

export function useUsersSearch(name: string) {
  return useQuery<UserLean[], ErrorResponse>(
    ['search/users', name],
    () => searchUsers(name),
    {
      enabled: !!name
    }
  );
}

export function useEventsSearch(name: string) {
  return useQuery<EventBasic[], ErrorResponse>(
    ['search/events', name],
    () => searchEvents(name),
    {
      enabled: !!name
    }
  );
}

export function useGroupsSearch(name: string) {
  return useQuery<Group[], ErrorResponse>(
    ['search/groups', name],
    () => searchGroups(name),
    {
      enabled: !!name
    }
  );
}

export function requestJoinGroup() {
  return useMutation<
    { success: boolean },
    ErrorResponse,
    {
      groupId: string;
    }
  >(requestMembership);
}

export function requestJoinAssociation() {
  return useMutation<
    { success: boolean },
    ErrorResponse,
    {
      associationId: string;
    }
  >(requestAssociationMembership);
}

export function useEventInviteListSearch(eventId: string, name: string) {
  return useInfiniteQuery<EventInviteListItem[], ErrorResponse>(
    ['search/inviteList', eventId, name],
    ({ pageParam = 0 }) => searchEventInviteList(eventId, name, pageParam)
  );
}

export function useEventGuestListSearch(
  isOrganizer: boolean,
  eventId: string,
  name: string
) {
  return isOrganizer
    ? useInfiniteQuery<EventGuestBreakdown[], ErrorResponse>(
        ['search/guestListBreakdown', eventId, name],
        ({ pageParam = 0 }) =>
          searchEventGuestListBreakdown(eventId, name, pageParam)
      )
    : useInfiniteQuery<UserLean[], ErrorResponse>(
        ['search/guestList', eventId, name],
        ({ pageParam = 0 }) => searchEventGuestList(eventId, name, pageParam)
      );
}

export function useMultiTierGuestListSearch(
  eventId: string,
  search: string,
  category = EventGuestsCategories.All
) {
  return useInfiniteQuery<EventGuestsSearchResults, ErrorResponse>(
    ['search/multiTierGuestList', eventId, search],
    ({ pageParam = 0 }) =>
      searchMultiTierGuestList(eventId, search, pageParam, category)
  );
}
