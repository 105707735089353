import { useDebounce } from 'use-debounce';

import { useCombinedSearch } from 'legacy/search/hooks';

import * as datetime from 'legacy/common/utils/datetime';

import Message from './Message';
import ResultList from './ResultList';
import RecentSearch from './RecentSearch';

type AllProps = {
  input: string;
};

const All: React.FC<AllProps> = ({ input }) => {
  const [debouncedInput] = useDebounce(input, 200);
  const isDebouncing = input !== debouncedInput;

  const { data, isLoading, isFetching, refetch } =
    useCombinedSearch(debouncedInput);

  if (!debouncedInput && !isDebouncing) {
    return <RecentSearch />;
  }

  if (debouncedInput && !isLoading && !isDebouncing && !data?.results?.length) {
    return <Message message={`No results for "${debouncedInput}"`} />;
  }

  return (
    <ResultList
      id="results-all"
      isLoading={isLoading || isDebouncing || isFetching}
      refetch={refetch}
      results={data?.results?.map((result) => {
        if (result.type === 'group') {
          return {
            id: result.id,
            image: result.badgeImageUrl || '/img/search/user.svg',
            value: result.name,
            subtitle: result.description,
            linkTo: `/organizations/${result.id}`,
            rawData: result
          };
        }

        if (result.type === 'event') {
          return {
            id: result._id,
            image: result.photo?.url || '/img/search/event.svg',
            value: result.title,
            subtitle: datetime.format(result.startDate),
            linkTo: result.isOrganizer
              ? `/events/${result._id}/dashboard`
              : `/events/${result._id}`,
            rawData: result
          };
        }

        if (result.type === 'user') {
          return {
            id: result._id,
            image: result.photo?.url || '/img/search/user.svg',
            value: result.name,
            linkTo: `/profile/${result._id}`,
            rawData: result
          };
        }

        return undefined;
      })}
    />
  );
};

export default All;
