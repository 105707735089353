import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  inviteFriends,
  types as friendTypes
} from 'legacy/actions/friendActions';
import { hideModal } from 'legacy/actions/modalActions';
import styles from './InviteFriends.module.scss';
import phoneStyles from '../AddPhone/AddPhone.module.scss';
import modalInputStyles from '../ModalUtils/ModalInput.module.scss';

import ModalBase from '../ModalBase/ModalBase';
import { ModalInput } from '../ModalUtils';
import {
  isEmail,
  isRequired,
  composeValidators
} from '../../../util/validators';
import {
  withForm,
  SubmitButton,
  ValidatedInput,
  PhoneNumberField
} from '../../util/FormHelpers';
import cn from 'classnames';

import { forEach, isEmpty, get } from 'lodash';

const initialFormState = {
  entries: [{ value: '', type: 'email', valid: false }]
};
class Invite extends Component {
  constructor(props) {
    super(props);
    props.formProps.form.batch(() => {
      forEach(initialFormState, (val, key) => {
        props.formProps.form.change(key, val);
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      if (!this.props.formProps.hasSubmitErrors && isEmpty(this.props.error)) {
        this.props.hideModal();
        this.props.formProps.form.batch(() => {
          forEach(initialFormState, (val, key) => {
            this.props.formProps.form.change(key, val);
          });
        });
      }
    }
  }

  submit = (e) => {
    e.preventDefault();
    const { formProps } = this.props;

    formProps.handleSubmit();
    if (
      !formProps.hasValidationErrors &&
      (!formProps.hasSubmitErrors || formProps.dirtySinceLastSubmit)
    ) {
      const { entries } = formProps.values;
      const emails = entries
        .filter((entry) => entry.type === 'email')
        .map((entry) => entry.value);
      const numbers = entries
        .filter((entry) => entry.type === 'number')
        .map((entry) => entry.value);
      this.props.inviteFriends(emails, numbers);
    }
  };

  add = () => {
    const { formProps } = this.props;
    const { entries } = formProps.values;
    formProps.form.change('entries', [
      ...entries,
      { value: '', type: 'email', valueValid: false }
    ]);
  };

  remove = (i) => {
    const { formProps } = this.props;
    const { entries } = formProps.values;
    formProps.form.change('entries', [
      ...entries.slice(0, i),
      ...entries.slice(i + 1)
    ]);
  };

  toggleType = (i, type) => {
    const { formProps } = this.props;
    formProps.form.change(`entries[${i}].type`, type);
  };

  render() {
    const { formProps, modalOpen, user } = this.props;
    const { entries = [] } = formProps.values;

    const formFields = entries.map((entry, i) => {
      const { type } = entry;
      const isValidNumber = (value, allValues) =>
        allValues.entries[i].valueValid ? undefined : 'Invalid phone number';
      const validate =
        type === 'number'
          ? composeValidators(isRequired, isValidNumber)
          : composeValidators(isRequired, isEmail);
      const InputComponent = type === 'number' ? PhoneNumberField : ModalInput;
      const inputClasses = cn({
        [phoneStyles.phoneInput]: type === 'number',
        [modalInputStyles.wrapper]: type === 'number'
      });
      return (
        <div className={styles.inputWrapper} key={i}>
          <div className={styles.toggle}>
            <div
              onClick={() => this.toggleType(i, 'email')}
              className={cn({
                [styles.active]: type === 'email'
              })}
            >
              Email
            </div>
            <div
              onClick={() => this.toggleType(i, 'number')}
              className={cn({
                [styles.active]: type === 'number'
              })}
            >
              Phone
            </div>
          </div>

          <div className={styles.inputRow}>
            <ValidatedInput
              formProps={formProps}
              errorVisible={modalOpen}
              {...{ validate }}
              key={i}
              placeholder={type === 'email' ? 'Email Address' : 'Phone Number'}
              name={`entries[${i}].value`}
              component={InputComponent}
              className={inputClasses}
              inputProps={{
                defaultCountry: user.country
              }}
            />
            <div className={styles.remove} onClick={() => this.remove(i)}>
              <span className="fa fa-times" aria-label="remove" />
            </div>
          </div>
        </div>
      );
    });

    return (
      <ModalBase
        baseModalProps={this.props.baseModalProps}
        back_img="Back"
        contentClassName="basic"
      >
        <form className={styles.InviteFriends} onSubmit={this.submit}>
          <h2 className="modalTitle">Invite Your Friends</h2>

          {formFields}
          <div onClick={this.add} className="bn-btn secondary">
            <i className="fas fa-plus" />
          </div>
          <SubmitButton
            errorContent={get(formProps, 'submitErrors.FORM_ERROR')}
            errorOpen={
              formProps.hasSubmitErrors &&
              !formProps.dirtySinceLastSubmit &&
              !formProps.submitting
            }
            buttonText="Invite"
            loading={this.props.loading}
          />
        </form>
      </ModalBase>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  loading: state.friends.loading.INVITE_FRIENDS,
  modalOpen: state.modal.isOpen,
  error: state.friends.errors.INVITE_FRIENDS
});

const actions = { inviteFriends, hideModal };

export default connect(
  mapStateToProps,
  actions
)(withForm(friendTypes.INVITE_FRIENDS_FORM)(Invite));
