import React, { useState } from 'react';

import {
  useConfirmEditPhoneVerificationSessionCommand,
  useCreateEditPhoneVerificationSessionCommand,
  useGetMe
} from 'legacy/user/hooks';

import * as toast from 'legacy/common/utils/toast';

import FieldSkeleton from '../FieldSkeleton';
import ConfirmPhoneNumberModal from './ConfirmPhoneNumberModal';
import AddUpdatePhoneNumberModal from './AddUpdatePhoneNumberModal';
import { VerificationSessionDto } from 'legacy/auth/types';

type AddUpdatePhoneNumberFlow = () => {
  onClose: () => void;
  onSuccess: () => void;
};

const AddUpdatePhoneNumberFlow = ({ onClose, onSuccess }) => {
  const {
    data: user,
    refetch: refetchUser,
    isFetching: isFetchingUser
  } = useGetMe({ enabled: false });
  const [showAddPhoneModal, setShowAddPhoneModal] = useState(true);

  const [newPhoneNumber, setNewPhoneNumber] = useState<string>();
  const [newCallingCode, setNewCallingCode] = useState<string>();
  const [verificationSession, setVerificationSession] =
    useState<VerificationSessionDto>();

  const createEditPhoneVerificationSessionCommand =
    useCreateEditPhoneVerificationSessionCommand();

  const confirmEditPhoneVerificationSessionCommand =
    useConfirmEditPhoneVerificationSessionCommand();

  const handleSubmit = ({
    phoneNumber,
    callingCode
  }: {
    phoneNumber: string;
    callingCode: string;
  }) => {
    createEditPhoneVerificationSessionCommand.mutate(
      {
        phone: phoneNumber,
        countryCode: callingCode
      },
      {
        onSuccess: (data) => {
          setShowAddPhoneModal(false);
          setNewPhoneNumber(phoneNumber);
          setNewCallingCode(callingCode);
          setVerificationSession(data);
        }
      }
    );
  };

  const handleResendCode = () => {
    handleSubmit({
      phoneNumber: newPhoneNumber,
      callingCode: newCallingCode
    });
  };

  const handleConfirm = (code: string) => {
    if (!createEditPhoneVerificationSessionCommand.data.verificationSessionId) {
      return;
    }

    confirmEditPhoneVerificationSessionCommand.mutate(
      {
        smsCode: code,
        verificationSessionId:
          createEditPhoneVerificationSessionCommand.data.verificationSessionId
      },
      {
        onSuccess: () => {
          reset();
          refetchUser();
          toast.success('Phone Number Updated');

          onSuccess();
        }
      }
    );
  };

  const reset = () => {
    setVerificationSession(null);

    createEditPhoneVerificationSessionCommand.reset();
    confirmEditPhoneVerificationSessionCommand.reset();

    onClose();
  };

  if (isFetchingUser) {
    return <FieldSkeleton />;
  }

  return (
    <>
      {showAddPhoneModal && (
        <AddUpdatePhoneNumberModal
          phone={user?.phone}
          errorMessage={
            createEditPhoneVerificationSessionCommand.error?.message
          }
          isLoading={createEditPhoneVerificationSessionCommand.isLoading}
          onSubmit={handleSubmit}
          onClose={reset}
        />
      )}

      {verificationSession && (
        <ConfirmPhoneNumberModal
          errorMessage={
            confirmEditPhoneVerificationSessionCommand.error?.message ||
            createEditPhoneVerificationSessionCommand.error?.message
          }
          isLoading={confirmEditPhoneVerificationSessionCommand.isLoading}
          isResendingCode={createEditPhoneVerificationSessionCommand.isLoading}
          onSubmit={handleConfirm}
          onClose={reset}
          onResendCode={handleResendCode}
        />
      )}
    </>
  );
};

export default AddUpdatePhoneNumberFlow;
