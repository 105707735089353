import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/searchActions';
import { types as userTypes } from 'legacy/actions/userActions';
import { toActionID } from '../util/util';

export const initialState = {
  results: [],
  loading: _.mapValues(types, () => false),
  errors: _.mapValues(types, () => ({}))
};

// Allow specific sister types from other actions
const SISTER_TYPES = [userTypes.LOGOUT.success];

export function searchReducer(state = initialState, action) {
  const actionID = toActionID(action.type);
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
  }
  switch (action.type) {
    case userTypes.LOGOUT.success:
      return initialState;
    case types.SEARCH_ALL.success:
      return {
        ...newState,
        results: action.payload
      };
    default:
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: searchReducer };
