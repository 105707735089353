import React from 'react';
import { IoIosArrowRoundBack } from 'react-icons/io';
import classNames from 'classnames';

interface Props {
  onClick?: React.MouseEventHandler;
  left?: number;
}

const BackButton: React.FC<Props> = ({ onClick, left }) => (
  <IoIosArrowRoundBack
    className={classNames(
      'text-4xl text-slate-500 cursor-pointer rounded-full hover:bg-slate-50',
      left != null && `absolute left-${left}`
    )}
    onClick={onClick}
  />
);

export default BackButton;
