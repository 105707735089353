import { useDebounce } from 'use-debounce';

import { useGroupsSearch } from 'legacy/search/hooks';

import Message from './Message';
import ResultList from './ResultList';

type GroupsProps = {
  input: string;
};

const Groups: React.FC<GroupsProps> = ({ input }) => {
  const [debouncedInput] = useDebounce(input, 200);
  const isDebouncing = input !== debouncedInput;

  const {
    data: groups,
    isLoading,
    refetch,
    isFetching
  } = useGroupsSearch(debouncedInput);

  if (!debouncedInput && !isDebouncing) {
    return <Message message="Search for organizations" />;
  }

  if (debouncedInput && !isLoading && !isDebouncing && !groups?.length) {
    return <Message message={`No results for "${debouncedInput}"`} />;
  }

  return (
    <ResultList
      id="results-events"
      isLoading={isLoading || isDebouncing || isFetching}
      refetch={refetch}
      results={groups?.map((group) => ({
        id: group.id,
        image: group.badgeImageUrl || '/img/search/event.svg',
        value: group.name,
        subtitle: group.description,
        linkTo: `/organizations/${group.id}`,
        rawData: {
          ...group,
          type: 'group'
        }
      }))}
    />
  );
};

export default Groups;
