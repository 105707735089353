import moment from 'moment';
import { get, isEmpty } from 'lodash';
import {
  showModal,
  clearHistory,
  hideModal,
  clearModal
} from '../actions/modalActions';
import { store } from '../store';

import storage, { sessionStorage } from '../common/utils/storage';
import { STORAGE_KEYS } from '../common/constants/storage';

import * as toast from '../common/utils/toast';

/**
 * Create a chain of modals where the successful completion of one modal leads
 * to the next modal being opened
 *
 * @param {Array<Object>} modals
 */
function showModalSeries(modals) {
  for (let i = modals.length - 2; i >= 0; i--) {
    modals[i].props = modals[i].props || {};
    const currentOnSuccess = modals[i].props.onSuccess;
    const showNextModal = () =>
      store.dispatch(showModal(modals[i + 1].type, modals[i + 1].props));
    if (currentOnSuccess) {
      modals[i].props.onSuccess = (...args) => {
        showNextModal();
        currentOnSuccess(...args);
      };
    } else {
      modals[i].props.onSuccess = showNextModal;
    }
  }
  store.dispatch(showModal(modals[0].type, modals[0].props));
}

/**
 * Modal flow for sending a p2p transfer.  Requires a credit card.
 *
 * @export
 * @param {Function?} onFulfilledRequirements
 */
export function sendMoneyFlow(onFulfilledRequirements) {
  const { cards } = store.getState().payment;
  const defaultCard = cards.find((c) => c.default);
  if (defaultCard) {
    onFulfilledRequirements();
  } else {
    showModalSeries([
      {
        type: 'ALERT',
        props: {
          message: 'You must register a credit card before sending a transfer.',
          actionText: 'Add Card',
          type: 'error'
        }
      },
      {
        type: 'ADD_PAYMENT_METHOD',
        props: { onSuccess: () => store.dispatch(clearHistory()) }
      },
      {
        type: 'ALERT',
        props: {
          message: 'Successfully added a new card.',
          actionText: `Send Money`,
          onSuccess: onFulfilledRequirements,
          type: 'success'
        }
      }
    ]);
  }
}

/**
 * Modal flow for creating a new event.  Requires a bank account
 *
 * @export
 * @param {Function?} onFulfilledRequirements
 */
export function hostEventFlow(onFulfilledRequirements) {
  const { banks } = store.getState().connect;
  if (banks.length > 0) {
    onFulfilledRequirements();
  } else {
    showModalSeries([
      {
        type: 'ALERT',
        props: {
          message: 'You must register a bank account before hosting an event.',
          actionText: 'Add Bank'
        }
      },
      {
        type: 'ADD_BANK',
        props: { onSuccess: () => store.dispatch(clearHistory()) }
      },
      {
        type: 'ALERT',
        props: {
          message: 'Successfully created new bank account.',
          actionText: 'Create Event',
          onSuccess: onFulfilledRequirements
        }
      }
    ]);
  }
}

/**
 * Modal flow to add card on event checkout flow.
 */
export function addCardOnCheckoutFlow(onAddPaymentMethod, emailOptions) {
  showModalSeries([
    {
      type: 'ADD_PAYMENT_METHOD',
      props: {
        emailOptions,
        onSuccess: (cards) => {
          store.dispatch(hideModal());
          store.dispatch(clearHistory());
          store.dispatch(clearModal());

          if (onAddPaymentMethod) {
            onAddPaymentMethod(cards);
          }

          toast.success(
            'Your card has been successfully added to your profile.'
          );
        }
      }
    }
  ]);
}

/**
 * Modal flow for providing extra verification info to Stripe
 * @param {Object} connectVerification
 */
export function connectVerificationFlow(connectVerification) {
  const hasReminded = sessionStorage.getItem(
    STORAGE_KEYS.COMPLETE_PROFILE_REMINDERS
  );

  if (hasReminded || !connectVerification) {
    return;
  }

  const currentlyDue = get(connectVerification, 'fieldsNeeded', []);
  const pastDue = get(connectVerification, 'overdueFields', []);
  if (
    currentlyDue.includes('individual.id_number') ||
    currentlyDue.includes('individual.verification.document') ||
    pastDue.includes('individual.id_number')
  ) {
    sessionStorage.setItem(STORAGE_KEYS.COMPLETE_PROFILE_REMINDERS, 'true');
    let message = `In order to meet Canadian rules & regulations, we must verify your SIN number.
        Your payouts will be paused if you do not successfully verify this information.`;

    // Provide a different message if the information is past due.
    if (pastDue.includes('individual.id_number')) {
      message = `Your payouts were paused because we have not yet verified your SIN number.
            We will unpause your payouts as soon as you verify this info.`;
    }
    showModalSeries([
      {
        type: 'ALERT',
        props: {
          message,
          actionText: 'Verify SIN'
        }
      },
      {
        type: 'CONNECT_VERIFICATION'
      },
      {
        type: 'ALERT',
        props: {
          message:
            'Thanks for verifying your info!  It may take a few days for this to be processed.',
          actionText: 'Got It'
        }
      }
    ]);
  }
}

/**
 * Flow to add/verify a phone number.
 * @param {*} user logged in user
 */
export function addPhoneFlow(user, orgId) {
  if (user.phoneVerified) {
    return;
  }

  // Make user confirm that they want to cancel
  // adding a phone once they have started
  const confirmClose = () => {
    store.dispatch(
      showModal('CONFIRMATION', {
        destructive: true,
        message: 'Are you sure you want to cancel your phone verification?',
        cancelText: 'No',
        onCancel: () => addPhoneFlow(user) // Re-open this flow
      })
    );
  };
  const allModals = [
    {
      type: 'ADD_PHONE',
      props: {
        onRequestClose: confirmClose,
        orgId
      }
    },
    {
      type: 'VERIFY_PHONE',
      props: {
        onRequestClose: confirmClose,
        orgId
      }
    },
    { type: 'VERIFY_PHONE_SUCCESS', props: { orgId } }
  ];
  if (!user.phone) {
    showModalSeries(allModals);
  } else if (!user.phoneVerified) {
    showModalSeries(allModals.slice(1));
  }
}

/**
 * Remind users to fill out their profile information
 * @param {*} user logged in user
 * @param {Object} history history object
 * @deprecated
 */
export function completeProfileReminderFlow(user, router) {
  // Only show reminder if user's interests, education, and gallery are all empty
  if (
    !isEmpty(user.profile.interests) ||
    !isEmpty(user.profile.education) ||
    !isEmpty(user.profile.gallery)
  ) {
    return;
  }
  // Define constants
  const NOTIFICATION_TIMEOUT_HOURS = 48;

  // Get existing user's reminder history on this device/browser,
  // or use the default.
  const reminderHistory =
    storage.getItem(STORAGE_KEYS.COMPLETE_PROFILE_REMINDERS) || '{}';
  const defaultReminderHistory = {
    lastNotified: null,
    notificationCount: 0, // Currently unused, could use to tailor cool-off period, etc.
    shouldNotify: true
  };
  const userHistory = reminderHistory[user._id] || defaultReminderHistory;

  // Only notify the user if enough time has lapsed since their last
  // reminder, AND if they haven't asked us not to.
  const { shouldNotify, lastNotified } = userHistory;
  if (!shouldNotify) {
    return;
  }

  const now = moment();
  const durationSinceLastNotified = lastNotified
    ? now.diff(moment(lastNotified), 'hours')
    : Infinity;

  if (durationSinceLastNotified >= NOTIFICATION_TIMEOUT_HOURS) {
    // Update localStorage with most recent notification.
    userHistory.lastNotified = now.toISOString();

    userHistory.notificationCount += 1;
    reminderHistory[user._id] = userHistory;
    storage.setItem(STORAGE_KEYS.COMPLETE_PROFILE_REMINDERS, reminderHistory);

    // Stop future reminders if the user asks us to.
    const stopFutureReminders = () => {
      reminderHistory[user._id] = { shouldNotify: false };
      storage.setItem(STORAGE_KEYS.COMPLETE_PROFILE_REMINDERS, reminderHistory);
    };

    const modalProps = {
      title: 'Fill in your profile?',
      message: `Filling in your profile will allow other users to get to know you. Don’t worry, you can also control who will see this info.`,
      actionText: 'Fill Out Profile',
      onSuccess: () => {
        router.push('/profile?view=settings');
        store.dispatch(hideModal());
      },
      cancelText: "Don't Ask Again",
      onCancel: stopFutureReminders
    };

    store.dispatch(showModal('CONFIRMATION', modalProps));
  }
}

/**
 * Flow to display QuickView modal (show QuickView if self != user, else redirect to user's own profile)
 * @param {String} userId user whose profile is to be displayed
 * @param {String} selfId logged in user
 * @param {Object} router router object
 */
export function showQuickView(userId, selfId, router) {
  if (selfId === userId) {
    router.push(`/profile/${selfId}`);
    store.dispatch(hideModal());
  } else {
    store.dispatch(
      showModal('QUICKVIEW_PROFILE', {
        userId
      })
    );
  }
}
