import React from 'react';
import composeClass from 'classnames';

import { useRouter } from 'next/router';
import Link from 'next/link';
import styles from './NavLink.module.scss';
import { useDesktopOnlyHover } from '../../../util/hooks';

// NavLink connects to React Router, gives the 'active' class to the component
export const NavLink = ({ id, activeHref, children }) => {
  const router = useRouter();
  const { pathname } = router;

  const [hover, handleMouseEnter, handleMouseLeave] = useDesktopOnlyHover();

  const isActive = pathname === activeHref;

  return (
    <Link href={activeHref} className={styles.wrapper} data-testid="navlink">
      <div
        id={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={composeClass('nav', {
          active: isActive,
          hover,
          'underline underline-offset-4': isActive
        })}
      >
        {children}
      </div>
    </Link>
  );
};

export default NavLink;
