import React from 'react';
import Link from 'next/link';

import { saveSearchItem } from '../service';
import ResultItemOrg from './ResultItemOrg';

import styles from './ResultList.module.scss';

export function ResultItem({ result, saveHistory, onDelete, refetch }) {
  if (result.rawData?.type === 'group') {
    return (
      <ResultItemOrg
        result={result}
        onDelete={onDelete}
        saveHistory={saveHistory}
        refetch={refetch}
      />
    );
  }

  return (
    <div className={styles.itemContainer}>
      <div className="flex-1">
        <Link
          href={result.linkTo}
          key={result.id}
          className={styles.listItem}
          onClick={() => {
            if (saveHistory) {
              saveSearchItem(result.rawData);
            }
          }}
        >
          <img className={styles.pic} src={result.image} />
          <div className={styles.content}>
            <p>{result.value}</p>
            {!!result.subtitle && <span>{result.subtitle}</span>}
          </div>
          {onDelete && (
            <img
              data-id="delete-button"
              src="/img/icons/cross.svg"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                onDelete(result.id);
              }}
            />
          )}
        </Link>
      </div>
    </div>
  );
}
