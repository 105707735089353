import { Event, EventPricingTier } from 'legacy/common/types/models';
import { Waiver } from 'legacy/components/pages/Events/EventForm/Groups/Waiver/EventWaiverModal';
import { GroupDto } from 'legacy/groups/types';
import { FriendStatus, LeanUserDto, UserLean } from 'legacy/user/types';
import { SurveyModel } from 'survey-core';

type ContactHostBody = {
  name: string;
  email: string;
  message: string;
};

export interface ContactHostOptions {
  eventId: string;
  details: ContactHostBody;
}

type TicketBasket = Record<string, number>;

interface CheckoutPricingDto {
  price: number;
  discount: number;
  feesBreakdown: {
    processingFees: number;
    bounceFees: number;
    eventOrganizerFees: number;
  };
  taxes: number;
  totalFees: number;
}

interface CheckoutSessionDiscountCodeDto {
  id: string;
  code: string;
  discount: {
    kind: 'amount' | 'percentage';
    value: number;
  };
}

export interface MultiTierCheckoutSession {
  pricing: CheckoutPricingDto;
  paymentIntentId: string;
  ticketBasket: TicketBasket;
  currency: string;
  card: string;
  discountCode?: CheckoutSessionDiscountCodeDto;
  total: number;
}

export interface CheckoutSessionUpdateParams {
  ticketBasket: Record<string, number>;
  card?: string;
  discountCode?: string;
  tierShareTokens?: string[];
}

export type CheckoutUpdateDataToSend = {
  ticketTiers: Record<string, { quantity: number }>;
  card: string;
  discountCode: string;
  privateTierCodes: Record<string, string>;
};

export interface UpdateCheckoutSessionParams {
  eventId: string;
  paymentIntentId: string;
  body: {
    card: string;
    tierId: string;
    tierShareToken?: string;
    tip: number;
    numberOfTickets: number;
    discountCode: string;
  };
}

export interface UpdateMultiTierCheckoutSessionParams {
  eventId: string;
  paymentIntentId: string;
  body: CheckoutSessionUpdateParams;
}

export type UpdateUnauthenticatedCheckoutSessionParams = Omit<
  UpdateCheckoutSessionParams,
  'body'
> & {
  body: Omit<UpdateCheckoutSessionParams['body'], 'card'>;
};

export type UpdateUnauthenticatedMultiTierCheckoutSessionParams =
  UpdateMultiTierCheckoutSessionParams;

export interface ConfirmCheckoutSessionParams {
  eventId: string;
  paymentIntentId: string;
  isWaiverAccepted?: boolean;
  trackingId?: string;
}

export interface RequestApprovalParams {
  eventId: string;
  tierId: string;
  answers: { questionId: string; answer: string }[];
}

export interface FormattedPricing {
  price: string;
  discount: string;
  fees: string;
  feesBreakdown: {
    eventOrganizerFees: string;
    processingFees: string;
    bounceFees: string;
    totalFees: string;
  };
  total: string;
  tip?: string;
  taxes: string;
}

export interface CheckoutSession {
  pricing: FormattedPricing;
  paymentIntentId: string;
  card: string;
  tierId: string;
  tip: number;
  currency: string;
  numberOfTickets?: number;
  discountCode?: DiscountCodeDto;
}

export type StartCheckoutFn = (eventId: string) => void;

export type UpdateCheckoutFn = (
  updateData: CheckoutUpdateDataToSend
) => Promise<MultiTierCheckoutSession>;

export interface EventBasic {
  _id: string;
  title: string;
  startDate: string;
  seriesId?: string;
  endDate: string;
  useNewCheckout: boolean;
  photo: {
    url?: string;
    position?: 'center' | 'wide';
  };
}

export interface EventLean extends EventBasic {
  host: UserLean;
  totalGuests?: number;
}

export type EventGuest = UserLean & {
  id?: string;
  photoUrl?: string;
  friendStatus: FriendStatus;
};

/**
 * `event.tiers[]` element returned for public (users who are not event organizers)
 */
export type EventTierForPublic = Pick<
  EventPricingTier,
  | '_id'
  | 'name'
  | 'description'
  | 'price'
  | 'private'
  | 'status'
  | 'remaining'
  | 'isDisabled'
  | 'startDate'
  | 'endDate'
  | 'imageUrl'
  | 'requiredAssociationIds'
  | 'requiredOrganizationIds'
>;

/**
 * `event.tiers[]` element returned for organizers (host, cohost, operator)
 */
export type EventTierForOrganizer = EventTierForPublic &
  Pick<EventPricingTier, 'shareToken' | 'maxAttendance' | 'totalGuests'>;

/**
 * Populated event organizers
 * This includes `event.host`, `event.cohosts`, `event.operators`, `event.scanners`, and `event.photographers`.
 */
export type EventOrganizers = {
  host: UserLean;
  cohosts?: UserLean[];
  operators: UserLean[];
  scanners: UserLean[];
  photographers: UserLean[];
};

/**
 * Event returned for public (users who are not event organizers)
 */
export type EventForPublic = EventBasic &
  EventOrganizers &
  Pick<
    Event,
    | 'description'
    | 'address'
    | 'coordinates'
    | 'gallery'
    | 'totalGuests'
    | 'remainingGuests'
    | 'ticketNotesRequest'
    | 'googleForms'
    | 'endDate'
    | 'private'
    | 'status'
    | 'pricing'
    | 'showGuests'
    | 'maxAttendance'
    | 'userStatus'
    | 'activeStatus'
    | 'isFree'
    | 'canEdit'
    | 'canDelete'
    | 'isOrganizer'
    | 'isScanner'
    | 'isPhotographer'
    | 'createdAt'
    | 'maxTicketsPerTransaction'
    | 'metaPixelId'
    | 'waiver'
    | 'isDraft'
    | 'updatedAt'
    | 'album'
    | 'requestToPublishFormId'
  > & {
    guestPreview?: EventGuest[];
    /**
     * @deprecated use `guestPreview` instead
     */
    friendsAttending?: EventGuest[];
    /** only returned on nearby events queries */
    distance?: number;
    groups?: GroupDto[];
    waivers?: Waiver[];
  };

/**
 * Event returned for operators
 */
export type EventForOperator = EventForPublic &
  Pick<
    Event,
    | 'shareToken'
    | 'requestListSummary'
    | 'shouldRequestToPublish'
    | 'requestToPublishFormId'
  >;

/**
 * Event returned for hosts or cohosts
 */
export type EventForHost = EventForOperator &
  Pick<Event, 'payoutStatus' | 'lastVerificationNotif'> & {
    payoutDestination?: EventPayoutDestination;
    canRequestEarlyPayout?: boolean;
    availablePayoutDestinations: {
      hostBanks: PayoutBank[];
      associationsBanks: PayoutBank[];
    };
  };

export type EventForUser = EventForHost | EventForOperator | EventForPublic;

export type EventSummary = {
  totals: {
    payoutAmount: number;
    guests: number;
    ticketsSold: number;
    scannedGuests: number;
    unclaimedTickets: number;
  };
  payoutCurrency: 'CAD' | 'USD';
};

export type EventAnalytics = {
  discountCodes: {
    activeCount: number;
    pausedCount: number;
    revenue: number;
  };
  tickets: {
    addon: { sales: number; scans: number };
    admission: { sales: number; scans: number };
    revenue: number;
    sales: number;
    views: number;
  };
  trackingLinks: {
    count: number;
    revenue: number;
    sales: number;
    views: number;
  };
};

export type EventTierCreation = {
  id?: string;
  name: EventPricingTier['name'];
  description?: EventPricingTier['description'];
  price: EventPricingTier['price'];
  private?: EventPricingTier['private'];
  maxAttendance?: EventPricingTier['maxAttendance'] | string;
  numberToDuplicate?: number;
  isDisabled?: boolean;
  requiresApproval?: boolean;
  questions?: { question: string; _id: string }[];
  isPrivate?: boolean;
  shareToken?: string;
};

export interface VerifyTicketResultDto {
  answers: { questionId: string; question: string; answer: string }[];
  tierRequestAnswers: TierApprovalAnswer[];
  transaction: {
    amount: number;
    currency: 'CAD' | 'USD';
    user: {
      id: string;
      firstName: string;
      lastName: string;
      name: string;
      photoUrl?: string;
    };
    tier: {
      id: string;
      name: string;
    };
  };
  hasAlreadyScanned: boolean;
  numberOfUnclaimedTickets: number;
  scanDate: string;
}

export interface LeanEventDto {
  id: string;
  title: string;
  address: string;
  photoUrl?: string;
  startDate: Date;
  endDate?: Date;
}

export interface DiscountCodeType {
  type: string;
}

export interface RequiredEventRating {
  event: LeanEventDto;
}

export interface RequiredEventRatingsDto {
  requiredRatings: RequiredEventRating[];
}

export enum Impression {
  GOOD = 'good',
  OK = 'ok',
  BAD = 'bad'
}

export interface SubmitEventRatingBody {
  overall?: {
    score?: number;
  };
  safety?: {
    explanation?: string;
    impression?: Impression;
  };
}

export interface SuggestedFriendsFromEventDto {
  eventId: string;
  suggestedFriends: SuggestedFriendDto[];
}

export interface SuggestedFriendDto {
  score: number;
  user: LeanUserDto & { friendStatus: FriendStatus };
}

export interface TicketInfoRequiredResponse {
  googleForms: {
    required: boolean;
    url?: string;
  };
  ticketNotes: {
    required: boolean;
    guestQuestions?: { id: string; question: string }[];
  };
}

export interface AnalyticsDto {
  views: number;
  sources: { source: string; views: number }[];
  trackingLinks: {
    id: string;
    name: string;
    pathname: string;
    views: number;
    sales: number;
    revenue: number;
    sources: {
      source: string;
      views: number;
      sales: number;
      revenue: number;
    }[];
  }[];
}

export interface DiscountCodeDto {
  id: string;
  eventId: string;
  code: string;
  discount: {
    kind: 'amount' | 'percentage';
    value: number;
  };
  numberOfUses: number;
  isActive: boolean;
  maxUsage?: number;
  startDate?: Date;
  endDate?: Date;
  createdAt: string;
  removedAt?: string;
}

export interface DiscountCodeAnalyticsDto {
  discountCodeId: string;
  revenue: number;
}

export interface EventDiscountCodesAnalyticsDto {
  activeCount: number;
  pausedCount: number;
  totalRevenue: number;
}

export interface EventDiscountCodesDto {
  eventId: string;
  analytics: EventDiscountCodesAnalyticsDto;
  discountCodes: Array<DiscountCodeDto>;
}

export interface EventWaiverDto {
  isWaiverSet: boolean;
  waiverContent?: string;
  waivers?: Waiver[];
}
export type TierScreeningQuestion = {
  id?: string;
  question: string;
};

export enum TierTypes {
  Admission = 'admission',
  Addon = 'addon'
}

export type TierDto = {
  id: string;
  type: TierTypes;
  name: string;
  description?: string;
  price: number;
  isPrivate: boolean;
  shareToken?: string;
  isDisabled: boolean;
  requiresApproval: boolean;
  totalGuests: number;
  maxAttendance?: number;
  questions?: TierScreeningQuestion[];
  requiredOrganizationIds: string[];
  requiredAssociationIds: string[];
  imageUrl?: string;
  startDate?: string;
  endDate?: string;
  canDelete: boolean;
};

export type RequestToPublishFormDto = {
  id: string;
  schema: SurveyModel;
};

export type SubmitRequestToPublishFormDto = {
  formId: string;
  id: string;
  response: {
    [key: string]: unknown;
  };
  userId: string;
};

export type CreateTierScreeningQuestion = {
  question: string;
};

export type UpdateTierScreeningQuestion = {
  id?: string;
  question: string;
};

export type UpdateTierDto = Pick<
  TierDto,
  | 'type'
  | 'name'
  | 'description'
  | 'price'
  | 'isPrivate'
  | 'maxAttendance'
  | 'isDisabled'
  | 'requiresApproval'
  | 'questions'
  | 'imageUrl'
  | 'startDate'
  | 'endDate'
> & {
  questions?: UpdateTierScreeningQuestion[];
};

export type CreateTierDto = Pick<
  TierDto,
  | 'type'
  | 'name'
  | 'description'
  | 'price'
  | 'isPrivate'
  | 'maxAttendance'
  | 'isDisabled'
  | 'requiresApproval'
  | 'imageUrl'
  | 'shareToken'
  | 'startDate'
  | 'endDate'
> & {
  questions?: CreateTierScreeningQuestion[];
};

export type CreateWaiverDto = {
  title: string;
  content: string;
};

export type UpdateWaiverDto = {
  title: string;
  content: string;
};

export type WaiverDto = {
  id: string;
  title: string;
  content: string;
};

export type CreateDiscountCodeBody = Pick<
  DiscountCodeDto,
  'code' | 'discount' | 'maxUsage' | 'startDate' | 'endDate'
>;

export interface CreateDiscountCodeParams {
  eventId: string;
  body: CreateDiscountCodeBody;
}

export type UpdateDiscountCodeBody = Pick<
  DiscountCodeDto,
  'code' | 'discount' | 'maxUsage' | 'startDate' | 'endDate' | 'isActive'
>;

export interface UpdateDiscountCodeParams {
  eventId: string;
  discountCodeId: string;
  body: UpdateDiscountCodeBody;
}

export interface DeleteDiscountCodeParams {
  eventId: string;
  discountCodeId: string;
}

export interface TextAllGuestsDto {
  message: string;
}

export interface TextAllGuestsParams {
  tierId?: string;
  eventId: string;
  message: string;
}

export interface PutPayoutDestinationParams {
  bankId?: string;
  eventId: string;
}

export type EventPayoutDestination = {
  stripeId: string;
  last4: string;
  country: 'CA' | 'US';
  type: 'bank' | 'card';
  institution: string;
  name?: string;
  holderName?: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export interface PostEarlyPayoutParams {
  eventId: string;
}

export type PayoutBank = {
  id: string;
  stripeId: string;
  bankName: string;
  last4: string;
  label?: string;
};

export interface UploadPhotoResponseDto {
  photo: any;
}

export interface UploadVideoResponseDto {
  hypeVideoUrl: string;
}

export interface CreateUploadVideoUrlResponseDto {
  uploadUrl: string;
  videoId: string;
}

type EventDiscountCode = {
  eventId: string;
  code: string;
  isActive: boolean;
  maxUsage?: number;
  discount: {
    kind: 'amount' | 'percentage';
    value: number;
  };
  startDate?: string;
  endDate?: string;
  id?: string;
};

export type EventCreation = {
  title?: string;
  address?: string;
  description?: string;
  groups?: string[];
  cohosts?: string[];
  operators?: string[];
  scanners?: string[];
  photographers?: string[];
  showGuests?: boolean;
  photo?: {
    url?: string;
    position?: 'center' | 'wide';
  };
  /** website formprops sometimes send as a string, so we accept both (mongoose handles casting) */
  private?: boolean;
  video?: {
    enabled?: boolean;
    type?: 'group' | 'webinar' | 'custom';
    customUrl?: string;
  };
  startDate?: string;
  endDate?: string;
  googleForms?: {
    url?: string;
    embedUrl?: string;
  };
  ticketNotesRequest?: string;
  coordinates?: number[];
  pricing?: {
    tiers: EventPricingTier[];
    currency: 'CAD' | 'USD';
    transactionFeesPaidBy?: 'host' | 'guest';
    tips: {
      areEnabled: boolean;
      label?: string;
    };
    tax?: {
      taxId: string;
      name: string;
      ratePercent: number;
    };
  };
  maxTicketsPerTransaction?: number;
  discountCodes?: EventDiscountCode[];
  waiver?: {
    content: string;
  };
  waivers?: Waiver[];
  chargeOrganizationFee?: boolean;
  momentsPermissions?: {
    createMoments: 'admin' | 'member';
    commentMoments: 'admin' | 'member';
  };
  metaPixelId?: string;
};

type EventTaxCreation = {
  taxId?: string;
  name?: string;
  ratePercent?: number;
};

type EventTipsCreation = {
  areEnabled?: boolean;
  label?: string;
};

type EventPricingCreation = {
  tips?: EventTipsCreation;
  transactionFeesPaidBy?: 'host' | 'guest';
  tiers: EventTierCreation[];
  tax?: EventTaxCreation;
  currency?: 'CAD' | 'USD';
};

type EventPricingEdit = Partial<
  // TODO: Also omit 'transactionFeesPaidBy' once the frontend stops sending it.
  Omit<EventPricingCreation, 'tiers'> & {
    /** NOTE: we allow `any` here since the frontend sends both existing and new tiers when editing events.
     * We should probably fix this in the frontend so that it only sends relevant properties and we can use stronger typing here.
     */
    tiers: (EventTierCreation | any)[];
  }
>;

export type EventEdit = Partial<
  Pick<
    EventCreation,
    | 'title'
    | 'description'
    | 'startDate'
    | 'endDate'
    | 'address'
    | 'coordinates'
    | 'private'
    | 'cohosts'
    | 'operators'
    | 'scanners'
    | 'photographers'
    | 'groups'
    | 'showGuests'
    | 'ticketNotesRequest'
    | 'googleForms'
    | 'video'
    | 'maxTicketsPerTransaction'
    | 'metaPixelId'
    | 'discountCodes'
    | 'waiver'
    | 'chargeOrganizationFee'
    | 'momentsPermissions'
    | 'photo'
    | 'waivers'
  > & {
    pricing: EventPricingEdit;
  } & {
    isDraft: boolean;
  }
>;

export interface TierApprovalAnswer {
  questionId: string;
  question?: string;
  answer: string;
}

export interface TierApprovalTier {
  id: string;
  name: string;
}

export interface TierApprovalUser {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  email: string;
}

export interface TierApproval {
  id: string;
  tier: TierApprovalTier;
  status: 'approved' | 'rejected' | 'pending';
  readStatus: 'read' | 'unread';
  answers: TierApprovalAnswer[];
  user: TierApprovalUser;
  createdAt: Date;
  updatedAt: Date;
}

export interface TierApprovals {
  pages: any;
  items: TierApproval[];
}

export interface GuestEntryUser {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl?: string;
}

export interface GuestEntryTier {
  id: string;
  name: string;
}

export interface EntryLogItem {
  type: 'scanned' | 'admitted';
  date: Date;
  user?: GuestEntryUser;
}

export interface GuestEntryListItem {
  id: string;
  type: 'ticket' | 'complimentary';
  name?: string;
  user?: GuestEntryUser;
  tier?: GuestEntryTier;

  latestEntry?: EntryLogItem;

  isScanned: boolean;

  scanDate: Date;
}

export interface EventQuestion {
  questionId: string;
  question: string;
  answer: string;
}

export interface GuestEntryDto {
  id: string;
  type: 'ticket' | 'complimentary';
  name?: string;
  user?: GuestEntryUser;
  purchaser?: GuestEntryUser;
  purchasedAt?: Date;
  tier?: GuestEntryTier;
  createdBy?: GuestEntryUser;
  createdAt?: Date;
  entryLog: EntryLogItem[];
  screeningQuestions?: EventQuestion[];
  guestQuestions?: EventQuestion[];
}

export interface GuestEntryList {
  pages: any;
  items: GuestEntryListItem[];
}

export interface MomentDto {
  id: string;
  media: MediaDto[];
  metadata: {
    numberOfComments: number;
    numberOfReactions: number;
    recentReactions: ReactionDto[];
  };
  permissions: {
    canComment: boolean;
    canEdit: boolean;
  };
  caption: string;
  creator: MomentCreatorDto;
  createdAt: Date;
  updatedAt: Date;
}

export interface MediaDto {
  url?: string;
  downloadUrl?: string;
  videoId?: string;
  thumbnail?: string;
  type: string;
}

export interface MomentCreatorDto {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
}

export type MomentUploadType = 'professional' | 'consumer';

export interface CreateMomentMetadata {
  uploadType: MomentUploadType;
}

export interface CreateMoment {
  media: CreateMomentMedia[];
  caption?: string;
  metadata?: CreateMomentMetadata;
}

export interface CreateMomentMedia {
  url: string;
  type: 'image' | 'video';
}

export interface ReactionDto {
  id: string;
  type:
    | 'heart'
    | 'fire'
    | 'money_mouth_face'
    | 'call_me_hand'
    | 'rolling_on_the_floor_laughing'
    | 'party_face';
  creator: ReactionCreator;
}

export interface ReactionCreator {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl?: string;
  friendStatus?: FriendStatus;
}

export enum RecurringFrequency {
  Weekly = 'weekly',
  BiWeekly = 'biweekly',
  Monthly = 'monthly'
}

export const RECURRING_FREQUENCY_LABELS = {
  [RecurringFrequency.Weekly]: 'Weekly',
  [RecurringFrequency.BiWeekly]: 'Bi-Weekly',
  [RecurringFrequency.Monthly]: 'Monthly'
};

export interface RecurringEventDto {
  canEdit: boolean;
  seriesId: string;
  startDate: string;
  endDate: string;
  frequency: RecurringFrequency;
  eventIds: string[];
}
