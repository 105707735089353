import React, { useState, useEffect } from 'react';
import styles from '../AddPhone.module.scss';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import { Popup } from 'semantic-ui-react';
import TelInput from 'react-intl-tel-input';
import BounceButton from '../../../util/BounceButton/BounceButton';
import { SUPPORTED_COUNTRIES } from '../../../../util/constants';

const EnterPhoneForm = ({
  loading,
  errors,
  user,
  onSuccess,
  onSubmitNumber,
  submitActionType: SUBMIT_ACTION_TYPE,
  titleText = 'Add a Phone Number',
  submitText = 'ADD PHONE'
}) => {
  const phone = user.phone;

  // If the user has a phone, this step was successful
  useEffect(() => {
    if (phone && onSuccess) {
      onSuccess();
    }
  }, [phone]);

  const [number, setNumber] = useState('');
  const [isValidNumber, setIsValidNumber] = useState(false);
  const submit = () => {
    if (number) {
      let country = '1';
      const selected = document.querySelector('.selected-flag');
      if (selected.title) {
        country = selected.title.split('+')[1];
      }
      // Remove all non-numeric characters
      const parsedNumber = number.replace(/\D/g, '');
      const fullNumber = country + parsedNumber;
      onSubmitNumber(fullNumber);
    }
  };

  // Auto-submit when the phone number if a valid one.
  useEffect(() => {
    if (isValidNumber) {
      submit();
    }
  }, [isValidNumber]);

  const handlePhoneChange = (valid, value) => {
    if (valid && !isValidNumber) {
      setIsValidNumber(true);
    } else if (isValidNumber) {
      setIsValidNumber(false);
    }
    setNumber(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  return (
    <div className={classnames(styles.AddPhone, 'component')}>
      <h2 className="modalTitle">{titleText}</h2>
      <img src="/img/illustrations/addPhone.svg" alt="" />
      <p>Phone Number</p>
      <TelInput
        format
        autocomplete="tel"
        defaultCountry={user.country?.toLowerCase() || 'ca'}
        onlyCountries={SUPPORTED_COUNTRIES}
        preferredCountries={[]}
        customPlaceholder={() => '(416) 123-4567'}
        inputClassName={styles.phoneInput}
        fieldName="number"
        telInputProps={{
          onKeyPress: handleKeyPress
        }}
        onPhoneNumberChange={handlePhoneChange}
        value={number}
      />
      <Popup
        open={
          !loading[SUBMIT_ACTION_TYPE] && !isEmpty(errors[SUBMIT_ACTION_TYPE])
        }
        trigger={
          <BounceButton
            className={!number && 'disabled'}
            primary
            loading={loading[SUBMIT_ACTION_TYPE]}
            onClick={submit}
            data-cy="add-phone-button"
          >
            {submitText}
          </BounceButton>
        }
        position="right center"
      >
        {errors[SUBMIT_ACTION_TYPE]?.message}
      </Popup>
    </div>
  );
};

export default EnterPhoneForm;
