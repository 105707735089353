import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useOnRouteChange = (onRouteChange: () => void) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, [router.events]);
};

export default useOnRouteChange;
