import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';

import { useEditMeCommand } from 'legacy/user/hooks';

import * as toast from 'legacy/common/utils/toast';

import Modal, { ModalType } from 'legacy/common/components/modal/Modal';
import Button, { ButtonType } from 'legacy/common/components/button/Button';

const ConfirmPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(8, 'Must be at least 8 characters'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required')
});

const CreatePasswordFlow = ({ onClose, onSuccess }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible);
  };

  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(
      (isConfirmPasswordVisible) => !isConfirmPasswordVisible
    );
  };

  const editMeCommand = useEditMeCommand();

  const handleSubmit = (values: {
    password: string;
    passwordConfirmation: string;
  }) => {
    editMeCommand.mutate(
      {
        password: values.password
      },
      {
        onSuccess: () => {
          toast.success('Password Updated');

          onSuccess();
        }
      }
    );
  };

  return (
    <Modal
      visible
      onClose={onClose}
      type={ModalType.ALERT}
      shouldStopModalClose={editMeCommand.isLoading}
    >
      <div className="px-8 py-6" data-testid="modal-create-password">
        <h1 className="flex-shrink-0 text-2xl text-gray-500 font-normal">
          Create Password
        </h1>
        <p className="mt-4 font-normal text-base text-gray-500 leading-loose">
          Set a password for your account.
        </p>

        {editMeCommand.error?.message && (
          <div className="mt-2 text-red-500">
            {editMeCommand.error?.message}
          </div>
        )}

        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={ConfirmPasswordSchema}
          enableReinitialize
        >
          {({ errors, touched, isValid }) => (
            <Form>
              <div className="mt-6 mb-2">
                <p className="text-sm text-gray-400">Password</p>
              </div>

              <div className="relative">
                <Field
                  id="password"
                  name="password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  data-testid="input-password"
                  placeholder="Enter your password"
                  className="w-full h-full py-2 px-4 border-1 rounded-2xl text-base text-gray-600 placeholder:text-gray-400"
                />
                <div
                  className="absolute top-1 right-3 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <img src="/img/icons/eye.svg" />
                  ) : (
                    <img src="/img/icons/eye-slash.svg" />
                  )}
                </div>
              </div>

              {errors.password && touched.password && (
                <div className="mt-1 text-xs text-right text-red-500">
                  {errors.password}
                </div>
              )}

              <div className="mt-6 mb-2">
                <p className="text-sm text-gray-400">Confirm Password</p>
              </div>

              <div className="relative">
                <Field
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type={isConfirmPasswordVisible ? 'text' : 'password'}
                  data-testid="input-password-confirmation"
                  placeholder="Re-enter your password"
                  className="w-full h-full py-2 px-4 border-1 rounded-2xl text-base text-gray-600 placeholder:text-gray-400"
                />
                <div
                  className="absolute top-1 right-3 cursor-pointer"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {isConfirmPasswordVisible ? (
                    <img src="/img/icons/eye.svg" />
                  ) : (
                    <img src="/img/icons/eye-slash.svg" />
                  )}
                </div>
              </div>

              {errors.passwordConfirmation && touched.passwordConfirmation && (
                <div className="mt-1 text-xs text-right text-red-500">
                  {errors.passwordConfirmation}
                </div>
              )}

              <div className="pl-20 mt-8 flex justify-end">
                <Button
                  disabled={editMeCommand.isLoading}
                  type={ButtonType.Clear}
                  onClick={() => onClose()}
                >
                  <span className="text-gray-400">Cancel</span>
                </Button>
                <Button
                  isSubmit
                  loading={editMeCommand.isLoading}
                  testId="button-submit"
                  disabled={!isValid}
                >
                  Confirm
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default CreatePasswordFlow;
