import styles from './SelectFriend.module.scss';

import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import React, { useCallback, useState } from 'react';

import { useUsersSearch } from 'legacy/search/hooks';

import { ModalInput } from '../ModalUtils';
import ModalBase from '../ModalBase/ModalBase';
import Spinner from '../../util/Spinner/Spinner';

function User({ user, onClick }) {
  return (
    <div className={styles.user} onClick={onClick}>
      <div className="pic">
        {user.photo ? (
          <img src={user.photo.url} alt="" />
        ) : (
          <i className="fas fa-user-alt" />
        )}
      </div>
      <div className="name">{`${user.firstName} ${user.lastName}`}</div>
    </div>
  );
}

function SelectFriend({ friends, onSelect, baseModalProps, message, loading }) {
  const [search, setSearch] = useState('');
  const [debouncedInput] = useDebounce(search, 200);

  const { data: searchedUsers, isLoading } = useUsersSearch(debouncedInput);

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  const shownUsers = debouncedInput ? searchedUsers : friends;

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
    >
      <div className={styles.wrapper}>
        <div className="search">
          <div className="icon">
            <i className="fas fa-search" />
          </div>
          <ModalInput
            name="search"
            value={search}
            onChange={handleChange}
            placeholder="Search users..."
          />
        </div>

        {!(loading || isLoading) && (
          <div className="message">
            {shownUsers?.length > 0 ? message : 'No Users Available.'}
          </div>
        )}

        <div className="friends">
          {loading || isLoading ? (
            <div className={styles.loading}>
              <Spinner width={60} height={60} />
            </div>
          ) : (
            shownUsers.map((u) => (
              <User
                key={u._id}
                user={u}
                onClick={() => {
                  onSelect(u);
                }}
              />
            ))
          )}
        </div>
      </div>
    </ModalBase>
  );
}

function mapStateToProps(state) {
  return {
    friends: state.friends.friends,
    loading: state.friends.loading.GET_FRIENDS
  };
}

export default connect(mapStateToProps)(SelectFriend);
