import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class CheckboxField extends React.Component {
  render() {
    const { validate, name, form, text, initial } = this.props;

    return (
      <Field
        initialValue={initial}
        validate={validate}
        name={name}
        render={({ input }) => (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => form.change(input.name, !input.value)}
          >
            <i
              className={classnames(
                'fab',
                {
                  'far fa-square': !input.value
                },
                {
                  'fas fa-check-square': input.value
                }
              )}
              data-cy="checkbox-field"
            />
            {text && (
              <span className="text" style={{ marginLeft: '5px' }}>
                {text}
              </span>
            )}
          </div>
        )}
      />
    );
  }
}

CheckboxField.propTypes = {
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  text: PropTypes.string,
  initial: PropTypes.bool
};

export default CheckboxField;
