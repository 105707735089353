import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';

dayjs.extend(advancedFormat);
dayjs.extend(relativeTimePlugin);

export function format(
  date: string | number | Date | dayjs.Dayjs,
  format = 'MMM D, YYYY | h:mma'
) {
  if (!date) {
    return '';
  }

  return dayjs(date).format(format);
}

export function fromNow(date: string | number | Date | dayjs.Dayjs) {
  if (!date) {
    return '';
  }

  return dayjs(date).fromNow();
}

export function isSame(
  date: string | number | Date | dayjs.Dayjs,
  anotherDate: string | number | Date | dayjs.Dayjs,
  type: dayjs.OpUnitType
) {
  if (!date) {
    return false;
  }

  return dayjs(date).isSame(anotherDate, type);
}

export function isBefore(
  date: string | number | Date | dayjs.Dayjs,
  anotherDate: string | number | Date | dayjs.Dayjs,
  type: dayjs.OpUnitType
) {
  if (!date) {
    return false;
  }

  return dayjs(date).isBefore(anotherDate, type);
}

export function toISOString(date: string | number | Date | dayjs.Dayjs) {
  if (!date) {
    return '';
  }

  return dayjs(date).toISOString();
}

export function set(
  date: string | number | Date | dayjs.Dayjs,
  {
    hour = 0,
    minute = 0,
    second = 0
  }: {
    hour?: number;
    minute?: number;
    second?: number;
  }
) {
  return dayjs(date).hour(hour).minute(minute).second(second);
}
