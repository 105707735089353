import React, { useEffect } from 'react';
import BounceButton from '../BounceButton/BounceButton';
import { connect } from 'react-redux';
import { showModal, modalBack, hideModal } from 'legacy/actions/modalActions';

const SubmitButton = ({
  buttonText,
  loading,
  errorContent,
  errorActionText,
  errorOpen,
  disabled,
  type,
  onSuccess,
  showModal,
  modalBack,
  hideModal,
  className
}) => {
  useEffect(() => {
    if (!loading && errorOpen && errorContent) {
      showModal('ALERT', {
        message: errorContent,
        actionText: errorActionText || 'Go back',
        onSuccess: onSuccess || modalBack,
        onBack: hideModal,
        back_img: 'xBlock'
      });
    }
  }, [errorOpen, errorContent, loading]);

  return (
    <BounceButton
      loading={loading}
      disabled={disabled}
      primary={!type || type === 'primary'}
      secondary={type === 'secondary'}
      plain={type === 'plain'}
      type="submit"
      className={className || 'submit'}
    >
      {buttonText}
    </BounceButton>
  );
};

export default connect(null, { showModal, modalBack, hideModal })(SubmitButton);
