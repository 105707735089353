import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import classnames from 'classnames';
import styles from './FormFields.module.scss';

function CollapsibleField({
  label,
  labelComponent,
  disabled,
  validate,
  name,
  step,
  onKeyDown,
  nullValue,
  inputProps,
  parse,
  format,
  formatOnBlur,
  formProps
}) {
  const { values, form } = formProps;
  const handleToggle = (meta) => {
    const open = meta.active || values[name] !== nullValue;

    if (name === 'price') {
      form.change(name, 0);
    } else if (open) {
      form.change(name, nullValue);
      form.blur(name);
    } else {
      form.focus(name);
      requestAnimationFrame(() => {
        // Setting numeric field focus without requestAnimationFrame
        // doesn't work for some reason.
        document.querySelector(`input[name=${name}]`).focus();
      });
    }
  };

  const labelContent = labelComponent || (label && <div>{label}</div>);

  return (
    <Field
      parse={parse}
      format={format}
      formatOnBlur={formatOnBlur}
      validate={validate}
      name={name}
      render={({ input, meta }) => {
        const showInput = meta.active || values[input.name] !== nullValue;
        return (
          <div className={styles.fieldContainer}>
            <div
              className={classnames(styles.field, {
                [styles.hasError]: meta.error !== undefined && meta.touched
              })}
            >
              {labelContent}
              <Input
                className={classnames(styles.input, {
                  [styles.collapsed]: !showInput,
                  [styles.disabled]: disabled
                })}
                type="number"
                min="0"
                step={step}
                onKeyDown={onKeyDown}
                iconPosition="left"
                disabled={disabled}
                {...input}
                {...inputProps}
              />
              {!showInput && <div className={styles.divider} />}
              {!disabled && (
                <div
                  className={styles.addRemove}
                  onClick={() => handleToggle(meta)}
                >
                  {showInput ? (
                    <i className="fas fa-times" />
                  ) : (
                    <i className="fas fa-plus" />
                  )}
                </div>
              )}
            </div>
            {meta.error !== undefined && meta.touched && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        );
      }}
    />
  );
}

CollapsibleField.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  nullValue: 0
  // parse: s => Number(s),
  // format: n => String(n)
};

CollapsibleField.propTypes = {
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  nullValue: PropTypes.string.isRequired
};

export default CollapsibleField;
