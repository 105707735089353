import styles from './Info.module.scss';

type InfoProps = {
  message: string;
};

const Info: React.FC<InfoProps> = ({ message }) => (
  <div className={styles.container}>
    <img src="/img/checkout/money.svg" />
    {message}
  </div>
);

export default Info;
