import { useMemo } from 'react';
import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { createLogger } from 'redux-logger'; // logs actions very nicely
import thunk from 'redux-thunk';
import createReduxPromiseListener from 'redux-promise-listener';
import userReducer from './reducers/userReducer';
import eventReducer from './reducers/eventReducer';
import paymentReducer from './reducers/paymentReducer';
import connectReducer from './reducers/connectReducer';
import hostReducer from './reducers/hostReducer';
import friendReducer from './reducers/friendReducer';
import p2pReducer from './reducers/p2pReducer';
import notificationReducer from './reducers/notificationReducer';
import appReducer from './reducers/appReducer';
import searchReducer from './reducers/searchReducer';
import modalReducer from './reducers/modalReducer';
import serviceReducer from './reducers/serviceReducer';
import orgReducer from './reducers/orgReducer';

const reduxPromiseListener = createReduxPromiseListener();

const ignoreList = [];
const logger = createLogger({
  collapsed: true,
  diff: true,
  duration: false,
  predicate: (getState, action) =>
    // skip logging if running cypress tests, full our test logs up with a ton of redundant stuff and makes it hard to debug failures

    // if any actions get fired related to an action in ignoreList, we dont log it
    !window.Cypress &&
    process.env.NODE_ENV !== 'test' &&
    !ignoreList.some((rootType) => action.type.includes(rootType))
});

let middleware = [thunk, reduxPromiseListener.middleware];

// dont run redux logger in production
if (process.env.NEXT_PUBLIC_BUILD_ENV !== 'production') {
  middleware = [...middleware, logger];
}
middleware = applyMiddleware(...middleware);

export const initialStoreState = {
  user: userReducer.initialState,
  events: eventReducer.initialState,
  payment: paymentReducer.initialState,
  connect: connectReducer.initialState,
  host: hostReducer.initialState,
  friends: friendReducer.initialState,
  p2p: p2pReducer.initialState,
  notifications: notificationReducer.initialState,
  app: appReducer.initialState,
  search: searchReducer.initialState,
  modal: modalReducer.initialState,
  service: serviceReducer.initialState,
  orgs: orgReducer.initialState
};

export const reducers = combineReducers({
  user: userReducer.reducer,
  events: eventReducer.reducer,
  payment: paymentReducer.reducer,
  connect: connectReducer.reducer,
  host: hostReducer.reducer,
  friends: friendReducer.reducer,
  p2p: p2pReducer.reducer,
  notifications: notificationReducer.reducer,
  app: appReducer.reducer,
  search: searchReducer.reducer,
  modal: modalReducer.reducer,
  service: serviceReducer.reducer,
  orgs: orgReducer.reducer
});

export const promiseListener = reduxPromiseListener;

// TODO: dont epxort as `let`
// eslint-disable-next-line import/no-mutable-exports
export let store;

const initStore = (initialState) =>
  createStore(reducers, initialState, compose(middleware));

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store;
  }
  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
};

export const useStore = (initialState) => {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
};
