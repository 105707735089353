import moment from 'moment';

interface Option {
  title: string;
  description?: string;
  startDate: Date;
  endDate: Date;
  location: string;
}
export function toCalendarEvent(option: Option) {
  const startDateMoment = moment(option.startDate);

  // Set endDateMoment to end of day if event has no scheduled end date
  // Moment does not return the correct object when chaining promises - we have to clone the startDate
  let endDateMoment = startDateMoment.clone();
  endDateMoment.add(1, 'days').startOf('day');

  if (option.endDate) {
    endDateMoment = moment(option.endDate);
  }

  const calendarEvent = {
    title: option.title,
    description: option.description || '',
    location: option.location,
    startTime: startDateMoment.format(),
    endTime: endDateMoment.format()
  };

  return calendarEvent;
}

export const hasPurchasedAdditionalTickets = ({
  isPurchaser,
  numberOfTickets
}: {
  isPurchaser: boolean;
  numberOfTickets: number;
}) => numberOfTickets > 1 || (!isPurchaser && numberOfTickets > 0); // NOTE: Accounts for the case where the ticket claimer purchased tickets for others

/**
 * Returns the total number of additional tickets based on whether or not we are in the context of the purchaser.
 * For purchaser tickets we subtract 1 from the total number of tickets, because the purchaser is included in the total.
 */
export const getTotalAdditionalTickets = ({
  isPurchaser,
  totalPurchased
}: {
  isPurchaser: boolean;
  totalPurchased: number;
}) => (isPurchaser ? totalPurchased - 1 : totalPurchased);
