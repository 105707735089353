import _ from 'lodash';
import { types, actionIDs } from 'legacy/actions/hostActions';
import { types as userTypes } from 'legacy/actions/userActions';
import { toActionID } from '../util/util';

export const initialState = {
  codes: {},
  availableOperators: {},
  availableCohosts: {},
  fbEvents: [],
  fbEventDetails: {},
  lastCreated: null,
  validated: false,
  loading: _.mapValues(types, () => false),
  errors: _.mapValues(types, () => ({})),
  attempted: _.mapValues(types, () => false)
};

// Allow sister types from other actions
const SISTER_TYPES = [userTypes.LOGOUT.success];

export function hostReducer(state = initialState, action) {
  const actionID = toActionID(action.type);

  // Break out early if not a relevant action type
  if (!actionIDs.includes(actionID) && !SISTER_TYPES.includes(action.type)) {
    return state;
  }
  const newState = _.cloneDeep(state);
  if (actionIDs.includes(actionID)) {
    newState.errors[actionID] = {};
    newState.loading[actionID] = false;
    if (action.type.endsWith('_SUCCESS') || action.type.endsWith('_ERROR')) {
      newState.attempted[actionID] = true;
    }
  }
  switch (action.type) {
    case userTypes.LOGOUT.success:
      return initialState;
    case types.GET_AVAILABLE_OPERATORS.success:
      newState.availableOperators[action.payload.eventId] =
        action.payload.operators;
      return newState;
    case types.GET_AVAILABLE_COHOSTS.success:
      newState.availableCohosts[action.payload.eventId] =
        action.payload.cohosts;
      return newState;
    case types.CREATE_EVENT.success:
      newState.lastCreated = action.payload._id;
      newState.fbEventDetails = {};
      return newState;
    case types.GET_HOSTED_FB_EVENTS.success:
      return {
        ...newState,
        fbEvents: action.payload
      };
    case types.GET_FB_EVENT_DETAILS.success:
      return {
        ...newState,
        fbEventDetails: action.payload
      };
    case types.RETURN_TO_IMPORT.success:
      return {
        ...newState,
        fbEventDetails: {}
      };
    case types.SCAN_TICKET.start:
      newState.loading[actionID] = true;
      newState.validated = false;
      return newState;
    case types.SCAN_TICKET.success:
      newState.validated = true;
      return newState;
    case types.VERIFY_EVENT_CODE.success:
      newState.codes[action.code] = action.valid;
      return newState;
    // case types.SCAN_TICKET.error:
    //     newState.validated[action.payload.uid] = action.payload;
    //     return newState;
    default:
      // Default case, set error field on ERROR action types, set loading
      // field on START types
      if (action.type.endsWith('START')) {
        newState.loading[actionID] = true;
      } else if (action.type.endsWith('ERROR')) {
        newState.errors[actionID] = action.payload.error;
      }
      return newState;
  }
}

export default { initialState, reducer: hostReducer };
