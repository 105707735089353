import { useEffect, Fragment } from 'react';
import styles from './AddBank.module.scss';
import PlaidLink from 'react-plaid-link';
import ModalBase from '../ModalBase/ModalBase';
import { connect } from 'react-redux';
import {
  addPlaidBank,
  addBank,
  types as connectTypes
} from 'legacy/actions/connectActions';
import { showModal } from 'legacy/actions/modalActions';
import { Popup } from 'semantic-ui-react';
import BounceButton from '../../util/BounceButton/BounceButton';
import { usePrevious } from '../../../util/hooks';
import config from '../../../../config';

import {
  ValidatedInput,
  withForm,
  SubmitButton,
  CheckboxField
} from '../../util/FormHelpers';
import { Field } from 'react-final-form';
import {
  CURRENCY_OPTIONS,
  COUNTRY_OPTIONS,
  SUPPORTED_COUNTRY_OPTIONS
} from '../../../util/constants';
import classnames from 'classnames';
import {
  isRequired,
  composeValidators,
  isValidZip
} from '../../../util/validators';

import { getMyProfile } from 'legacy/actions/userActions';

function AddBank({
  banks,
  baseModalProps,
  onSuccess,
  addPlaidBank,
  showModal,
  loadingPlaid,
  loadingManual,
  errors,
  device,
  addBank,
  isOpen,
  formProps,
  org,
  orgs,
  onBack,
  getMyProfile,
  close,
  user
}) {
  // We were successful if our number of banks increased
  const numBanks = banks.length;
  const prevNumBanks = usePrevious(numBanks);
  const numOrgBanks = orgs[org]?.banks?.length;
  const prevNumOrgBanks = usePrevious(orgs[org]?.banks?.length);
  useEffect(() => {
    if (numBanks > prevNumBanks || numOrgBanks > prevNumOrgBanks) {
      onSuccess();
      close();
    }
  }, [numBanks, prevNumBanks, numOrgBanks, prevNumOrgBanks]);

  const handlePlaidSuccess = (token, meta) =>
    addPlaidBank(token, meta.account_id, org);

  const sendToken = (formData) => {
    const countryName = COUNTRY_OPTIONS.find(
      (e) => e.value === formData.country
    ).text;
    const currency = CURRENCY_OPTIONS.find(
      (e) => e.country === countryName
    ).currencyCode;

    addBank(
      {
        country: formData.country,
        currency,
        routing_number:
          formData.routing_number ||
          `${formData.transit_number.trim()}-${formData.institution_number.trim()}`,
        account_number: formData.account_number.trim(),
        account_holder_name: formData.account_holder_name,
        account_holder_type: 'individual',
        defaultBank: formData.defaultBank
      },
      org,
      () => {
        onSuccess();

        setTimeout(() => {
          getMyProfile();
        }, 3000);
      }
    );
  };

  const handleSubmit = (e, formProps) => {
    e.preventDefault();
    formProps.handleSubmit();
    if (formProps.valid || formProps.dirtySinceLastSubmit) {
      sendToken(formProps.values);
    }
  };

  const findBankNumbers = () => {
    showModal('FIND_BANK_NUMBERS');
  };

  // don't show input titles when on mobile (takes too much space)
  // TODO: we need to handle USA routing numbers a bit differently than Canadian ones
  const accountDetails =
    device !== 'mobile' ? (
      <>
        <p>Account Details</p>
        <div className="row">
          <ValidatedInput
            labelComponent={
              <div className="inputTitleBox">
                <div className="inputTitle">Account Number</div>
              </div>
            }
            errorVisible={isOpen}
            validate={isRequired}
            type="tel"
            name="account_number"
            placeholder="1234567"
            className="inputWrapper withTitle"
            inputProps={{
              maxLength: '16',
              pattern: '[0-9]+'
            }}
          />
        </div>
        {formProps.values.country === 'US' ? (
          <div className="row">
            <ValidatedInput
              labelComponent={
                <div className="inputTitleBox">
                  <div className="inputTitle">Routing Number</div>
                </div>
              }
              errorVisible={isOpen}
              validate={isRequired}
              type="tel"
              name="routing_number"
              placeholder="123456789"
              className="inputWrapper withTitle"
              errorProps={{
                position: 'bottom center'
              }}
              inputProps={{
                maxLength: '9',
                pattern: '[0-9]+'
              }}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <ValidatedInput
                labelComponent={
                  <div className="inputTitleBox">
                    <div className="inputTitle">Transit Number</div>
                  </div>
                }
                errorVisible={isOpen}
                validate={isRequired}
                type="tel"
                name="transit_number"
                placeholder="12345"
                className="inputWrapper withTitle"
                errorProps={{
                  position: 'bottom center'
                }}
                inputProps={{
                  maxLength: '5',
                  pattern: '[0-9]+'
                }}
              />
            </div>
            <div className="col">
              <ValidatedInput
                labelComponent={
                  <div className="inputTitleBox">
                    <div className="inputTitle">Institution Number</div>
                  </div>
                }
                errorVisible={isOpen}
                validate={isRequired}
                type="tel"
                name="institution_number"
                placeholder="001"
                className="inputWrapper withTitle"
                inputProps={{
                  maxLength: '3',
                  pattern: '[0-9]+'
                }}
              />
            </div>
          </div>
        )}
      </>
    ) : (
      <>
        <p>Account Details</p>
        <div className="row">
          <ValidatedInput
            errorVisible={isOpen}
            validate={isRequired}
            type="tel"
            name="account_number"
            placeholder="Account Number"
            className="inputWrapper withTitle"
            inputProps={{
              maxLength: '16',
              pattern: '[0-9]+'
            }}
          />
        </div>

        {formProps.values.country === 'US' ? (
          <div className="row">
            <div className="col">
              <ValidatedInput
                errorVisible={isOpen}
                validate={isRequired}
                type="tel"
                name="routing_number"
                placeholder="Routing Number"
                className="inputWrapper withTitle"
                errorProps={{
                  position: 'bottom center'
                }}
                inputProps={{
                  maxLength: '9',
                  pattern: '[0-9]+'
                }}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <ValidatedInput
                errorVisible={isOpen}
                validate={isRequired}
                type="tel"
                name="transit_number"
                placeholder="Transit Number"
                className="inputWrapper withTitle"
                errorProps={{
                  position: 'bottom center'
                }}
                inputProps={{
                  maxLength: '5',
                  pattern: '[0-9]+'
                }}
              />
            </div>
            <div className="col">
              <ValidatedInput
                errorVisible={isOpen}
                validate={isRequired}
                type="tel"
                name="institution_number"
                placeholder="Institution Number"
                className="inputWrapper withTitle"
                inputProps={{
                  maxLength: '3',
                  pattern: '[0-9]+'
                }}
              />
            </div>
          </div>
        )}
      </>
    );

  return (
    <ModalBase
      baseModalProps={baseModalProps}
      back_img="Back"
      contentClassName="basic"
      large
      // only passed if opening in a react native webview
      onBack={onBack}
    >
      <div
        className={classnames(styles.AddBank, device, 'component')}
        data-cy="modal-bank-verification"
      >
        <h2 className="modalTitle">Add Bank Account (Optional)</h2>
        {device !== 'mobile' && (
          <>
            <Popup
              open={Boolean(errors.ADD_PLAID_BANK.message && !loadingPlaid)}
              position="right center"
              trigger={
                <BounceButton
                  primary
                  className={styles.plaidButton}
                  loading={loadingPlaid}
                  as="div"
                  role="button"
                  tabindex="0"
                >
                  Link Your Bank
                  <PlaidLink
                    clientName="Bounce"
                    env={config.PLAID_ENV}
                    publicKey={config.PLAID_PUBLIC_KEY}
                    product={['auth']}
                    countryCodes={[user.country]}
                    language="en"
                    apiVersion="v2"
                    onSuccess={handlePlaidSuccess}
                  />
                </BounceButton>
              }
            >
              {errors.ADD_PLAID_BANK.message}
            </Popup>
            <div className="or">
              <span />
              <div>Or Add Manually</div>
              <span />
            </div>
          </>
        )}
        <form
          className={styles.addBankForm}
          onSubmit={(e) => handleSubmit(e, formProps)}
        >
          {accountDetails}

          <p className="name">Account Holder Info</p>
          <div className="row">
            <ValidatedInput
              errorVisible={isOpen}
              validate={isRequired}
              type="text"
              name="account_holder_name"
              placeholder="Name on Bank Account"
              className="inputWrapper"
            />
          </div>
          <div className="row">
            <div className="col">
              <Field
                initialValue={user.country}
                name="country"
                component="select"
                className="ui selection dropdown"
                autoComplete="shipping-region"
                style={{
                  width: '100%',
                  height: '40px'
                }}
              >
                {SUPPORTED_COUNTRY_OPTIONS.map((p) => (
                  <option key={p} value={p.value}>
                    {p.text}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col">
              <ValidatedInput
                errorVisible={isOpen}
                validate={composeValidators(isRequired, isValidZip)}
                type="text"
                name="postalCode"
                placeholder="Zip/Postal Code"
                autoComplete="shipping postal-code"
                className="inputWrapper"
              />
            </div>
          </div>
          <div className="row defaultBank">
            <div className="col">
              <CheckboxField
                initial
                name="defaultBank"
                form={formProps.form}
                text="Set as Default"
              />
            </div>
            <div className="col">
              <div className="findBankNumbersButton" onClick={findBankNumbers}>
                Where do I find these numbers?
              </div>
            </div>
          </div>
          <SubmitButton
            type="secondary"
            errorOpen={
              formProps.hasSubmitErrors &&
              !formProps.dirtySinceLastSubmit &&
              !formProps.submitting
            }
            buttonText="ADD BANK INFO"
            errorContent={
              formProps.hasSubmitErrors
                ? formProps.submitErrors.FORM_ERROR
                : null
            }
            loading={loadingManual}
            // disabled={
            //     !formProps.valid && !formProps.dirtySinceLastSubmit
            // }
          />
        </form>
      </div>
    </ModalBase>
  );
}

const actions = {
  showModal,
  addPlaidBank,
  addBank,
  getMyProfile
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  orgs: state.orgs.orgs,
  banks: state.connect.banks,
  errors: state.connect.errors,
  loadingPlaid: state.connect.loading.ADD_PLAID_BANK,
  loadingManual:
    state.connect.loading.ADD_BANK_MANUALLY || !state.app.stripeHasLoaded
  // loading: state.connect.loading.ADD_BANK_MANUALLY || !state.app.stripeHasLoaded
});

export default connect(
  mapStateToProps,
  actions
)(withForm(connectTypes.ADD_BANK_MANUALLY_FORM)(AddBank));
